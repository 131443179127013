import * as React from "react";
import Badge from "@mui/material/Badge";
import {ReactElement, ReactNode} from "react";

interface IFlagBadgeProps {
    flag: ReactElement;
    children: ReactNode;
}

export const FlagBadge = ({flag, children}: IFlagBadgeProps) => (
    <Badge badgeContent={<div style={{fontSize: '180%'}}>{flag}</div>} overlap="rectangular">
        {children}
    </Badge>
);
