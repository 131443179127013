import * as React from "react";
import {Box} from "@mui/material";
import {
    PieChart as MuiPieChart,
    pieArcLabelClasses,
    legendClasses,
} from "@mui/x-charts";
import {CardinalDirections} from "@mui/x-charts/models/layout";

interface IPieChartProps {
    dataset: {value: any; label: string;}[];
    size: number;
    startAngle?: number;
    margin?: CardinalDirections<number>;
    valueFormatter(v: {value: any}): string;
}

export const PieChart = ({dataset, size, startAngle = -26, margin = {}, valueFormatter}: IPieChartProps) => {
    const TOTAL = dataset.map((item) => item.value).reduce((a, b) => a + b, 0);

    // const LEGEND_CHARS = dataset
    //     .map(item => item.label.length)
    //     .reduce((acc, v) => acc + v, 0);
    // // - mark
    // // - label
    // // - spacing
    // const LEGEND_SIZE = ((15 * dataset.length) + (LEGEND_CHARS * 10) + (17 * dataset.length - 1));

    const getArcLabel = ({value}: {value: number}) => {
        const percent = value / TOTAL;
        return `${(percent * 100).toFixed(0)}%`;
    };

    return (
        <Box sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <MuiPieChart
                series={[
                    {
                        data: dataset,
                        innerRadius: 20,
                        paddingAngle: 3,
                        cornerRadius: 5,
                        startAngle: startAngle,
                        arcLabel: getArcLabel,
                        valueFormatter: valueFormatter,
                        highlightScope: {
                            fade: 'global',
                            highlight: 'item',
                        },
                        faded: {
                            innerRadius: 10,
                            additionalRadius: -40,
                            color: 'grey',
                        },
                    },
                ]}
                slotProps={{
                    legend: {
                        // hidden: true,
                        direction: 'row',
                        position: { vertical: 'bottom', horizontal: 'middle' },
                        padding: 0,
                        itemMarkWidth: 10,
                        itemMarkHeight: 10,
                        markGap: 5,
                        itemGap: 15,
                    },
                }}
                // margin={{right: 5}}
                margin={{ top: 0, bottom: 40, left: 0, right: 0, ...margin }}
                // width={size}
                height={size}
                sx={{
                    '&&': {
                        touchAction: 'auto',
                    },
                    [`& .${pieArcLabelClasses.root}`]: {
                        fill: 'white',
                        fontSize: 14,
                    },
                    [`& .${legendClasses.mark}`]: {
                        ry: 10,
                    },
                }}
            />
        </Box>
    );
};
