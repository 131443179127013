import * as React from "react";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {FormModal} from "../../../Components/FormModal";
import {SnackbarStore} from "../../../../stores/snackbar";
import { IBudget, IBudgetRequest } from "../../../../config/budget";
import { updateBudget } from "../../../../stores/budgets";
import { BudgetForm } from "../Form/BudgetForm";

interface IEditBudgetProps {
    budget: IBudget;
    open: boolean;
    onClose(): void;
}

export const EditBudget = ({budget, open, onClose}: IEditBudgetProps) => {
    const { budgetStore, snackbarStore } = useGlobalStores();

    const handleSubmit = (data: IBudgetRequest) => {
        return updateBudget(budget.id, data)
            .then(budget => {
                snackbarStore.open(SnackbarStore.SUCCESS, 'Budget updated successfully!');
                budgetStore.replaceBudget(budget);
                onClose();
                return Promise.resolve();
            })
            .catch(reason => {
                snackbarStore.open(SnackbarStore.FAIL);
                return Promise.reject(reason);
            });
    };

    return (
        <FormModal title="Edit budget" open={open} loading={false} onClose={onClose}>
            <BudgetForm budget={budget} onSubmit={handleSubmit} />
        </FormModal>
    );
};
