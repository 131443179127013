import * as React from "react";
import {observer} from "mobx-react";
import {useEffect, useState} from "react";
import {useDashboardStores} from "../../../hooks/use_dashboard_stores";
import {PageLoader} from "../../Components/PageLoader";
import Error from "../../Components/Error";
import {reaction} from "mobx";
import {fetchTransactionsStats} from "../../../stores/dashboard";
import {DashboardItemsContainer} from "../Components/DashboardItemsContainer";
import {DashboardItem} from "../Components/DashboardItem";
import {TotalChart} from "../Charts/TotalChart";
import {DaysChart} from "../Charts/DaysChart";
import {DaysDistributionChart} from "../Charts/DaysDistributionChart";
import {WeeksChart} from "../Charts/WeeksChart";
import {CategoriesChart} from "../Charts/CategoriesChart";
import {LocationsChart} from "../Charts/LocationsChart";
import {CurrenciesChart} from "../Charts/CurrenciesChart";
import {PaymentMethodsChart} from "../Charts/PaymentMethodsChart";
import {NotDefaultSearchWarning} from "../../Transactions/Components/Search/Components/NotDefaultSearchWarning";
import {CategoriesDetails} from "../CategoriesDetails/CategoriesDetails";
import { NoResults } from "../../Components/NoResults";
import { Today } from "@mui/icons-material";

export const DashboardCharts = observer(() => {
    const { dashboardStore, refreshStore, searchStore } = useDashboardStores();

    const [loading, setLoading] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false);

    const loadTransactionsStats = () => {
        setLoading(true);

        const query = searchStore.queryRequest;

        fetchTransactionsStats(query)
            .then(stats => {
                dashboardStore.stats = stats;

                setLoading(false);
                setIsError(false);
            })
            .catch(error => {
                setLoading(false);
                setIsError(true);
            });
    }

    useEffect(() => {
        const queryReactionDisposer = reaction(
            () => refreshStore.dashboard,
            () => loadTransactionsStats(),
        );

        if (!!dashboardStore.stats) {
            return;
        }

        loadTransactionsStats();

        return () => {
            !!queryReactionDisposer && queryReactionDisposer();
        }
    }, []);

    if (loading) {
        return (
            <PageLoader />
        );
    }

    if (isError) {
        return (
            <Error />
        );
    }

    const stats = dashboardStore.stats;
    if (!stats) {
        return null;
    }

    if (stats.total <= 0) {
        return (
            <NoResults
                icon={<Today />}
                text="You haven't added any transaction yet :("
                onClick={() => {
                    (window.document.getElementsByClassName('new-transaction-button')[0] as any).click();
                }}
            />
        )
    }

    return (
        <DashboardItemsContainer>
            {!searchStore.isDefault && <NotDefaultSearchWarning />}

            <DashboardItem title="Total" fullWidth>
                <TotalChart total={stats.total} size="large" />
            </DashboardItem>

            <DashboardItem title="Transactions per day" fullWidth hasDetails detailsTarget="/transactions" detailsLabel="View list">
                <DaysChart days={stats.days} chartHeight={400} />
            </DashboardItem>

            <DashboardItem title="Categories" hasDetails detailsAction={<>
                <CategoriesDetails categories={stats.main_categories} label="Main Categories"/>
                {" | "}
                <CategoriesDetails categories={stats.categories} />
            </>}>
                <CategoriesChart categories={stats.main_categories} chartHeight={400} showTopCategories={5} />
            </DashboardItem>
            <DashboardItem title="Top locations" hasDetails detailsTarget="/dashboard/locations">
                <LocationsChart locations={stats.locations} showTopCompanies={5} chartHeight={400} />
            </DashboardItem>

            <DashboardItem title="Payment methods">
                <PaymentMethodsChart paymentMethods={stats.payment_methods} chartHeight={400} />
            </DashboardItem>
            <DashboardItem title="Currencies">
                <CurrenciesChart currencies={stats.currencies as any} chartHeight={400} />
            </DashboardItem>

            <DashboardItem title="Days distribution">
                <DaysDistributionChart days={stats.days_of_the_week} chartHeight={400} />
            </DashboardItem>
            <DashboardItem title="Weeks">
                <WeeksChart weeks={stats.weeks} chartHeight={400} />
            </DashboardItem>
        </DashboardItemsContainer>
    );
});
