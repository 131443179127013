import * as React from "react";
import {GridColDef, GridValueFormatterParams} from "@mui/x-data-grid";
import {Currency} from "../../../../../config/currencies";
import moment from "moment";
import {GridValueGetterParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {TransactionsListAction, TransactionsListField} from "./config";
import {getActionsColumnWidth} from "./helpers";
import {ViewAction} from "../../Actions/ViewAction";
import {ITransaction} from "../../../../../config/transactions";
import {DeleteAction} from "../../Actions/DeleteAction";
import {EditAction} from "../../Actions/EditAction";
import {GridDeleteAction} from "../GridDeleteAction";
import {DescriptionField} from "../Fields/DescriptionField";
import {AmountField} from "../Fields/AmountField";
import {GridEditAction} from "../GridEditAction";
import {ILocation} from "../../../../../config/location";
import { DescriptiveField } from "../Fields/DescriptiveField";

const defaultFields: TransactionsListField[] = [
    TransactionsListField.ACTIONS,
    TransactionsListField.PURCHASED_AT,
    TransactionsListField.AMOUNT,
    TransactionsListField.PAYMENT_METHOD,
    TransactionsListField.MAIN_CATEGORY,
    TransactionsListField.CATEGORY,
    TransactionsListField.LOCATION,
    TransactionsListField.DESCRIPTION,
    TransactionsListField.TRANSACTION_ID,
];

const defaultActions: TransactionsListAction[] = [
    TransactionsListAction.VIEW,
    TransactionsListAction.EDIT,
    TransactionsListAction.DELETE,
];

export const columns = (currency: Currency, onDelete: any, fields?: TransactionsListField[], actions?: TransactionsListAction[], isCheckboxSelectionVisible?: boolean): GridColDef[] => {
    const acts = (transaction: ITransaction, onDelete: any) => ({
        [TransactionsListAction.VIEW]: <ViewAction transaction={transaction} />,
        [TransactionsListAction.EDIT]: <EditAction
            transaction={transaction}
            component={GridEditAction}
        />,
        [TransactionsListAction.DELETE]: <DeleteAction
            transaction={transaction}
            onDelete={onDelete}
            component={GridDeleteAction}
        />,
    } as any);

    const cols = {
        [TransactionsListField.ACTIONS]: {
            field: 'actions',
            headerName: 'Actions',
            type: 'actions',
            width: getActionsColumnWidth(actions),
            getActions: (params: any) => {
                const v = acts(params.row, onDelete);
                return (actions || defaultActions).map(a => v[a]);
            },
        },
        [TransactionsListField.PURCHASED_AT]: {
            field: 'purchased_at',
            headerName: 'Date',
            width: 150,
            type: 'date',
            valueGetter: (params: GridValueGetterParams<moment.Moment>): Date => {
                return params.value.toDate();
            },
            valueFormatter: (params: GridValueFormatterParams): string => {
                return moment(params.value).format('MMMM Do YYYY');
            },
        },
        [TransactionsListField.AMOUNT]: {
            field: 'amount',
            headerName: 'Amount',
            width: 105,
            renderCell: (params: GridValueGetterParams) => (
                <AmountField transaction={params.row} currency={currency} />
            ),
        },
        [TransactionsListField.PAYMENT_METHOD]: {
            field: 'payment_method',
            headerName: 'Payment Method',
            width: 200,
        },
        [TransactionsListField.MAIN_CATEGORY]: {
            field: 'main_category',
            headerName: 'Main Category',
            width: 155,
        },
        [TransactionsListField.CATEGORY]: {
            field: 'category',
            headerName: 'Category',
            width: 155,
        },
        [TransactionsListField.LOCATION]: {
            field: 'location',
            headerName: 'Location',
            width: 200,
            valueGetter: (params: GridValueGetterParams<ILocation>): Date => {
                return params.value?.name || '';
            },
        },
        [TransactionsListField.DESCRIPTION]: {
            field: 'description',
            headerName: 'Description',
            maxWidth: 300,
            renderCell: (params: GridValueGetterParams) => (
                <DescriptionField transaction={params.row} />
            ),
        },
        [TransactionsListField.TRANSACTION_ID]: {
            field: 'transaction_id',
            headerName: 'Transaction ID',
            width: 300,
        },
        [TransactionsListField.DESCRIPTIVE_TRANSACTION]: {
            field: 'description',
            headerName: 'Description',
            flex: 1,
            renderCell: (params: GridValueGetterParams) => (
                <DescriptiveField transaction={params.row} currency={currency} selectable={!!isCheckboxSelectionVisible} />
            ),
        },
    } as { [key in TransactionsListField]: GridColDef};

    return (fields || defaultFields).filter(f => {
        if (f === TransactionsListField.ACTIONS && actions?.length === 1 && actions.indexOf(TransactionsListAction.SELECT) > -1) {
            return false;
        }
        return true;
    }).map(f => cols[f]);
};
