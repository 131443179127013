import * as React from "react";
import { IAttachment } from "../../../../../config/attachments";
import { Section } from "../Components/Section";
import { Icon } from "../../../Components/Form/Fields/Attachments/Icon";

interface IAttachmentsProps {
    attachments: IAttachment[];
}

export const Attachments = ({attachments}: IAttachmentsProps) => {
    const openAttachment = (filePath: string) => () => {
        window.open(filePath, '_blank', 'noreferrer');
    };

    return (
        <Section title="Attachments" sections={attachments.map((attachment, index) => (
            {
                title: 'File',
                value: `Attachment #${attachment.name || index + 1}`,
                icon: <Icon type={attachment.type} />,
                onClick: openAttachment(attachment.full_path),
            }
        ))} />
    );
};
