import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';

const debug = process.env.NODE_ENV === 'development';

export enum Language {
    ENGLISH = 'en',
    ITALIAN = 'it',
}
export const availableLanguages = [
    {
        code: Language.ENGLISH,
        label: 'English',
    },
    {
        code: Language.ITALIAN,
        label: 'Italiano',
    },
];
export const defaultLanguage = Language.ENGLISH;

i18n
    .use(Backend)
    .use(initReactI18next)
    .init({
        debug: debug,

        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },

        lng: defaultLanguage,
        fallbackLng: defaultLanguage,
        preload: [defaultLanguage],
        ns: ['translations'],
        defaultNS: 'translations',
        backend: {
            loadPath: `${process.env.REACT_APP_API_HOST}/api/v1/{{ns}}/{{lng}}`,
            crossDomain: true,
            parse(data: string, languages?: string | string[], namespaces?: string | string[]): { [p: string]: any } {
                const response = JSON.parse(data);

                if (response.status !== 200) {
                    return {};
                }

                return response.data.translations;
            },
            reloadInterval: false,
        },
    });

export default i18n;
