import {IMuiSelectOption} from "../config/mui";
import {ICategory} from "../config/category";

export const transformToMuiSelectFormat = (data: ICategory[]): IMuiSelectOption[] => {
    return data.map(({id, name}) => {
        return {value: id, label: name};
    });
};

export const filterOutNonGlobalCategories = (data: ICategory[]): ICategory[] => {
    const map = {} as any;
    const tmp = [];
    for (let i = 0; i < data.length; i++) {
        map[data[i].id] = data[i];

        if (!data[i].is_global) {
            tmp.push(data[i])
        }
    }

    let tmp2 = [] as ICategory[];
    for (let i = 0; i < tmp.length; i++) {
        const arr = [tmp[i]];

        let cur = tmp[i];
        while (!!cur.parent_category_id) {
            arr.push(map[cur.parent_category_id]);
            cur = map[cur.parent_category_id];
        }

        tmp2 = tmp2.concat(arr.reverse());
    }

    let tmp3 = {} as any;
    for (let i = 0; i < tmp2.length; i++) {
        if (!!tmp3[tmp2[i].id]) {
            continue;
        }

        tmp3[tmp2[i].id] = tmp2[i];
        tmp3[tmp2[i].id].subcategories = [];

        if (!!tmp2[i].parent_category_id) {
            tmp3[tmp2[i].parent_category_id!].subcategories.push(tmp3[tmp2[i].id])
        }
    }

    const res = [] as any;
    Object.keys(tmp3).forEach((id) => {
        if (!tmp3[id].parent_category_id) {
            res.push(tmp3[id])
        }
    });

    return res;
};
