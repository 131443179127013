import * as React from "react";
import {Grid} from "@mui/material";
import {PageItem} from "../../../../Components/PageItem";

interface IItemsSectionProps {
    title: string;
    children: JSX.Element | JSX.Element[];
}

export const ItemsSection = ({title, children}: IItemsSectionProps) => (
    <PageItem title={title} styles={{paper: {height: '100%'}}}>
        <Grid container spacing={3}>
            {children}
        </Grid>
    </PageItem>
);
