import * as React from "react";
import {
    gridPageSizeSelector, GridPagination, gridRowCountSelector,
    useGridApiContext, useGridSelector
} from '@mui/x-data-grid';
import "../../../../utils/number_extensions";
import {TablePaginationProps} from "@mui/material";
import MuiPagination from "@mui/material/Pagination";
import {useSearchParams} from "react-router-dom";

type IPaginationProps = Pick<TablePaginationProps, 'page' | 'onPageChange' | 'className'>
const PaginationComponent = ({page, onPageChange, className}: IPaginationProps) => {
    const [, setSearchParams] = useSearchParams();
    const apiRef = useGridApiContext();
    const rowsCount = useGridSelector(apiRef, gridRowCountSelector);
    const perPage = useGridSelector(apiRef, gridPageSizeSelector);
    const pagesCount = Math.ceil(rowsCount / perPage); // useGridSelector(apiRef, gridPageCountSelector); // not working

    const handleChange = (event: React.ChangeEvent<unknown>, newPage: number) => {
        onPageChange(event as any, newPage - 1);

        setSearchParams((prev) => {
            prev.set('page', String(newPage));
            return prev;
        });
    };

    return (
        <MuiPagination
            color="primary"
            className={className}
            count={pagesCount}
            page={page + 1}
            onChange={handleChange}
        />
    );
}

export const Pagination = (props: any) => <GridPagination ActionsComponent={PaginationComponent} {...props} />
