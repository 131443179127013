import * as React from "react";
import {transformToMuiSelectFormat} from "../../../../../helpers/category_helpers";
import {useGlobalStores} from "../../../../../hooks/use_global_stores";
import {SelectField} from "../../../../Components/Form/SelectField";

const CategorySelectOption = ({label}: {label: string}) => (
    <span dangerouslySetInnerHTML={{__html: label.replace(/--/g, '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')}} />
);

interface ICategoryFieldProps {
    value: string;
    onChange(value: any): void;
}

export const CategoryField = ({value, onChange}: ICategoryFieldProps) => {
    const { categoryStore } = useGlobalStores();

    const options = transformToMuiSelectFormat(categoryStore.categories);

    return (
        <SelectField
            label="Category"
            options={options}
            value={value}
            onChange={onChange}
            optionValueGetter={o => o.value}
            optionLabelGetter={o => <CategorySelectOption label={o.label} />}
        />
    );
};
