import * as React from "react";
import { IVehicleForHireDetails } from "../../../../../config/vehicle_for_hire";
import { Section } from "../Components/Section";
import PlaceIcon from '@mui/icons-material/Place';
import PersonIcon from '@mui/icons-material/Person';
import RouteIcon from '@mui/icons-material/Route';
import moment from "moment";
import { AttachmentType, IAttachment } from "../../../../../config/attachments";
import { Box, styled } from "@mui/material";

interface IExtraVehicleForHireProps {
    value: IVehicleForHireDetails;
    attachments?: IAttachment[];
}

const MapContainer = styled(Box)(({theme}) => ({
    'img': {
        display: 'block',
        width: '100%',
    },
}));

export const ExtraVehicleForHire = ({value, attachments}: IExtraVehicleForHireProps) => {
    let pickupTime;
    if (!!value.pickupTime) {
        pickupTime = moment(value.pickupTime, 'hh:mm');
    }

    let dropoffTime;
    if (!!value.dropoffTime) {
        dropoffTime = moment(value.dropoffTime, 'hh:mm');
    }

    const tripMap = attachments?.find(item => item.type === AttachmentType.MAP);

    return (
        <Section title="Vehicle for Hire" sections={[
            ...!!value.addressFrom && !!pickupTime ? [
                {
                    title: 'Pick up',
                    value: `${value.addressFrom}, at ${pickupTime.format('HH:mm')}`,
                    icon: <PlaceIcon />
                },
            ] : [],
            ...!!value.addressTo && !!dropoffTime ? [
                {
                    title: 'Drop-off',
                    value: `${value.addressTo}, at ${dropoffTime.format('HH:mm')}`,
                    icon: <PlaceIcon />
                },
            ] : [],
            ...!!value.distanceUnit && value.distance ? [
                {
                    title: 'Distance',
                    value: `${value.distance} ${value.distanceUnit}`,
                    icon: <RouteIcon />
                },
            ] : [],
            ...!!value.driverName ? [
                {
                    title: 'Your Driver',
                    value: `${value.driverName}`,
                    icon: <PersonIcon />
                },
            ] : [],
            ...!!tripMap ? [
                {
                    value: (
                        <MapContainer>
                            <img alt="Your trip" src={tripMap.full_path} />
                        </MapContainer>
                    ),
                    // icon: <MapIcon />,
                    noIcon: true,
                },
            ] : [],
        ]} />
    );
};
