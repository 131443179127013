import * as React from "react";
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import {Grid} from "@mui/material";

interface IAddButtonProps {
    anchorRef: any;
    onOpen(): void;
}

export const AddButton = ({anchorRef, onOpen}: IAddButtonProps) => (
    <Grid item xs={12}>
        <Button
            ref={anchorRef}
            color="primary"
            size="small"
            onClick={onOpen}
        >
            <AddIcon/> Add detail
        </Button>
    </Grid>
);
