import * as React from "react";
import {updateRecurringExpense} from "../../../stores/recurring_expense";
import {SnackbarStore} from "../../../stores/snackbar";
import {IRecurringExpenseFormOutputData, RecurringExpenseForm} from "../Form/RecurringExpenseForm";
import {convertFormOutputDataToRecurringExpenseUpdate} from "../../../helpers/recurring_expenses_helper";
import {PageItem} from "../../Components/PageItem";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {PageOuterContainer} from "../../Components/PageOuterContainer";
import {useNavigate, useParams} from "react-router-dom";
import {useRecurringExpenseStore} from "../../../hooks/use_recurring_expense_store";
import {useEffect, useState} from "react";
import {IRecurringExpense} from "../../../config/recurring_expense";
import Error from "../../Components/Error";
import {PageLoader} from "../../Components/PageLoader";

export const EditRecurringExpense = () => {
    const recurringExpensesStore = useRecurringExpenseStore();
    const { snackbarStore } = useGlobalStores();

    const [recurringExpense, setRecurringExpense] = useState<IRecurringExpense>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);

    const { id } = useParams();
    const navigate = useNavigate();

    const handleSubmit = (data: IRecurringExpenseFormOutputData): Promise<any> => {
        return updateRecurringExpense(id!, convertFormOutputDataToRecurringExpenseUpdate(data));
    };

    const handleSuccess = (res: IRecurringExpense) => {
        recurringExpensesStore.replaceRecurringExpense(res);
        if (
            res.time_frequency != recurringExpense!.time_frequency ||
            res.amount != recurringExpense!.amount ||
            res.currency != recurringExpense!.currency
        ) {
            recurringExpensesStore.shouldStatsBeReloaded = true;
        }

        snackbarStore.open(SnackbarStore.SUCCESS, 'Recurring expense modified successfully!');
        navigate('/recurring-expenses');
    };

    useEffect(() => {
        setLoading(true);
        setError(false);

        (async () => {
            if (!recurringExpensesStore.shouldBePopulated) {
                setRecurringExpense(recurringExpensesStore.findById(id!)!);
            } else {
                setError(true);
            }

            setLoading(false);
        })();
    }, [id]);

    if (loading) {
        return (
            <PageLoader />
        );
    }

    if (error) {
        return (
            <Error />
        );
    }

    return (
        <PageOuterContainer>
            <PageItem title="Edit Recurring Expense">
                <RecurringExpenseForm recurringExpense={recurringExpense} onSubmit={handleSubmit} onSuccess={handleSuccess} />
            </PageItem>
        </PageOuterContainer>
    );
};
