import {Typography, alpha, styled} from "@mui/material";
import {grey} from "@mui/material/colors";

export const MoneySpentContainer = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    marginBottom: theme.spacing(6),

    [theme.breakpoints.down('md')]: {
        zIndex: theme.zIndex.appBar - 5,
        backgroundImage: `linear-gradient(${theme.palette.primary.main}, ${alpha(grey[100], 0.6)})`,
        height: 150,
        marginBottom: theme.spacing(3),
    },
}));

export const MoneySpentAmount = styled(Typography)(({theme}) => ({
    fontSize: 60,
    fontWeight: 'bold',

    [theme.breakpoints.down('md')]: {
        color: theme.palette.primary.contrastText,
        textShadow: `0px 0px 20px ${theme.palette.primary.dark}`,
    },
}));
