import * as React from "react";
import {useModal} from "../../../../hooks/use_modal";
import {ILocation, LocationVendor} from "../../../../config/location";
import {updateLocation} from "../../stores/locations";
import {LocationForm} from "../Components/Form";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import {toRequest} from "../helpers";

export interface IUpdateActionFormState {
    name: string;
    vendor: LocationVendor;
    vendor_id: string;
    default_category_id?: number;
    address?: string | null;
}

interface IEditActionProps {
    location: ILocation;
    onChange(id: number, location: ILocation | null): void;
}

export const EditAction = ({ location, onChange }: IEditActionProps) => {
    const { loading, open, handleModalOpen, handleModalClose } = useModal();

    const handleSubmit = async (data: IUpdateActionFormState): Promise<any> => {
        await updateLocation(location.id!, toRequest(data));
        onChange(location.id!, data);

        handleModalClose();

        return await Promise.resolve();
    };

    return (
        <>
            <IconButton size="small" color="primary" disabled={loading} onClick={handleModalOpen}>
                {!loading ? <EditIcon /> : <CircularProgress size={24} />}
            </IconButton>

            <LocationForm<IUpdateActionFormState> location={location} loading={loading} open={open} onClose={handleModalClose} onSubmit={handleSubmit} />
        </>
    );
};
