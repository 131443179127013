import * as React from "react";
import { Attachment } from "@mui/icons-material";
import { Box, Button, CircularProgress } from "@mui/material";
import { IAttachment } from "../../../../../../config/attachments";
import { useParams } from "react-router-dom";
import { fetchAttachments } from "../../../../../../stores/attachments";

interface IManageActionProps {
    onChange(values: IAttachment[]): void;
}

export const LoadAction = ({onChange}: IManageActionProps) => {
    const { transaction_id } = useParams() as any;

    const [loading, setLoading] = React.useState<boolean>(false);

    const handleLoad = () => {
        setLoading(true);
        fetchAttachments(transaction_id).then(onChange);
    };

    return (
        <Box sx={{padding: 1}}>
            <Button onClick={handleLoad} disabled={loading} component="label" variant="contained" startIcon={!loading ? <Attachment /> : <CircularProgress size={20} />}>
                Manage attachments
            </Button>
        </Box>
    );
};