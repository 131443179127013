import * as React from "react";
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import {
    detailsFormFields as options,
    ITransactionDetailFormField,
    TransactionDetailFields
} from "../../../../../../../config/transaction_details";
import {TransactionFormScope} from "../../../config/config";
import {filterOptionsByScope} from "../../../../../../../helpers/transaction_details_helpers";

interface IFieldSelectorProps {
    scope: TransactionFormScope;
    open: boolean;
    anchorRef: any;
    onClose(event: MouseEvent | TouchEvent): void;
    onSelect(field: ITransactionDetailFormField, event: any): void;
    selectedOptions: TransactionDetailFields[];
}

export const FieldSelector = ({scope, open, anchorRef, onClose, onSelect, selectedOptions}: IFieldSelectorProps) => {
    const handleSelect = (option: ITransactionDetailFormField) => (event: any) => {
        onSelect(option, event);
    };

    return (
        <Popper open={open} anchorEl={anchorRef.current} transition disablePortal sx={{zIndex: (theme) => theme.zIndex.tooltip}}>
            {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={onClose}>
                            <MenuList>
                                {filterOptionsByScope(options, scope).map(option => {
                                    if (selectedOptions.indexOf(option.code) !== -1) {
                                        return null;
                                    }

                                    return (
                                        <MenuItem
                                            key={option.code}
                                            onClick={handleSelect(option)}
                                        >
                                            {option.label}
                                        </MenuItem>
                                    )
                                })}
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );
};
