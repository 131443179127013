import * as React from "react";
import "../../../../utils/number_extensions";
import {Currency} from "../../../../config/currencies";
import {SnapshotCard, SnapshotCardColor} from "../../../Components/SnapshotCard";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import {styled} from "@mui/material";

const BlurredContainer = styled('div')(() => ({
    color: 'transparent',
    textShadow: '0 0 20px rgba(0, 0, 0, 0.8)',
}));

const BlurredText = ({currency}: { currency: Currency }) => (
    <BlurredContainer>
        {(12345 as any).toCurrency(currency, {minimumFractionDigits: 0})}
    </BlurredContainer>
)

interface IBudgetLeftHiddenItemProps {
    currency: Currency;
}

export const BudgetLeftHiddenItem = ({currency}: IBudgetLeftHiddenItemProps) => {
    return (
        <SnapshotCard
            title="Budget left"
            icon={<AccountBalanceWalletIcon />}
            color={SnapshotCardColor.PURPLE}
            value={<BlurredText currency={currency} />}
            details={"."}
        />
    );
};
