import * as React from "react";
import * as moment from "moment";
import {ITransaction} from "../../../config/transactions";
import {IEventDetails} from "../../../config/event";
import {toForm, toFormOutput} from "../../../helpers/event_helpers";
import {FreeTextField} from "../../Components/Form/FreeTextField";
import {MoneyField} from "../../Components/Form/MoneyField";
import {DateField, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import { Form } from "../../Components/Form/Form/Form";
import { useForm } from "../../../hooks/use_form_v2";
import { SearchTransactionsAction } from "../SearchTransactions/SearchTransactionsAction";

interface IEventFormProps {
    event?: IEventDetails;
    onSubmit(data: IEventFormOutputData, transactions: ITransaction[]): Promise<any>;
}

export interface IEventFormState {
    name: string;
    budget: string;
    hasDateRange: boolean;
    from: moment.Moment;
    to: moment.Moment;
    transactions: ITransaction[];
}

export interface IEventFormOutputData {
    name: string;
    budget?: number;
    from: string;
    to: string;
}

export const EventForm = ({event, onSubmit}: IEventFormProps) => {
    const form = useForm<IEventFormState>(toForm(event));
    const { register, watch } = form;

    const handleSubmit = (values: IEventFormState) => {
        return onSubmit(toFormOutput(values), values.transactions);
    };

    return (
        <Form form={form} onSubmit={handleSubmit}>
            <Form.Field type={FreeTextField} {...register('name')} />
            <Form.Field type={MoneyField} {...register('budget')} />

            <Form.Field type={LocalizationProvider} noWrapper dateAdapter={AdapterMoment}>
                <Form.Field type={DateField} {...register('from')} format="DD/MM/YYYY" fullWidth gridProps={{sm: 6}} />
                <Form.Field type={DateField} {...register('to')} format="DD/MM/YYYY" fullWidth gridProps={{sm: 6}} />
            </Form.Field>

            <Form.Field type={SearchTransactionsAction} {...register('transactions')} dateRange={[watch('from'), watch('to')]} />
        </Form>
    );
};
