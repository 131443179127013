import * as React from "react";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {Add} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {FormModal} from "../../../Components/FormModal";
import {useState} from "react";
import {Form} from "../Form/Form";
import {createCategory} from "../../../../stores/categories";
import {SnackbarStore} from "../../../../stores/snackbar";

export const NewAction = () => {
    const { snackbarStore, refreshStore } = useGlobalStores();

    const [open, setOpen] = useState<boolean>(false);

    const handleClick = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSuccess = () => {
        refreshStore.refreshCategories();
        snackbarStore.open(SnackbarStore.SUCCESS);

        setOpen(false);
    };

    return (
        <>
            <FormModal
                title="Add new category"
                open={open}
                loading={false}
                onClose={handleClose}
            >
                <Form onSubmit={createCategory} onSuccess={handleSuccess} />
            </FormModal>

            <Button
                className="add-new-custom-category-cta"
                size="small"
                startIcon={<Add />}
                onClick={handleClick}
            >
                Add new category
            </Button>
        </>
    );
};
