import * as React from "react";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {FormModal} from "../../../Components/FormModal";
import {SnackbarStore} from "../../../../stores/snackbar";
import { AllowanceForm } from "../Form/AllowanceForm";
import { createAllowance } from "../../../../stores/allowances";
import { IAllowanceRequest } from "../../../../config/allowance";

interface INewAllowanceProps {
    open: boolean;
    onClose(): void;
}

export const NewAllowance = ({open, onClose}: INewAllowanceProps) => {
    const { allowanceStore, snackbarStore } = useGlobalStores();

    const handleSubmit = (data: IAllowanceRequest) => {
        return createAllowance(data)
            .then((newAllowance) => {
                snackbarStore.open(SnackbarStore.SUCCESS, 'Allowance created successfully!');
                allowanceStore.addAllowance(newAllowance);
                onClose();
                return Promise.resolve();
            })
            .catch(reason => {
                snackbarStore.open(SnackbarStore.FAIL);
                return Promise.reject(reason);
            });
    };

    return (
        <FormModal title="New allowance" open={open} loading={false} onClose={onClose}>
            <AllowanceForm onSubmit={handleSubmit} />
        </FormModal>
    );
};
