import * as React from "react";
import { useGlobalStores } from "../../../hooks/use_global_stores";
import { Button } from "@mui/material";
import { useModal } from "../../../hooks/use_modal";
import { FeaturesModal } from "./Components/FeaturesModal";

export const ProButton = ({min, text, feature, children, ...props}: any) => {
    const { authStore } = useGlobalStores();
    const { open, handleModalOpen, handleModalClose } = useModal();

    if (!min || !authStore.accessToken?.hasAccess(min)) {
        return (
            <>
                <Button {...props} onClick={handleModalOpen}>{text}</Button>
                <FeaturesModal open={open} onClose={handleModalClose} selectedFeature={feature} />
            </>
        );
    }

    return (
        <Button {...props}>
            {children}
        </Button>
    );
};
