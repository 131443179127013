import * as React from "react";
import {useEffect, useState} from "react";
import {ITimeFrame, TimeFrame, TimeFramesCollection} from "../../../../../../config/time_frames";
import moment from "moment/moment";
import {DateRangePicker} from "./DateRangePicker";
import { SelectField } from "../../../../../Components/Form/SelectField";

const getTimeFrameByID = (id: TimeFrame): ITimeFrame => {
    return TimeFramesCollection[id];
};

interface ITimePeriodFilterProps {
    timePeriod: TimeFrame;
    timeRange?: moment.Moment[];
    onChange(frame: TimeFrame, range: moment.Moment[]): void;
}

export const TimePeriodFilter = ({timePeriod, timeRange, onChange}: ITimePeriodFilterProps) => {
    const [timeFrame, setTimeFrame] = useState<TimeFrame>(timePeriod);
    const [range, setRange] = useState<moment.Moment[]>(timeRange || getTimeFrameByID(timeFrame).getRange());

    const handleChange = (value: any) => {
        setTimeFrame(parseInt(value));
        setRange(getTimeFrameByID(value).getRange());
    };

    const handleDateChange = (from: moment.Moment, to: moment.Moment) => {
        setRange([from, to]);
    };

    useEffect(() => {
        onChange(timeFrame, range);
    }, [timeFrame, range]);

    return (
        <>
            <SelectField
                label="Time range"
                value={timeFrame}
                onChange={handleChange}
                options={Object.keys(TimeFramesCollection)}
                optionValueGetter={key => key}
                optionLabelGetter={key => getTimeFrameByID(key as any).label}
            />

            {timeFrame === TimeFrame.CUSTOM &&
                <DateRangePicker
                    from={range[0] || moment()}
                    to={range[1] || moment()}
                    onChange={handleDateChange}
                />
            }
        </>
    );
};
