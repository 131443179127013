import * as React from "react";
import {EventForm, IEventFormOutputData} from "../Form/EventForm";
import {createEvent, createEventTransactions} from "../../../stores/events";
import {INewEvent} from "../../../config/event";
import {useEventStore} from "../../../hooks/use_event_store";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {SnackbarStore} from "../../../stores/snackbar";
import {ITransaction} from "../../../config/transactions";
import { useModal } from "../../../hooks/use_modal";
import { FormModal } from "../../Components/FormModal";
import { Button } from "@mui/material";
import Add from "@mui/icons-material/Add";

export const NewEvent = () => {
    const { snackbarStore } = useGlobalStores();
    const eventStore = useEventStore();

    const {open, handleModalOpen, handleModalClose} = useModal();

    const handleSubmit = (event: IEventFormOutputData, transactions: ITransaction[]) => {
        // as of now IEventFormOutputData and INewEvent are identical
        return createEvent(event as INewEvent)
            .then(event => {
                eventStore.addEvent(event);

                return createEventTransactions(event?.id!, transactions.map(t => ({transaction_id: t.transaction_id})) || []);
            })
            .then(() => {
                snackbarStore.open(SnackbarStore.SUCCESS, 'Event added successfully!');
                handleModalClose();
            })
            .catch(reason => {
                snackbarStore.open(SnackbarStore.FAIL);
                return Promise.reject(reason);
            });
    };

    return (
        <>
            <FormModal title="New event" open={open} onClose={handleModalClose}>
                <EventForm onSubmit={handleSubmit} />
            </FormModal>

            <Button
                size="small"
                startIcon={<Add />}
                onClick={handleModalOpen}
            >
                Add new event
            </Button>
        </>
    );
};
