import {IErrorResponseItem, ValidationError, ValidationErrors} from "../services/validator";

export enum IncomesValidationField {
    NAME = "Income.Name",
    AMOUNT = "Income.Amount",
}

export type IncomesValidation = {
    [key in IncomesValidationField]?: ValidationError[];
};

export type IncomeValidationErrors = ValidationErrors<IncomesValidation, IncomesValidationField>;

export const incomesValidationFactory = (errors: IErrorResponseItem[]): IncomeValidationErrors => {
    const v = new ValidationErrors<IncomesValidation, IncomesValidationField>();
    v.setMap({
        name: IncomesValidationField.NAME,
        amount: IncomesValidationField.AMOUNT,
    })
    v.setKeyParser((key) => {
        return key
            .replace('NewIncome', 'Income')
            .replace('UpdateIncome', 'Income');
    });
    v.setErrors(errors);

    return v;
};
