import * as React from "react";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {observer} from "mobx-react";
import {useState} from "react";
import {List, useMediaQuery, useTheme} from "@mui/material";
import {NoResults} from "../../../Components/NoResults";
import {AccountBalance} from "@mui/icons-material";
import {PageItem} from "../../../Components/PageItem";
import { IBudget } from "../../../../config/budget";
import { NewBudget } from "../New/NewBudget";
import { NewAction } from "../Actions/NewAction";
import { EditBudget } from "../Edit/EditAllowance";
import { BudgetItem } from "./BudgetItem";

export const ListContainer = (props: any) => {
    const dense = useMediaQuery(useTheme().breakpoints.down('sm'));

    return (
        <List sx={{maxWidth: 600}} dense={dense}>
            {props.children}
        </List>
    );
};

export const BudgetsList = observer(() => {
    const { budgetStore, currencyStore } = useGlobalStores();
    const budgets = budgetStore.budgets;

    const [newOpen, setNewOpen] = useState<boolean>(false);
    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [selected, setSelected] = useState<IBudget>();

    const handleNewVisibility = () => {
        setNewOpen(!newOpen);
    };

    const handleEditOpen = (allowance: IBudget) => {
        setEditOpen(true);
        setSelected(allowance);
    };

    const handleEditClose = () => {
        setEditOpen(false);
    };

    if (!budgets.length) {
        return (
            <>
                <NoResults
                    text="You haven't added any budget yet :("
                    onClick={handleNewVisibility}
                    icon={<AccountBalance />}
                />
                <NewBudget open={newOpen} onClose={handleNewVisibility} />
            </>
        );
    }

    return (
        <PageItem
            title="Budgets"
            actions={<NewAction onClick={handleNewVisibility} />}
        >
            <ListContainer>
                {budgets.map(budget => <BudgetItem budget={budget} currency={currencyStore.currentCurrency} key={budget.id} onEditClick={handleEditOpen} />)}
            </ListContainer>

            {newOpen && <NewBudget open={newOpen} onClose={handleNewVisibility} />}
            {editOpen && selected && <EditBudget open={editOpen} budget={selected} onClose={handleEditClose} />}
        </PageItem>
    );
});
