import {ICreateActionFormState} from "./Actions/CreateAction";
import {IUpdateActionFormState} from "./Actions/EditAction";
import {IFormLocationRequest} from "../stores/locations";

export const toRequest = (data: ICreateActionFormState | IUpdateActionFormState): IFormLocationRequest => {
    const res: IFormLocationRequest = {
        name: data.name,
        vendor: data.vendor,
        vendor_id: data.vendor_id,
    };

    if (!!data.address) {
        res.address = data.address;
    }
    if (!!data.default_category_id) {
        res.default_category_id = data.default_category_id;
    }

    return res;
};
