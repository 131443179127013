import * as React from "react";
import "../../../../utils/number_extensions";
import {ITransaction} from "../../../../config/transactions";
import {IconButton, styled, Typography} from "@mui/material";
import {LocationLogo} from "./LocationLogo";
import {LocationOn} from "@mui/icons-material";

const Container = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(0.5),
}));

const Pin = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
}));

interface ILocationProps {
    transaction: ITransaction;
}

export const Location = ({transaction}: ILocationProps) => {
    return (
        <Container>
            <div>
                {transaction.location?.name}

                {!!transaction.location?.address &&
                    <Pin>
                        <IconButton color="primary" size="small">
                            <LocationOn/>
                        </IconButton>
                        <Typography>
                            {transaction.location?.address}
                        </Typography>
                    </Pin>
                }
            </div>

            <LocationLogo transaction={transaction} />
        </Container>
    );
};
