import {styled} from "@mui/material";
import { blue } from '@mui/material/colors';

export const Middle = styled('div')(({theme}) => ({
    position: 'absolute',
    zIndex: 10,
    left: '50%',
    marginLeft: -1,
    height: '100%',
    borderLeft: 'dashed 1px',
    borderLeftColor: blue[800],
}));
