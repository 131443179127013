import * as React from "react";
import { AttachmentType } from "../../../../../../config/attachments";
import { Icon } from "./Icon";
import { ListItem, ListItemIcon, ListItemText, styled, IconButton } from "@mui/material";
import { HighlightOff, Replay } from "@mui/icons-material";

const StyledListItem = styled(ListItem)(() => ({
    padding: 0,
    paddingLeft: 1,
    paddingBottom: 10,

    '&:last-of-type': {
        paddingBottom: 0,
    },
}));

interface IItemProps {
    type: AttachmentType;
    label: string;
    deleted?: boolean;
    onDelete(): void;
    onUndelete?(): void;
}

export const Item = ({type, label, onDelete, onUndelete, deleted}: IItemProps) => {
    return (
        <StyledListItem
            secondaryAction={
                !deleted ?
                <IconButton edge="start" size="small" color="error" onClick={onDelete}>
                    <HighlightOff />
                </IconButton> :
                <IconButton edge="start" size="small" color="primary" onClick={onUndelete}>
                    <Replay sx={{transform: 'rotate(-90deg)'}} />
                </IconButton>
            }
        >
            <ListItemIcon sx={{minWidth: 0, paddingRight: 1.5}}>
                <Icon type={type} />
            </ListItemIcon>
            <ListItemText primary={label} sx={!!deleted ? {color: 'grey', textDecoration: 'line-through'} : {}} />
        </StyledListItem>
    );
};
