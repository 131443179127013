import * as React from "react";
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ListIcon from '@mui/icons-material/List';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import {CompanyAppearance} from "../../../../config/company";
import {useEffect, useState} from "react";

interface ISortByActionProps {
    defaultView?: CompanyAppearance;
    onSelect(view: CompanyAppearance): void;
}

export const AppearanceAction = ({defaultView, onSelect}: ISortByActionProps) => {
    const [view, setView] = useState<CompanyAppearance>();

    const handleChange = (event: any, view: CompanyAppearance) => {
        setView(view);
        onSelect(view);
    };

    useEffect(() => {
        setView(defaultView);
    }, []);

    return (
        <ToggleButtonGroup
            exclusive
            size="small"
            value={view}
            onChange={handleChange}
            color="primary"
        >
            <ToggleButton value={CompanyAppearance.BOXES}>
                <ViewModuleIcon />
            </ToggleButton>
            <ToggleButton value={CompanyAppearance.LIST}>
                <ListIcon />
            </ToggleButton>
        </ToggleButtonGroup>
    )
};
