import * as React from "react";
import {EventForm, IEventFormOutputData} from "../Form/EventForm";
import {createEventTransactions, fetchEventDetails, updateEvent} from "../../../stores/events";
import {IEvent, IEventDetails} from "../../../config/event";
import {useEventStore} from "../../../hooks/use_event_store";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {SnackbarStore} from "../../../stores/snackbar";
import {toUpdateRequest} from "../../../helpers/event_helpers";
import {useEffect, useState} from "react";
import {ITransaction} from "../../../config/transactions";
import { FormModal } from "../../Components/FormModal";
import { useModal } from "../../../hooks/use_modal";
import { Box, CircularProgress } from "@mui/material";

interface IEditEventProps {
    event: IEvent;
    onClose(): void;
}

export const EditEvent = ({event, onClose}: IEditEventProps) => {
    const { snackbarStore } = useGlobalStores();
    const eventStore = useEventStore();

    const {open, loading, setLoading} = useModal(true);

    const [eventDetails, setEventDetails] = useState<IEventDetails>();

    useEffect(() => {
        setLoading(true);
        fetchEventDetails(event.id).then(res => {
            setEventDetails(res);
            setLoading(false);
        });
    }, []);

    const handleSubmit = (data: IEventFormOutputData, transactions: ITransaction[]) => {
        // as of now IEventFormOutputData and INewEvent are identical
        return updateEvent(event.id!, toUpdateRequest(data))
            .then(updatedEvent => {
                eventStore.replaceEvent(updatedEvent as IEvent);

                return createEventTransactions(event?.id!, transactions.map(t => ({transaction_id: t.transaction_id})) || []);
            })
            .then(() => {
                snackbarStore.open(SnackbarStore.SUCCESS, 'Event modified successfully!');
                onClose();
            })
            .catch(reason => {
                snackbarStore.open(SnackbarStore.FAIL);
                return Promise.reject(reason);
            });
    };

    return (
        <FormModal title="Edit event" open={open} loading={loading} onClose={onClose}>
            {!loading ?
                <EventForm onSubmit={handleSubmit} event={eventDetails} /> :
                <Box sx={{display: 'flex', justifyContent: 'center'}}>
                    <CircularProgress color="primary" size={50} />
                </Box>
            }
        </FormModal>
    );
};
