import * as React from "react";
import {FreeTextField} from "../../../Components/Form/FreeTextField";
import {MoneyField} from "../../../Components/Form/MoneyField";
import { useForm } from "../../../../hooks/use_form_v2";
import { toForm } from "../../../../helpers/budget_helpers";
import { IBudget, IBudgetRequest } from "../../../../config/budget";
import { Form } from "../../../Components/Form/Form/Form";

export interface IBudgetState {
    name: string;
    amount: string;
}

interface IBudgetFormProps {
    budget?: IBudget;
    onSubmit(data: IBudgetRequest): Promise<any>;
}

export const BudgetForm = ({budget, onSubmit}: IBudgetFormProps) => {
    const form = useForm<IBudgetState>(budget);
    const { register } = form;

    const handleSubmit = (values: IBudgetState) => {
        return onSubmit(toForm(values));
    };

    return (
        <Form form={form} onSubmit={handleSubmit}>
            <Form.Field type={FreeTextField} {...register('name')} />
            <Form.Field type={MoneyField} {...register('amount')} />
        </Form>
    );
};
