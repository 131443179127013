import * as React from "react";
import "../../../utils/array_extentions";
import {IStatsCurrency} from "../../../config/stats";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {CurrencyLabel} from "../../../config/currencies";
import {PieChart} from "../../Components/Charts/PieChart";

interface ICurrenciesChartProps {
    currencies: IStatsCurrency[];
    chartHeight: number;
}

export const CurrenciesChart = ({currencies, chartHeight}: ICurrenciesChartProps) => {
    const { currencyStore } = useGlobalStores();

    let data = currencies as any;
    data = data.removeNegativeValuesFromObject('total');
    data.sortArrayOfObjectsDesc('total');

    return (
        <PieChart
            dataset={data.map((v: any) => ({
                value: v.total,
                label: (CurrencyLabel as any)[v.code],
            }))}
            size={chartHeight}
            valueFormatter={(v: any) => {
                return v.value.toCurrency(currencyStore.currentCurrency, {});
            }}
        />
    );
};
