import * as React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {styled} from "@mui/material";

const Container = styled('div')(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(3),
}));

export const Loading = () => (
    <Container>
        <CircularProgress size={40} />
    </Container>
);
