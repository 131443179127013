import {action, makeObservable, observable} from "mobx";
import {ICallbackAction, IPaymentMethodCallback} from "../config/user_payment_method_callback";
import { executeCallback, toForm } from "../helpers/payment_method_callbacks/helpers";
import { ITransactionFormState } from "../components/Transactions/Components/Form/TransactionForm";
import { fetchDelete, fetchGet, fetchPost, fetchPut } from "../services/api";
import { convertFromResponse } from "../validators/user_payment_method_callbacks";

const PAYMENT_METHODS_CALLBACKS_CACHE_KEY = 'em:user:payment_methods:callbacks';

export class UserPaymentMethodCallbackStore {
    @observable callbacks: IPaymentMethodCallback[] = [];

    constructor() {
        makeObservable(this);

        const data = window.localStorage.getItem(PAYMENT_METHODS_CALLBACKS_CACHE_KEY);
        if (!data) {
            return;
        }

        this.callbacks = JSON.parse(data);
    }

    @action
    load(callbacks: IPaymentMethodCallback[]) {
        this.callbacks = callbacks;
        this.updateLocalStorage();
    }

    @action
    addCallback(paymentMethodId: number, callback: ICallbackAction) {
        let index = this.findIndexByUserPaymentMethodId(paymentMethodId);
        if (index === -1) {
            index = this.callbacks.length;
            this.callbacks.push({
                user_payment_method_id: paymentMethodId,
                actions: [],
            });
        }

        this.callbacks[index].actions.push(callback);
        this.updateLocalStorage();
    }

    @action
    replaceCallback(paymentMethodId: number, callback: ICallbackAction) {
        const index = this.findIndexByUserPaymentMethodId(paymentMethodId);
        if (index === -1) {
            return;
        }

        const actionIndex = this.callbacks[index].actions.findIndex(item => item.id === callback.id) || null;
        if (!actionIndex) {
            return;
        }

        this.callbacks[index].actions[actionIndex] = callback;
        this.updateLocalStorage();
    }

    @action
    removeCallback(paymentMethodId: number, id: number) {
        const index = this.findIndexByUserPaymentMethodId(paymentMethodId);
        if (index === -1) {
            return;
        }

        this.callbacks[index].actions = this.callbacks[index].actions.filter(a => a.id !== id);
        this.updateLocalStorage();
    }

    exec(paymentMethodId: number): ITransactionFormState | {} {
        const callback = this.findByUserPaymentMethodId(paymentMethodId);
        if (!callback) {
            return {};
        }

        return toForm(callback.actions.map(executeCallback));
    }
    
    findByUserPaymentMethodId(paymentMethodId: number): IPaymentMethodCallback | null {
        return this.callbacks.find(item => item.user_payment_method_id === paymentMethodId) || null;
    }

    private findIndexByUserPaymentMethodId(paymentMethodId: number): number {
        return this.callbacks.findIndex(item => item.user_payment_method_id === paymentMethodId);
    }

    private updateLocalStorage() {
        window.localStorage.setItem(PAYMENT_METHODS_CALLBACKS_CACHE_KEY, JSON.stringify(this.callbacks));
    }
}

export const fetchPaymentMethodsCallbacks = (): Promise<IPaymentMethodCallback[]> => {
    return fetchGet('/ego/payment_methods/callbacks')
        .then(response => response.json())
        .then(data => data.data);
};

export const createPaymentMethodsCallbacks = (userPaymentMethodId: number, data: ICallbackAction): Promise<ICallbackAction> => {
    return fetchPost(`/ego/payment_methods/${userPaymentMethodId}/callbacks`, data)
        .then((response: any) => {
            if (response.status !== 201) {
                return response.json().then((reason: any) => {
                    if (reason.errors === undefined) {
                        return Promise.reject(reason);
                    }

                    return Promise.reject(convertFromResponse(reason.errors));
                });
            }

            return Promise.resolve({
                id: parseInt(response.headers.get('Location').replace(/.*\/([0-9]+)$/, '$1')),
                action: data.action,
                payload: JSON.parse(data.payload),
            });
        });
};

export const updateUserPaymentMethodCallback = (userPaymentMethodId: number, id: number, data: ICallbackAction): Promise<ICallbackAction> => {
    return fetchPut(`/ego/payment_methods/${userPaymentMethodId}/callbacks/${id}`, data)
        .then((response: any) => {
            if (response.status !== 200) {
                return response.json().then((reason: any) => {
                    if (reason.errors === undefined) {
                        return Promise.reject(reason);
                    }

                    return Promise.reject(convertFromResponse(reason.errors));
                });
            }

            return response.json();
        })
        .then(data => data.data);
};

export const deleteUserPaymentMethodCallback = (userPaymentMethodId: number, id: number): Promise<boolean> => {
    return fetchDelete(`/ego/payment_methods/${userPaymentMethodId}/callbacks/${id}`);
};
