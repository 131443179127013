import * as React from "react";
import {PageItem} from "../../Components/PageItem";
import {SnackbarStore} from "../../../stores/snackbar";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {IGeneralSettings} from "../../../config/settings";
import {useTranslation} from "react-i18next";
import {CheckboxField} from "../../Components/Form/CheckboxField";
import {SelectField} from "../../Components/Form/SelectField";
import {RecurringExpensesTimeFrequencies} from "../../../config/recurring_expense";
import { SnapshotVersion, snapshotVersionLabels, snapshotVersions } from "../../../config/snapshot";
import { useForm } from "../../../hooks/use_form_v2";
import { Form } from "../../Components/Form/Form/Form";

export const General = () => {
    const { t } = useTranslation();
    const { generalSettings, snackbarStore } = useGlobalStores();

    const form = useForm<IGeneralSettings>(generalSettings.settings);
    const { register, watch } = form;

    const handleSubmit = (values: IGeneralSettings) => {
        generalSettings.save(values);
        snackbarStore.open(SnackbarStore.SUCCESS, t('settings.general_settings.form.success_message')!);

        return Promise.resolve();
    };

    return (
        <PageItem title={t('settings.general_settings.title')}>
            <Form form={form} onSubmit={handleSubmit}>
                <Form.Field
                    type={SelectField}
                    {...register('snapshotVersion')}
                    label={t('settings.general_settings.form.snapshot_version')}
                    options={snapshotVersions}
                    optionValueGetter={(o: any) => o}
                    optionLabelGetter={(o: any) => snapshotVersionLabels[o]}
                />

                <Form.Field
                    type={CheckboxField}
                    conditional={watch('snapshotVersion') === SnapshotVersion.LEGACY}
                    {...register('budgetLeftVisibility')}
                    label={t('settings.general_settings.form.display_budget_left')}
                />

                <Form.Field
                    type={SelectField}
                    {...register('defaultRecurringExpensesAggregator')}
                    label={t('settings.general_settings.form.recurring_expenses_aggregator')}
                    options={RecurringExpensesTimeFrequencies}
                    optionValueGetter={(o: any) => o.code}
                    optionLabelGetter={(o: any) => t(`settings.general_settings.form.rec_exp_aggregator.${o.code}`)}
                />
            </Form>
        </PageItem>
    )
};
