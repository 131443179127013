import * as React from "react";
import {ITransaction} from "../../../../config/transactions";
import {ListContainer, ListItem} from "./Components/Lists";
import "../../../../utils/number_extensions";

interface IAllowancesProps {
    transaction: ITransaction;
}

export const Allowances = ({transaction}: IAllowancesProps) => {
    return (
        <ListContainer>
            {transaction.allowances?.map(a => {
                return (
                    <ListItem key={a.id} label={a.name!} value={(a.amount as any).toCurrency(transaction.original_currency)} />
                )
            })}
        </ListContainer>
    )
};
