import * as React from "react";
import {ITransaction} from "../../../../../config/transactions";
import Box from "@mui/material/Box";

interface IDateProps {
    transaction: ITransaction;
}

export const Date = ({transaction}: IDateProps) => (
    <Box sx={{fontSize: 12}}>
        <strong>Date</strong>: {transaction.purchased_at.format('MMMM Do YYYY')}
    </Box>
);
