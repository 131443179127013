import * as React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Paper from '@mui/material/Paper';
import {styled} from "@mui/material";
import {convertAmountToFloat} from "../../../../../../../helpers/transaction_helpers";
import { IAllowanceTransaction } from "../../../../../../../config/allowance";
import { useGlobalStores } from "../../../../../../../hooks/use_global_stores";

const StyledPaper = styled(Paper)(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

interface IDetailsProps {
    value: IAllowanceTransaction;
    onEdit(): void;
    onDelete(): void;
}

export const Details = ({value, onEdit, onDelete}: IDetailsProps) => {
    const { allowanceStore } = useGlobalStores();

    const allowance = allowanceStore.findById(value.allowance_id)!;

    return (
        <StyledPaper elevation={2}>
            <div>
                <Typography sx={{fontSize: '120%'}} variant="body2">
                    {allowance.name}:{' '}
    
                    <strong>
                        {(convertAmountToFloat(value.amount) as any).toCurrency(allowance.currency)}
                    </strong>
                </Typography>
            </div>
    
            <div>
                <IconButton onClick={onEdit} size="small" color="primary">
                    <EditIcon />
                </IconButton>
                <IconButton onClick={onDelete}  size="small" color="error">
                    <DeleteIcon />
                </IconButton>
            </div>
        </StyledPaper>
    )
};
