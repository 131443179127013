import * as React from "react";
import {useTranslation} from "react-i18next";
import {IBulkFormData} from "../../../../../../config/bulk";
import {Button, styled} from "@mui/material";
import {useEffect, useState} from "react";
import {PaymentMethodField} from "../../../../Components/Form/Fields/PaymentMethodField";
import {DataTable} from "./DataTable";

const ActionsContainer = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
}));

const DefaultPaymentMethodsContainer = styled('div')(({theme}) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(3),
    width: 300,
}));

interface ICheckDataStepProps {
    data: IBulkFormData[];
    onSubmit(promise: Promise<any>): void;
    onCancel(): void;
}

export const CheckDataStep = ({data, onSubmit, onCancel}: ICheckDataStepProps) => {
    const { t } = useTranslation();

    const [value, setValue] = useState(data);
    const [defaultPaymentMethod, setDefaultPaymentMethod] = useState('');

    const handleDefaultPaymentMethod = (value: any) => {
        setDefaultPaymentMethod(value);
    };

    const handleSubmit = () => {
        const promise = Promise.resolve(
            value.filter(v => !v.isDeleted)
        );

        onSubmit(promise);
    };

    const handleBack = () => {
        onCancel();
    };

    const handleEdit = (editData: IBulkFormData, index: number) => {
        setValue((prev: any[]): any[] => {
            const newData = prev.slice();
            newData[index] = editData;

            return newData;
        });
    };

    const handleDelete = (index: number, withError?: boolean) => {
        const updateArray = (prev: any[]): any[] => {
            const newData = prev.map(p => p);
            if (newData[index].isDeleted === undefined) {
                newData[index].isDeleted = true;
            } else {
                newData[index].isDeleted = !newData[index].isDeleted;
            }

            return newData;
        };

        setValue(updateArray);
    };

    useEffect(() => {
        if (defaultPaymentMethod === '') {
            return;
        }

        setValue((prev: IBulkFormData[]): IBulkFormData[] => {
            const newData = prev.map(p => p);
            for (let i = 0; i < newData.length; ++i) {
                newData[i].paymentMethod = defaultPaymentMethod;
            }

            return newData;
        });
    }, [defaultPaymentMethod]);

    return <>
        <DefaultPaymentMethodsContainer>
            <PaymentMethodField label="Default Payment Method" value={defaultPaymentMethod} onChange={handleDefaultPaymentMethod}/>
        </DefaultPaymentMethodsContainer>

        <DataTable
            data={value}
            onEdit={handleEdit}
            onDelete={handleDelete}
        />

        <ActionsContainer>
            <Button type="button" onClick={handleBack} variant="contained">
                {t('transactions.bulk.upload_form.back')}
            </Button>

            <Button
                type="submit"
                onClick={handleSubmit}
                variant="contained"
                color="primary"
            >
                {t('transactions.bulk.upload_form.next')}
            </Button>
        </ActionsContainer>
    </>;
};
