import * as React from "react";
import "../../../utils/number_extensions";
import { periodPercentageComparisonV3 } from "../../../helpers/snapshot_helpers";
import ArrowCircleLeft from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRight from "@mui/icons-material/ArrowCircleRight";
import { ISnapshotV3 } from "../../../config/snapshot";
import { MoneySpentAmount, MoneySpentContainer } from "./Components/MoneySpent";
import { Amount, PreviousPeriodContainer, PreviousPeriodContent, PreviousPeriodIcon } from "./Components/PreviousPeriod";
import { useGlobalStores } from "../../../hooks/use_global_stores";

interface ICurrentMonthDataProps {
    snapshot: ISnapshotV3;
}

export const CurrentMonthData = ({snapshot}: ICurrentMonthDataProps) => {
    const { currencyStore } = useGlobalStores();

    return (
        <MoneySpentContainer>
            <MoneySpentAmount variant="button">
                {(Math.round(snapshot.amount) as any).toCurrency(currencyStore.currentCurrency, {minimumFractionDigits: 0})}
            </MoneySpentAmount>
    
            <PreviousPeriodContainer>
                <PreviousPeriodIcon>
                    {snapshot.amount_difference > 0 ? <ArrowCircleLeft /> : <ArrowCircleRight/>}
                </PreviousPeriodIcon>
                
                <PreviousPeriodContent variant="button">
                    <Amount>{periodPercentageComparisonV3(snapshot.amount_difference)}</Amount> from last month
                </PreviousPeriodContent>
            </PreviousPeriodContainer>
        </MoneySpentContainer>
    );    
};
