import * as React from 'react';
import {CircularProgress, styled} from "@mui/material";

const Container = styled('div')(({ theme }) => ({
    padding: theme.spacing(10),
    textAlign: 'center',
}));

export const PageLoader = () => (
    <Container>
        <CircularProgress size={60} />
    </Container>
);
