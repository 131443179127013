import * as React from "react";
import "../../../../utils/number_extensions";
import {ITransaction} from "../../../../config/transactions";
import {IUserPaymentMethod} from "../../../../config/user_payment_method";
import {IPaymentMethod, PaymentMethodType} from "../../../../config/payment_method";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {ReactElement} from "react";
import {UnknownIcon} from "../../../Components/Icons/PaymentMethods/UnknownIcon";
import {AmexIcon} from "../../../Components/Icons/PaymentMethods/AmexIcon";
import {CashIcon} from "../../../Components/Icons/PaymentMethods/CashIcon";
import {ElectronicCashIcon} from "../../../Components/Icons/PaymentMethods/ElectronicCashIcon";
import {MasterCardIcon} from "../../../Components/Icons/PaymentMethods/MasterCardIcon";
import {VisaIcon} from "../../../Components/Icons/PaymentMethods/VisaIcon";
import {TransferIcon} from "../../../Components/Icons/PaymentMethods/TransferIcon";
import {styled} from "@mui/material";

const Container = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(0.5),
}));

const Label = styled('div')(({theme}) => ({
}));

const ICON_STYLE = {
    marginTop: -20,
    maxWidth: 75,
};

interface IPaymentMethodProps {
    transaction: ITransaction;
}

export const PaymentMethod = ({transaction}: IPaymentMethodProps) => {
    const { paymentMethodStore, userPaymentMethodStore } = useGlobalStores();

    if (!transaction.payment_method_id) {
        return (
            <div>
                <UnknownIcon style={ICON_STYLE} />
            </div>
        );
    }

    const userPaymentMethod = userPaymentMethodStore.getById(transaction.payment_method_id) as IUserPaymentMethod;
    const paymentMethod = paymentMethodStore.getByCode(userPaymentMethod.type) as IPaymentMethod;

    let icon: ReactElement;

    switch (paymentMethod.code) {
        case PaymentMethodType.AMEX: icon = <AmexIcon style={ICON_STYLE} />; break;
        case PaymentMethodType.CASH: icon = <CashIcon style={ICON_STYLE} />; break;
        case PaymentMethodType.EC: icon = <ElectronicCashIcon style={ICON_STYLE} />; break;
        case PaymentMethodType.MASTER_CARD: icon = <MasterCardIcon style={ICON_STYLE} />; break;
        case PaymentMethodType.VISA: icon = <VisaIcon style={ICON_STYLE} />; break;
        case PaymentMethodType.WIRE: icon = <TransferIcon style={ICON_STYLE} />; break;
        default: icon = <UnknownIcon style={ICON_STYLE} />;
    }

    return (
        <Container>
            <Label>
                {paymentMethod.name}
            </Label>
            {icon}
        </Container>
    );
};
