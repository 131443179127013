import {IErrorResponseItem, ValidationError, ValidationErrors} from "../services/validator";

export enum EventsValidationField {
    NAME = "Event.Name",
}

export type EventsValidation = {
    [key in EventsValidationField]?: ValidationError[];
};

export type EventsValidationErrors = ValidationErrors<EventsValidation, EventsValidationField>;

export const eventsValidationFactory = (errors: IErrorResponseItem[]): EventsValidationErrors => {
    const v = new ValidationErrors<EventsValidation, EventsValidationField>();
    v.setMap({
        name: EventsValidationField.NAME,
    });
    v.setKeyParser((key) => {
        return key
            .replace('NewEvent', 'Event')
            .replace('EventUpdate', 'Event');
    });
    v.setErrors(errors);

    return v;
};
