import * as React from "react";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from "@mui/material/CircularProgress";
import {SnackbarStore} from "../../../../stores/snackbar";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import { IAllowance } from "../../../../config/allowance";
import { deleteAllowance } from "../../../../stores/allowances";

interface IDeleteActionProps {
    item: IAllowance;
}

export const DeleteAction = ({item: allowance}: IDeleteActionProps) => {
    const { allowanceStore, snackbarStore } = useGlobalStores();
    const [loading, setLoading] = React.useState(false);

    const handleClick = () => {
        if (loading) {
            return;
        }

        if (!window.confirm('Are you sure to delete this allowance? It cannot be undone.')) {
            return;
        }

        setLoading(true);

        deleteAllowance(allowance.id)
            .then(() => {
                setLoading(false);

                snackbarStore.open(SnackbarStore.SUCCESS, 'Allowance deleted successfully!');
                allowanceStore.removeAllowance(allowance);
            })
            .catch(() => {
                setLoading(false);

                snackbarStore.open(SnackbarStore.FAIL);
            });
    };

    return (
        <IconButton edge="end" onClick={handleClick} color="primary" size="large">
            {!loading ? <DeleteIcon /> : <CircularProgress size={24} />}
        </IconButton>
    );
};
