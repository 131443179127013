import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {styled} from "@mui/material";

const StyledTableHead = styled(TableHead)(() => ({
    "& .MuiTableCell-root.MuiTableCell-head": {
        fontWeight: 'bold',
    }
}));

const ActionsCell = styled(TableCell)(() => ({
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'center',
}));

const AddButton = styled(Button<any>)(({theme}) => ({
    [theme.breakpoints.up('md')]: {
        display: 'none',
    },
}));

const PaymentMethodCell = styled(TableCell)(({theme}) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

const CategoryCell = styled(TableCell)(({theme}) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

const CompanyCell = styled(TableCell)(({theme}) => ({
    [theme.breakpoints.down('md')]: {
        display: 'none',
    },
}));

export const TableHeader = () => (
    <StyledTableHead>
        <TableRow>
            <ActionsCell>
                <AddButton
                    component={Link}
                    size="small"
                    startIcon={<AddCircleIcon />}
                    to="/recurring-expenses/new"
                >
                    Add
                </AddButton>
            </ActionsCell>
            <TableCell>Description</TableCell>
            <TableCell>Amount</TableCell>
            <PaymentMethodCell>Payment Method</PaymentMethodCell>
            <CategoryCell>Category</CategoryCell>
            <CompanyCell>Company</CompanyCell>
        </TableRow>
    </StyledTableHead>
);
