import * as React from "react";
import "../../../../utils/number_extensions";
import {fetchYearsSnapshot} from "../../../../stores/snapshot";
import Error from "../../../Components/Error";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {IYearSnapshotItem} from "../../../../config/snapshot";
import {LoadingPlaceholders} from "../Components/LoadingPlaceholders";
import {YearCard} from "./YearCard";
import {NoResults} from "./Components/NoResults";
import {YearsSelector} from "./Components/YearsSelector";
import {Outlet} from "react-router-dom";

export const YearsList = () => {
    const { currencyStore } = useGlobalStores();

    const [data, setData] = React.useState<IYearSnapshotItem[]>([]);
    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<boolean>(false);

    React.useEffect(() => {
        (async () => {
            setLoading(true);

            try {
                const singleYearData = await fetchYearsSnapshot();

                setData(singleYearData.reverse());
            } catch (error) {
                setError(true);
            }

            setLoading(false);
        })();
    }, [fetchYearsSnapshot]);

    if (loading) {
        return (
            <LoadingPlaceholders showYearsLoading />
        );
    }

    if (error) {
        return (
            <Error />
        );
    }

    if (!data.length) {
        return (
            <NoResults />
        );
    }

    const currency = currencyStore.currentCurrency;

    return (
        <>
            <YearsSelector>
                {data.map(year => <YearCard year={year} currency={currency} key={year.year} />)}
            </YearsSelector>

            <Outlet />
        </>
    );
};
