import * as React from "react";
import {FreeTextField} from "../../../../../../Components/Form/FreeTextField";
import {FieldWrapper} from "./FieldWrapper";

interface IMerchantTransactionIdFieldProps {
    value: string;
    onChange(value: string): void;
    onDelete(): void;
}

export const MerchantTransactionIdField = ({value, onChange, onDelete}: IMerchantTransactionIdFieldProps) => (
    <FieldWrapper onDelete={onDelete}>
        <FreeTextField label="Merchant Transaction ID" value={value} onChange={onChange}/>
    </FieldWrapper>
);
