import {fetchDelete, fetchGet, fetchPatch, fetchPost} from "../services/api";
import {action, makeObservable, observable} from "mobx";
import { IBudget, IBudgetDetails, IBudgetDetailsUsage, IBudgetProgress, IBudgetRequest } from "../config/budget";
import { budgetsValidationFactory } from "../validators/budgets";
import moment from "moment";

const BUDGETS_CACHE_KEY = 'em:budgets:budgets';

export class BudgetStore {
    @observable budgets: IBudget[] = [];

    constructor() {
        makeObservable(this);

        const data = window.localStorage.getItem(BUDGETS_CACHE_KEY);
        if (!data) {
            return;
        }

        this.budgets = JSON.parse(data) || [];
    }

    @action
    load(budgets: IBudget[]) {
        this.budgets = budgets;

        this.updateLocalStorage();
    }

    @action
    addBudget(budget: IBudget) {
        this.budgets.push(budget);

        this.updateLocalStorage();
    }

    findBudget(budgetId: number): IBudget | null {
        return this.budgets.find(b => b.id === budgetId) || null;
    }

    @action
    replaceBudget(budget: IBudget) {
        const index = this.budgets.findIndex(p => p.id === budget.id);

        if (index === -1) {
            return;
        }

        this.budgets[index] = budget;

        this.updateLocalStorage();
    }

    @action
    removeBudget(budget: IBudget) {
        this.budgets = this.budgets.filter(b => b.id !== budget.id);

        this.updateLocalStorage();
    }

    private updateLocalStorage() {
        window.localStorage.setItem(BUDGETS_CACHE_KEY, JSON.stringify(this.budgets));
    }
}

export const fetchBudgets = (): Promise<IBudget[]> => {
    return fetchGet('/ego/budgets')
        .then(response => response.json())
        .then(data => data.data);
};

export const fetchBudgetDetails = (id: number | string): Promise<IBudgetDetails> => {
    return fetchGet(`/ego/budgets/${id}/details`)
        .then(response => response.json())
        .then(data => {
            const res = data.data;

            res.usages = res.usages.map((item: IBudgetDetailsUsage) => {
                item.from = moment(item.from);
                item.to = moment(item.to);

                return item;
            });

            return res;
        });
};

export const fetchBudgetsProgress = (): Promise<IBudgetProgress[]> => {
    return fetchGet('/ego/budgets/progress')
        .then(response => response.json())
        .then(data => data.data);
};

export const createBudget = (data: IBudgetRequest): Promise<IBudget> => {
    return fetchPost( `/ego/budgets`, data)
        .then((response: any) => {
            if (response.status !== 201) {
                return response.json().then((reason: any) => {
                    if (reason.errors === undefined) {
                        return Promise.reject(reason);
                    }

                    return Promise.reject(budgetsValidationFactory(reason.errors));
                });
            }

            return Promise.resolve({
                id: parseInt(response.headers.get('Location').replace(/.*\/([0-9]+)$/, '$1')),
                ...data
            });
        });
};

export const updateBudget = (id: number, data: IBudgetRequest): Promise<IBudget> => {
    return fetchPatch( `/ego/budgets/${id}`, data)
        .then((response: any) => {
            if (response.status !== 200) {
                return response.json().then((reason: any) => {
                    if (reason.errors === undefined) {
                        return Promise.reject(reason);
                    }

                    return Promise.reject(budgetsValidationFactory(reason.errors));
                });
            }

            return response.json();
        })
        .then((data) => data.data);
};

export const deleteBudget = (id: number): Promise<boolean> => {
    return fetchDelete(`/ego/budgets/${id}`);
};
