import { TransactionFormField } from "../../components/Transactions/Components/Form/config/config";
import { ICallbackResult } from "../../config/user_payment_method_callback";

export const shareWithFriendsPercentageCallback = (payload: any): ICallbackResult | undefined => {
    const errors = [];
    if (!!payload) {
        if (!payload.name) {
            errors.push('<name> property is missing in the payload');
        }
        if (!payload.split_method) {
            errors.push('<split_method> property is missing in the payload');
        }
        if (!payload.percentage) {
            errors.push('<percentage> property is missing in the payload');
        }
    }

    if (!!errors.length) {
        console.error('shareWithFriendsPercentageCallback', errors);
        return;
    }

    return {
        fieldKey: TransactionFormField.AMOUNT_SHARING,
        field: 'splits',
        data: payload,
    };
};
