import {IErrorResponseItem, ValidationError, ValidationErrors} from "../services/validator";

export enum BudgetsValidationField {
    NAME = "Budget.Name",
    AMOUNT = "Budget.Amount",
}

export type BudgetsValidation = {
    [key in BudgetsValidationField]?: ValidationError[];
};

export type BudgetsValidationErrors = ValidationErrors<BudgetsValidation, BudgetsValidationField>;

export const budgetsValidationFactory = (errors: IErrorResponseItem[]): BudgetsValidationErrors => {
    const v = new ValidationErrors<BudgetsValidation, BudgetsValidationField>();
    v.setMap({
        name: BudgetsValidationField.NAME,
        amount: BudgetsValidationField.AMOUNT,
    })
    v.setKeyParser((key) => {
        return key
            .replace('newBudget', 'Budget')
            .replace('updateBudget', 'Budget');
    });
    v.setErrors(errors);

    return v;
};
