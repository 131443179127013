import * as React from "react";
import {Wrapper} from "./Components/Wrapper";
import {Loading} from "./Components/Loading";
import {ITransaction} from "../../../../config/transactions";
import {fetchTransactions} from "../../../../stores/transactions";
import {TransactionsList} from "./Components/TransactionsList";
import {debounce} from "@mui/material";

interface IAutocompleteSearchResultProps {
    element: any | null;
    query: string;
    onTransactionClick(): void;
}

export const SearchAutocomplete = ({element, query, onTransactionClick}: IAutocompleteSearchResultProps) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [transactions, setTransactions] = React.useState<ITransaction[]>([]);

    const debouncedFetchTransactions = debounce((query: string) => {
        fetchTransactions({query} as any)
            .then(result => {
                setLoading(false);
                setTransactions(result.transactions);
            })
            .catch(() => {
                setLoading(false);
            });
    }, 300);

    React.useEffect(() => {
        setLoading(true);
        debouncedFetchTransactions(query);
    }, [query]);

    if (loading) {
        return (
            <Wrapper element={element}>
                <Loading />
            </Wrapper>
        );
    }

    if (!transactions.length) {
        return null;
    }

    return (
        <Wrapper element={element}>
            <TransactionsList transactions={transactions} onTransactionClick={onTransactionClick} />
        </Wrapper>
    );
};
