import {useState} from "react";

export const useModal = (isOpen: boolean = false) => {
    const [open, setOpen] = useState<boolean>(isOpen);
    const [loading, setLoading] = useState<boolean>(false);

    const handleModalOpen = () => {
        setOpen(true);
    };

    const handleModalClose = () => {
        setOpen(false);
    };

    return {
        open, setOpen,
        loading, setLoading,
        handleModalOpen, handleModalClose,
    };
};
