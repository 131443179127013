import {IEventDetails, IUpdateEvent} from "../config/event";
import moment from "moment/moment";
import {IEventFormOutputData, IEventFormState} from "../components/Events/Form/EventForm";

export const toForm = (event?: IEventDetails): IEventFormState => {
    if (!event) {
        return {
            name: '',
            budget: '',
            hasDateRange: false,
            from: moment(),
            to: moment(),
            transactions: [],
        }
    }

    return {
        name: event.name,
        budget: event.budget?.toString() || '',
        hasDateRange: false,
        from: event.from,
        to: event.to,
        transactions: event.transactions,
    };
};

export const toFormOutput = (state: IEventFormState): IEventFormOutputData => {
    const data = {
        name: state.name,
    } as IEventFormOutputData;

    if (!!state.budget) {
        data.budget = parseFloat(state.budget);
    }

    data.from = moment(state.from).format("YYYY-MM-DD");
    data.to = moment(state.to).format("YYYY-MM-DD");

    return data;
};

export const toUpdateRequest = (data: IEventFormOutputData): IUpdateEvent => {
    return {
        name: data.name,
        budget: data.budget || -1,
        from: data.from,
        to: data.to,
    };
};
