import * as React from "react";
import { lighten } from "@mui/material/styles";
import {ITransaction} from "../../../../../config/transactions";
import {useGlobalStores} from "../../../../../hooks/use_global_stores";
import {Divider, styled} from "@mui/material";
import {Link} from 'react-router-dom';
import {Description} from "../Parts/Description";
import {Date} from "../Parts/Date";
import {Amount} from "../Parts/Amount";

const StyledLink = styled(Link)(({ theme }) => ({
    display: 'block',
    cursor: 'pointer',
    padding: theme.spacing(1.5),
    textDecoration: 'none',
    color: 'black',

    '&:hover': {
        backgroundColor: lighten(theme.palette.primary.light, 0.85),
    },
}));

interface ITransactionsListProps {
    transactions: ITransaction[];
    onTransactionClick(): void;
}

export const TransactionsList = ({transactions, onTransactionClick}: ITransactionsListProps) => {
    const {currencyStore} = useGlobalStores();

    return (
        <div>
            {transactions.map(transaction => (
                <React.Fragment key={transaction.transaction_id}>
                    <StyledLink to={`/transactions/${transaction.transaction_id}`} onClick={onTransactionClick}>
                        <Description transaction={transaction} />
                        <Date transaction={transaction} />
                        <Amount transaction={transaction} currency={currencyStore.currentCurrency} />
                    </StyledLink>
                    <Divider/>
                </React.Fragment>
            ))}
        </div>
    );
};
