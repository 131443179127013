import {
    IRecurringExpense, IRecurringExpenseCreate, IRecurringExpensesStats, IRecurringExpenseUpdate,
    RecurringExpensesTimeFrequencies,
    RecurringExpensesTimeFrequency
} from "../config/recurring_expense";
import {IRecurringExpenseFormOutputData} from "../components/RecurringExpenses/Form/RecurringExpenseForm";
import {ICompany} from "../config/company";
import moment from "moment";
import {weeksOfMonth} from "../utils/date";
import { IIncome } from "../config/income";

export interface IRecurringExpensesCategory {
    code: RecurringExpensesTimeFrequency;
    name: string;
    count: number;
}

export const buildCompanyFromRecurringExpense = (recurringExpense: IRecurringExpense): ICompany | null => {
    if (!recurringExpense.company_id || !recurringExpense.company_name) {
        return null;
    }

    return {
        id: recurringExpense.company_id,
        name: recurringExpense.company_name,
    };
};

const categorizeIntoTimeFrequencies = (recurringExpense: IRecurringExpense[]): IRecurringExpensesCategory[] => {
    const categories = {} as any;

    RecurringExpensesTimeFrequencies.forEach(timeFrequency => {
        categories[timeFrequency.code] = {
            ...timeFrequency,
            count: 0,
        };
    });

    recurringExpense.forEach(recurringExpense => {
        categories[recurringExpense.time_frequency].count++;
    });

    return (Object as any).values(categories);
};

interface IIncomeRecurringExpense {
    income?: IIncome;
    recurringExpenses: IRecurringExpense[];
}

export const categorizeIntoIncomes = (incomes: IIncome[], recurringExpenses: IRecurringExpense[]): IIncomeRecurringExpense[] => {
    const tmp = {
        null: {
            income: null,
            recurringExpenses: [],
        },
    } as any;

    incomes.forEach(item => tmp[item.id] = {
        income: item,
        recurringExpenses: [],
    });

    recurringExpenses.forEach(item => tmp[item.income_id || 'null']?.recurringExpenses.push(item));

    return Object.values(tmp).filter((item: any) => !!item.recurringExpenses.length) as any;
};

const getFirstCategoryWithElements = (categories: IRecurringExpensesCategory[]): IRecurringExpensesCategory | null => {
    const length = categories.length;
    for (let i = 0; i < length; ++i) {
        if (categories[i].count) {
            return categories[i];
        }
    }

    return null;
};

const convertFormOutputDataToRecurringExpenseCreate = (data: IRecurringExpenseFormOutputData): IRecurringExpenseCreate => {
    return {
        income_id: data.income_id ? parseInt(data.income_id.toString()) : null,
        description: data.description,
        time_frequency: data.time_frequency as any,
        currency: data.currency  as any,
        amount: data.amount,
        category_id: data.category_id,
        company_id: data.company_id,
        payment_method_id: data.payment_method_id,
        active: data.active,
    };
};

const convertFormOutputDataToRecurringExpenseUpdate = (data: IRecurringExpenseFormOutputData): IRecurringExpenseUpdate => {
    return {
        income_id: data.income_id ? parseInt(data.income_id.toString()) : null,
        description: data.description,
        time_frequency: data.time_frequency as any,
        currency: data.currency as any,
        amount: data.amount,
        category_id: data.category_id || 0,
        company_id: data.company_id || 0,
        payment_method_id: data.payment_method_id || 0,
        active: data.active,
    };
};

const convertFormOutputDataToRecurringExpense = (id: number, data: IRecurringExpenseFormOutputData, additionalData: object = {}): IRecurringExpense => {
    return {
        id: id,
        description: data.description,
        time_frequency: data.time_frequency as any,
        currency: data.currency as any,
        amount: data.amount,
        category_id: data.category_id || 0,
        company_id: data.company_id || 0,
        payment_method_id: data.payment_method_id || 0,
        active: data.active,
        ...additionalData,
    };
};

const convertTimeFrequencyToQueryStringParam = (timeFrequency: RecurringExpensesTimeFrequency) => {
    return timeFrequency.toLowerCase();
};

const convertQueryStringParamToTimeFrequency = (): RecurringExpensesTimeFrequency | null => {
    const params = new URLSearchParams(window.location.search);

    if (!params.get('t') || params.get('t') === '') {
        return null;
    }

    return (params.get('t') as string).toUpperCase() as RecurringExpensesTimeFrequency;
};

export const calculateAverage = (stats: IRecurringExpensesStats, frequency: RecurringExpensesTimeFrequency): number => {
    const daily = stats.DAILY || 0;
    const weekly = stats.WEEKLY || 0;
    const monthly = stats.MONTHLY || 0;
    const yearly = stats.YEARLY || 0;

    const today = moment();

    if (frequency === RecurringExpensesTimeFrequency.DAILY) {
        return (
            daily +
            (weekly / 7) +
            (monthly / moment().daysInMonth()) +
            (yearly / 365)
        );
    } else if (frequency === RecurringExpensesTimeFrequency.WEEKLY) {
        return (
            (daily * 7) +
            weekly +
            (monthly / weeksOfMonth(today)) +
            (yearly / 52)
        );
    } else if (frequency === RecurringExpensesTimeFrequency.MONTHLY) {
        return (
            (daily * moment().daysInMonth()) +
            (weekly * weeksOfMonth(today)) +
            monthly +
            (yearly / 12)
        );
    } else if (frequency === RecurringExpensesTimeFrequency.YEARLY) {
        return (
            (daily * 365) +
            (weekly * 52) +
            (monthly * 12) +
            yearly
        );
    }

    return 0;
};

export {categorizeIntoTimeFrequencies, getFirstCategoryWithElements, convertFormOutputDataToRecurringExpenseCreate, convertFormOutputDataToRecurringExpenseUpdate, convertFormOutputDataToRecurringExpense, convertTimeFrequencyToQueryStringParam, convertQueryStringParamToTimeFrequency}
