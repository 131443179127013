import * as React from "react";
import {IStatsCompany} from "../../../../config/stats";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {styled} from "@mui/material";

const Container = styled('ul')(({theme}) => ({
    margin: 0,
    padding: 0,
    marginTop: theme.spacing(1),
    listStyle: 'none',
    fontSize: 18,
}));

interface ICompanyDetailsProps {
    company: IStatsCompany;
}

export const CompanyDetails = ({company}: ICompanyDetailsProps) => {
    const { currencyStore } = useGlobalStores();

    return (
        <Container>
            <li>Count: <strong>{company.count}</strong></li>
            <li>Amount: <strong>{(company.total as any).toCurrency(currencyStore.currentCurrency)}</strong></li>
        </Container>
    );
};
