import * as React from "react";
import {ReactNode} from "react";
import {Trend, TrendIcon} from "./Icons/TrendIcon";
import {grey} from "@mui/material/colors";
import {alpha, Avatar, Card, CardContent, Grid, styled, Typography} from "@mui/material";
import {LinkableCardActionArea} from "./LinkableCardActionArea";
import {colorToMuiColor} from "../../helpers/snapshot_helpers";

export enum SnapshotCardColor {
    GREEN,
    PINK,
    ORANGE,
    PURPLE,
}

interface ISnapshotCardProps {
    title: string;
    icon: ReactNode;
    color: SnapshotCardColor;
    trend?: Trend,
    value: any;
    details?: any;
    target?: string;
    condensed?: boolean
}

interface IStyled {
    condensed?: boolean;
    color?: SnapshotCardColor;
}

const Title = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "condensed",
})<IStyled>(({condensed, theme}) => ({
    marginLeft: theme.spacing(condensed ? 1 : 2),
    color: grey[800],
    fontSize: condensed ? 14 : 18,
}));

const StyledCardContent = styled(CardContent, {
    shouldForwardProp: (prop) => prop !== "condensed",
})<IStyled>(({condensed, color, theme}) => ({
    padding: condensed ? theme.spacing(1) : 'auto',
    backgroundColor: alpha(colorToMuiColor(color!!)[50], 0.4),
}));

const StyledAvatar = styled(Avatar, {
    shouldForwardProp: (prop) => prop !== "condensed",
})<IStyled>(({condensed, color, theme}) => {
    const coloring = {
        color: theme.palette.getContrastText(colorToMuiColor(color!!)[500]),
        backgroundColor: colorToMuiColor(color!!)[500],
    };

    if (condensed) {
        return {
            ...coloring,
            width: theme.spacing(3),
            height: theme.spacing(3),
            '& > svg': {
                fontSize: 16,
            },
        };
    }

    return {
        ...coloring,
        width: theme.spacing(6),
        height: theme.spacing(6),
    };
});

const MainContainer = styled('div', {
    shouldForwardProp: (prop) => prop !== "condensed",
})<IStyled>(({condensed, theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing(condensed ? 0 : 2),
    paddingBottom: theme.spacing(condensed ? 0 : 2),
    textAlign: 'center',
    fontSize: condensed ? 36 : 46,
}));

const DetailsContainer = styled('div', {
    shouldForwardProp: (prop) => prop !== "condensed",
})<IStyled>(({condensed, theme}) => ({
    color: grey[400],
    fontSize: condensed ? 12 : 14,
    textAlign: 'center',
}));

export const SnapshotCard = ({title, value, color, icon, trend, details, target, condensed}: ISnapshotCardProps) => {
    return (
        <Card>
            <LinkableCardActionArea target={target}>
                <StyledCardContent condensed={condensed} color={color as any}>
                    <Grid
                        container
                        direction="row"
                        alignItems="center"
                    >
                        <Grid item>
                            <StyledAvatar condensed={condensed} color={color as any}>
                                {icon}
                            </StyledAvatar>
                        </Grid>

                        <Grid item>
                            <Title variant="h5" condensed={condensed}>
                                {title}
                            </Title>
                        </Grid>
                    </Grid>

                    <MainContainer condensed={condensed}>
                        {trend && <TrendIcon trend={trend}/>}
                        {value}
                    </MainContainer>

                    {
                        details &&
                        <DetailsContainer condensed={condensed}>
                            {details}
                        </DetailsContainer>
                    }
                </StyledCardContent>
            </LinkableCardActionArea>
        </Card>
    );
};
