import * as React from "react";
import {ITransaction, ITransactionSplit} from "../../../../../config/transactions";
import {convertAmountToFloat} from "../../../../../helpers/transaction_helpers";
import {SplitMethod} from "../../../Components/Form/Fields/ShareWithFriends/FriendSelector/FriendSelector";
import {TableCell, styled} from "@mui/material";
import { DataTable } from "../../../../Components/DataTable";

const NameCell = styled(TableCell)(() => ({
    paddingLeft: 0,
    width: 100,
}));

const AmountCell = styled(TableCell)(() => ({
}));

const Percentage = styled('div')(({theme}) => ({
    display: 'inline-block',
    marginLeft: theme.spacing(0.5),
    fontSize: '65%',
}));

interface IValueProps {
    transaction: ITransaction;
    split: ITransactionSplit;
}

const Value = ({transaction, split}: IValueProps) => {
    if (split.split_method === SplitMethod.PERCENTAGE) {
        return (
            <>
                {((convertAmountToFloat(transaction.original_amount) * split.percentage!! / 100) as any).toCurrency(transaction.original_currency)}
                <Percentage>
                    ({split.percentage}%)
                </Percentage>
            </>
        )
    } else if (split.split_method === SplitMethod.FIXED) {
        return (
            <>
                {(convertAmountToFloat(split.amount!) as any).toCurrency(transaction.original_currency)}
            </>
        )
    }

    return null;
};

interface ISharedWithProps {
    transaction: ITransaction;
}

export const SharedWith = ({transaction}: ISharedWithProps) => (
    <DataTable
        size="small"
        hideHeader
        items={transaction.splits || []}
        config={[
            {
                title: 'Name',
                fieldName: 'name',
                component: NameCell,
            },
            {
                title: 'Amount',
                fieldName: 'total',
                component: AmountCell,
                render(value: any, item: any): any {
                    return <Value transaction={transaction} split={item} />;
                }
            },
        ]}
    />
);
