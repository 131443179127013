import {action, makeObservable, observable} from "mobx";
import {ISingleYear, ISnapshotTimeline, ISnapshotV3, IYearSnapshotItem} from "../config/snapshot";
import {fetchGetV2, fetchGetV3} from "../services/api";

export class SnapshotStore {
    @observable snapshotTimeline: ISnapshotTimeline | null = null;
    @observable snapshotV3: ISnapshotV3 | null = null;

    constructor() {
        makeObservable(this);
    }

    @action
    setSnapshotTimeline(snapshotTimeline: ISnapshotTimeline) {
        this.snapshotTimeline = snapshotTimeline;
    }

    @action
    setSnapshotV3(setSnapshotV3: ISnapshotV3) {
        this.snapshotV3 = setSnapshotV3;
    }
}

export const fetchSnapshot = (): Promise<ISnapshotTimeline> => {
    return fetchGetV2( '/stats/snapshot')
        .then(response => response.json())
        .then(data => data.data);
};
export const fetchSnapshotV3 = (): Promise<ISnapshotV3> => {
    return fetchGetV3( '/stats/snapshot')
        .then(response => response.json())
        .then(data => data.data);
};

export const fetchYearsSnapshot = (): Promise<IYearSnapshotItem[]> => {
    return fetchGetV2( '/stats/years')
        .then(response => response.json())
        .then(data => data.data);
};

export const fetchSingleYearSnapshot = (year: number | string): Promise<ISingleYear> => {
    return fetchGetV3( `/stats/years/${year}`)
        .then(response => response.json())
        .then(data => data.data);
};
