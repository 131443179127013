import * as React from "react";
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import {transformToMuiSelectFormat} from "../../../../helpers/category_helpers";
import {useGlobalStores} from "../../../../hooks/use_global_stores";

interface ICategoryFieldProps {
    value: any;
    onChange: any;
}

export const CategoryField = ({value, onChange}: ICategoryFieldProps) => {
    const { categoryStore } = useGlobalStores();
    const options = transformToMuiSelectFormat(categoryStore.categories);

    const handleChange = (event: any) => {
        onChange(event.target.value);
    };

    return (
        <FormControl fullWidth variant="outlined">
            <TextField
                select
                value={value !== '' ? parseInt(value) : ''}
                onChange={handleChange}
                label="Category"
                variant="outlined"
                SelectProps={{
                    renderValue: (categoryId: any) => {
                        const category = categoryStore.categoriesMap.get(categoryId);
                        if (!category) {
                            return '';
                        }

                        return category.name.replace(/--/g, '').trim();
                    },
                }}
            >
                {options.map((option, i) => (
                    <MenuItem key={i} value={option.value}>
                        <span dangerouslySetInnerHTML={{__html: option.label.replace(/--/g, '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;')}} />
                    </MenuItem>
                ))}
            </TextField>
        </FormControl>
    );
};
