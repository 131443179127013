import * as React from "react";
import {
    AdvancedMarker,
    ControlPosition,
    Map,
    MapControl,
    useAdvancedMarkerRef,
    useMap,
    useMapsLibrary
} from "@vis.gl/react-google-maps";
import {MapMouseEvent} from "@vis.gl/react-google-maps/dist/components/map/use-map-events";
import {useModal} from "../../../../hooks/use_modal";
import {useEffect, useState} from "react";
import {FormModal} from "../../FormModal";
import CircularProgress from "@mui/material/CircularProgress";
import {Box, styled, Typography, useMediaQuery, useTheme} from "@mui/material";
import {SaveButton} from "../../SaveButton";
import {MapHandler, PlaceAutocomplete} from "./PlaceAutocomplete";
import {IPosition} from "./GoogleMapsLocationFinderField";

export interface IGoogleMapsLocation {
    location_id: string;
    name: string;
    address: string;
    lat: number;
    lon: number;
    categories: string[];
}

interface IMapViewProps {
    defaultPosition?: IPosition;
    onSelect(value: IGoogleMapsLocation): void;
}

const StyledMap = styled(Map)(({theme}) => ({
    width: '100%',
    height: '600px',

    [theme.breakpoints.down('sm')]: {
        height: '90vh',
    },
}));

export const MapView = ({defaultPosition, onSelect}: IMapViewProps) => {
    const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

    const { loading, setLoading, open, handleModalOpen, handleModalClose } = useModal();
    const [selection, setSelection] = useState<IGoogleMapsLocation>();

    const [selectedPlace, setSelectedPlace] = useState<google.maps.places.PlaceResult | null>(null);
    const [markerRef, marker] = useAdvancedMarkerRef();
    const map = useMap();
    const places = useMapsLibrary('places');

    const handleClick = (event: MapMouseEvent) => {
        if (!map || !places || !event?.detail?.placeId) {
            return;
        }

        setLoading(true);

        const svc = new places.PlacesService(map);
        svc.getDetails({placeId: event.detail.placeId}, (result) => {
            setLoading(false);

            if (!result) {
                setSelection(undefined);
                return;
            }

            setSelection({
                location_id: result.place_id!,
                name: result.name!,
                address: result.formatted_address!,
                lat: event.detail.latLng!.lat,
                lon: event.detail.latLng!.lng,
                categories: result.types || [],
            });
        });
    };

    const handleSelect = () => {
        handleModalClose();
        onSelect(selection!);
    };

    useEffect(() => {
        if (!!selection) {
            handleModalOpen();
        }
    }, [selection]);

    return (
        <>
            <StyledMap
                mapId={'12345'}
                defaultCenter={!!defaultPosition ? {lat: defaultPosition.lat, lng: defaultPosition.lon} : undefined}
                defaultZoom={12}
                gestureHandling="greedy"
                clickableIcons
                streetViewControl={false}
                fullscreenControl={false}
                mapTypeControl={false}
                scaleControl={!isSmallScreen}
                onClick={handleClick}
            >
                <AdvancedMarker ref={markerRef} position={null} />
            </StyledMap>
            <MapControl position={ControlPosition.TOP}>
                <PlaceAutocomplete onPlaceSelect={setSelectedPlace} />
            </MapControl>
            <MapHandler place={selectedPlace} marker={marker} />

            <FormModal
                title="Selection"
                loading={false}
                open={open}
                onClose={handleModalClose}
                maxWidth={"sm"}
            >
                <div>
                    {loading && (
                        <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                            <CircularProgress size={40}/>
                        </Box>
                    )}

                    {!!selection && (
                        <>
                            <div>
                                <Typography variant="h6">{selection.name}</Typography>
                                <Typography variant="body2">{selection.address}</Typography>
                            </div>

                            <Box marginTop={2}>
                                <SaveButton loading={false} label="Select" onSubmit={handleSelect} />
                            </Box>
                        </>
                    )}
                </div>
            </FormModal>
        </>
    )
};
