import {ILocation, LocationVendor} from "../../../config/location";
import {apiAtlasV1} from "../../../services/api";

interface ISearchLocations {
    page: number;
}

interface ISearchResult {
    locations: ILocation[];
    count: number;
    total: number;
}

interface IUpdateLogoResult {
    logo: string;
}

export interface IFormLocationRequest {
    name: string;
    vendor: LocationVendor;
    vendor_id: string;
    default_category_id?: number;
    address?: string | null;
}

export const fetchLocations = ({page}: ISearchLocations): Promise<ISearchResult> => {
    return apiAtlasV1.GET( '/locations', {page})
        .then(response => response.json())
        .then(data => data.data);
};

export const createLocation = (data: IFormLocationRequest): Promise<ILocation> => {
    return apiAtlasV1.POST( `/locations`, data)
        .then((response: any) => {
            if (response.status !== 201) {
                return response.json().then((reason: any) => {
                    if (reason.errors === undefined) {
                        return Promise.reject(reason);
                    }

                    return Promise.reject();
                });
            }

            return Promise.resolve({
                id: parseInt(response.headers.get('Location').replace(/.*\/([0-9]+)$/, '$1')),
                ...data
            });
        });
};

export const updateLocation = (id: number, data: IFormLocationRequest): Promise<ILocation> => {
    return apiAtlasV1.PATCH( `/locations/${id}`, data)
        .then((response: any) => {
            if (response.status !== 204) {
                return response.json().then((reason: any) => {
                    if (reason.errors === undefined) {
                        return Promise.reject(reason);
                    }

                    return Promise.reject();
                });
            }
        });
};

export const uploadLogo = (locationId: number, formData: FormData): Promise<IUpdateLogoResult> => {
    return apiAtlasV1.PUT(`/locations/${locationId}/logo`, formData)
        .then(response => response.json())
        .then(data => data.data);
};

export const deleteLocation = (id: number): Promise<boolean> => {
    return apiAtlasV1.DELETE(`/locations/${id}`);
};

export const mergeLocations = (id: number, mergedIds: number[]): Promise<boolean> => {
    return apiAtlasV1.POST(`/locations/${id}/merge`, {
        location_ids: mergedIds,
    });
};
