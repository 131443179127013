import * as React from "react";
import {Currency} from "../../../../../config/currencies";
import {DashboardItem} from "../../../Components/DashboardItem";
import {prefillMonthsOfTheYear} from "../../../../../helpers/dashboard_charts_helpers";
import {IMonthSnapshotItem} from "../../../../../config/snapshot";
import moment from "moment";
import {BarChart} from "@mui/x-charts";
import {useGlobalStores} from "../../../../../hooks/use_global_stores";

interface ICurrentYearMonthsItemProps {
    data: IMonthSnapshotItem[];
    currency: Currency;
}

export const CurrentYearMonths = ({data, currency}: ICurrentYearMonthsItemProps) => {
    const { currencyStore } = useGlobalStores();

    const dataset = prefillMonthsOfTheYear(data) as any[];

    return (
        <DashboardItem title="Current year overview" fullWidth styles={{
            root: {
            },
            paper: {
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
            },
            main: {
                flexGrow: 1,
            },
        }}>
            <BarChart
                dataset={dataset}
                series={[
                    {
                        type: 'bar',
                        dataKey: 'total',
                        color: 'rgb(83 131 236)',
                        valueFormatter: (v): string => {
                            return v?.toCurrency(currencyStore.currentCurrency, {}) || '';
                        },
                    }
                ]}
                axisHighlight={{
                    x: 'none',
                }}
                xAxis={[
                    {

                        dataKey: 'date',
                        scaleType: 'band',
                        valueFormatter: (date: Date ) => {
                            return moment(date).format('MMMM');
                        },
                    },
                ]}
                grid={{horizontal: true}}
                borderRadius={3}
                margin={{top: 5, right: 20, bottom: 30}}
                sx={{
                    '&&': {
                        touchAction: 'auto',
                    },
                }}
            />
        </DashboardItem>
    );
};
