import * as React from "react";
import {PageOuterContainer} from "../../../Components/PageOuterContainer";
import {PageItem} from "../../../Components/PageItem";
import {IBulkDryRunResult} from "../../../../config/bulk";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import {Steps} from "./Steps/Steps";
import {styled} from "@mui/material";
import {SelectFormStep} from "./Steps/SelectFormStep/SelectFormStep";
import {CheckDataStep} from "./Steps/CheckDataStep/CheckDataStep";
import {ImportStep} from "./Steps/ImportStep/ImportStep";
import {DoneStep} from "./Steps/DoneStep/DoneStep";

const StyledPageItem = styled(PageItem)(({theme}) => ({
    marginTop: 0,
}));

const STEP_KEYS = [
    'selectForm',
    'checkData',
    'import',
    'done',
];

interface IDataProps {
    selectForm: any;
    checkData: IBulkDryRunResult[];
    import: IBulkDryRunResult[];
    done: any;
}

const defaultData = {
    selectForm: null,
    checkData: [],
    import: [],
    done: null,
};

export const Bulk = () => {
    const { t } = useTranslation();

    const [activeStep, setActiveStep] = useState(0);
    const [data, setData] = useState<IDataProps>(defaultData);

    const handleNext = (promise: Promise<any>) => {
        promise.then(result => {
            setData((prevData: any) => {
                return { ...prevData, [STEP_KEYS[activeStep + 1]]: result }
            });
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        });
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
        setData(defaultData);
    };

    return (
        <PageOuterContainer>
            <StyledPageItem title={t('transactions.bulk.title')}>
                <Steps activeStep={activeStep} />

                {activeStep === 0 && <SelectFormStep onSubmit={handleNext} />}
                {activeStep === 1 && <CheckDataStep data={data.checkData} onSubmit={handleNext} onCancel={handleBack} />}
                {activeStep === 2 && <ImportStep data={data.import} onDone={handleNext} />}
                {activeStep === 3 && <DoneStep data={data.done} onRestart={handleReset} />}
            </StyledPageItem>
        </PageOuterContainer>
    )
}
