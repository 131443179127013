import * as React from "react";
import {useGlobalStores} from "../../../../../hooks/use_global_stores";
import {Alert, AlertTitle, Button, Grid, useMediaQuery, useTheme} from "@mui/material";

export const NotDefaultSearchWarning = () => {
    const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('xs'));

    const { searchStore, refreshStore } = useGlobalStores();

    const handleReset = () => {
        searchStore.reset();
        refreshStore.refreshDashboard();
        refreshStore.refreshTransactionsList();
    };

    return (
        <Grid item xs={12}>
            <Alert
                severity="warning"
                action={
                    <Button color="inherit" size="small" onClick={handleReset}>
                        RESET {!isSmallScreen && "SEARCH"}
                    </Button>
                }
            >
                <AlertTitle>Warning</AlertTitle>
                <span style={{cursor: 'pointer'}} onClick={handleReset}>
                    The current data displayed is coming from a custom search, if you wanna see the current month results click here.
                </span>
            </Alert>
        </Grid>
    );
};
