import * as React from "react";
import {LOGO_WIDTH} from "./Logo";
import ImageIcon from '@mui/icons-material/Image';
import {grey} from "@mui/material/colors";
import {styled} from "@mui/material";

const Container = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: LOGO_WIDTH,
    height: LOGO_WIDTH,
}));

const Icon = styled(ImageIcon)(() => ({
    fontSize: 120,
    color: grey[100],
}));

export const MissingLogo = () => (
    <Container>
        <Icon />
    </Container>
);
