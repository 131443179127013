import * as React from "react";
import AdjustIcon from "@mui/icons-material/Adjust";
import ArrowUpwardRoundedIcon from "@mui/icons-material/ArrowUpwardRounded";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import {green, orange, red} from "@mui/material/colors";

export enum Trend {
    NONE,
    UP,
    DOWN,
    SIDEWAYS,
}

interface ITrendIcon {
    trend: Trend;
}

const sharedStyle = {
    fontSize: 20,
    fontWeight: 900,
    marginRight: 1,
};

export const TrendIcon = ({trend}: ITrendIcon) => {
    switch (trend) {
        case Trend.NONE: return null;
        case Trend.UP: return <ArrowUpwardRoundedIcon sx={{...sharedStyle, color: red[900]}} />;
        case Trend.DOWN: return <ArrowDownwardRoundedIcon sx={{...sharedStyle, color: green[900]}} />;
        case Trend.SIDEWAYS: return <AdjustIcon sx={{...sharedStyle, color: orange[900]}} />;
        default: return null;
    }
};
