import { Feature, IFeature } from "../config/features";

export const featuredSelected = (selected?: Feature) => (v: IFeature): boolean => {
    return v.code === selected;
};

export const featureNotSelected = (selected?: Feature) => (v: IFeature): boolean => {
    if (!selected) {
        return true;
    }

    return v.code !== selected;
};
