import * as React from "react";
import {Snackbar, styled} from "@mui/material";
import MuiAlert  from '@mui/material/Alert';
import Slide, { SlideProps } from '@mui/material/Slide';
import { useGlobalStores } from "../../hooks/use_global_stores";
import { observer } from "mobx-react";

const SlideTransition = (props: SlideProps) => (
    <Slide {...props} direction="up" />
);

const StyledSnackbar = styled(Snackbar)(({ theme }) => ({
    [theme.breakpoints.down('sm')]: {
        bottom: 100,
    },
}));


export const ConfirmationSnackbar = observer(() => {
    const { snackbarStore } = useGlobalStores();

    const handleClose = () => {
        snackbarStore.close();
    };

    React.useEffect(() => {
        if (!snackbarStore.isOpen) {
            return;
        }

        setTimeout(() => {
            handleClose();
        }, 3000);
    }, [snackbarStore.isOpen]);

    if (!snackbarStore.isOpen) {
        return null;
    }

    return (
        <StyledSnackbar
            open={snackbarStore.isOpen}
            autoHideDuration={3000}
            disableWindowBlurListener={true}
            TransitionComponent={SlideTransition}
            anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
        >
            <MuiAlert
                elevation={6}
                variant="filled"
                onClose={handleClose}
                severity={snackbarStore.isSuccessful ? "success" : "error"}
            >
                {snackbarStore.isSuccessful ?
                    (snackbarStore.successMessage || "The operation has been successful!") :
                    (snackbarStore.failMessage || "An error occurred. Try again!")
                }
            </MuiAlert>
        </StyledSnackbar>
    );
});
