import * as React from "react";
import {DashboardStore} from "../stores/dashboard";
import {SearchStore} from "../stores/search";
import {CurrencyStore} from "../stores/currency";
import {createGlobalStores} from "./global_stores_context";
import {RefreshStore} from "../stores/refresh_store";

interface IDashboardStores {
    dashboardStore: DashboardStore,
    searchStore: SearchStore,
    currencyStore: CurrencyStore,
    refreshStore: RefreshStore,
}

export const createDashboardStores = (): IDashboardStores => {
    return {
        dashboardStore: createGlobalStores().dashboardStore,
        searchStore: createGlobalStores().searchStore,
        currencyStore: createGlobalStores().currencyStore,
        refreshStore: createGlobalStores().refreshStore,
    }
};

export const DashboardStoresContext = React.createContext<IDashboardStores | null>(null);
