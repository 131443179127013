import * as React from "react";
import {IStatsCompany} from "../../../../config/stats";
import {styled} from "@mui/material";

const Container = styled('span')(({theme}) => ({
    display: 'block',
    marginTop: theme.spacing(1),
    fontWeight: 500,
}));

interface ICompany {
    company: IStatsCompany;
}

export const CompanyName = ({company}: ICompany) => (
    <Container>
        {company.name === '___NA___' ? 'No company name' : company.name}
    </Container>
);
