import * as React from "react";
import {ITransaction} from "../../../../../config/transactions";
import {styled} from "@mui/material";
import { getDisplayLabel } from "../../../../../helpers/transaction_helpers";

const Container = styled('div')(({ theme }) => ({
    color: theme.palette.primary.main,
    fontSize: 16,
    fontWeight: 500,
}));

interface IDescriptionProps {
    transaction: ITransaction;
}

export const Description = ({transaction}: IDescriptionProps) => {
    const label = getDisplayLabel(transaction);
    if (label === '') {
        return null;
    }

    return (
        <Container>{label}</Container>
    );
};
