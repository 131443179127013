import {ITransactionDetails} from "../config/transactions";
import {ITransactionDetailFormField, TransactionDetailFields} from "../config/transaction_details";
import {TransactionFormScope} from "../components/Transactions/Components/Form/config/config";

export class DetailsLocalStore {
    private readonly details: ITransactionDetails;
    private tags: string[] | undefined;

    private constructor(details: ITransactionDetails) {
        this.details = details;
    }

    static Init(details: ITransactionDetails): DetailsLocalStore {
        return new DetailsLocalStore(details);
    }

    withTags(tags: string[]): this {
        if (tags.length === 0) {
            return this
        }

        this.tags = tags
        return this
    }

    get(): ITransactionDetails {
        if (!this.details.tags && !!this.tags && this.tags.length > 0) {
            this.details.tags = this.tags;
        }

        return this.details;
    }
}

export const filterOptionsByScope = (options: ITransactionDetailFormField[], scope: TransactionFormScope): ITransactionDetailFormField[] => {
    if (scope === TransactionFormScope.BULK_EDIT) {
        return options.filter(o => o.code === TransactionDetailFields.TAGS);
    }

    return options;
};
