import {TransactionsListAction} from "./config";

export const hasActionSelect = (actions?: TransactionsListAction[]): boolean => {
    return !actions || (!!actions && actions.indexOf(TransactionsListAction.SELECT) > -1)
};

export const getActionsColumnWidth = (actions?: TransactionsListAction[]): number => {
    if (hasActionSelect(actions) && (!actions || actions!!.length > 1)) {
        return 90;
    }

    return 100;
};
