import * as React from "react";
import {ReactNode} from "react";
import Box from "@mui/material/Box";
import {styled} from "@mui/material";

const Container = styled('div')(({theme}) => ({
    padding: theme.spacing(1),
    margin: theme.spacing(-1),
    marginBottom: theme.spacing(2),
    overflowX: 'auto',
    overflowY: 'hidden',
    '-webkit-overflow-scrolling': 'touch',
}));

interface IYearsSelectorProps {
    children: ReactNode;
}

export const YearsSelector = ({children}: IYearsSelectorProps) => (
    <Container>
        <Box sx={{display: 'flex'}}>
            {children}
        </Box>
    </Container>
);
