import * as React from "react";
import {IUserPaymentMethod} from "../../../../config/user_payment_method";
import {AccountBalance, CreditCard, Money} from "@mui/icons-material";

interface IPaymentMethodIconProps {
    paymentMethod: IUserPaymentMethod;
}

export const PaymentMethodIcon = ({paymentMethod}: IPaymentMethodIconProps) => {
    switch (paymentMethod.type) {
        case 'AMEX':
        case 'EC':
        case 'MASTER_CARD':
        case 'VISA':
            return <CreditCard />;

        case 'CASH':
            return <Money />;

        case 'WIRE':
            return <AccountBalance />;
    }

    return (
        <span>{paymentMethod.name[0]}</span>
    );
};
