import * as React from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";

interface IEditActionProps {
    onClick(): void;
}

export const EditAction = ({onClick}: IEditActionProps) => (
    <IconButton size="small" color="primary" onClick={onClick}>
        <EditIcon />
    </IconButton>
);
