import * as React from "react";
import { IAttachment, INewAttachment } from "../../../../../../config/attachments";
import { Status } from "./AttachmentsField";
import { List as MuiList } from "@mui/material";
import { Item } from "./Item";

interface IListProps {
    attachments: IAttachment[];
    newAttachments: INewAttachment[];
    deletedAttachments: number[];
    onDelete(status: Status, id: number): void;
    onUndelete(status: Status, id: number): void;
}

export const List = ({attachments, newAttachments, deletedAttachments, onDelete, onUndelete}: IListProps) => {
    if (!attachments.length && !newAttachments.length) {
        return (
            <div>No attachments</div>
        )
    }

    const handleDelete = (status: Status, id: number) => () => {
        onDelete(status, id);
    };

    const handleUndelete = (status: Status, id: number) => () => {
        onUndelete(status, id);
    };

    return (
        <MuiList>
            {attachments.map((a, index) => {
                return (
                    <Item
                        key={index}
                        type={a.type}
                        label={a.name || `Attachment #${index+1}`}
                        onDelete={handleDelete(Status.EXISTING, a.id)}
                        onUndelete={handleUndelete(Status.EXISTING, a.id)}
                        deleted={deletedAttachments.indexOf(a.id) > -1}
                    />
                )
            })}

            {newAttachments.map((a, index) => (
                <Item
                    key={index}
                    type={a.type}
                    label={a.file.name}
                    onDelete={handleDelete(Status.NEW, index)}
                />
            ))}
        </MuiList>
    );
};
