import * as React from 'react';
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {SxProps} from "@mui/system";
import {Theme} from "@mui/material";

interface IConfig {
    title: string;
    fieldName: string;
    render?(value: any, item: any): any;
    component?: React.FunctionComponent;
    sx?: SxProps<Theme>;
}

interface IDataTableProps {
    size?: 'small' | 'medium';
    sx?: SxProps<Theme>;
    config?: IConfig[];
    items: any[];
    hideHeader?: boolean;
}

export const DataTable = ({size, sx, config, items, hideHeader = false}: IDataTableProps) => {
    return (
        <TableContainer>
            <Table size={size} sx={sx}>
                {!!config && !!config.length && !hideHeader &&
                    <TableHead>
                        <TableRow>
                            {config?.map(item => {
                                let Component = TableCell;
                                if (!!item.component) {
                                    Component = item.component as any;
                                }

                                return (
                                    <Component key={item.fieldName} sx={item.sx}>{item.title}</Component>
                                );
                            })}
                        </TableRow>
                    </TableHead>
                }

                <TableBody>
                    {items?.map((item, index) => {
                        return (
                            <TableRow key={index} sx={{'&:last-of-type > td': {borderBottom: 0}}}>
                                {config?.map(field => {
                                    let Component = TableCell;
                                    if (!!field.component) {
                                        Component = field.component as any;
                                    }
                                    
                                    if (!!field.render) {
                                        return (
                                            <Component key={field.fieldName}>{field.render(item[field.fieldName], item)}</Component>
                                        );
                                    }

                                    return (
                                        <Component key={field.fieldName}>{item[field.fieldName]}</Component>
                                    );
                                })}
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    );
};
