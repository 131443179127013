import * as React from "react";
import {FreeTextField} from "../../../../../../Components/Form/FreeTextField";

interface IFriendsNameFieldProps {
    value: string;
    onChange(value: string): void;
}

export const FriendsNameField = ({value, onChange}: IFriendsNameFieldProps) => (
    <FreeTextField label="Friend's name" value={value} onChange={onChange} />
);
