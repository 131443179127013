import {IErrorResponseItem, ValidationError, ValidationErrors} from "../services/validator";

export enum UserPaymentMethodCallbacksValidationField {
    TYPE = "UserPaymentMethodCallbackRequest.Action",
    NAME = "UserPaymentMethodCallbackRequest.Payload",
}

export type UserPaymentMethodCallbacksValidation = {
    [key in UserPaymentMethodCallbacksValidationField]: ValidationError[];
};

export const createUserPaymentMethodCallbacksValidation = (): ValidationErrors<UserPaymentMethodCallbacksValidation, UserPaymentMethodCallbacksValidationField> => {
    const v = new ValidationErrors<UserPaymentMethodCallbacksValidation, UserPaymentMethodCallbacksValidationField>();
    v.setKeyParser((key) => {
        return key
            .replace('NewUserPaymentMethodCallbackRequest', 'UserPaymentMethod')
            .replace('UpdateUserPaymentMethodCallbackRequest', 'RecurringExpense');
    });

    return v;
};

export const convertFromResponse = (errors: IErrorResponseItem[]): ValidationErrors<UserPaymentMethodCallbacksValidation, UserPaymentMethodCallbacksValidationField> => {
    const data = createUserPaymentMethodCallbacksValidation();

    errors.forEach(e => {
        data.add(e.key as any, e.validator as any);
    });

    return data;
};
