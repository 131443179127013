import * as React from "react";
import {SnackbarStore} from "../../../stores/snackbar";
import {FormModal} from "../../Components/FormModal";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {createTransaction} from "../../../stores/transactions";
import {convertFormOutputDataToTransactionForCreate} from "../../../helpers/transaction_helpers";
import {TransactionForm} from "../Components/Form/TransactionForm";
import {TransactionFormScope} from "../Components/Form/config/config";
import { ITransactionFormSubmit } from "../Components/Form/config/models";
import { createAttachment, deleteAttachment } from "../../../stores/attachments";

interface INewTransactionProps {
    open: boolean;
    onClose: any;
}

export const NewTransaction = ({open, onClose}: INewTransactionProps) => {
    const { snackbarStore, searchStore, refreshStore } = useGlobalStores();

    const [loading, setLoading] = React.useState(false);

    const closeCallback = () => {
        window.location.hash = '';

        onClose();
    };

    const handleClose = () => {
        if (loading) {
            return;
        }

        closeCallback();
    };

    const handleSubmit = (output: ITransactionFormSubmit): Promise<any> => {
        setLoading(true);

        return createTransaction(convertFormOutputDataToTransactionForCreate(output.data))
            .then((transaction: any) => {
                return Promise.all([
                    ...(output.newAttachments?.map(data => createAttachment(transaction.id, data)) || []),
                    ...(output.deleteAttachments?.map(data => deleteAttachment(transaction.id, data.fileId)) || []),
                ]);
            });
    };

    const successfulCallback = () => {
        setLoading(false);

        snackbarStore.open(SnackbarStore.SUCCESS, 'New transaction added successfully!');
        if (searchStore.isDefault) {
            refreshStore.refreshAll();
        } else {
            refreshStore.refreshDashboardSnapshot();
        }

        closeCallback();
    };

    const unsuccessfulCallback = () => {
        setLoading(false);

        snackbarStore.open(SnackbarStore.FAIL);
    };

    return (
        <FormModal
            title="New transaction"
            loading={loading}
            open={open}
            onClose={handleClose}
        >
            <TransactionForm
                scope={TransactionFormScope.NEW}
                onSubmit={handleSubmit}
                successfulCallback={successfulCallback}
                unsuccessfulCallback={unsuccessfulCallback}
            />
        </FormModal>
    );
};
