import * as React from "react";
import {observer} from "mobx-react";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {List, useMediaQuery, useTheme} from "@mui/material";
import {PaymentMethodItem} from "./PaymentMethodItem";
import {AccountBalanceWallet} from "@mui/icons-material";
import {NoResults} from "../../Components/NoResults";
import {NewAction} from "./Actions/NewAction";
import {PageItem} from "../../Components/PageItem";
import {createRef} from "react";

export const ListContainer = (props: any) => {
    const dense = useMediaQuery(useTheme().breakpoints.down('sm'));

    return (
        <List sx={{maxWidth: 600}} dense={dense}>
            {props.children}
        </List>
    );
};

export const PaymentMethodsList = observer(() => {
    const { userPaymentMethodStore } = useGlobalStores();

    const paymentMethods = userPaymentMethodStore.paymentMethods;

    if (!paymentMethods.length) {
        const ctaRef = createRef();
        return (
            <>
                <NoResults
                    icon={<AccountBalanceWallet />}
                    text="You haven't added any payment method yet :("
                    onClick={() => {
                        (ctaRef.current! as any).click()
                    }}
                />
                <NewAction ctaRef={ctaRef} hideCta />
            </>
        );
    }

    return (
        <PageItem
            title="Payment Methods"
            actions={<NewAction />}
        >
            <ListContainer>
                {paymentMethods.map(p => <PaymentMethodItem paymentMethod={p} key={p.id}/>)}
            </ListContainer>
        </PageItem>
    );
});
