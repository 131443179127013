import * as React from "react";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {SnackbarStore} from "../../../stores/snackbar";
import {deleteExtraIncome} from "../../../stores/extra_income";
import {ConfirmationDialogWithTrigger} from "../../Components/ConfirmationDialogWithTrigger";
import Button from "@mui/material/Button";
import {IExtraIncome} from "../../../config/extra_income";
import {useExtraIncomeStore} from "../../../hooks/use_extra_income";

interface IDeleteAction {
    extraIncome: IExtraIncome;
}

export const DeleteAction = ({extraIncome}: IDeleteAction) => {
    const {refreshStore, snackbarStore} = useGlobalStores();
    const extraIncomeStore = useExtraIncomeStore();

    const [loading, setLoading] = React.useState(false);

    const handleSubmit = (closeDialog: any) => () => {
        closeDialog();
        setLoading(true);

        const successfulCallback = () => {
            extraIncomeStore.removeExtraIncome(extraIncome);
            refreshStore.refreshExtraIncomes();
            snackbarStore.open(SnackbarStore.SUCCESS, 'Extra income deleted successfully');

            setLoading(false);
        };

        const unsuccessfulCallback = () => {
            setLoading(false);

            snackbarStore.open(SnackbarStore.FAIL);
        };

        deleteExtraIncome(extraIncome.id)
            .then(successfulCallback)
            .catch(unsuccessfulCallback);
    };

    return (
        <ConfirmationDialogWithTrigger
            loading={loading}
            title="Delete Extra Income"
            description={<>
                Are you sure you wanna delete this extra income?<br/>
                <strong>The operation cannot be undone.</strong>
            </>}
            actions={({closeDialog}) => (
                [
                    <Button onClick={handleSubmit(closeDialog)} color="primary" autoFocus key="yes">
                        Yes
                    </Button>,
                ]
            )}
        />
    );
};
