import { ITransactionFormState } from "../components/Transactions/Components/Form/TransactionForm";
import { TransactionFormField } from "../components/Transactions/Components/Form/config/config";

export interface IPaymentMethodCallback {
    user_payment_method_id: number;
    actions: ICallbackAction[];
}

export interface ICallbackAction {
    id?: number;
    action: CallbackAction;
    payload: any;
}

export enum CallbackAction {
    SHARE_WITH_FRIENDS_PERCENTAGE = "SHARE_WITH_FRIENDS_PERCENTAGE",
}

export interface ICallbackResult {
    fieldKey: TransactionFormField;
    field: keyof ITransactionFormState;
    data: any;
}

export const labels = {
    [CallbackAction.SHARE_WITH_FRIENDS_PERCENTAGE]: 'Share with friends - Percentage',
}

export const options = [
    {
        key: CallbackAction.SHARE_WITH_FRIENDS_PERCENTAGE,
        label: labels[CallbackAction.SHARE_WITH_FRIENDS_PERCENTAGE],
    },
];
