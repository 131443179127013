import * as React from "react";
import Badge from "@mui/material/Badge";
import SearchIcon from '@mui/icons-material/Search';
import IconButton from "@mui/material/IconButton";
import {observer} from "mobx-react";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {SearchTransactions} from "../../Transactions/Components/Search/SearchTransactions";

const SEARCH_LOCATION_HASH = '#searchtrns';

interface ISearchActionProps {
    iconConfig?: {
        color?: 'inherit' | 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
        fontSize?: number | 'auto',
    };
}

const SearchActionComponent = ({iconConfig = {color: "inherit", fontSize: "auto"}}: ISearchActionProps) => {
    const { searchStore } = useGlobalStores();

    const [open, setOpen] = React.useState(window.location.hash === SEARCH_LOCATION_HASH);

    const handleVisibility = () => {
        setOpen(!open);

        window.location.hash = !open ? SEARCH_LOCATION_HASH : '';
    };

    const hasSearch = searchStore.hasQuery;

    return (
        <>
            <IconButton color={iconConfig.color} onClick={handleVisibility} size="large">
                <Badge invisible={!hasSearch} color="error" variant="dot" overlap="circular">
                    <SearchIcon sx={{fontSize: iconConfig.fontSize}} />
                </Badge>
            </IconButton>

            <SearchTransactions open={open} onClose={() => {setOpen(false)}} />
        </>
    );
};

export const SearchAction = observer(SearchActionComponent);
