import * as React from "react";
import {Box, Button, styled} from "@mui/material";
import {IEventsFilterQuery} from "../../../config/event";
import {useState} from "react";
import { Form } from "../../Components/Form/Form/Form";
import { useForm } from "../../../hooks/use_form_v2";
import { FreeTextField } from "../../Components/Form/FreeTextField";

const ClearFiltersButton = styled(Button)(({theme}) => ({
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(2),
}));

interface ISearchFormProps {
    onSubmit(query: IEventsFilterQuery): void;
}

interface ISearchFormState {
    query: string;
}

export const SearchForm = ({onSubmit}: ISearchFormProps) => {
    const [isFiltered, setIsFiltered] = useState(false);

    const form = useForm<ISearchFormState>();
    const { register, reset } = form;

    const handleSubmit = (values: ISearchFormState) => {
        if (values.query === '') {
            if (isFiltered) {
                handleClearFilters();
            }
            return Promise.resolve();
        }

        setIsFiltered(true);

        onSubmit({query: values.query});

        return Promise.resolve();
    };

    const handleClearFilters = () => {
        setIsFiltered(false);
        reset();

        onSubmit({query: ''});
    };

    return (
        <Box>
            <Form form={form} onSubmit={handleSubmit} withSubmitButton={false}>
                <Form.Field
                    type={FreeTextField}
                    {...register('query')}
                    label="Filter"
                    helperText={"You can search by name and by month of the event."}
                />

                {isFiltered &&
                    <ClearFiltersButton
                        onClick={handleClearFilters}
                        variant="contained"
                        color="primary"
                    >
                        Clear filters
                    </ClearFiltersButton>
                }
            </Form>
        </Box>
    );
}
