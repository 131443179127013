import * as React from "react";
import {ExtraIncomeForm} from "../Form/ExtraIncomeForm";
import {FormModal} from "../../Components/FormModal";
import {useNavigate, useParams} from "react-router-dom";
import {IExtraIncome, IExtraIncomeFormData} from "../../../config/extra_income";
import {useExtraIncomeStore} from "../../../hooks/use_extra_income";
import {fetchExtraIncome, updateExtraIncome} from "../../../stores/extra_income";
import Error from "../../Components/Error";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {SnackbarStore} from "../../../stores/snackbar";

export const EditExtraIncome = () => {
    const extraIncomeStore = useExtraIncomeStore();
    const { snackbarStore } = useGlobalStores();

    const navigate = useNavigate();
    const { id } = useParams() as any;

    const [loading, setLoading] = React.useState(false);
    const [formLoading, setFormLoading] = React.useState(false);
    const [extraIncome, setExtraIncome] = React.useState<IExtraIncome>();

    const handleSubmit = (data: IExtraIncomeFormData) => {
        setFormLoading(true);

        updateExtraIncome(id, data)
            .then(result => {
                setFormLoading(false);

                extraIncomeStore.replaceExtraIncome(result);
                snackbarStore.open(SnackbarStore.SUCCESS, 'Extra income updated successfully!');

                handleClose();
            })
            .catch(() => {
                setLoading(false);

                snackbarStore.open(SnackbarStore.FAIL);

                handleClose();
            });
    };

    const handleClose = () => {
        navigate('/extra-incomes');
    };

    React.useEffect(() => {
        (async () => {
            setExtraIncome(undefined);
            setLoading(true);

            let data = extraIncomeStore.findExtraIncomeById(id);
            if (data !== null) {
                setExtraIncome(data);
            } else {
                try {
                    data = await fetchExtraIncome(id);
                    setExtraIncome(data);
                } catch (error) {
                }
            }

            setLoading(false);
        })();
    }, [id]);

    if (loading) {
        return (
            <FormModal
                title="Edit extra income"
                loading={loading}
                open={true}
                onClose={handleClose}
            >
                <div>loading</div>
            </FormModal>
        )
    }

    if (!extraIncome) {
        return (
            <Error />
        );
    }

    return (
        <FormModal
            title="Edit extra income"
            loading={formLoading}
            open={true}
            onClose={handleClose}
        >
            <ExtraIncomeForm loading={formLoading} extraIncome={extraIncome} onSubmit={handleSubmit} />
        </FormModal>
    );
};
