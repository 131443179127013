import * as React from "react";
import {AutoFixHigh} from "@mui/icons-material";
import {FormModal} from "../../../../Components/FormModal";
import {Badge} from "@mui/material";
import { IUserPaymentMethod } from "../../../../../config/user_payment_method";
import { useModal } from "../../../../../hooks/use_modal";
import { ItemForm } from "./ItemForm";
import { ICallbackAction } from "../../../../../config/user_payment_method_callback";
import { useGlobalStores } from "../../../../../hooks/use_global_stores";
import { createPaymentMethodsCallbacks, deleteUserPaymentMethodCallback, updateUserPaymentMethodCallback } from "../../../../../stores/user_payment_methods_callback";
import { toForm } from "../../../../../helpers/user_payment_method_callback_helpers";
import { ProIconButton } from "../../../../Pro/Components/ProIconButton";
import { Level } from "../../../../../config/auth";
import { Feature } from "../../../../../config/features";

interface IAutomationActionProps {
    paymentMethod: IUserPaymentMethod;
}

export const AutomationAction = ({paymentMethod}: IAutomationActionProps) => {
    const { userPaymentMethodCallbackStore } = useGlobalStores();

    const {open, handleModalOpen, handleModalClose, setLoading} = useModal();
    const [values, setValues] = React.useState<ICallbackAction[]>(
        userPaymentMethodCallbackStore.findByUserPaymentMethodId(paymentMethod.id)?.actions || []
    );

    const handleAdd = (data: ICallbackAction) => {
        setLoading(true);
        createPaymentMethodsCallbacks(paymentMethod.id, toForm(data)).then((res) => {
            setValues(currentData => [...currentData, res]);
            userPaymentMethodCallbackStore.addCallback(paymentMethod.id, res);
            setLoading(false);
        });
    };

    const handleChange = (index: number) => (data: ICallbackAction | null) => {
        setLoading(true);

        const id = values.find((_, i) => i === index)!.id!;

        if (data === null) {
            deleteUserPaymentMethodCallback(paymentMethod.id, id).then(() => {
                userPaymentMethodCallbackStore.removeCallback(paymentMethod.id, id);
                setValues(prev => prev.filter((d, i) => i !== index));
                setLoading(false);
            });
        } else {
            updateUserPaymentMethodCallback(paymentMethod.id, id, toForm(data)).then((res) => {
                setValues(prev => {
                    const newData = prev.slice();
                    newData[index] = res;

                    return newData;
                });
                userPaymentMethodCallbackStore.replaceCallback(paymentMethod.id, res);
                setLoading(false);
            });
        }
    };

    return (
        <>
            <FormModal
                title="Automations"
                open={open}
                loading={false}
                onClose={handleModalClose}
            >
                <>
                    <ItemForm onChange={handleAdd} />

                    {values.map((value, i) => (
                        <ItemForm key={i} value={value} onChange={handleChange(i)} />
                    ))}
                </>
            </FormModal>

            <ProIconButton min={Level.PLATINUM} feature={Feature.USER_PAYMENT_METHOD_CALLBACKS} edge="end" size="small" color="primary" onClick={handleModalOpen} disabled={!paymentMethod.is_active}>
                <Badge invisible={!paymentMethod.is_active} color="error" variant="standard" overlap="rectangular" badgeContent={values.length}>
                    <AutoFixHigh />
                </Badge>
            </ProIconButton>
        </>
    );
};
