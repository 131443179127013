import * as React from "react";
import {IIncome, IIncomeRequest} from "../../../../config/income";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {FormModal} from "../../../Components/FormModal";
import {IncomeForm} from "../Form/IncomeForm";
import {updateIncome} from "../../../../stores/incomes";
import {SnackbarStore} from "../../../../stores/snackbar";

interface IEditIncomeProps {
    open: boolean;
    income: IIncome;
    onClose(): void;
}

export const EditIncome = ({open, income, onClose}: IEditIncomeProps) => {
    const { incomeStore, snackbarStore } = useGlobalStores();

    const handleSubmit = (data: IIncomeRequest) => {
        return updateIncome(income.id, data)
            .then((updatedIncome) => {
                snackbarStore.open(SnackbarStore.SUCCESS, 'Income modified successfully!');
                incomeStore.replaceIncome(updatedIncome);
                onClose();
            })
            .catch(() => {
                snackbarStore.open(SnackbarStore.FAIL);
            })
    };

    return (
        <FormModal title="Edit income" open={open} loading={false} onClose={onClose}>
            <IncomeForm
                income={income}
                onSubmit={handleSubmit}
            />
        </FormModal>
    );
};
