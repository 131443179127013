import * as React from "react";
import {fuelUnitLabel, IFuelDetails} from "../../../../config/fuel";
import {ITransaction} from "../../../../config/transactions";
import {ItemValue} from "./Components/ItemValue";

interface IFuelAmountProps {
    label: string;
    transaction: ITransaction;
    value: IFuelDetails;
}

export const FuelDetails = ({label, transaction, value}: IFuelAmountProps) => (
    <ItemValue transaction={transaction} label={label} value={`${value.amount} ${fuelUnitLabel[value.unit]}`} />
);
