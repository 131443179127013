import {IErrorResponseItem, ValidationError, ValidationErrors} from "../services/validator";

export enum CategoriesValidationField {
    NAME = "Category.Name",
    CODE = "Category.Code",
}

export type CategoriesValidation = {
    [key in CategoriesValidationField]?: ValidationError[];
};

export type CategoryValidationErrors = ValidationErrors<CategoriesValidation, CategoriesValidationField>;

export const categoriesValidationFactory = (errors: IErrorResponseItem[]): CategoryValidationErrors => {
    const v = new ValidationErrors<CategoriesValidation, CategoriesValidationField>();
    v.setMap({
        name: CategoriesValidationField.NAME,
        code: CategoriesValidationField.CODE,
    })
    v.setKeyParser((key) => {
        return key
            .replace('newCategory', 'Category')
            .replace('editCategory', 'Category');
    });
    v.setErrors(errors);

    return v;
};
