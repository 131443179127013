import * as React from 'react';
import {useTranslation} from "react-i18next";
import {Alert, AlertTitle, styled} from "@mui/material";

const Container = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
}));

interface IErrorProps {
    message?: string;
}

export default function Error({message}: IErrorProps) {
    const { t } = useTranslation();

    return (
        <Container>
            <Alert severity="error" variant="filled" sx={{width: 350}}>
                <AlertTitle>{t('global.errors.error_title')}</AlertTitle>

                {message || (t('global.errors.unknown') + ' :(')}
            </Alert>
        </Container>
    )
}
