export enum RefreshItem {
    PAYMENT_METHODS                = 1,
	INCOMES                        = 2,
	ALLOWANCES                     = 4,
	BUDGETS                        = 8,
	CATEGORIES                     = 16,
	USER_PAYMENT_METHODS           = 32,
	USER_PAYMENT_METHODS_CALLBACKS = 64,

	ALL = PAYMENT_METHODS | INCOMES | ALLOWANCES | BUDGETS | CATEGORIES | USER_PAYMENT_METHODS | USER_PAYMENT_METHODS_CALLBACKS,
}
