import * as React from "react";
import {IconButton} from "@mui/material";
import { Delete } from "@mui/icons-material";

interface IDeleteActionProps {
    onDelete(): void;
}

export const DeleteAction = ({onDelete}: IDeleteActionProps) => (
    <IconButton size="small" color="primary" onClick={onDelete}>
        <Delete sx={{fontSize: 18}} />
    </IconButton>
);
