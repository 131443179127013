import * as React from "react";
import {NoResults} from "../../../Components/NoResults";
import {Today} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {PageOuterContainer} from "../../../Components/PageOuterContainer";

export const NoRecurringExpenses = () => {
    const navigate = useNavigate();

    const handleNoResultsClick = () => {
        navigate('/recurring-expenses/new');
    };

    return (
        <PageOuterContainer>
            <NoResults
                icon={<Today />}
                text="You haven't created any recurring expense yet :("
                onClick={handleNoResultsClick}
            />
        </PageOuterContainer>
    );
};
