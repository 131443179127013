import {UserStore} from "./users";
import {Currency} from "../config/currencies";
import {computed} from "mobx";

export class CurrencyStore {
    private userStore: UserStore;

    constructor(userStore: UserStore) {
        this.userStore = userStore;
    }

    @computed get currentCurrency(): Currency {
        return this.userStore.currentUser?.default_currency || Currency.EUR;
    }
}
