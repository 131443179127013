import * as React from "react";
import "../../../utils/number_extensions";
import {fetchSnapshot} from "../../../stores/snapshot";
import {SnapshotLoadingPlaceholders} from "./Components/SnapshotLoadingPlaceholders";
import Error from "../../Components/Error";
import {TransactionsCountItem} from "./Items/TransactionsCountItem";
import {TotalSpentItem} from "./Items/TotalSpentItem";
import {AverageSpentPerDayItem} from "./Items/AverageSpentPerDayItem";
import {BudgetLeftItem} from "./Items/BudgetLeftItem";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {DashboardItemsContainer} from "../Components/DashboardItemsContainer";
import {IReactionDisposer, reaction} from "mobx";
import {observer} from "mobx-react";
import {BudgetLeftHiddenItem} from "./Items/BudgetLeftHiddenItem";
import {useEffect, useState} from "react";
import {Grid} from "@mui/material";

export const SnapshotComponent = () => {
    const { generalSettings, snapshotStore, refreshStore, currencyStore } = useGlobalStores();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const loadSnapshotTimeline = () => {
        setLoading(true);

        fetchSnapshot()
            .then(result => {
                snapshotStore.setSnapshotTimeline(result);

                setLoading(false);
                setError(false);
            })
            .catch(error => {
                setLoading(false);
                setError(true);
            });
    };

    let reloadReactionDisposer: IReactionDisposer;
    useEffect(() => {
        if (!reloadReactionDisposer) {
            reloadReactionDisposer = reaction(
                () => refreshStore.dashboardSnapshot,
                () => {
                    loadSnapshotTimeline();
                }
            );
        }

        return () => {
            reloadReactionDisposer();
        }
    });

    useEffect(() => {
        if (!!snapshotStore.snapshotTimeline) {
            return;
        }

        loadSnapshotTimeline();
    }, []);

    if (loading) {
        return (
            <SnapshotLoadingPlaceholders />
        );
    }

    if (error) {
        return (
            <Error />
        );
    }

    const snapshotTimeline = snapshotStore.snapshotTimeline;
    if (!snapshotTimeline) {
        return null;
    }

    const currency = currencyStore.currentCurrency;

    return (
        <DashboardItemsContainer>
            <Grid item xs={12} md={6} lg={3}>
                <TransactionsCountItem snapshotTimeline={snapshotTimeline} />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <TotalSpentItem snapshotTimeline={snapshotTimeline} currency={currency} />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <AverageSpentPerDayItem snapshotTimeline={snapshotTimeline} currency={currency} />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                {generalSettings.settings.budgetLeftVisibility ?
                    <BudgetLeftItem snapshotTimeline={snapshotTimeline} currency={currency} /> :
                    <BudgetLeftHiddenItem currency={currency} />
                }
            </Grid>
        </DashboardItemsContainer>
    );
};
export const Snapshot = observer(SnapshotComponent);
