import * as React from 'react'
import {DashboardStoresContext} from "../contexts/dashboard_stores_context";

export const useDashboardStores = () => {
    const store = React.useContext(DashboardStoresContext);
    if (!store) {
        throw new Error('useDashboardStores must of type IDashboardStores');
    }

    return store;
};
