import * as React from "react";
import {Map} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {useEffect, useState} from "react";
import {Box, Tooltip} from "@mui/material";
import { FormModal } from "../../FormModal";
import { IPosition } from "../LocationField/GeoLocationAction";
import { RevertAction } from "./RevertAction";
import { GeocodingLocationFieldAsync } from "./GeocodingLocationFieldAsync";
import { CountryField } from "../CountryField";
import { useForm } from "../../../../hooks/use_form_v2";
import { IGeocodingCity } from "../../../../config/geocoding";
import { Form } from "../Form/Form";
import { useModal } from "../../../../hooks/use_modal";
import { ICountry } from "../../../../config/countries";

interface IGeocodingPosition {
    label: string;
    position?: IPosition;
}

interface IGeocodingFieldProps {
    title?: string;
    defaultPosition?: IPosition;
    onChange(position?: IPosition): void;
}

interface IGeocodingFieldFormState {
    country: ICountry;
    city: IGeocodingCity;
}

export const GeocodingField = ({title = "Select location manually", defaultPosition, onChange}: IGeocodingFieldProps) => {
    const [position, setPosition] = useState<IPosition>();
    const [value, setValue] = useState<IGeocodingPosition>();

    const { open, handleModalOpen, handleModalClose } = useModal();

    const form = useForm<IGeocodingFieldFormState>({
        countryCode: '',
        city: null,
    });
    const { register, watch, reset } = form;

    const handleRevert = () => {
        handleModalClose();
        setValue(undefined);
        setPosition(undefined);
        reset();
    };
    
    const handleSubmit = (values: IGeocodingFieldFormState) => {
        handleModalClose();
        setPosition({
            lat: values.city.lat,
            lon: values.city.lon,
        });

        return Promise.resolve();
    };

    useEffect(() => {
        if (!position) {
            onChange(defaultPosition);
            return;
        }

        setValue({
            label: `${watch('city').city}${!!watch('city').state ? `, ${watch('city').state}` : ''}, ${watch('city').country_code}`,
            position: position,
        });

        onChange(position);
    }, [position]);

    return (
        <>
            <Tooltip title={title} arrow tabIndex={-1}>
                <IconButton size="small" onClick={handleModalOpen}>
                    <Map color="primary" />
                </IconButton>
            </Tooltip>

            <FormModal
                title={title}
                loading={false}
                open={open}
                onClose={handleModalClose}
            >
                {!value ?
                    <Form form={form} onSubmit={handleSubmit}>
                        <Form.Field
                            type={CountryField}
                            {...register('country')}
                        />
                        <Form.Field
                            type={GeocodingLocationFieldAsync}
                            {...register('city')}
                            useCache
                            countryCode={watch('country')?.code || ''}
                        />
                    </Form>
                    :
                    <>
                        <Box sx={{marginBottom: 2}}>
                            Selected position: {value?.label}
                        </Box>

                        <RevertAction onClick={handleRevert} />
                    </>
                }
            </FormModal>
        </>
    )
};
