import * as React from "react";
import { OutlinedContainer } from "../../../Components/Form/OutlinedContainer";

interface IEmailReadonlyFieldProps {
    email: string;
}

export const EmailReadonlyField = ({email}: IEmailReadonlyFieldProps) => (
    <OutlinedContainer
        label="Email"
        helperText="This field is readonly, the email can't be changed"
    >
        {email}
    </OutlinedContainer>
)
