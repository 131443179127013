import * as React from "react";
import {useEffect, useState} from "react";
import {DataGrid, GridColumnHeaders} from '@mui/x-data-grid';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
    ITransaction,
    ITransactionsResult,
    TRANSACTIONS_FETCH_LIMIT
} from "../../../../config/transactions";
import "../../../../utils/number_extensions";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {getPage} from "../../../../helpers/transaction_helpers";
import {useLocation, useSearchParams} from "react-router-dom";
import {LinearProgress, styled} from "@mui/material";
import {Pagination} from "./Pagination";
import {TransactionsListAction, TransactionsListField} from "./config/config";
import {columns} from "./config/columns";
import {GridRowSelectionModel} from "@mui/x-data-grid/models/gridRowSelectionModel";
import {GridCallbackDetails} from "@mui/x-data-grid/models/api";

const StyledDataGrid = styled(DataGrid<ITransaction>)(() => ({
    "&.MuiDataGrid-root": {
        borderRadius: 0,
        border: 0,
    },
    // hack to disable cell selection
    "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus, &.MuiDataGrid-root .MuiDataGrid-cell:focus, & .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
        outline: 'none',
    },
    "&.MuiDataGrid-root .MuiDataGrid-footerContainer": {
        justifyContent: 'center!important',
    },

    "& .MuiDataGrid-columnHeaderTitle": {
        fontWeight: 'bold',
    },
}));

export type TransactionsListLoader = (page?: number) => Promise<ITransactionsResult>;

interface ITransactionsListProps {
    staticLoader?: ITransaction[];
    loader?: TransactionsListLoader;
    pageSize?: number;
    hideHeader?: boolean;
    hideFooter?: boolean;
    hideSelectCheckbox?: boolean;
    rowHeight?: number | 'auto';
    fields?: TransactionsListField[];
    actions?: TransactionsListAction[];
    selected?: string[];
    onLoad?(data: ITransactionsResult): void;
    onSelect?(transactions: ITransaction[]): void;
    onDelete?(transaction: ITransaction): void;
}

export const TransactionsList = ({staticLoader, loader, pageSize, hideHeader = false, hideFooter, hideSelectCheckbox = false, rowHeight = 52, fields, actions, selected, onLoad, onSelect, onDelete}: ITransactionsListProps) => {
    const [searchParams,] = useSearchParams();
    const { currencyStore } = useGlobalStores();

    const [, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [transactions, setTransactions] = useState<ITransaction[]>([]);
    const [totalResultsCount, setTotalResultsCount] = useState<number>(0);
    const [paginationModel, setPaginationModel] = useState({
        page: getPage(useLocation().search) - 1,
        pageSize: pageSize || TRANSACTIONS_FETCH_LIMIT,
    });
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [hideCheckboxSelection, setHideCheckboxSelection] = useState<boolean>(hideSelectCheckbox);
    
    const exec = async (page: number) => {
        if (!!staticLoader) {
            setTransactions(staticLoader);
            setTotalResultsCount(staticLoader.length);
            return;
        }

        if (!staticLoader && !loader) {
            return;
        }

        setLoading(true);
        setError(false);

        try {
            const result = await loader!!(page);

            !!onLoad && onLoad(result);

            setTransactions(result.transactions);
            setTotalResultsCount(result.total);
        } catch (error) {
            setError(true);
        }

        setLoading(false);
    };

    const handleSelect = (rowSelectionModel: GridRowSelectionModel, details: GridCallbackDetails) => {
        setSelectionModel(rowSelectionModel);
        !!onSelect && onSelect(transactions.filter(t => rowSelectionModel.indexOf(t.transaction_id) > -1));
    };

    const handleDelete = (deletedTransaction: ITransaction) => {
        setTransactions((prev) => prev.filter(t => t.transaction_id !== deletedTransaction.transaction_id));
        onDelete && onDelete(deletedTransaction);
    };

    useEffect(() => {
        setPaginationModel((prevState) => ({
            ...prevState,
            page: parseInt(searchParams.get('page') || '1') - 1,
        }));
    }, [searchParams.get('page')]);

    useEffect(() => {
        exec(paginationModel.page).then();
    }, [staticLoader, loader, paginationModel.page]);

    useEffect(() => {
        if (selected !== undefined) {
            setSelectionModel(selected);
        }
    }, [selected]);

    useEffect(() => {
        setHideCheckboxSelection(hideSelectCheckbox);
    }, [hideSelectCheckbox]);

    return (
        <StyledDataGrid
            autoHeight
            getRowHeight={() => rowHeight || 'auto'}
            checkboxSelection={!hideCheckboxSelection && (!actions || (!!actions && actions.indexOf(TransactionsListAction.SELECT) > -1))}
            onRowSelectionModelChange={handleSelect}
            rowSelectionModel={selectionModel}
            disableColumnMenu
            disableRowSelectionOnClick
            columns={columns(currencyStore.currentCurrency, handleDelete, fields, actions, !hideCheckboxSelection)}
            rows={transactions}
            loading={loading}
            pagination
            pageSizeOptions={[paginationModel.pageSize]}
            paginationMode="server"
            rowCount={totalResultsCount}
            paginationModel={paginationModel}
            onPaginationModelChange={setPaginationModel}
            hideFooter={!!hideFooter}
            slots={{
                columnHeaders: !!hideHeader ? (() => null) : GridColumnHeaders,
                columnSortedDescendingIcon: () => <ExpandMoreIcon className="icon" />,
                columnSortedAscendingIcon: () => <ExpandLessIcon className="icon" />,
                pagination: Pagination,
                loadingOverlay: LinearProgress,
            }}
            getRowId={(row: ITransaction) => row.transaction_id}
        />
    );
};
