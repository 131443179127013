import * as React from "react";
import {ReactElement} from "react";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import Button from "@mui/material/Button";

interface IConfirmationDialogProps {
    open: boolean;
    onClose(): void;
    title?: string;
    description?: ReactElement | string;
    actions?: ReactElement[];
}

export const ConfirmationDialog = ({open, onClose, title = "Confirm operation", description, actions}: IConfirmationDialogProps) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
        >
            <DialogTitle>{title}</DialogTitle>

            {description &&
                <DialogContent>
                    <DialogContentText component="div">
                        {description}
                    </DialogContentText>
                </DialogContent>
            }

            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>

                {actions}
            </DialogActions>
        </Dialog>
    );
};
