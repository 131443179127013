import * as React from "react";
import { ITransaction } from "../../../../../config/transactions";
import { Grid } from "@mui/material";
import { Section } from "../Components/Section";
import { detailsAutomaticDisplayFields } from "../../../../../config/transaction_details";
import TagIcon from '@mui/icons-material/Tag';
import { Tags } from "./Tags";
import { IAttachment } from "../../../../../config/attachments";

interface IExtrasProps {
    transaction: ITransaction;
    attachments: IAttachment[];
}

export const Extras = ({transaction, attachments}: IExtrasProps) => {
    const standards = !!transaction.details ?
        Object
            .keys(transaction.details)
            .filter(k => (transaction.details as any)[k] !== undefined)    
            .filter(k => detailsAutomaticDisplayFields[k] !== undefined)
            .filter(k => detailsAutomaticDisplayFields[k].v3.type === 'standard') : [];

    const standalones = !!transaction.details ?
        Object
        .keys(transaction.details)
        .filter(k => (transaction.details as any)[k] !== undefined)    
        .filter(k => detailsAutomaticDisplayFields[k] !== undefined)
        .filter(k => detailsAutomaticDisplayFields[k].v3.type === 'standalone') : [];

    return (
        <>
            {!!transaction.tags?.length && (
                <Grid item xs={12}>
                    <Section title="Tags" sections={[
                        {
                            value: <Tags tags={transaction.tags} />,
                            icon: <TagIcon />,
                        }
                    ]} />
                </Grid>
            )}

            {!!standards.length && (
                <Grid item xs={12}>
                    <Section title="Extras" sections={standards.map(k => {
                        const item = detailsAutomaticDisplayFields[k];
                        
                        const Component = detailsAutomaticDisplayFields[k].v3.component;
                        const Icon = detailsAutomaticDisplayFields[k].v3.icon;
        
                        return {
                            title: item.label,
                            value: !!Component ? <Component value={(transaction.details as any)[k]} transaction={transaction} /> : (transaction.details as any)[k],
                            icon: <Icon />,
                        };
                    }) as any} />
                </Grid>
            )}

            {!!standalones.length && (
                standalones.map((k, index) => {
                    const item = detailsAutomaticDisplayFields[k];
                    const Component = item.v3.component;

                    return (
                        <Grid item xs={12} key={index}>
                            <Component value={(transaction.details as any)[k]} transaction={transaction} attachments={attachments} />
                        </Grid>
                    );
                })
            )}
        </>
    )
};
