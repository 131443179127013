import * as React from "react";
import {useTranslation} from "react-i18next";
import {Button, styled} from "@mui/material";
import CheckIcon from '@mui/icons-material/Check';
import Typography from "@mui/material/Typography";

const Container = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
}));

const Message = styled(Typography)(({theme}) => ({
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(2),
}));

interface IDoneStepProps {
    data: number;
    onRestart(): void;
}

export const DoneStep = ({data, onRestart}: IDoneStepProps) => {
    const { t } = useTranslation();

    return (
        <Container>
            <CheckIcon sx={{fontSize: 100}} color="primary" />

            <Message variant="subtitle2">
                {t('transactions.bulk.done.message', { count: data })}
            </Message>

            <Button variant="contained" color="primary" component="span" onClick={onRestart}>
                {t('transactions.bulk.done.button_restart')}
            </Button>
        </Container>
    );
};
