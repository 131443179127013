import * as React from "react";
import {useState} from "react";
import {availableLanguages, Language} from "../../../../../config/i18n";
import {flags} from "../../../../../config/flags";
import {List, ListItemButton, ListItemText, Paper, styled} from "@mui/material";

const StyledPaper = styled(Paper)(({ theme }) => ({
    position: 'absolute',
    right: 0,
    width: 120,
}));

interface ILanguageSelectorProps {
    language: Language;
    onSelect(language: Language): void;
}

export const LanguageSelector = ({language, onSelect}: ILanguageSelectorProps) => {
    const [value, setValue] = useState<Language>(language);

    const handleClick = (language: Language) => () => {
        setValue(language);
        onSelect(language);
    };

    return (
        <StyledPaper elevation={3}>
            <List dense>
                {availableLanguages.map(language => {
                    const Flag = flags[language.code];

                    return (
                        <ListItemButton
                            key={language.code}
                            onClick={handleClick(language.code)}
                            selected={value === language.code}
                        >
                            <ListItemText primary={<div style={{fontSize: 16}}><Flag /> {language.label}</div>} />
                        </ListItemButton>
                    )
                })}
            </List>
        </StyledPaper>
    );
};
