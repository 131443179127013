import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {observer} from "mobx-react";
import "../../../../utils/number_extensions";
import {RecurringExpensesTimeFrequency} from "../../../../config/recurring_expense";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {useRecurringExpenseStore} from "../../../../hooks/use_recurring_expense_store";

interface ITableFooterProps {
    timeFrequency: RecurringExpensesTimeFrequency;
}

export const TableFooter = observer(({timeFrequency}: ITableFooterProps) => {
    const { currencyStore } = useGlobalStores();
    const recurringExpenseStore = useRecurringExpenseStore();

    const currency = currencyStore.currentCurrency;
    const stats = recurringExpenseStore.stats;

    if (!stats || stats[timeFrequency] === undefined) {
        return null;
    }

    return (
        <TableRow>
            <TableCell colSpan={2} sx={{fontWeight: 'bold', textAlign: 'right'}}>Total:</TableCell>
            <TableCell colSpan={4}>{(stats[timeFrequency] as any).toCurrency(currency)}</TableCell>
        </TableRow>
    );
});
