import * as React from "react";
import {IStatsLocation} from "../../../../config/stats";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {Logo} from "../BoxView/Logo";
import {MissingLogo} from "../BoxView/MissingLogo";
import {styled} from "@mui/material";

interface IScreenSizeAware {
    isSmallScreen: boolean;
}

const Container = styled('div', {
    shouldForwardProp: (prop) => prop !== "isSmallScreen",
})<IScreenSizeAware>(({isSmallScreen, theme}) => ({
    backgroundColor: '#fff',
    marginLeft: theme.spacing(-(isSmallScreen ? 2 : 3)),
    marginRight: theme.spacing(-(isSmallScreen ? 2 : 3)),
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.divider,
}));

const StyledTableHead = styled(TableHead)(() => ({
    "& .MuiTableCell-root.MuiTableCell-head": {
        fontWeight: 'bold',
    },
}));

const StyledTableRow = styled(TableRow, {
    shouldForwardProp: (prop) => prop !== "isSmallScreen",
})<IScreenSizeAware>(({isSmallScreen, theme}) => ({
    "& .MuiTableCell-root": {
        ...(isSmallScreen ? {
            paddingTop: theme.spacing(1.5),
            paddingBottom: theme.spacing(1.5),

            '& img': {
                maxWidth: '75px!important',
                maxHeight: '75px!important',
            },

            '& > div': {
                maxWidth: '75px!important',
                maxHeight: '75px!important',
            },
        } : {}),
    },
}));

const TableCellIcon = styled(TableCell)(() => ({
    width: 100,

    '& img': {
        maxWidth: 100,
        maxHeight: 100,
    },

    '& > div': {
        maxWidth: '100px!important',
        maxHeight: '100px!important',
    },
}));

const TableCellCompany = styled(TableCell)(() => ({
    width: 300,
}));

const TableCellAmount = styled(TableCell)(() => ({
    width: 100,
}));

interface IListViewProps {
    companies: IStatsLocation[];
    smallScreen?: boolean;
}

export const ListView = ({companies, smallScreen = false}: IListViewProps) => {
    const { currencyStore } = useGlobalStores();

    return (
        <Container isSmallScreen={smallScreen}>
            <TableContainer>
                <Table size={smallScreen ? "small" : "medium"}>
                    <StyledTableHead>
                        <TableRow>
                            <TableCellIcon>Company</TableCellIcon>
                            <TableCellCompany></TableCellCompany>
                            <TableCellAmount>Amount</TableCellAmount>
                            <TableCell>Count</TableCell>
                        </TableRow>
                    </StyledTableHead>

                    <TableBody>
                        {companies.map((company, index) => {
                            return (
                                <StyledTableRow isSmallScreen={smallScreen} key={index}>
                                    <TableCellIcon>{company.logo ? <Logo logo={company.logo} hasContainer={false} /> : <MissingLogo />}</TableCellIcon>
                                    <TableCellCompany>{company.name}</TableCellCompany>
                                    <TableCellAmount>{(company.total as any).toCurrency(currencyStore.currentCurrency)}</TableCellAmount>
                                    <TableCell>{company.count}</TableCell>
                                </StyledTableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};
