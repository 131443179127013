import { Level } from "./auth";

// the order must reflect the api
export enum Feature {
    TRANSACTIONS_IMPORT,
	DASHBOARD_CHARTS,
	DASHBOARD_YEARS_DETAILS,
	TRANSACTIONS_BULK_EDIT,
	TRANSACTIONS_BULK_DELETE,
	TRANSACTIONS_MERGE,
	TRANSACTIONS_EXPORT,
	TRANSACTIONS_ATTACHMENTS,
	TRANSACTIONS_UPLOAD_RECEIPT,
	ALLOWANCES,
	SPLITS,
	EXTRA_INCOMES,
	EVENTS,
	CUSTOM_CATEGORIES,
	USER_PAYMENT_METHOD_CALLBACKS,
	RECURRING_EXPENSES,

	UNLIMITED_TRANSACTIONS,
	UNLIMITED_INCOMES,
	UNLIMITED_PAYMENT_METHODS,
}

export interface IFeature {
    code?: Feature;
    label: string;
    levels: Level[];
    amounts?: { [key in Level]: string };
}

export const features = [
    {
        label: 'Transactions',
        levels: [Level.PRO, Level.PLATINUM],
        amounts: {
            [Level.BASIC]: '31/month',
            [Level.NO_ADS]: '31/month',
            [Level.PRO]: 'unlimited',
            [Level.PLATINUM]: 'unlimited',
        },
    },
	{
        label: 'Payment Methods',
        levels: [Level.PRO, Level.PLATINUM],
        amounts: {
            [Level.BASIC]: '2',
            [Level.NO_ADS]: '2',
            [Level.PRO]: 'unlimited',
            [Level.PLATINUM]: 'unlimited',
        },
    },
    {
        label: 'Incomes',
        levels: [Level.PRO, Level.PLATINUM],
        amounts: {
            [Level.BASIC]: '1',
            [Level.NO_ADS]: '1',
            [Level.PRO]: 'unlimited',
            [Level.PLATINUM]: 'unlimited',
        },
    },
    {
        label: 'Mobile App',
        levels: [Level.BASIC, Level.NO_ADS, Level.PRO, Level.PLATINUM],
    },
    {
        label: 'Web App',
        levels: [Level.BASIC, Level.NO_ADS, Level.PRO, Level.PLATINUM],
    },
    {
        label: 'Transactions - Manual entry',
        levels: [Level.BASIC, Level.NO_ADS, Level.PRO, Level.PLATINUM],
    },
    {
        label: 'Transactions - Import',
        levels: [Level.BASIC, Level.NO_ADS, Level.PRO, Level.PLATINUM],
    },
    {
        label: 'No Ads',
        levels: [Level.NO_ADS, Level.PRO, Level.PLATINUM],
    },
	{
        code: Feature.DASHBOARD_CHARTS,
        label: 'Transactions - Charts',
        levels: [Level.PRO, Level.PLATINUM],
    },
    {
        code: Feature.TRANSACTIONS_BULK_EDIT,
        label: 'Transactions - Bulk edit',
        levels: [Level.PRO, Level.PLATINUM],
    },
	{
        code: Feature.TRANSACTIONS_BULK_DELETE,
        label: 'Transactions - Bulk delete',
        levels: [Level.PRO, Level.PLATINUM],
    },
	{
        code: Feature.TRANSACTIONS_MERGE,
        label: 'Transactions - Merge',
        levels: [Level.PRO, Level.PLATINUM],
    },
	{
        code: Feature.TRANSACTIONS_EXPORT,
        label: 'Transactions - Export',
        levels: [Level.PRO, Level.PLATINUM],
    },
    {
        code: Feature.RECURRING_EXPENSES,
        label: 'Recurring expenses',
        levels: [Level.PRO, Level.PLATINUM],
    },
	{
        code: Feature.ALLOWANCES,
        label: 'Allowances',
        levels: [Level.PRO, Level.PLATINUM],
    },
	{
        code: Feature.SPLITS,
        label: 'Share with Friends',
        levels: [Level.PRO, Level.PLATINUM],
    },
	{
        code: Feature.EXTRA_INCOMES,
        label: 'Extra Incomes',
        levels: [Level.PRO, Level.PLATINUM],
    },
	{
        code: Feature.EVENTS,
        label: 'Events',
        levels: [Level.PRO, Level.PLATINUM],
    },
	{
        code: Feature.CUSTOM_CATEGORIES,
        label: 'Custom Categories',
        levels: [Level.PRO, Level.PLATINUM],
    },
    {
        code: Feature.TRANSACTIONS_ATTACHMENTS,
        label: 'Transactions - Attachments',
        levels: [Level.PLATINUM],
    },
	{
        code: Feature.TRANSACTIONS_UPLOAD_RECEIPT,
        label: 'Transactions - Receipt Scan',
        levels: [Level.PLATINUM],
    },
    {
        code: Feature.USER_PAYMENT_METHOD_CALLBACKS,
        label: 'Payment Methods - Automations',
        levels: [Level.PLATINUM],
    },
] as IFeature[];
