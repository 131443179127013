import * as React from "react";
import {Form} from "../../../Components/Form/Form/Form";
import {FormModal} from "../../../Components/FormModal";
import {useForm} from "../../../../hooks/use_form_v2";
import {FreeTextField} from "../../../Components/Form/FreeTextField";
import {CategoryField} from "../../../Transactions/Components/Form/Fields/CategoryField";
import {SelectField} from "../../../Components/Form/SelectField";
import {ILocation, LocationVendor} from "../../../../config/location";

interface ILocationFormProps<T> {
    location?: ILocation;
    loading: boolean;
    open: boolean;
    onClose(): void;
    onSubmit(data: T): Promise<any>;
}

export const LocationForm = <T,>({ location, onClose, loading, open, onSubmit }: ILocationFormProps<T>) => {
    const form = useForm<T | any>(location);
    const { register, watch } = form;

    return (
        <FormModal
            title="Create Location"
            loading={loading}
            open={open}
            onClose={onClose}
        >
            <Form form={form} onSubmit={onSubmit}>
                <Form.Field
                    type={SelectField}
                    {...register('vendor')}
                    options={[
                        {
                            key: LocationVendor.LOCAL_COMPANY,
                            label: 'Local company',
                        },
                        {
                            key: LocationVendor.GOOGLE_MAPS,
                            label: 'Google Maps',
                        },
                    ]}
                    optionValueGetter={(o: any) => o.key}
                    optionLabelGetter={(o: any) => o.label}
                />

                <Form.Field type={FreeTextField} {...register('name')} />

                {watch('vendor') === LocationVendor.GOOGLE_MAPS && <Form.Field type={FreeTextField} {...register('vendor_id')} />}
                {watch('vendor') === LocationVendor.GOOGLE_MAPS && <Form.Field type={FreeTextField} {...register('address')} />}

                <Form.Field type={CategoryField} {...register('default_category_id')} />
            </Form>
        </FormModal>
    );
};
