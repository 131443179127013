import * as React from "react";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import { Form } from "../../../Components/Form/Form/Form";
import { SelectField } from "../../../Components/Form/SelectField";

export const PaymentMethodField = (props: any) => {
    const { userPaymentMethodStore } = useGlobalStores();

    return (
        <Form.Field
            type={SelectField}
            options={userPaymentMethodStore.paymentMethods}
            optionValueGetter={(p: any) => p.id}
            optionLabelGetter={(p: any) => p.name}
            {...props}
            label="Payment Method"
        />
    );
};
