import {action, makeObservable, observable} from "mobx";
import {fetchDelete, fetchPatch, fetchPost} from "../services/api";
import "../utils/array_extentions";
import {IIncome, IIncomeRequest} from "../config/income";
import {incomesValidationFactory} from "../validators/incomes";

const USER_BUDGETS_CACHE_KEY = 'em:user:incomes';

export class IncomeStore {
    @observable incomes: IIncome[] = [];

    constructor() {
        makeObservable(this);

        const data = window.localStorage.getItem(USER_BUDGETS_CACHE_KEY);
        if (!data) {
            return;
        }

        this.incomes = JSON.parse(data);
    }

    @action
    load(budgets: IIncome[]) {
        this.incomes = budgets;

        this.updateLocalStorage();
    }

    @action
    addIncome(income: IIncome) {
        this.incomes.push(income);

        this.updateLocalStorage();
    }

    @action
    removeIncome(income: IIncome) {
        this.incomes = this.incomes.filter(b => b.id !== income.id);

        this.updateLocalStorage();
    }

    @action
    replaceIncome(income: IIncome) {
        const index = this.incomes.findIndex(p => p.id === income.id);

        if (index === -1) {
            return;
        }

        this.incomes[index] = income;

        this.updateLocalStorage();
    }

    private updateLocalStorage() {
        window.localStorage.setItem(USER_BUDGETS_CACHE_KEY, JSON.stringify(this.incomes));
    }
}

export const createIncome = (data: IIncomeRequest): Promise<IIncome> => {
    return fetchPost( `/ego/incomes`, data)
        .then((response: any) => {
            if (response.status !== 201) {
                return response.json().then((reason: any) => {
                    if (reason.errors === undefined) {
                        return Promise.reject(reason);
                    }

                    return Promise.reject(incomesValidationFactory(reason.errors));
                });
            }

            return Promise.resolve({
                id: parseInt(response.headers.get('Location').replace(/.*\/([0-9]+)$/, '$1')),
                ...data
            });
        });
};

export const updateIncome = (id: number, data: IIncomeRequest): Promise<IIncome> => {
    return fetchPatch( `/ego/incomes/${id}`, data)
        .then((response: any) => {
            if (response.status !== 200) {
                return response.json().then((reason: any) => {
                    if (reason.errors === undefined) {
                        return Promise.reject(reason);
                    }

                    return Promise.reject(incomesValidationFactory(reason.errors));
                });
            }

            return response.json();
        })
        .then((data) => data.data);
};

export const deleteIncome = (id: number): Promise<boolean> => {
    return fetchDelete(`/ego/incomes/${id}`);
};
