import * as React from "react";
import {ReactNode} from "react";
import {Grid, styled} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { IForm } from "../../../../hooks/use_form_v2";
import { Field } from "./Field";
import { Submit } from "./Submit";
import { Buttons, ButtonsContainer } from "./ButtonsContainer";
import { isAfterSubmitButtonElements, isBeforeSubmitButtonElements, isFieldElement } from "./helpers";

const StyledForm = styled('form')(() => ({
    position: 'relative',
}));

const Backdrop = styled('div')(() => ({
    top: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
}));

const LoadingSpinner = styled(CircularProgress)(() => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -30,
    marginLeft: -30,
}));

interface IFormProps {
    form: IForm<any>;
    onSubmit(values: any): Promise<any>;
    withSubmitButton?: boolean;
    gridProps?: any;
    buttonsContainerGridProps?: any;
    component?: React.ReactElement | any;
    children: ReactNode;
}

export const Form = ({form, onSubmit, withSubmitButton = true, gridProps, buttonsContainerGridProps, children, component}: IFormProps) => {
    const handleSubmit = (e: any) => {
        e.preventDefault();

        form.setLoading(true);
        onSubmit(form.getValues())
            .then(() => form.setErrors(undefined))
            .catch(reason => form.setErrors(reason))
            .finally(() => form.setLoading(false));
    };

    const FormComponent = !!component ? component : StyledForm;

    const childrenArray = React.Children.toArray(children);

    return (
        <FormComponent noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="start"
                spacing={2}
                {...gridProps}
            >
                {childrenArray.filter(isFieldElement)}

                <ButtonsContainer gridProps={buttonsContainerGridProps}>
                    {childrenArray.filter(isBeforeSubmitButtonElements)}

                    {!!withSubmitButton &&
                        <Submit loading={form.isLoading()} onSubmit={handleSubmit} />
                    }

                    {childrenArray.filter(isAfterSubmitButtonElements)}
                </ButtonsContainer>
            </Grid>

            {form.isLoading() && <Backdrop />}
            {form.isLoading() && <LoadingSpinner size={60} />}
        </FormComponent>
    );
}

Form.Field = Field;
Form.Submit = Submit;
Form.Buttons = Buttons;
