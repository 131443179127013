import * as React from "react";
import {PageItem} from "../../Components/PageItem";
import {NewAction} from "./Actions/NewAction";
import {useEffect, useState} from "react";
import {ICategory} from "../../../config/category";
import {CategoryFetchMode, fetchCategories} from "../../../stores/categories";
import {filterOutNonGlobalCategories} from "../../../helpers/category_helpers";
import {PageLoader} from "../../Components/PageLoader";
import {NoResults} from "../../Components/NoResults";
import {Segment} from "@mui/icons-material";
import {ListItems} from "./List/ListItems";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {IReactionDisposer, reaction} from "mobx";
import {observer} from "mobx-react";

export const CustomCategories = observer(() => {
    const { categoryStore, refreshStore } = useGlobalStores();

    const [loading, setLoading] = useState<boolean>(false);
    const [categories, setCategories] = useState<ICategory[]>()

    const handleNoResultsCTAClick = () => {
        (window.document.getElementsByClassName('add-new-custom-category-cta')[0] as any).click();
    };

    const globalLoad = () => {
        fetchCategories({mode: CategoryFetchMode.INDENTED})
            .then((res) => categoryStore.load(res));
    };

    const sectionLoad = () => {
        setLoading(true);
        fetchCategories({mode: CategoryFetchMode.DEFAULT})
            .then((res) => setCategories(filterOutNonGlobalCategories(res)))
            .finally(() => setLoading(false));
    };

    let reloadReactionDisposer: IReactionDisposer;
    useEffect(() => {
        sectionLoad();

        if (!reloadReactionDisposer) {
            reloadReactionDisposer = reaction(
                () => refreshStore.categories,
                () => {
                    sectionLoad();
                    globalLoad();
                }
            );
        }

        return () => {
            reloadReactionDisposer();
        }
    }, []);

    if (loading) {
        return (
            <PageLoader />
        );
    }

    if (!categories) {
        return null;
    }

    if (!categories.length) {
        return (
            <>
                <NoResults
                    text="You haven't added any custom category yet :("
                    onClick={handleNoResultsCTAClick}
                    icon={<Segment />}
                />
                <div style={{display: 'none'}}><NewAction /></div>
            </>
        );
    }

    return (
        <PageItem
            title="Custom Categories"
            actions={<NewAction />}
        >
            <ListItems categories={categories!} />
        </PageItem>
    );
});
