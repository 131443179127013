import * as React from "react";
import {useModal} from "../../../hooks/use_modal";
import {Button} from "@mui/material";
import {availableLanguages, Language} from "../../../config/i18n";
import {Form} from "../../Components/Form/Form/Form";
import {useForm} from "../../../hooks/use_form_v2";
import {FormModal} from "../../Components/FormModal";
import {invalidateCache} from "../stores/translations";
import {SelectField} from "../../Components/Form/SelectField";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {SnackbarStore} from "../../../stores/snackbar";

export interface IInvalidateCacheFormState {
    language: Language;
}

export const InvalidateCacheAction = () => {
    const { snackbarStore } = useGlobalStores();

    const { loading, open, handleModalOpen, handleModalClose } = useModal();
    const form = useForm<IInvalidateCacheFormState>();
    const { register } = form;

    const handleSubmit = async (data: IInvalidateCacheFormState): Promise<any> => {
        await invalidateCache(data);

        snackbarStore.open(SnackbarStore.SUCCESS, 'Cache invalidate successfully');
        handleModalClose();

        return await Promise.resolve();
    };

    return (
        <>
            <Button variant="contained" color="primary" onClick={handleModalOpen}>
                Invalidate cache
            </Button>

            <FormModal
                title="Invalidate cache"
                loading={loading}
                open={open}
                onClose={handleModalClose}
            >
                <Form form={form} onSubmit={handleSubmit}>
                    <Form.Field
                        type={SelectField}
                        {...register('language')}
                        options={availableLanguages}
                        optionValueGetter={(o: any) => o.code}
                        optionLabelGetter={(o: any) => o.label}
                    />
                </Form>
            </FormModal>
        </>
    );
};
