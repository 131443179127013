import * as React from "react";
import {Grid} from "@mui/material";

interface IButtonsContainerProps {
    gridProps?: any;
    children: React.ReactNode;
}

export const ButtonsContainer = ({gridProps = {}, children}: IButtonsContainerProps) => {
    return (
        <Grid item xs={12} {...gridProps}>
            {children}
        </Grid>
    );
};

interface IButtonsProps {
    position?: "beforeSubmit" | "afterSubmit";
    children: React.ReactNode;
}

export const Buttons = ({children}: IButtonsProps) => {
    return (
        <>
            {children}
        </>
    );
};
