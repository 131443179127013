import * as React from "react";
import {useParams} from "react-router-dom";
import {ITransaction} from "../../../config/transactions";
import Error from "../../Components/Error";
import {fetchTransaction} from "../../../stores/transactions";
import {TransactionDetails} from "./Details/TransactionDetails";
import {PageLoader} from "../../Components/PageLoader";
import {useEffect, useState} from "react";
import { fetchAttachments } from "../../../stores/attachments";
import { IAttachment } from "../../../config/attachments";
import { isIphone } from "../../../services/user_agent_helper";
import { MobileView } from "./MobileView/MobileView";

export const ViewTransaction = () => {
    const [loading, setLoading] = useState(false);
    const [transaction, setTransaction] = useState<ITransaction>();
    const [attachments, setAttachments] = useState<IAttachment[]>([]);

    const {transaction_id} = useParams() as any;

    useEffect(() => {
        (async () => {
            setLoading(true);

            try {
                const [transaction, attachments] = await Promise.all([
                    fetchTransaction(transaction_id),
                    fetchAttachments(transaction_id),
                ]);

                setTransaction(transaction);
                setAttachments(attachments);
            } catch (error) {
            }

            setLoading(false);
        })();
    }, [transaction_id]);

    if (loading) {
        return (
            <PageLoader />
        );
    }

    if (!transaction) {
        return (
            <Error message="Transaction not found" />
        );
    }

    if (isIphone) {
        return (
            <MobileView transaction={transaction} attachments={attachments} />
        );
    }

    return (
        <TransactionDetails transaction={transaction} attachments={attachments} />
    )
};
