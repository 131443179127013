import * as React from "react";
import {ITransaction} from "../../../../../config/transactions";
import {Item} from "./Item";

interface IItemValueProps {
    label: string;
    transaction: ITransaction;
    value: any;
}

export const ItemValue = ({label, transaction, value}: IItemValueProps) => <Item label={label} xs={12}>{value}</Item>;
