import * as React from "react";
import {Avatar, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, styled} from "@mui/material";
import {PaymentMethodIcon} from "./Components/PaymentMethodIcon";
import {IUserPaymentMethod} from "../../../config/user_payment_method";
import {PaymentMethodNameField} from "./Fields/PaymentMethodNameField";
import {EditAction} from "./Actions/EditAction";
import {ActiveToggleAction} from "./Actions/ActiveToggleAction";
import {FavoriteAction} from "./Actions/FavoriteAction";
import {DeleteAction} from "./Actions/DeleteAction";
import { AutomationAction } from "./Actions/AutomationAction/AutomationAction";

interface IStyledListItem {
    active: boolean;
}

const StyledListItem = styled(ListItem, {
    shouldForwardProp: (prop) => prop !== "active",
})<IStyledListItem>(({theme, active}) => ({
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
    },
    background: !active ? 'repeating-linear-gradient(\n' +
        '    -60deg,\n' +
        '    #fff,\n' +
        '    #fff 10px,\n' +
        '    #efefef 10px,\n' +
        '    #efefef 20px\n' +
        ');' : '',
}));

const AvatarContainer = styled(ListItemAvatar)(({theme}) => ({
    [theme.breakpoints.down('sm')]: {
        minWidth: 48,
    },
}));

const TextContainer = styled('div')(({theme}) => ({
    marginRight: theme.spacing(13),
    width: '100%',

    [theme.breakpoints.down('sm')]: {
        marginRight: theme.spacing(6.4),
    },
}));

const ActionsContainer = styled(ListItemSecondaryAction)(({theme}) => ({
    [theme.breakpoints.down('sm')]: {
        right: 0,
    },

    '& > button': {
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(0.9),
        },
    },
}));

const PaymentName = styled(ListItemText)(({theme}) => ({
    [theme.breakpoints.down('sm')]: {
        maxWidth: 150,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
}));

interface IPaymentMethodItemProps {
    paymentMethod: IUserPaymentMethod;
}

export const PaymentMethodItem = ({paymentMethod}: IPaymentMethodItemProps) => {
    const [value, setValue] = React.useState(paymentMethod.name);
    const [editing, setEditing] = React.useState(false);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value);
    };

    const handleEdit = (isEditing: boolean) => {
        setEditing(isEditing);
    };

    const handleOnTextClick = () => {
        (window.document.getElementById(`edit-user-payment-method-button-${paymentMethod.id}`) as any).click();
    };

    return (
        <StyledListItem active={paymentMethod.is_active}>
            <AvatarContainer>
                <Avatar>
                    <PaymentMethodIcon paymentMethod={paymentMethod} />
                </Avatar>
            </AvatarContainer>

            <TextContainer>
                {!editing ?
                    <PaymentName primary={paymentMethod.name} onDoubleClick={handleOnTextClick} /> :
                    <PaymentMethodNameField paymentMethod={paymentMethod} value={value} onChange={handleChange} />
                }
            </TextContainer>

            <ActionsContainer>
                {paymentMethod.is_active && <FavoriteAction paymentMethod={paymentMethod} />}
                <EditAction paymentMethod={paymentMethod} onEdit={handleEdit} value={value} />
                <ActiveToggleAction paymentMethod={paymentMethod} />
                <DeleteAction paymentMethod={paymentMethod} />
                <AutomationAction paymentMethod={paymentMethod} />
            </ActionsContainer>
        </StyledListItem>
    );
};
