import * as React from "react";
import {ListAlertContainer} from "../../Components/ListAlertContainer";
import {Alert, AlertTitle} from "@mui/material";
import {LocalOffer} from "@mui/icons-material";
import {styled} from "@mui/material";

const Tag = styled('span')(({theme}) => ({
    display: 'inline-block',
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
    borderRadius: 3,
    paddingTop: 1,
    paddingLeft: theme.spacing(0.55),
    paddingRight: theme.spacing(0.75),
    paddingBottom: 1,
    textDecoration: 'none',
    fontWeight: 'bold',

    '&:hover': {
        backgroundColor: theme.palette.warning.dark,
        cursor: 'default',
    },
}));

interface ITagSearchAlertProps {
    tag: string;
}

export const TagSearchAlert = ({tag}: ITagSearchAlertProps) => (
    <ListAlertContainer>
        <Alert
            icon={<LocalOffer fontSize="inherit" />}
            severity="warning"
        >
            <AlertTitle>Tag search</AlertTitle>
            <div>
                You're seeing results for the <Tag>#{tag}</Tag> tag!
            </div>
        </Alert>
    </ListAlertContainer>
);
