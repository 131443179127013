import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import {Grid} from "@mui/material";
import {FormWrapper} from "../../Components/Form/FormWrapper";
import Box from "@mui/material/Box";
import moment from "moment";

export interface IEventSearchTransactionsQuery {
    useEventDateRange: boolean;
    query: string;
}

interface ISearchTransactionsFormProps {
    onSubmit(query: IEventSearchTransactionsQuery): void;
    loading: boolean;
    dateRange?: [moment.Moment, moment.Moment] | null;
}

export const SearchTransactionsForm = ({onSubmit, loading, dateRange}: ISearchTransactionsFormProps) => {
    const [query, setQuery] = React.useState<string>('');
    const [useEventDateRange, setUseEventDateRange] = React.useState<boolean>(false);

    const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value);
    };

    const handleUseEventDateRangeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setUseEventDateRange(event.target.checked);
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        if (query.trim() === '' && !useEventDateRange) {
            return;
        }

        onSubmit({
            useEventDateRange,
            query,
        });
    };

    return (
        <Box sx={{display: 'flex', justifyContent: 'center'}}>
            <Box sx={{maxWidth: 600}}>
                <FormWrapper onSubmit={handleSubmit} loading={loading}>
                    {dateRange &&
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <FormControlLabel
                                    control={<Checkbox value="remember" color="primary" checked={useEventDateRange} onChange={handleUseEventDateRangeChange} />}
                                    label={`Use event date range (from ${dateRange[0].format('MMMM Do YYYY')} to ${dateRange[1].format('MMMM Do YYYY')})`}
                                />
                            </FormControl>
                        </Grid>
                    }

                    <Grid item xs={8} sm={9}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Query</InputLabel>
                            <OutlinedInput
                                value={query}
                                onChange={handleQueryChange}
                                label="Query"
                                autoFocus={true}
                            />
                        </FormControl>
                    </Grid>

                    <Grid item xs={4} sm={3}>
                        <Button
                            type="submit"
                            onClick={handleSubmit}
                            disabled={loading}
                            variant="contained"
                            color="primary"
                            size="large"
                        >
                            Search
                        </Button>
                    </Grid>
                </FormWrapper>
            </Box>
        </Box>
    );
};
