import * as React from "react";
import moment from "moment";
import {PaymentMethodField} from "./Fields/PaymentMethodField";
import {CategoryField} from "./Fields/CategoryField";
import {storeLastPurchasedAt} from "../../../../helpers/transaction_helpers";
import {TransactionValidation} from "../../../../validators/transactions";
import {TransactionDetailFields} from "../../../../config/transaction_details";
import {CategoryType} from "../../../../config/category";
import {PurchaseDateField} from "./Fields/PurchaseDateField";
import {Currency} from "../../../../config/currencies";
import {ICompany} from "../../../../config/company";
import {IReceiptData, ITransaction, ITransactionDetails, ITransactionSplit} from "../../../../config/transactions";
import {CurrencyField} from "../../../Components/Form/CurrencyField";
import {MoneyField} from "../../../Components/Form/MoneyField";
import {TransactionFormField, TransactionFormScope} from "./config/config";
import {ITransactionFormSubmit} from "./config/models";
import {toAttachmentsFormRequest, toForm, toFormRequest} from "./config/helpers";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {AmountDetailsField, IAmountDetails} from "./Fields/AmountDetailsField";
import {ShareWithFriendsField} from "./Fields/ShareWithFriends/ShareWithFriendsField";
import {DetailsField} from "./Fields/Details/DetailsField";
import {FreeTextField} from "../../../Components/Form/FreeTextField";
import {LocationFieldAsync, LocationFieldChangeScope} from "../../../Components/Form/LocationField/LocationFieldAsync";
import {ILocation} from "../../../../config/location";
import {useForm} from "../../../../hooks/use_form_v2";
import {AllowancesField} from "./Fields/Allowances/AllowancesField";
import {IAllowanceTransaction} from "../../../../config/allowance";
import {BudgetField} from "./Fields/BudgetField";
import {UploadReceipt} from "./Fields/UploadReceipt/UploadReceipt";
import {AttachmentsField} from "./Fields/Attachments/AttachmentsField";
import {
    createTransactionFormStore,
    TransactionFormStoreContext
} from "../../../../contexts/transaction_form_store_context copy";
import {IAttachmentChanges, INewAttachment} from "../../../../config/attachments";
import {Form} from "../../../Components/Form/Form/Form";

interface ITransactionFormProps {
    scope: TransactionFormScope;
    fields?: TransactionFormField[];
    transaction?: ITransaction;
    onSubmit(output: ITransactionFormSubmit): Promise<any>;
    successfulCallback?(transaction: ITransaction): void;
    unsuccessfulCallback?(validation: TransactionValidation): void;
}

export interface ITransactionFormState {
    currency: Currency;
    amount: string;
    purchasedAt: moment.Moment;
    amountDetails?: IAmountDetails;
    paymentMethodId?: string;
    company?: ICompany;
    location?: ILocation;
    categoryId?: string;
    budgetId?: string;
    description?: string;
    isVisible?: boolean;
    details?: ITransactionDetails;
    splits?: ITransactionSplit[];
    tags?: string[] | null;
    allowances?: IAllowanceTransaction[] | null;
}

const transactionFormStore = createTransactionFormStore();

export const TransactionForm = ({scope, fields, transaction, onSubmit, successfulCallback, unsuccessfulCallback}: ITransactionFormProps) => {
    const { userPaymentMethodCallbackStore: paymentMethodCallbackStore, userStore } = useGlobalStores();

    const [defaultLocations, setDefaultLocations] = React.useState<ILocation[]>();
    const [attachmentsChanges, setAttachmentsChanges] = React.useState<IAttachmentChanges>({});

    const form = useForm<ITransactionFormState>(toForm(userStore.currentUser!!, transaction));
    const { setLoading, change, errors, register, watch } = form;

    const hasField = (field: TransactionFormField): boolean => {
        if (fields === undefined) {
            return true;
        }

        return fields.indexOf(field) > -1;
    }

    const handlePaymentMethodChange = (value: string) => {
        change()({
            paymentMethodId: value,
            ...paymentMethodCallbackStore.exec(parseInt(value, 10)),
        });
    };

    const handleLocationChange = (location: ILocation, scope: LocationFieldChangeScope) => {
        if (!location) {
            change('location')('');
            return;
        }

        change('location')(location);

        if (!!location.default_category_id) {
            handleCategoryChange(location.default_category_id);
        }
    };

    const handleCategoryChange = (categoryId: any) => {
        change('categoryId')(categoryId);

        if (categoryId === CategoryType.FUEL) {
            if (!watch('details')?.hasOwnProperty(TransactionDetailFields.FUEL_DETAILS)) {
                change('details')({
                    ...watch('details'),
                    [TransactionDetailFields.FUEL_DETAILS]: {
                        unit: "L",
                        amount: 1,
                    },
                });
            }
        } else if (categoryId === CategoryType.VEHICLE_FOR_HIRE) {
            if (!watch('details')?.hasOwnProperty(TransactionDetailFields.VEHICLE_FOR_HIRE)) {
                change('details')({
                    ...watch('details'),
                    [TransactionDetailFields.VEHICLE_FOR_HIRE]: {},
                });
            }
        } else if (categoryId === CategoryType.GROCERY) {
            if (!watch('details')?.hasOwnProperty(TransactionDetailFields.LINE_ITEMS)) {
                change('details')({
                    ...watch('details'),
                    [TransactionDetailFields.LINE_ITEMS]: [],
                });
            }
        }
    };

    const handleAttachmentsChange = (newAttachments: INewAttachment[], deletedAttachments: number[]) => {
        setAttachmentsChanges({newAttachments, deletedAttachments});
    };

    const handleDetailsChange = (details: ITransactionDetails) => {
        const update = {} as any;

        if (!!details?.tags) {
            update.tags = details.tags;
        }
        update.details = details;

        change()(update);
    };

    const handleSubmit = (values: ITransactionFormState) => {
        if (scope === TransactionFormScope.NEW) {
            storeLastPurchasedAt(values.purchasedAt);
        }

        return onSubmit({
            data: toFormRequest(values),
            ...toAttachmentsFormRequest(attachmentsChanges),
        })
            .then(transaction => {
                successfulCallback && successfulCallback(transaction);

                transactionFormStore.pushAttachmentStore.cleanUp();

                return Promise.resolve(transaction);
            })
            .catch(reason => {
                unsuccessfulCallback && unsuccessfulCallback(reason);

                return Promise.reject(reason);
            });
    };

    const handleUploadReceiptLoad = () => {
        setLoading(true);
    };
    const handleUploadReceiptChange = (data: IReceiptData) => {
        setLoading(false);

        change()({
            amount: data.amount.toString(),
            currency: data.currency,
            purchasedAt: moment(data.purchased_at),
        });

        if (!!data.category_id) {
            handleCategoryChange(data.category_id.toString());
        }

        if (!!data.locations?.length) {
            setDefaultLocations(data.locations);
        }

        if (!!data.details) {
            if (!!data.details?.hasOwnProperty(TransactionDetailFields.FUEL_DETAILS)) {
                change('details')({
                    ...watch('details'),
                    [TransactionDetailFields.FUEL_DETAILS]: {
                        unit: 'L',
                        amount: data.details[TransactionDetailFields.FUEL_DETAILS].amount,
                    },
                });
            }

            if (!!data.details?.hasOwnProperty(TransactionDetailFields.LINE_ITEMS)) {
                change('details')({
                    ...watch('details'),
                    [TransactionDetailFields.LINE_ITEMS]: data.details[TransactionDetailFields.LINE_ITEMS],
                });
            }
        }
    };

    return (
        <TransactionFormStoreContext.Provider value={transactionFormStore}>
            <Form form={form} onSubmit={handleSubmit}>
                {scope === TransactionFormScope.NEW && hasField(TransactionFormField.UPLOAD_RECEIPT) && (
                    <Form.Field
                        type={UploadReceipt}
                        onLoad={handleUploadReceiptLoad}
                        onChange={handleUploadReceiptChange}
                    />
                )}

                {hasField(TransactionFormField.CURRENCY) && (
                    <Form.Field
                        {...register('currency')}
                        type={CurrencyField}
                        gridProps={{xs: 4, md: 3, lg: 2}}
                    />
                )}

                {hasField(TransactionFormField.AMOUNT) && (
                    <Form.Field
                        {...register('amount')}
                        type={MoneyField}
                        currency={watch('currency')}
                        endAdornment={
                            <>
                                {hasField(TransactionFormField.AMOUNT_ALLOWANCES) && (
                                    <AllowancesField
                                        {...register('allowances')}
                                        amount={watch('amount')}
                                    />
                                )}

                                {hasField(TransactionFormField.AMOUNT_SHARING) && (
                                    <ShareWithFriendsField
                                        {...register('splits')}
                                        amount={watch('amount')}
                                        currency={watch('currency')}
                                    />
                                )}

                                {hasField(TransactionFormField.AMOUNT_DETAILS) && (
                                    <AmountDetailsField
                                        {...register('amountDetails')}
                                        amount={watch('amount')}
                                        currency={watch('currency')}
                                    />
                                )}
                            </>
                        }
                        autoFocus
                        gridProps={{xs: 8, md: 5, lg: 5}}
                    />
                )}

                {hasField(TransactionFormField.DATE) && (
                    <Form.Field
                        {...register('purchasedAt')}
                        type={PurchaseDateField}
                        gridProps={{xs: 12, md: 4, lg: 5}}
                    />
                )}

                {hasField(TransactionFormField.PAYMENT_METHOD) && (
                    <Form.Field
                        {...register('paymentMethodId')}
                        type={PaymentMethodField}
                        onChange={handlePaymentMethodChange}
                    />
                )}

                {hasField(TransactionFormField.LOCATION) && (
                    <Form.Field
                        {...register('location')}
                        type={LocationFieldAsync}
                        onChange={handleLocationChange}
                        defaults={defaultLocations}
                    />
                )}

                {hasField(TransactionFormField.CATEGORY) && (
                    <Form.Field
                        {...register('categoryId')}
                        type={CategoryField}
                        onChange={handleCategoryChange}
                    />
                )}

                {hasField(TransactionFormField.BUDGET) && (
                    <Form.Field
                        {...register('budgetId')}
                        type={BudgetField}
                    />
                )}

                {hasField(TransactionFormField.DESCRIPTION) && (
                    <Form.Field
                        {...register('description')}
                        type={FreeTextField}
                    />
                )}

                {hasField(TransactionFormField.ATTACHMENTS) && (
                    <Form.Field
                        type={AttachmentsField}
                        onChange={handleAttachmentsChange}
                        preload={scope === TransactionFormScope.EDIT}
                    />
                )}

                {hasField(TransactionFormField.DETAILS) && (
                    <Form.Field
                        noWrapper
                        {...register('details')}
                        type={DetailsField}
                        onChange={handleDetailsChange}
                        errors={errors}
                    />
                )}
            </Form>
        </TransactionFormStoreContext.Provider>
    );
};
