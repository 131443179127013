import * as React from "react";
import {ITransaction} from "../../../../config/transactions";
import {detailsAutomaticDisplayFields} from "../../../../config/transaction_details";

interface IDetailsProps {
    transaction: ITransaction;
}

export const Details = ({transaction}: IDetailsProps) => (
    <>
        {Object.keys(transaction.details!).map(k => {
            if (detailsAutomaticDisplayFields[k] === undefined) {
                return null;
            }

            if ((transaction.details as any)[k] === 'undefined') {
                return null;
            }

            const DetailItem = detailsAutomaticDisplayFields[k].element;

            return (
                <DetailItem key={k} label={detailsAutomaticDisplayFields[k].label} transaction={transaction} value={(transaction.details as any)[k]}/>
            );
        })}
    </>
);
