import * as React from "react";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {observer} from "mobx-react";
import {useState} from "react";
import {NoResults} from "../../../Components/NoResults";
import {AccountBalance} from "@mui/icons-material";
import {PageItem} from "../../../Components/PageItem";
import { NewAction } from "../Actions/NewAction";
import { NewAllowance } from "../New/NewAllowance";
import { EditAllowance } from "../Edit/EditAllowance";
import { IAllowance, frequencies } from "../../../../config/allowance";
import { List } from "../../../Components/Admin/List/List";
import { DeleteAction } from "../Actions/DeleteAction";
import { EditAction } from "../Actions/EditAction";

export const AllowancesList = observer(() => {
    const { allowanceStore } = useGlobalStores();
    const allowances = allowanceStore.allowances;

    const [newOpen, setNewOpen] = useState<boolean>(false);
    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [selected, setSelected] = useState<IAllowance>();

    const handleNewVisibility = () => {
        setNewOpen(!newOpen);
    };

    const handleEditOpen = (allowance: IAllowance) => () => {
        setEditOpen(true);
        setSelected(allowance);
    };

    const handleEditClose = () => {
        setEditOpen(false);
    };

    if (!allowances.length) {
        return (
            <>
                <NoResults
                    text="You haven't added any budget yet :("
                    onClick={handleNewVisibility}
                    icon={<AccountBalance />}
                />
                <NewAllowance open={newOpen} onClose={handleNewVisibility} />
            </>
        );
    }

    return (
        <PageItem
            title="Allowances"
            actions={<NewAction onClick={handleNewVisibility} />}
        >
            <List<IAllowance>
                items={allowances}
                primaryGetter={(allowance) => allowance.name}
                secondaryGetter={(allowance) => (allowance.amount as any).toCurrency(allowance.currency) + '/' + frequencies[allowance.frequency]}
                iconGetter={(allowance) => allowance.name[0]}
                actions={(allowance) => [
                    [EditAction, {onClick: handleEditOpen(allowance)}],
                    DeleteAction,
                ]}
            />

            {newOpen && <NewAllowance open={newOpen} onClose={handleNewVisibility} />}
            {editOpen && selected && <EditAllowance open={editOpen} allowance={selected} onClose={handleEditClose} />}
        </PageItem>
    );
});
