import * as React from "react";
import {FieldWrapper} from "./FieldWrapper";
import {toArray} from "../../../../../../../utils/array";
import { MuiChipsInput } from 'mui-chips-input'

export const TagsField = ({value, onChange, onDelete}: any) => (
    <FieldWrapper onDelete={onDelete}>
        <div style={{marginBottom: 20}}>
            <MuiChipsInput
                value={toArray(value)}
                onChange={onChange}
                variant="outlined"
                label="Tags"
                fullWidth
            />
        </div>
    </FieldWrapper>
);
