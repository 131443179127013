import * as React from "react";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {FormModal} from "../../../Components/FormModal";
import {SnackbarStore} from "../../../../stores/snackbar";
import { BudgetForm } from "../Form/BudgetForm";
import { IBudgetRequest } from "../../../../config/budget";
import { createBudget } from "../../../../stores/budgets";

interface INewBudgetProps {
    open: boolean;
    onClose(): void;
}

export const NewBudget = ({open, onClose}: INewBudgetProps) => {
    const { budgetStore, snackbarStore } = useGlobalStores();

    const handleSubmit = (data: IBudgetRequest) => {
        return createBudget(data)
            .then((newBudget) => {
                snackbarStore.open(SnackbarStore.SUCCESS, 'Budget created successfully!');
                budgetStore.addBudget(newBudget);
                onClose();
                return Promise.resolve();
            })
            .catch(reason => {
                snackbarStore.open(SnackbarStore.FAIL);
                return Promise.reject(reason);
            });
    };

    return (
        <FormModal title="New budget" open={open} loading={false} onClose={onClose}>
            <BudgetForm onSubmit={handleSubmit} />
        </FormModal>
    );
};
