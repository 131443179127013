import * as React from "react";
import {ReactNode} from "react";
import {Link} from "react-router-dom";
import {Button, Grid} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import {PageItem} from "../../Components/PageItem";

interface IDashboardItemProps {
    title: string;
    fullWidth?: boolean;
    hasDetails?: boolean;
    detailsAction?: ReactNode;
    detailsTarget?: string;
    detailsIconPosition?: "start" | "end";
    detailsLabel?: string;
    styles?: {
        root?: object;
        paper?: object;
        main?: object;
    };
    children: ReactNode;
}

export const DashboardItem = ({title, fullWidth, hasDetails, detailsAction = null, detailsTarget, detailsLabel, detailsIconPosition = "end", styles, children}: IDashboardItemProps) => {
    return (
        <Grid item xs={12} md={fullWidth ? 12 : 6} sx={styles?.root}>
            <PageItem
                title={title}
                actions={
                    hasDetails  &&
                    <Grid item>
                        {detailsTarget &&
                            <Button
                                component={Link}
                                size="small"
                                startIcon={detailsIconPosition === "start" ? <ChevronLeftIcon /> : undefined}
                                endIcon={detailsIconPosition === "end" ? <ChevronRightIcon /> : undefined}
                                to={detailsTarget}
                            >
                                {detailsLabel || "Details"}
                            </Button>
                        }

                        {detailsAction}
                    </Grid>
                }
                styles={{
                    paper: styles?.paper,
                    main: styles?.main,
                }}
            >
                {children}
            </PageItem>
        </Grid>
    );
};
