import { Buttons } from "./ButtonsContainer";
import { Field } from "./Field";

export const isReactElement = (arg: any): arg is React.ReactElement => {
    return arg.hasOwnProperty('type');
};

export const isFieldElement = (child: any): boolean => {
    return (
        isReactElement(child) &&
        (
            child.type === Field ||
            (child.type as any).name.toString().endsWith('Field')
        )
    );
};

export const isBeforeSubmitButtonElements = (child: any): boolean => {
    return (
        isReactElement(child) &&
        child.type === Buttons &&
        child.props.position === 'beforeSubmit'
    )
};

export const isAfterSubmitButtonElements = (child: any): boolean => {
    return (
        isReactElement(child) &&
        child.type === Buttons &&
        child.props.position === 'afterSubmit'
    )
};
