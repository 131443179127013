import * as React from "react";
import {PageOuterContainer} from "../Components/PageOuterContainer";
import {createExtraIncomeStore} from "../../stores/extra_income";
import {ExtraIncomeStoreContext} from "../../contexts/extra_income_store_context";
import {ExtraIncomeList} from "./List/ExtraIncomeList";
import {Outlet} from "react-router-dom";

const extraIncomeStore = createExtraIncomeStore();

export const ExtraIncome = () => (
    <ExtraIncomeStoreContext.Provider value={extraIncomeStore}>
        <PageOuterContainer>
            <ExtraIncomeList />

            <Outlet />
        </PageOuterContainer>
    </ExtraIncomeStoreContext.Provider>
);
