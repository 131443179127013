import * as React from "react";
import { OutlinedContainer } from "../../../../../Components/Form/OutlinedContainer";
import { AddAttachment } from "./AddAttachment";
import { List } from "./List";
import { IAttachment, INewAttachment } from "../../../../../../config/attachments";
import { autorun } from "mobx";
import { useTransactionFormStore } from "../../../../../../hooks/use_transaction_form_store copy";
import { LoadAction } from "./LoadAction";

export enum Status {
    EXISTING,
    NEW,
}

interface IAttachmentsFieldProps {
    preload?: boolean;
    onChange(newAttachments: INewAttachment[], deletedAttachments: number[]): void;
}

export const AttachmentsField = ({preload, onChange}: IAttachmentsFieldProps) => {
    const { pushAttachmentStore } = useTransactionFormStore();

    const [loaded, setLoaded] = React.useState<boolean>(false);
    const [values, setValues] = React.useState<IAttachment[]>([]);
    const [newValues, setNewValues] = React.useState<INewAttachment[]>([]);
    const [deleteValues, setDeleteValues] = React.useState<number[]>([]);

    const handleLoad = (data: IAttachment[]) => {
        setLoaded(true);
        setValues(data);
    };

    const handleAdd = (value: INewAttachment) => {
        setNewValues(oldArray => [...oldArray, value]);
    };

    const handleDelete = (status: Status, value: number) => {
        if (status === Status.NEW) {
            setNewValues(oldArray => oldArray.filter((_, index) => index !== value));
        } else if (status === Status.EXISTING) {
            setDeleteValues(oldArray => [...oldArray, value]);
        }
    };

    const handleUndelete = (status: Status, value: number) => {
        if (status === Status.EXISTING) {
            setDeleteValues(oldArray => oldArray.filter(id => id !== value));
        }
    };

    React.useEffect(() => {
        const reactionDisposer = autorun(() => {
            if (!pushAttachmentStore.newAttachments.length) {
                return;
            }

            handleAdd(pushAttachmentStore.newAttachments[pushAttachmentStore.newAttachments.length - 1]);
        });

        return () => {
            !!reactionDisposer && reactionDisposer();
        };
    }, []);

    React.useEffect(() => {
        onChange(newValues, deleteValues);
    }, [newValues, deleteValues]);

    return (
        <OutlinedContainer label="Attachments" endAdornment={(!preload || loaded) && <AddAttachment onSubmit={handleAdd} />}>
            {!!preload && !loaded && <LoadAction onChange={handleLoad} />}

            {(!preload || loaded) && <List
                attachments={values}
                newAttachments={newValues}
                deletedAttachments={deleteValues}
                onDelete={handleDelete}
                onUndelete={handleUndelete}
            />}
        </OutlinedContainer>
    );
};
