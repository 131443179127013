import * as React from "react";
import {ReactNode} from "react";
import {Grid, Paper} from "@mui/material";
import {Title} from "./Title";
import {Subtitle} from "./Subtitle";
import Box from "@mui/material/Box";

interface IPageItemProps {
    title: string;
    subtitle?: string;
    styles?: {
        paper?: object;
        main?: object;
    };
    children: ReactNode;
    actions?: ReactNode;
}

export const PageItem = ({title, subtitle, actions, children, styles}: IPageItemProps) => (
    <Paper sx={{padding: (theme) => theme.spacing(2), ...(styles?.paper || {})}}>
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start"
            spacing={0}
        >
            <Grid item>
                <Title gutterBottom={!subtitle}>{title}</Title>
                {subtitle && <Subtitle>{subtitle}</Subtitle>}
            </Grid>

            {!!actions &&
                <Grid item>
                    {actions}
                </Grid>
            }
        </Grid>

        <Box sx={{marginTop: (theme) => theme.spacing(2), ...(styles?.main || {})}}>
            {children}
        </Box>
    </Paper>
);
