import * as React from "react";
import {NoResults} from "../../Components/NoResults";
import {PageOuterContainer} from "../../Components/PageOuterContainer";
import {Today} from "@mui/icons-material";

export const NoTransactions = () => {
    const handleClick = () => {
        (window.document.getElementsByClassName('new-transaction-button')[0] as any).click();
    };

    return (
        <PageOuterContainer>
            <NoResults
                icon={<Today />}
                text="You haven't added any transaction yet :("
                onClick={handleClick}
            />
        </PageOuterContainer>
    );
};
