import * as React from "react";
import {styled} from "@mui/material";

const Container = styled('span')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: LOGO_WIDTH,
    height: LOGO_WIDTH,
}));

const LogoImage = styled('img')(() => ({
    display: 'block',
    maxWidth: LOGO_WIDTH,
    maxHeight: LOGO_WIDTH,
}));

interface ILogoProps {
    logo: string;
    alt?: string;
    hasContainer?: boolean;
}

export const LOGO_WIDTH = 200;

export const Logo = ({logo, alt = "", hasContainer = true}: ILogoProps) => {
    if (!hasContainer) {
        return (
            <LogoImage
                width={LOGO_WIDTH}
                src={logo}
                alt={alt}
            />
        );
    }

    return (
        <Container>
            <LogoImage
                width="100%"
                src={logo}
                alt={alt}
            />
        </Container>
    );
};
