import * as React from "react";
import {APIProvider} from "@vis.gl/react-google-maps";
import {GoogleMapsLocationFinder} from "./GoogleMapsLocationFinder";

export interface IPosition {
    lat: number;
    lon: number;
}

export interface IGoogleMapsLocation {
    location_id: string;
    name: string;
    address: string;
    lat: number;
    lon: number;
}

export interface IGoogleMapsLocationFinderFieldProps {
    position?: IPosition;
    onChange(value: IGoogleMapsLocation): void;
}

export const GoogleMapsLocationFinderField = (props: IGoogleMapsLocationFinderFieldProps) => {
    return (
        <APIProvider apiKey={process.env.REACT_APP_GOOGLE_MAPS_PLACES_API!}>
            <GoogleMapsLocationFinder {...props} />
        </APIProvider>
    );
}
