import { TextField } from "@mui/material";
import * as React from "react";

// const InputComponent = ({ inputRef, ...other }: any) => <div ref={inputRef} {...other} />;

interface IOutlinedContainerProps {
    children: React.ReactNode;
    label: string;
    endAdornment?: React.ReactNode;
    helperText?: React.ReactNode;
}

export const OutlinedContainer = ({ children, label, endAdornment, helperText }: IOutlinedContainerProps) => (
    <TextField
        fullWidth
        multiline
        variant="outlined"
        label={label}
        InputLabelProps={{ shrink: true }}
        InputProps={{
            inputComponent: 'div' as any,
            sx: {
                cursor: 'default',
                // padding: 1,
                // paddingLeft: 1.7,
            },
            endAdornment: endAdornment,
        }}
        inputProps={{ children: children }}
        helperText={helperText}
    />
);
