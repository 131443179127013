import * as React from "react";
import {IStatsCategory} from "../../../config/stats";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {styled} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import {DataTable} from "../../Components/DataTable";

const TableCellCategoryName = styled(TableCell)(() => ({
    width: 180,
}));

const TableCellAmount = styled(TableCell)(() => ({
    width: 100,
}));

interface IListViewProps {
    categories: IStatsCategory[];
    selectedCategory: IStatsCategory | null;
}

export const ListView = ({categories, selectedCategory}: IListViewProps) => {
    const { currencyStore } = useGlobalStores();

    return (
        <DataTable
            size="medium"
            config={[
                {
                    title: 'Category name',
                    fieldName: 'name',
                    component: TableCellCategoryName,
                    render(value: any, item: any): any {
                        return (value !== '___NA___' ? value : null) || 'Others...'
                    },
                },
                {
                    title: 'Amount',
                    fieldName: 'total',
                    component: TableCellAmount,
                    render(value: any, item: any): any {
                        return (value as any).toCurrency(currencyStore.currentCurrency);
                    }
                },
                {
                    title: 'Transactions',
                    fieldName: 'count',
                },
            ]}
            items={categories}
        />
    );
};
