import * as React from "react";
import {ITransaction} from "../../../../config/transactions";
import {Grid, styled, useMediaQuery, useTheme} from "@mui/material";
import {Item} from "./Components/Item";
import {ItemsSection} from "./Components/ItemsSection";
import {Amount} from "./Amount";
import {PaymentMethod} from "./PaymentMethod";
import {PurchasedAt} from "./PurchasedAt";
import {Location} from "./Location";
import {SharedWith} from "./SharedWith";
import {AmountDetails} from "./AmountDetails";
import {Details} from "./Details";
import {Tags} from "./Tags";
import { Allowances } from "./Allowances";
import { IAttachment } from "../../../../config/attachments";
import { Attachments } from "./Attachments";
import { Actions } from "./Actions";

const Container = styled('div')(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    margin: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(1),
        marginBottom: theme.spacing(3),
    },
}));

interface ITransactionDetailsProps {
    transaction: ITransaction;
    attachments: IAttachment[];
}

export const TransactionDetails = ({transaction, attachments}: ITransactionDetailsProps) => {
    const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('md'));

    return (
        <Container>
            <Grid container spacing={isSmallScreen ? 1 : 3} alignItems="stretch">
                <Grid item md={12} lg={6}>
                    <ItemsSection title="Transaction">
                        <Item label="" xs={12}>
                            <Actions transaction={transaction} />
                        </Item>
                        <Item label="Amount" xs={12} sm={6}>
                            <Amount transaction={transaction} />
                        </Item>
                        <Item label="Date" xs={12} sm={6}>
                            <PurchasedAt transaction={transaction} />
                        </Item>

                        <Item label="Payment Method" conditional={!!transaction.payment_method_id} xs={12}>
                            <PaymentMethod transaction={transaction} />
                        </Item>

                        <Item label="Shared with" conditional={!!transaction.splits?.length} xs={12} sm={6}>
                            <SharedWith transaction={transaction} />
                        </Item>
                        <Item label="Allowances" conditional={!!transaction.allowances?.length} xs={12} sm={6}>
                            <Allowances transaction={transaction} />
                        </Item>
                        <Item label="Amount details" conditional={!!transaction.details?.amount_details} xs={12} sm={6}>
                            <AmountDetails transaction={transaction} />
                        </Item>

                        <Item label="Unique Identifier" value={transaction.transaction_id} xs={12} />
                    </ItemsSection>
                </Grid>

                <Grid item md={12} lg={6}>
                    <ItemsSection title="Information">
                        <Item label="Location" conditional={!!transaction.location} xs={12}>
                            <Location transaction={transaction} />
                        </Item>
                        <Item label="Description" conditional={!!transaction.description} xs={12}>
                            {transaction.description}
                        </Item>
                        <Item label="Category" conditional={!!transaction.category_id} xs={12}>
                            {transaction.category}
                        </Item>
                        <Item label="Main category" conditional={!!transaction.main_category_id} xs={12}>
                            {transaction.main_category}
                        </Item>

                        <Item label="Tags" conditional={!!transaction.tags?.length} xs={12}>
                            <Tags value={transaction.tags!} />
                        </Item>
                    </ItemsSection>
                </Grid>

                {!!transaction.details && !!Object.keys(transaction.details).length &&
                    <Grid item xs={12}>
                        <ItemsSection title="Details">
                            <Details transaction={transaction} />
                        </ItemsSection>
                    </Grid>
                }

                {!!attachments.length && <Attachments attachments={attachments} />}
            </Grid>
        </Container>
    )
};
