import * as React from "react";
import {TotalChart} from "../../../Charts/TotalChart";
import {IMonthSnapshotItem} from "../../../../../config/snapshot";

interface ICurrentYearTotalSpentProps {
    data: IMonthSnapshotItem[];
}

export const CurrentYearTotalSpent = ({data}: ICurrentYearTotalSpentProps) => {
    const total = data.reduce((prev, cur) => {
        return prev + cur.total;
    }, 0);

    return (
        <TotalChart total={total} size="medium" />
    );
};
