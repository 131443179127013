import * as React from "react";
import { IBudgetProgress } from "../../../../config/budget";
import { BudgetProgressCard } from "../Components/BudgetProgress/BudgetProgressCard";
import { useGlobalStores } from "../../../../hooks/use_global_stores";
import { fetchBudgetsProgress } from "../../../../stores/budgets";
import {styled} from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

const StyledCard = styled(Card)(({theme}) => ({
    marginBottom: theme.spacing(2),
}));

const StyledCardContent = styled(CardContent)(({theme}) => ({
    '&:last-child': {
        paddingBottom: theme.spacing(2),
    },
}));

export const BudgetsProgress = () => {
    const { currencyStore } = useGlobalStores();
    const [value, setValue] = React.useState<IBudgetProgress[]>([]);

    React.useEffect(() => {
        fetchBudgetsProgress().then(budgets => setValue(budgets));
    }, []);

    return (
        <>
            {value.map((budget) => (
                <StyledCard key={budget.id}>
                    <StyledCardContent>
                        <BudgetProgressCard budget={budget} currency={currencyStore.currentCurrency} />
                    </StyledCardContent>
                </StyledCard>
            ))}
        </>
    );
};
