import * as React from "react";
import {useGlobalStores} from "../../../../../hooks/use_global_stores";
import { SelectField } from "../../../../Components/Form/SelectField";

interface IPaymentMethodFieldProps {
    value: string;
    onChange(value: any): void;
    label?: string;
}

export const PaymentMethodField = ({value, onChange, label = "Payment Method"}: IPaymentMethodFieldProps) => {
    const { userPaymentMethodStore } = useGlobalStores();
    const options = userPaymentMethodStore.paymentMethods.filter(p => p.is_active || (!!value ? p.id === parseInt(value) : true));

    return (
        <SelectField
            label={label}
            options={options}
            value={value}
            optionValueGetter={(o) => o.id}
            optionLabelGetter={(o) => o.name}
            optionDisabledGetter={(o) => (!!value ? !o.is_active && o.id === parseInt(value) : false)}
            onChange={onChange}
        />
    );
};
