import * as React from "react";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {useNavigate} from "react-router-dom";
import {DialogTitle, Drawer, Grid, styled, Tooltip} from "@mui/material";
import {Close, RotateLeft} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import {SearchTransactionsForm} from "./SearchTransactionsForm";
import {ITransactionsSearchFormOutputData} from "../../../../config/search";

const StyledDrawer = styled(Drawer)(({theme}) => ({
    zIndex: theme.zIndex.drawer + 1,
    "&.MuiDrawer-root .MuiDrawer-paper": {
        width: 400,

        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
}));

interface ISearchTransactionsContainerProps {
    open: boolean;
    onClose: any;
}

export const SearchTransactions = ({open, onClose}: ISearchTransactionsContainerProps) => {
    const { searchStore, refreshStore } = useGlobalStores();

    const navigate = useNavigate();

    const handleClose = () => {
        navigate(window.location.pathname);
        onClose();
    };

    const handleReset = () => {
        searchStore.reset();
        refreshStore.refreshDashboard();
        refreshStore.refreshTransactionsList();

        handleClose();
    };

    const handleSubmit = (query: ITransactionsSearchFormOutputData) => {
        searchStore.setQuery(query);
        refreshStore.refreshDashboard();
        refreshStore.refreshTransactionsList();

        if (!!window.location.pathname.match(/^\/dashboard\/charts(\/.+)?$/) ||
            !!window.location.pathname.match(/^\/dashboard\/categories(\/.+)?$/) ||
            !!window.location.pathname.match(/^\/dashboard\/companies(\/.+)?$/) ||
            !!window.location.pathname.match(/^\/transactions$/)) {
            handleClose();
        } else if (!!window.location.pathname.match(/^\/dashboard$/)) {
            onClose();

            navigate('/dashboard/charts');
        } else {
            onClose();

            navigate('/transactions');
        }
    };

    return (
        <StyledDrawer anchor="right" open={open} onClose={handleClose}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0}
            >
                <Grid item>
                    <DialogTitle color="primary">Search transactions</DialogTitle>
                </Grid>
                <Grid item>
                    <Tooltip title="Clear search" arrow>
                        <IconButton onClick={handleReset}>
                            <RotateLeft />
                        </IconButton>
                    </Tooltip>

                    <IconButton onClick={handleClose} sx={{marginRight: (theme) => theme.spacing(2)}}>
                        <Close />
                    </IconButton>
                </Grid>
            </Grid>

            <Box sx={{padding: (theme) => theme.spacing(3)}}>
                <SearchTransactionsForm onSubmit={handleSubmit} />
            </Box>
        </StyledDrawer>
    );
};
