import * as React from "react";
import {ReactNode} from "react";
import {Link} from "react-router-dom";
import {CardActionArea} from "@mui/material";

interface ILinkableCardActionAreaProps {
    target?: string;
    children: ReactNode;
}

export const LinkableCardActionArea = ({target, children}: ILinkableCardActionAreaProps) => (
    <CardActionArea
        component={(!!target ? Link : 'div') as any}
        to={target || ""}
        sx={!target ? {cursor: 'default'} : {}}
    >
        {children}
    </CardActionArea>
);
