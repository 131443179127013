import {IErrorResponseItem, ValidationError, ValidationErrors} from "../services/validator";

export enum AllowancesValidationField {
    NAME = "Allowance.Name",
    AMOUNT = "Allowance.Amount",
    CURRENCY = "Allowance.Currency",
    FREQUENCY = "Allowance.Frequency",
    MAX_PER_FREQUENCY = "Allowance.MaxPerFrequency",
}

export type AllowancesValidation = {
    [key in AllowancesValidationField]?: ValidationError[];
};

export type AllowancesValidationErrors = ValidationErrors<AllowancesValidation, AllowancesValidationField>;

export const allowancesValidationFactory = (errors: IErrorResponseItem[]): AllowancesValidationErrors => {
    const v = new ValidationErrors<AllowancesValidation, AllowancesValidationField>();
    v.setMap({
        name: AllowancesValidationField.NAME,
        amount: AllowancesValidationField.AMOUNT,
        currency: AllowancesValidationField.CURRENCY,
        frequency: AllowancesValidationField.FREQUENCY,
        max_per_frequency: AllowancesValidationField.MAX_PER_FREQUENCY,
    });
    v.setKeyParser((key) => {
        return key
            .replace('NewAllowance', 'Allowance')
            .replace('UpdateAllowance', 'Allowance');
    });
    v.setErrors(errors);

    return v;
};
