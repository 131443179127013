import * as React from "react";
import { FileField } from "../../../../../../../Components/Form/FileField";

interface ITripMapProps {
    onChange(value: File | undefined): void;
}

export const TripMap = ({onChange}: ITripMapProps) => {
    return (
        <FileField
            preview
            label="Trip Map"
            accept="image/*"
            onChange={onChange}
        />
    );
};
