import {ICategory, IFormData} from "../config/category";
import {fetchGet, fetchPost} from "../services/api";
import {action, computed, makeObservable, observable} from "mobx";
import {categoriesValidationFactory} from "../validators/categories";

const CATEGORIES_CACHE_KEY = 'em:categories:categories';

export class CategoryStore {
    @observable categories: ICategory[] = [];

    constructor() {
        makeObservable(this);

        const data = window.localStorage.getItem(CATEGORIES_CACHE_KEY);
        if (!data) {
            return;
        }

        this.categories = JSON.parse(data) || [];
    }

    getById(id: number): ICategory | null {
        return this.categories.find(item => item.id === id) || null;
    }

    @action
    load(categories: ICategory[]) {
        this.categories = categories;

        window.localStorage.setItem(CATEGORIES_CACHE_KEY, JSON.stringify(categories));
    }

    @computed get categoriesMap(): Map<number, ICategory> {
        return (this.categories as any).toMap('id');
    }
}

export enum CategoryFetchMode {
    DEFAULT = "standard",
    INDENTED = "indented",
    SUB_ARRAYS = "sub_arrays",
}

interface IFetchCategoriesRequestParams {
    mode: CategoryFetchMode;
}

export const fetchCategories = ({mode}: IFetchCategoriesRequestParams): Promise<ICategory[]> => {
    return fetchGet( '/categories', {mode})
        .then(response => response.json())
        .then(data => data.data);
};

export const createCategory = (data: IFormData): Promise<boolean> => {
    return fetchPost('/categories', data).then((response) => {
        if (response.status !== 201) {
            return response.json().then((reason: any) => {
                if (reason.errors === undefined) {
                    return Promise.reject(reason);
                }

                return Promise.reject(categoriesValidationFactory(reason.errors));
            });
        }

        return true;
    });
};
