import * as React from "react";
import { Box, Button } from "@mui/material";
import {Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@mui/material";
import { FeaturesList } from "./FeaturesList";
import { Feature } from "../../../../config/features";

interface IFeaturesModalProps {
    open: boolean;
    onClose(): void;
    selectedFeature?: Feature;
}

export const FeaturesModal = ({open, onClose, selectedFeature}: IFeaturesModalProps) => (
    <Dialog
        maxWidth="lg"
        open={open}
        onClose={onClose}
    >
        <DialogTitle>Upgrade</DialogTitle>

        <DialogContent>
            <DialogContentText component="div" sx={{color: 'black'}}>
                This feature or page is not available for your subscription plan.<br />
                In order to use it, please upgrade your plan! 🎉
            </DialogContentText>

            <Box>
                <Box sx={{marginTop: 2}}>
                    <FeaturesList selectedFeature={selectedFeature} />
                </Box>
            </Box>
        </DialogContent>

        <DialogActions>
            <Button onClick={onClose} color="primary">
                Cancel
            </Button>
        </DialogActions>
    </Dialog>
)