import * as React from "react";
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import {grey} from "@mui/material/colors";
import {styled, SvgIcon} from "@mui/material";
import CurtainsIcon from '@mui/icons-material/Curtains';
import ReportIcon from '@mui/icons-material/Report';

const Container = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
}));

const StyledPaper = styled(Paper)(({theme}) => ({
    width: 500,
    padding: theme.spacing(5),

    [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

const IconContainer = styled('div')(({theme}) => ({
    marginBottom: theme.spacing(1),
    textAlign: 'center',
}));

const StyledIcon = styled(SvgIcon)(() => ({
    fontSize: 70,
    color: grey[400],
}));

const TextContainer = styled('div')(({theme}) => ({
    marginBottom: theme.spacing(5),
    textAlign: 'center',
    fontSize: 18,
}));

interface INoAccessProps {
    onClick(): void;
    icon?: React.ReactNode;
}

export const NoAccess = ({onClick, icon}: INoAccessProps) => (
    <Container>
        <StyledPaper elevation={3}>
            <IconContainer>
                <StyledIcon>{icon || <ReportIcon />}</StyledIcon>
            </IconContainer>

            <TextContainer>
                Section not enabled.<br />
                In order to view it, please upgrade your plan! 🎉
            </TextContainer>

            <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                startIcon={<CurtainsIcon />}
                onClick={onClick}
            >
                See plans
            </Button>
        </StyledPaper>
    </Container>
);
