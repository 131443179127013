import * as React from "react";
import { Typography, styled } from "@mui/material";

const StyledTypography = styled(Typography)(({theme}) => ({
    fontWeight: 'bold',
    fontSize: 16,
    marginLeft: theme.spacing(1.5),
}));

interface ISectionTitleProps {
    title: string;
}

export const SectionTitle = ({title}: ISectionTitleProps) => (
    <StyledTypography variant="button" color="primary">{title}</StyledTypography>
);
