import * as React from "react";
import {IStatsCategory} from "../../../config/stats";
import {ListView} from "./ListView";
import {CategoriesChart} from "../Charts/CategoriesChart";
import {Box, styled} from "@mui/material";

const SIZE_LARGE = 500;
const SIZE_MEDIUM = 300;

const Container = styled('div')(({theme}) => ({
    display: 'flex',

    [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
    },
}));

const ChartContainer = styled(Box)(({theme}) => ({
    width: SIZE_LARGE,
    height: SIZE_LARGE,

    [theme.breakpoints.down('md')]: {
        width: 'auto',
        height: SIZE_MEDIUM,
    },
}));

const ListContainer = styled(Box)(({theme}) => ({
    flexGrow: 1,
    // marginLeft: theme.spacing(2),

    [theme.breakpoints.down('md')]: {
        marginLeft: 0,
        marginTop: theme.spacing(3),
    },
    [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(15),
        borderTop: 'solid 1px #ccc',
    },
}));

interface ICombinedViewProps {
    categories: IStatsCategory[];
}

export const CombinedView = ({categories}: ICombinedViewProps) => {
    const [selected, setSelected] = React.useState<IStatsCategory | null>(null);

    const handleMouseOver = (category: IStatsCategory) => {
        setSelected(category);
    };
    const handleMouseOut = () => {
        setSelected(null);
    };

    return (
        <Container>
            <ChartContainer>
                <CategoriesChart
                    categories={categories}
                    chartHeight={400}
                    showLegend={false}
                    callbacks={{
                        onMouseOver: handleMouseOver,
                        onMouseOut: handleMouseOut,
                    }}
                />
            </ChartContainer>

            <ListContainer>
                <ListView categories={categories} selectedCategory={selected}/>
            </ListContainer>
        </Container>
    );
};
