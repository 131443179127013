import * as React from "react";
import {IEvent} from "../../../config/event";
import * as moment from "moment";
import Box from "@mui/material/Box";

interface ITimeRangeProps {
    event: IEvent;
}

export const TimeRange = ({event}: ITimeRangeProps) => {
    if (event.from === null || event.to === null) {
        return null;
    }

    const from = event.from as unknown as moment.Moment;
    const to = event.to as unknown as moment.Moment;

    if (from.isSame(to)) {
        return (
            <Box component="span">
                Happened on <Box component="span" sx={{color: '#000'}}>{from.format('MMMM Do')}</Box>
            </Box>
        );
    }

    return (
        <Box component="span">
            Happened from {" "}
            <Box component="span" sx={{color: '#000'}}>{from.format('MMMM Do')}</Box> to {" "}
            <Box component="span" sx={{color: '#000'}}>{to.format('MMMM Do')}</Box>
        </Box>
    );
};
