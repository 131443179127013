import {action, computed, makeObservable, observable} from "mobx";
import {ITransactionsSearchQuery} from "../config/transactions";
import {TimeFrame} from "../config/time_frames";
import moment from "moment";
import {toFormRequest} from "../helpers/search_helpers";
import {ITransactionsSearchFormOutputData} from "../config/search";

const DEFAULT_SEARCH_PARAMS = {
    timePeriod: TimeFrame.THIS_MONTH,
    timeRange: [
        moment().startOf('month'),
        moment().endOf('month'),
    ],
    excludeRecurringExpenses: true,
    excludeWithdrawals: true,
    excludeWeekdaysLunch: false,
    includeWeekdaysLunch: false,
    includePaymentMethods: [],
    includeTags: true,
    excludedTransactions: [],
    query: '',
};

const FORM_DATA_CACHE_KEY = 'em:search:form';

export class SearchStore {
    @observable private query: ITransactionsSearchFormOutputData = DEFAULT_SEARCH_PARAMS;
    @observable isDefault: boolean = true;

    constructor() {
        makeObservable(this);

        let query = window.localStorage.getItem(FORM_DATA_CACHE_KEY) as any;

        if (!query) {
            return;
        }

        query = JSON.parse(query);

        query.timeRange[0] = moment(query.timeRange[0]);
        query.timeRange[1] = moment(query.timeRange[1]);

        this.query = query;
        this.isDefault = false;
    }

    @action
    setQuery(query: ITransactionsSearchFormOutputData) {
        this.query = query;
        this.isDefault = false;

        window.localStorage.setItem(FORM_DATA_CACHE_KEY, JSON.stringify(query));
    }

    @action
    setFreeTextQuery(query: string) {
        const queryRequest = DEFAULT_SEARCH_PARAMS;
        queryRequest.timePeriod = TimeFrame.ALL_TIME;
        queryRequest.timeRange = [moment('1970-01-01'), moment()];
        queryRequest.excludeRecurringExpenses = false;
        queryRequest.excludeWithdrawals = false;
        queryRequest.query = query;

        this.setQuery(queryRequest);
    }

    @action
    reset() {
        this.query = DEFAULT_SEARCH_PARAMS;
        this.isDefault = true;

        window.localStorage.removeItem(FORM_DATA_CACHE_KEY);
    }

    @computed get queryFormData(): ITransactionsSearchFormOutputData {
        return this.query;
    }

    @computed get queryRequest(): ITransactionsSearchQuery {
        return toFormRequest(this.query);
    }

    @computed get hasQuery(): boolean {
        return !!this.query && !this.isDefault;
    }
}
