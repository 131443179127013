import * as React from "react";
import {useEffect} from "react";
import {convertToTranslationKeys, ValidationError} from "../../../services/validator";
import {useTranslation} from "react-i18next";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import FormControl from "@mui/material/FormControl";
import {LocalizationProvider, TimePicker} from "@mui/x-date-pickers";
import FormHelperText from "@mui/material/FormHelperText";
import moment from "moment";

interface ITimeFieldProps {
    label: string;
    format?: string;
    value: string;
    onChange(value: string): void;
    errors?: ValidationError[];
}

export const TimeField = ({label, format = "HH:mm", value, onChange, errors}: ITimeFieldProps) => {
    const { t } = useTranslation();
    const [time, setTime] = React.useState<string>(value);

    const handleChange = (value: moment.Moment | null) => {
        setTime(value!!.format(format));
    };

    useEffect(() => {
        onChange(time);
    }, [time]);

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <FormControl fullWidth variant="outlined">
                <TimePicker
                    label={label}
                    format={format}
                    value={time === '' ? null : moment(time, format)}
                    onChange={handleChange}
                />
                {!!errors && !!errors.length &&
                    <FormHelperText error>
                        {convertToTranslationKeys(errors).map(e => t(e)).join(', ')}
                    </FormHelperText>
                }
            </FormControl>
        </LocalizationProvider>
    );
};
