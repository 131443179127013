import * as React from 'react';
import {Typography} from "@mui/material";
import {ReactNode} from "react";

interface ISubtitleProps {
    children: ReactNode;
}

export const Subtitle = ({children}: ISubtitleProps) => (
    <Typography component="h3" variant="subtitle2" color="textSecondary" gutterBottom>
        {children}
    </Typography>
);
