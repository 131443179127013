import * as React from "react";
import {PaymentProviderLabel} from "../../../../config/payment_providers";
import {ItemValue} from "./Components/ItemValue";
import {ITransaction} from "../../../../config/transactions";

interface IPaidViaProps {
    label: string;
    transaction: ITransaction;
    value: any;
}

export const PaidVia = ({label, transaction, value}: IPaidViaProps) => (
    <ItemValue transaction={transaction} label={label} value={(PaymentProviderLabel as any)[value]} />
);
