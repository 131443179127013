import * as React from "react";
import {Launch} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {ITransaction} from "../../../../config/transactions";
import {GridActionsCellItem} from "@mui/x-data-grid";

interface IViewActionProps {
    transaction: ITransaction;
}

export const ViewAction = ({transaction}: IViewActionProps) => {
    const navigate = useNavigate();

    return (
        <GridActionsCellItem sx={{padding: 0, margin: 0}}
            icon={<Launch sx={{fontSize: 24}} color="primary"/>}
            onClick={() => {
                navigate(`/transactions/${transaction.transaction_id}`);
            }}
            label="View"
        />
    );
};
