import {IExtraIncome, IExtraIncomeFormData} from "../config/extra_income";
import moment from "moment";
import {action, observable} from "mobx";
import {fetchDelete, fetchGet, fetchPatch, fetchPost} from "../services/api";

export class ExtraIncomeStore {
    @observable extraIncomes: IExtraIncome[] = [];

    @action
    populate(extraIncomes: IExtraIncome[]) {
        this.extraIncomes = extraIncomes;
    }

    @action
    addExtraIncome(extraIncome: IExtraIncome) {
        this.extraIncomes.push(extraIncome);
    }

    @action
    removeExtraIncome(extraIncome: IExtraIncome) {
        this.extraIncomes = this.extraIncomes.filter(item => item.id == extraIncome.id)
    }

    @action
    replaceExtraIncome(extraIncome: IExtraIncome) {
        const index = this.extraIncomes.findIndex(item => item.id == extraIncome.id);

        if (index === -1) {
            return;
        }

        this.extraIncomes[index] = extraIncome;
    }

    findExtraIncomeById(id: number): IExtraIncome | null {
        return this.extraIncomes.find(item => item.id == id) || null;
    }
}

export const createExtraIncomeStore = () => {
    return new ExtraIncomeStore();
};

export const createExtraIncome = (data: IExtraIncomeFormData): Promise<number> => {
    return fetchPost( `/extra_incomes`, data)
        .then((response: any) => {
            if (response.status !== 201) {
                return Promise.reject(response);
            }

            const id = parseInt(response.headers.get('Location').replace(/.*\/([0-9]+)$/, '$1'));

            return Promise.resolve(id);
        });
};

export const updateExtraIncome = (id: number, data: IExtraIncomeFormData): Promise<IExtraIncome> => {
    return fetchPatch( `/extra_incomes/${id}`, data)
        .then((response: any) => {
            if (response.status !== 200) {
                return Promise.reject(response);
            }

            return response.json();
        })
        .then(data => {
            const item = data.data;

            item.date = moment(item.date);

            return item;
        });
};

export const deleteExtraIncome = (id: number): Promise<true> => {
    return fetchDelete(`/extra_incomes/${id}`);
};

export const fetchExtraIncomes = ({from, to}: any = {}): Promise<IExtraIncome[]> => {
    let query = {};
    if (!!from && !!to) {
        query = {from, to};
    }

    return fetchGet( `/extra_incomes`, query)
        .then(response => response.json())
        .then(data => {
            return data.data.map((item: any) => {
                item.date = moment(item.date);

                return item
            });
        });
};

export const fetchExtraIncome = (id: number): Promise<IExtraIncome> => {
    return fetchGet( `/extra_incomes/${id}`)
        .then(response => response.json())
        .then(data => {
            const item = data.data;

            item.date = moment(item.date);

            return item;
        });
};
