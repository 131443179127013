import * as React from "react";
import {fetchExtraIncomes} from "../../../stores/extra_income";
import {PageLoader} from "../../Components/PageLoader";
import Error from "../../Components/Error";
import {NoExtraIncomes} from "../Components/NoExtraIncomes";
import {useExtraIncomeStore} from "../../../hooks/use_extra_income";
import {IReactionDisposer, reaction} from "mobx";
import {useEffect} from "react";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {ExtraIncomeItem} from "./ExtraIncomeItem";
import {AddNewExtraIncome} from "../Components/AddNewExtraIncome";
import {styled} from "@mui/material";

const Container = styled('div')(() => ({
    display: 'flex',
    flexWrap: 'wrap',
}));

export const ExtraIncomeList = () => {
    const extraIncomeStore = useExtraIncomeStore();
    const { refreshStore } = useGlobalStores();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<boolean>(false);

    const loadExtraIncomes = () => {
        setError(false);
        setLoading(true);

        fetchExtraIncomes()
            .then(result => {
                extraIncomeStore.populate(result);

                setLoading(false);
                setError(false);
            })
            .catch(error => {
                setLoading(false);
                setError(true);
            });
    };

    React.useEffect(() => {
        loadExtraIncomes();
    }, []);

    let reloadReactionDisposer: IReactionDisposer;
    useEffect(() => {
        if (!reloadReactionDisposer) {
            reloadReactionDisposer = reaction(
                () => refreshStore.extraIncomes,
                () => {
                    loadExtraIncomes();
                },
            );
        }

        return () => {
            reloadReactionDisposer();
        }
    });

    if (loading) {
        return (
            <PageLoader />
        );
    }

    if (error) {
        return (
            <Error />
        );
    }

    if (!extraIncomeStore.extraIncomes.length) {
        return (
            <NoExtraIncomes />
        );
    }

    return (
        <Container>
            <AddNewExtraIncome />

            {extraIncomeStore.extraIncomes.map(extraIncome => (
                <ExtraIncomeItem
                    key={extraIncome.id}
                    extraIncome={extraIncome}
                />
            ))}
        </Container>
    );
};
