import * as React from "react";
import {observer} from "mobx-react";
import {useEffect, useState} from "react";
import {useRecurringExpenseStore} from "../../../hooks/use_recurring_expense_store";
import {fetchRecurringExpenses, fetchRecurringExpensesStats} from "../../../stores/recurring_expense";
import {reaction} from "mobx";
import {PageLoader} from "../../Components/PageLoader";
import Error from "../../Components/Error";
import {RecurringExpensesContainer} from "./RecurringExpensesContainer";
import {NoRecurringExpenses} from "./Components/NoRecurringExpenses";

export const RecurringExpensesList = observer(() => {
    const store = useRecurringExpenseStore();

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    useEffect(() => {
        reaction(
            () => store.shouldStatsBeReloaded,
            (reload) => {
                if (!reload) {
                    return;
                }

                fetchRecurringExpensesStats().then((res) => {
                    store.loadStats(res);
                });
            }
        );

        if (!store.shouldBePopulated && !store.shouldStatsBeReloaded) {
            return;
        }

        setLoading(true);
        setError(false);

        Promise.all([
            store.shouldBePopulated ? fetchRecurringExpenses() : Promise.resolve(),
            store.shouldStatsBeReloaded ? fetchRecurringExpensesStats() : Promise.resolve(),
        ])
            .then(response => {
                const [recurringExpenses, stats] = response;

                if (!!recurringExpenses) {
                    store.load(recurringExpenses);
                }
                if (!!stats) {
                    store.loadStats(stats);
                }
            })
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return (
            <PageLoader />
        );
    }

    if (error) {
        return (
            <Error />
        );
    }

    if (!store.count) {
        return (
            <NoRecurringExpenses />
        );
    }

    return (
        <RecurringExpensesContainer />
    );
});
