import * as React from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import {SnackbarStore} from "../../../../stores/snackbar";
import Button from "@mui/material/Button";
import {
    deleteUserPaymentMethod,
    updateUserPaymentMethod
} from "../../../../stores/user_payment_methods";
import {IUserPaymentMethod} from "../../../../config/user_payment_method";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {ConfirmationDialogWithTrigger} from "../../../Components/ConfirmationDialogWithTrigger";

enum DeleteActionMode {
    DELETE,
    SUSPEND,
}

interface IDeleteActionProps {
    paymentMethod: IUserPaymentMethod;
}

export const DeleteAction = ({paymentMethod}: IDeleteActionProps) => {
    const { userPaymentMethodStore, snackbarStore } = useGlobalStores();

    const [loading, setLoading] = React.useState(false);

    const handleSubmit = (mode: DeleteActionMode, closeDialog: any) => () => {
        closeDialog();
        setLoading(true);

        const successfulCallbackForDelete = () => {
            setLoading(false);

            snackbarStore.open(SnackbarStore.SUCCESS, 'Payment method deleted successfully!');
            userPaymentMethodStore.removePaymentMethod(paymentMethod);
        };

        const successfulCallbackForSuspend = (paymentMethod: IUserPaymentMethod) => {
            snackbarStore.open(SnackbarStore.SUCCESS, 'Payment method suspended successfully!');
            userPaymentMethodStore.replacePaymentMethod(paymentMethod);

            setLoading(false);
        };

        const unsuccessfulCallback = () => {
            setLoading(false);

            snackbarStore.open(SnackbarStore.FAIL);
        };

        if (mode === DeleteActionMode.DELETE) {
            deleteUserPaymentMethod(paymentMethod.id)
                .then(successfulCallbackForDelete)
                .catch(unsuccessfulCallback);
        } else if (mode === DeleteActionMode.SUSPEND) {
            updateUserPaymentMethod(paymentMethod.id, {
                payment_method_id: paymentMethod.payment_method_id,
                is_active: false,
                name: paymentMethod.name,
            })
                .then(successfulCallbackForSuspend)
                .catch(unsuccessfulCallback);
        }
    };

    return (
        <ConfirmationDialogWithTrigger
            icon={<DeleteIcon />}
            loading={loading}
            title="Delete payment method"
            description={<>
                Are you sure to delete this payment method?<br/>
                <strong>The operation cannot be undone.</strong><br/><br/>

                {paymentMethod.is_active &&
                "How about suspending it? When a payment method is suspended cannot be selected when adding " +
                "a new expense, etc. but you can resume it any time you want."
                }
            </>}
            actions={({closeDialog}) => (
                paymentMethod.is_active ?
                    [
                        <Button onClick={handleSubmit(DeleteActionMode.SUSPEND, closeDialog)} color="primary" key="suspend">
                            Suspend it
                        </Button>,
                        <Button onClick={handleSubmit(DeleteActionMode.DELETE, closeDialog)} color="error" key="delete">
                            Delete it
                        </Button>,
                    ] :
                    [
                        <Button onClick={handleSubmit(DeleteActionMode.DELETE, closeDialog)} color="error" key="delete">
                            Delete it
                        </Button>,
                    ]
            )}
        />
    );
};
