import { IVehicleForHireDetails } from "../config/vehicle_for_hire";
import { convertAmountToFloat } from "./transaction_helpers";

export const toForm = (input?: IVehicleForHireDetails): IVehicleForHireDetails => {
    if (!input) {
        return {
            addressFrom: '',
            addressTo: '',
            distanceUnit: 'KM',
            distance: '' as any,
            pickupTime: '',
            dropoffTime: '',
            driverName: '',
            tripMapFile: '' as any,
        };
    }

    input.distance = input.distance?.toString() as any;
    return input;
};

export const toExport = (values: IVehicleForHireDetails): IVehicleForHireDetails => {
    return {
        addressFrom: values.addressFrom || null,
        addressTo: values.addressTo || null,
        distanceUnit: values.distanceUnit || null,
        distance: !!values.distance ? convertAmountToFloat(values.distance.toString()) : null,
        pickupTime: values.pickupTime || null,
        dropoffTime: values.dropoffTime || null,
        driverName: values.driverName || null,
    };
};
