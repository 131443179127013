import * as React from "react";
import {Hidden, styled} from "@mui/material";
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Fab from '@mui/material/Fab';
import IconButton from '@mui/material/IconButton';
import AddIcon from '@mui/icons-material/Add';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import SettingsIcon from '@mui/icons-material/Settings';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import HomeIcon from '@mui/icons-material/Home';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ViewStreamIcon from '@mui/icons-material/ViewStream';
import ViewStreamOutlinedIcon from '@mui/icons-material/ViewStreamOutlined';
import { INewTransactionComponentProps, NewTransactionAction } from "../Actions/NewTransactionAction";
import { useLocation, useNavigate } from "react-router-dom";
import { DockSection } from "./config";
import { getCurrentSelection } from "./helpers";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    top: 'auto',
    bottom: 0,
    paddingBottom: theme.spacing(1.5),
    borderTop: 'solid 1px',
    borderTopColor: theme.palette.divider,
}));

const StyledFab = styled(Fab)({
    position: 'absolute',
    zIndex: 1,
    // top: -30,
    top: -15,
    left: 0,
    right: 0,
    margin: '0 auto',
});

const StyledToolbar = styled(Toolbar)({
    display: 'flex',
    justifyContent: 'space-around',
});

const NewTransactionActionComponent = ({onClick}: INewTransactionComponentProps) => (
    <StyledFab color="info" size="large" onClick={onClick} className="new-transaction-button">
        <AddIcon />
    </StyledFab>
);

interface IDockProps {
    onMenuOpen(): void;
}

export const Dock = ({onMenuOpen}: IDockProps) => {
    const { pathname } = useLocation();
    const navigate = useNavigate();

    const [current, setCurrent] = React.useState<DockSection>();

    const handleNavigate = (dest: string) => () => {
        navigate(dest);
    };

    React.useEffect(() => {
        setCurrent(getCurrentSelection(pathname));
    }, [pathname]);

    return (
        <Hidden smUp>
            <StyledAppBar position="fixed" color="default">
                <StyledToolbar>
                    <IconButton color="primary" size="large" onClick={handleNavigate('/')}>
                        {current === DockSection.HOME ? <HomeIcon sx={{fontSize: 30}} /> : <HomeOutlinedIcon sx={{fontSize: 30}} />}
                    </IconButton>

                    <IconButton color="primary" size="large" onClick={handleNavigate('/transactions')}>
                        {current === DockSection.TRANSACTIONS ? <ViewStreamIcon sx={{fontSize: 30}} /> : <ViewStreamOutlinedIcon sx={{fontSize: 30}} />}
                    </IconButton>
    
                    <Box sx={{width: 75}}>
                        <NewTransactionAction component={NewTransactionActionComponent}/>
                    </Box>

                    <IconButton color="primary" size="large" onClick={handleNavigate('/settings')}>
                        {current === DockSection.SETTINGS ? <SettingsIcon sx={{fontSize: 30}} /> : <SettingsOutlinedIcon sx={{fontSize: 30}} />}
                    </IconButton>
    
                    <IconButton color="primary" size="large" onClick={onMenuOpen}>
                        <MoreHorizIcon sx={{fontSize: 30}} />
                    </IconButton>
                </StyledToolbar>
            </StyledAppBar>
        </Hidden>
    );    
}