import * as React from "react";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {FreeTextField} from "../../../Components/Form/FreeTextField";
import {SelectField} from "../../../Components/Form/SelectField";
import {useForm} from "../../../../hooks/use_form_v2";
import {ICategory, IFormData} from "../../../../config/category";
import {IndentedItem} from "../../../Components/IndentedItem";
import { Form as FormComponent } from "../../../Components/Form/Form/Form";
import { CheckboxField } from "../../../Components/Form/CheckboxField";

interface INewCategoryState {
    parentCategoryId: string;
    name: string;
    code: string;
    isGlobal: boolean;
}

interface IFormProps {
    value?: ICategory;
    onSubmit(data: IFormData): Promise<any>;
    onSuccess?(): void;
}

export const Form = ({value, onSubmit, onSuccess}: IFormProps) => {
    const { categoryStore } = useGlobalStores();

    const form = useForm<INewCategoryState>(
        !value ?
            {
                parentCategoryId: '',
                name: '',
                code: '',
                isGlobal: false,
            } :
            {
                parentCategoryId: value.id.toString(),
                name: value.name,
                code: value.code,
                isGlobal: value.is_global,
            }
    );
    const { register } = form;

    const handleSubmit = (values: INewCategoryState) => {
        const data = {
            parent_category_id: parseInt(values.parentCategoryId),
            name: values.name,
            code: values.code,
            is_global: values.isGlobal,
        };

        return onSubmit(data).then(() => onSuccess && onSuccess());
    };

    return (
        <FormComponent form={form} onSubmit={handleSubmit}>
            <FormComponent.Field
                type={SelectField}
                {...register('parentCategoryId')}
                label="Parent Category"
                options={categoryStore.categories}
                optionValueGetter={(o: any) => o.id.toString()}
                optionLabelGetter={(o: any) => <IndentedItem value={o.name} />}
            />
            <FormComponent.Field type={FreeTextField} {...register('name')} />
            <FormComponent.Field type={FreeTextField} {...register('code')} />
            <FormComponent.Field type={CheckboxField} {...register('isGlobal')} conditional={false} label="Mark as global" />
        </FormComponent>
    );
};
