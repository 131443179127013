import * as React from "react";
import { Box, Grid, styled } from "@mui/material";
import { ITransaction } from "../../../../config/transactions";
import { IAttachment } from "../../../../config/attachments";
import { Amount } from "./Fields/Amount";
import { Title } from "./Fields/Title";
import { Actions } from "./Fields/Actions";
import { Attachments } from "./Fields/Attachments";
import { Location } from "./Fields/Location";
import { Details } from "./Fields/Details";
import { Extras } from "./Fields/Extras";

const Container = styled(Box)(({theme}) => ({
    padding: theme.spacing(1),
    height: '100vh',
}));

interface IMobileViewProps {
    transaction: ITransaction;
    attachments: IAttachment[];
}

export const MobileView = ({transaction, attachments}: IMobileViewProps) => {
    return (
        <Container>
            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Title transaction={transaction} />
                </Grid>

                <Grid item xs={12}>
                    <Amount transaction={transaction} />
                </Grid>

                <Grid item xs={12}>
                    <Actions transaction={transaction} />
                </Grid>

                {!!transaction.location && (
                    <Grid item xs={12}>
                        <Location transaction={transaction} />
                    </Grid>
                )}

                {(!!transaction.payment_method_id || !!transaction.payment_method_id || !!transaction.payment_method_id || !!transaction.description) && (
                    <Grid item xs={12}>
                        <Details transaction={transaction} />
                    </Grid>
                )}

                {!!attachments.length && (
                    <Grid item xs={12}>
                        <Attachments attachments={attachments} />
                    </Grid>
                )}

                <Extras transaction={transaction} attachments={attachments} />
            </Grid>

            <div style={{height: 30}} />
        </Container>
    );
};
