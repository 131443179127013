import * as React from "react";
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import {useTranslation} from "react-i18next";
import {styled} from "@mui/material";

const StyledStepper = styled(Stepper)(({theme}) => ({
    marginBottom: theme.spacing(2),
}));

const STEPS = [
    'select_file',
    'check_data',
    'save',
];

interface IStepsProps {
    activeStep: number;
}

export const Steps = ({activeStep}: IStepsProps) => {
    const { t } = useTranslation();

    return (
        <StyledStepper activeStep={activeStep} alternativeLabel>
            {STEPS.map(step => {
                const stepProps: { completed?: boolean } = {};

                return (
                    <Step key={step} {...stepProps}>
                        <StepLabel>{t(`transactions.bulk.steps.${step}`)}</StepLabel>
                    </Step>
                );
            })}
        </StyledStepper>
    )
}
