type KeyParser = (key: string) => string;

export class ValidationErrors<T, F> {
    private readonly errors: T | any = {} as any;
    private parseKey: KeyParser = (key) => { return key };
    private map: any = {};

    setKeyParser(parser: KeyParser): void {
        this.parseKey = parser;
    }

    has(field: F): boolean {
        const v = this.errors[field] as any;

        return !!v && v.length > 0;
    }

    setErrors(errors: IErrorResponseItem[]) {
        errors.forEach(e => {
            this.add(e.key as any, e.validator as any);
        });
    }

    add(field: F, error: ValidationError): this {
        field = this.parseKey(field as any) as any;
        if (!this.has(field)) {
            this.errors[field] = [];
        }

        this.errors[field].push(error);

        return this;
    }

    get(field: F): ValidationError[] {
        return this.errors[field] as any;
    }

    setMap(map: object): void {
        this.map = map;
    }

    getFromMap(field: string): ValidationError[] {
        if (!this.map.hasOwnProperty(field)) {
            return [];
        }
        return this.errors[this.map[field]] as any;
    }

    isValid(): boolean {
        return Object.keys(this.errors).length === 0;
    }

    getErrors(): T {
        return this.errors
    }
}

export interface IErrorResponseItem {
    key: string;
    validator: ValidationError;
}

export enum ValidationError {
    REQUIRED = 'required',
    CURRENCY_NOT_ALLOWED = 'allowed_currency',
    DISTANCE_VALUE = 'distance_value',
    WRONG_TIME = 'wrong_time',
    FIELDS_NOT_MATCHING = 'eqcsfield',
    WRONG_CURRENT_PASSWORD = 'current_user_password',
}

export const convertToTranslationKeys = (errors: ValidationError[]): string[] => {
    return errors.map(error => `global.validation.${error}`);
};
