import * as React from "react";
import Paper from '@mui/material/Paper';
import {grey} from "@mui/material/colors";
import {styled} from "@mui/material";
import {Today} from "@mui/icons-material";

const Container = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
}));

const StyledPaper = styled(Paper)(({theme}) => ({
    width: 500,
    padding: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
    },
}));

const IconContainer = styled('div')(({theme}) => ({
    marginBottom: theme.spacing(1),
    textAlign: 'center',
}));

const TodayIcon = styled(Today)(() => ({
    fontSize: 70,
    color: grey[400],
}));

const TextContainer = styled('div')(() => ({
    textAlign: 'center',
    fontSize: 18,
}));

export const NoResults = () => (
    <Container>
        <StyledPaper elevation={3}>
            <IconContainer>
                <TodayIcon />
            </IconContainer>

            <TextContainer>
                Data not yet available :(
            </TextContainer>
        </StyledPaper>
    </Container>
);
