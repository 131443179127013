import {IErrorResponseItem, ValidationError, ValidationErrors} from "../services/validator";

export enum RecurringExpenseValidationField {
    DESCRIPTION = "RecurringExpense.Description",
    TIME_FREQUENCY = "RecurringExpense.Time",
    AMOUNT = "RecurringExpense.Amount",
    CURRENCY = "RecurringExpense.Currency",
}

export type RecurringExpenseValidation = {
    [key in RecurringExpenseValidationField]: ValidationError[];
};

export const createRecurringExpensesValidation = (): ValidationErrors<RecurringExpenseValidation, RecurringExpenseValidationField> => {
    const v = new ValidationErrors<RecurringExpenseValidation, RecurringExpenseValidationField>();
    v.setMap({
        description: RecurringExpenseValidationField.DESCRIPTION,
        currency: RecurringExpenseValidationField.CURRENCY,
        amount: RecurringExpenseValidationField.AMOUNT,
        timeFrequency: RecurringExpenseValidationField.TIME_FREQUENCY,
    })
    v.setKeyParser((key) => {
        return key
            .replace('NewRecurringExpense', 'RecurringExpense')
            .replace('UpdateRecurringExpense', 'RecurringExpense');
    });

    return v;
};

export const convertFromResponse = (errors: IErrorResponseItem[]): ValidationErrors<RecurringExpenseValidation, RecurringExpenseValidationField> => {
    const data = createRecurringExpensesValidation();

    errors.forEach(e => {
        data.add(e.key as any, e.validator as any);
    });

    return data;
};
