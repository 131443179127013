import {IUser} from "../../../../config/user";
import {Currency} from "../../../../config/currencies";
import {IRecurringExpense, RecurringExpensesTimeFrequency} from "../../../../config/recurring_expense";
import {IRecurringExpenseFormOutputData, IRecurringExpenseFormState} from "../RecurringExpenseForm";
import {buildCompanyFromRecurringExpense} from "../../../../helpers/recurring_expenses_helper";
import {convertAmountToFloat} from "../../../../helpers/transaction_helpers";

export const toFormData = (user: IUser, e?: IRecurringExpense): IRecurringExpenseFormState => {
    if (!e) {
        return {
            description: '',
            amount: '',
            currency: user.default_currency || Currency.EUR,
            timeFrequency: RecurringExpensesTimeFrequency.MONTHLY,
            categoryId: '',
            company: null,
            paymentMethodId: '',
            active: true,
        };
    }

    return {
        incomeId: e.income_id,
        description: e.description,
        currency: e.currency,
        amount: e.amount.toString(),
        timeFrequency: e.time_frequency,
        categoryId: e.category_id?.toString() || '',
        company: buildCompanyFromRecurringExpense(e),
        paymentMethodId: e.payment_method_id?.toString() || '',
        active: e.active,
    };
};

export const toFormRequest = (input: IRecurringExpenseFormState): IRecurringExpenseFormOutputData => {
    return {
        income_id: input.incomeId ? parseInt(input.incomeId.toString()) : undefined,
        description: input.description,
        amount: convertAmountToFloat(input.amount),
        currency: input.currency,
        time_frequency: input.timeFrequency,
        category_id: parseInt(input.categoryId),
        company_id: input.company?.id || null,
        payment_method_id: parseInt(input.paymentMethodId),
        active: input.active,
    };
};
