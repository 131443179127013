import * as React from "react";

interface ICompanyFieldProps {
    companyName?: string | null;
}

export const CompanyField = ({companyName}: ICompanyFieldProps) => {
    if (!companyName) {
        return null;
    }

    return (
        <span>{companyName}</span>
    )
};
