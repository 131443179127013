import * as React from "react";
import { ICallbackAction } from "../../../../../../config/user_payment_method_callback";
import { Typography } from "@mui/material";

interface IShareWithFriendsPercentageProps {
    value: ICallbackAction;
}

export const ShareWithFriendsPercentage = ({value}: IShareWithFriendsPercentageProps) => {
    return (
        <Typography variant="button" sx={{fontSize: 26}}>
            Ena - {value.payload.percentage}%
        </Typography>
    )
};
