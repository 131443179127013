import * as React from "react";
import {IExtraIncome} from "../../../config/extra_income";
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import {Link} from "react-router-dom";
import {DeleteAction} from "../Delete/DeleteAction";
import {styled} from "@mui/material";

const Container = styled(Card)(({theme}) => ({
    flexGrow: 1,

    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),
    minWidth: 250,

    '&:last-of-type': {
        marginRight: 0,
    },

    [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        width: '100%',
    },
}));

const Amount = styled(Typography)(({theme}) => ({
    marginTop: theme.spacing(3),
    textAlign: 'center',
}));

interface IExtraIncomeItemProps {
    extraIncome: IExtraIncome;
}

export const ExtraIncomeItem = ({extraIncome}: IExtraIncomeItemProps) => (
    <Container>
        <CardContent>
            <Typography color="textPrimary" variant="subtitle1">
                {extraIncome.description}
            </Typography>
            <Typography color="textSecondary" variant="body2">
                {extraIncome.date.format('DD/MM/YYYY')}
            </Typography>

            <Amount color="textPrimary" variant="h4">
                {(extraIncome.amount as any).toCurrency(extraIncome.currency)}
            </Amount>
        </CardContent>

        <CardActions>
            <Button component={Link} to={`/extra-incomes/${extraIncome.id}/edit`} size="small" color="primary">Edit</Button>
            <DeleteAction extraIncome={extraIncome} />
        </CardActions>
    </Container>
);
