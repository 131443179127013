import moment from "moment";

export const isDateInCurrentMonth = (date: moment.Moment | string): boolean => {
    return moment(date).isSame(moment(), 'month');
};

export const weeksOfMonth = (date: moment.Moment): number => {
    const startMonth = date.clone().startOf('month');
    const startWeek = startMonth.clone().startOf('isoWeek');
    const startOffset = startMonth.diff(startWeek, 'days');

    const endMonth = date.clone().endOf('month');
    const endWeek = endMonth.clone().endOf('isoWeek');
    const endOffset = endWeek.diff(endMonth, 'days');

    return Math.ceil((endMonth.diff(startMonth, 'days') + startOffset + endOffset) / 7);
}
