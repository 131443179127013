import * as React from "react";
import { IBudgetDetails } from "../../../../config/budget";
import { fetchBudgetDetails } from "../../../../stores/budgets";
import { useParams } from "react-router-dom";
import { PageLoader } from "../../../Components/PageLoader";
import Error from "../../../Components/Error";
import { PageItem } from "../../../Components/PageItem";
import { BudgetDetailsUsages } from "../Components/BudgetDetailsUsages/BudgetDetailsUsages";

export const BudgetView = () => {
    const {id} = useParams();

    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<boolean>(false);
    const [budget, setBudget] = React.useState<IBudgetDetails>();

    React.useEffect(() => {
        setLoading(true);
        fetchBudgetDetails(id!)
            .then(res => setBudget(res))
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    }, [id]);

    if (loading) {
        return (
            <PageLoader />
        );
    }

    if (error) {
        return (
            <Error />
        );
    }

    if (!budget) {return null}

    return (
        <PageItem title={`Budget: ${budget?.name}`}>
            <BudgetDetailsUsages budget={budget} />
        </PageItem>
    );
}