import * as React from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    useMediaQuery,
    useTheme
} from "@mui/material";
import {Close} from "@mui/icons-material";
import {Breakpoint} from "@mui/system";

interface IFormModalProps {
    title: string;
    open: boolean;
    loading?: boolean;
    onClose: any;
    maxWidth?: Breakpoint;
    children: JSX.Element | JSX.Element[];
}

export const FormModal = ({title, open, loading = false, onClose, maxWidth = "md", children}: IFormModalProps) => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <Dialog
            fullWidth
            fullScreen={fullScreen}
            maxWidth={maxWidth}
            open={open}
            onClose={onClose}
        >
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
            >
                <Grid item>
                    <DialogTitle>{title}</DialogTitle>
                </Grid>
                <Grid item>
                    <IconButton
                        onClick={onClose}
                        disabled={loading}
                        sx={{marginRight: (theme) => theme.spacing(2)}}
                        size="large">
                        <Close />
                    </IconButton>
                </Grid>
            </Grid>

            <DialogContent>
                {children}
            </DialogContent>
            <DialogActions />
        </Dialog>
    );
};
