import {IUserPaymentMethod, IUserPaymentMethodCreate} from "../config/user_payment_method";
import {IMuiSelectOption} from "../config/mui";
import {INewPaymentMethodState} from "../components/Settings/UserPaymentMethods/Actions/NewAction";

export const transformToMuiSelectFormat = (data: IUserPaymentMethod[]): IMuiSelectOption[] => {
    return data.map(({id, name}) => {
        return {value: id, label: name};
    });
};

export const toFormRequest = (input: INewPaymentMethodState): IUserPaymentMethodCreate => {
    const data = {} as IUserPaymentMethodCreate;
    if (!!input.paymentMethodId) {
        data.payment_method_id = parseInt(input.paymentMethodId);
    }
    if (!!input.name) {
        data.name = input.name;
    }

    return data;
};
