import * as React from "react";
import {Grid} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";

interface IIncludeCsvHeaderProps {
    onChange(checked: boolean): void;
}

export const IncludeCsvHeader = ({onChange}: IIncludeCsvHeaderProps) => {
    const { t } = useTranslation();

    const [checked, setChecked] = React.useState<boolean>(true);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {
        onChange(checked);
    }, [checked]);

    return (
        <Grid item xs={12}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        color="primary"
                    />
                }
                label={t('bulk.action.export.form.include_csv_header')}
            />
        </Grid>
    );
};
