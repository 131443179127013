import * as React from "react";
import {useTranslation} from "react-i18next";
import {logout} from "../../services/login";
import {
    Box,
    Button,
    CircularProgress,
    Dialog, DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ListItemButton,
    ListItemIcon,
    ListItemText
} from "@mui/material";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { useModal } from "../../hooks/use_modal";

export const Logout = () => {
    const { t } = useTranslation();

    const { open, setOpen, loading, setLoading } = useModal();

    const handleOpenConfirmation = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleConfirm = () => {
        setLoading(true);

        logout().then(callback => {
            setLoading(false);
            setOpen(false);

            callback();
        });
    };

    return (
        <>
            <ListItemButton onClick={handleOpenConfirmation}>
                <ListItemIcon><ExitToAppIcon /></ListItemIcon>
                <ListItemText primary={t('sidebar.logout_title')} />
            </ListItemButton>

            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>{t('logout.title')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        {t('logout.description')}
                    </DialogContentText>

                    {loading &&
                        <Box sx={{display: 'flex', justifyContent: 'center', marginTop: 3}}>
                            <CircularProgress />
                        </Box>
                    }
                </DialogContent>
                <DialogActions>
                    <Button disabled={loading} onClick={handleClose} color="primary">
                        {t('global.buttons.cancel')}
                    </Button>
                    <Button disabled={loading} onClick={handleConfirm} color="primary" autoFocus>
                        {t('logout.form.logout_button')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}
