import * as React from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import {IEventDetails} from "../../../../config/event";
import {deleteEvent} from "../../../../stores/events";
import Button from "@mui/material/Button";
import {SnackbarStore} from "../../../../stores/snackbar";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {useEventStore} from "../../../../hooks/use_event_store";
import {ConfirmationDialogWithTrigger} from "../../../Components/ConfirmationDialogWithTrigger";
import {useState} from "react";

enum DeleteActionMode {
    EVENT_ONLY,
    EVENT_AND_TRANSACTIONS,
}

interface IDeleteEventActionProps {
    item: IEventDetails;

    onDelete?(): void;
}

export const DeleteAction = ({item, onDelete}: IDeleteEventActionProps) => {
    const {snackbarStore} = useGlobalStores();
    const eventStore = useEventStore();

    const [loading, setLoading] = useState(false);

    const handleSubmit = (mode: DeleteActionMode, closeDialog: any) => () => {
        closeDialog();
        setLoading(true);

        const successfulCallback = () => {
            eventStore.removeEvent(item);
            snackbarStore.open(SnackbarStore.SUCCESS, 'Event deleted successfully');

            setLoading(false);

            if (onDelete) {
                onDelete();
            }
        };

        const unsuccessfulCallback = () => {
            setLoading(false);

            snackbarStore.open(SnackbarStore.FAIL);
        };

        if (mode === DeleteActionMode.EVENT_ONLY) {
            deleteEvent(item.id)
                .then(successfulCallback)
                .catch(unsuccessfulCallback);
        } else if (mode === DeleteActionMode.EVENT_AND_TRANSACTIONS) {
            Promise.all([
                deleteEvent(item.id),
            ])
                .then(successfulCallback)
                .catch(unsuccessfulCallback);
        }
    };

    return (
        <ConfirmationDialogWithTrigger
            icon={<DeleteIcon/>}
            loading={loading}
            title="Delete Event"
            description={<React.Fragment>
                Are you sure you wanna delete this event?<br/>
                <strong>The operation cannot be undone.</strong>
            </React.Fragment>}
            actions={({closeDialog}) => (
                [
                    <Button onClick={handleSubmit(DeleteActionMode.EVENT_ONLY, closeDialog)} color="primary" autoFocus key="yes">
                        Yes
                    </Button>,
                    // <Button onClick={handleSubmit(DeleteActionMode.EVENT_AND_TRANSACTIONS, closeDialog)} color="error" disabled key="hard">
                    //     Yes and delete the transactions too
                    // </Button>
                ]
            )}
        />
    );
};
