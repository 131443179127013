import * as React from "react";
import Grid from "@mui/material/Grid";
import "../../../../utils/number_extensions";
import {fetchSingleYearSnapshot} from "../../../../stores/snapshot";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {DashboardItemsContainer} from "../../Components/DashboardItemsContainer";
import {YearlyView} from "./YearlyView";
import {ISingleYear} from "../../../../config/snapshot";
import Error from "../../../Components/Error";
import {useParams} from "react-router-dom";
import {LoadingPlaceholders} from "../Components/LoadingPlaceholders";

export const YearView = () => {
    const { currencyStore } = useGlobalStores();
    const { year } = useParams();

    const [data, setData] = React.useState<ISingleYear>({transactions: [], extra_incomes: []});
    const [loading, setLoading] = React.useState<boolean>(true);
    const [error, setError] = React.useState<boolean>(false);

    React.useEffect(() => {
        (async () => {
            setLoading(true);

            try {
                const singleYearData = await fetchSingleYearSnapshot(year!);
                setData(singleYearData);
            } catch (error) {
                setError(true);
            }

            setLoading(false);
        })();
    }, [year, fetchSingleYearSnapshot]);

    if (loading) {
        return (
            <LoadingPlaceholders showYearsLoading={false} />
        );
    }

    if (error) {
        return (
            <Error />
        );
    }

    const currency = currencyStore.currentCurrency;

    return (
        <DashboardItemsContainer>
            <Grid item xs={12}>
                <YearlyView data={data} currency={currency} />
            </Grid>
        </DashboardItemsContainer>
    );
};
