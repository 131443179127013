import * as React from "react";
import {ISnapshotTimeline} from "../../../../config/snapshot";
import {periodPercentageComparison} from "../../../../helpers/snapshot_helpers";
import {Currency} from "../../../../config/currencies";
import "../../../../utils/number_extensions";
import {SnapshotCard, SnapshotCardColor} from "../../../Components/SnapshotCard";
import {Trend} from "../../../Components/Icons/TrendIcon";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import EuroSymbolIcon from "@mui/icons-material/EuroSymbol";

interface ITotalSpentItemProps {
    snapshotTimeline: ISnapshotTimeline;
    currency: Currency;
}

export const TotalSpentItem = ({snapshotTimeline, currency}: ITotalSpentItemProps) => {
    return (
        <SnapshotCard
            title="Total spent"
            icon={currency === Currency.EUR ? <EuroSymbolIcon /> : <AttachMoneyIcon />}
            color={SnapshotCardColor.GREEN}
            trend={snapshotTimeline.current.amount > snapshotTimeline.previous.amount ? Trend.UP : Trend.DOWN}
            value={(Math.round(snapshotTimeline.current.amount) as any).toCurrency(currency, {minimumFractionDigits: 0})}
            details={`${periodPercentageComparison(snapshotTimeline, 'amount')} from last month`}
            target="/dashboard/charts"
        />
    );
};
