export enum DistanceUnit {
    KILOMETERS = "KM",
    MILES = "MI",
}

export const distanceUnitLabel = {
    [DistanceUnit.KILOMETERS]: "Kilometers",
    [DistanceUnit.MILES]: "Miles",
};

export const distances = [
    {
        unit: DistanceUnit.KILOMETERS,
        label: distanceUnitLabel[DistanceUnit.KILOMETERS],
    },
    {
        unit: DistanceUnit.MILES,
        label: distanceUnitLabel[DistanceUnit.MILES],
    },
];

export interface IVehicleForHireDetails {
    addressFrom?: string | null;
    addressTo?: string | null;
    distanceUnit?: 'KM' | 'MI' | null;
    distance?: number | null;
    pickupTime?: string | null;
    dropoffTime?: string | null;
    driverName?: string | null;
    tripMapFile?: File;
}
