import {action, makeObservable, observable} from "mobx";
import {IGeneralSettings} from "../config/settings";
import {RecurringExpensesTimeFrequency} from "../config/recurring_expense";
import { SnapshotVersion } from "../config/snapshot";

const GENERAL_SETTINGS_CACHE_KEY = 'em:settings:general';

const DEFAULT_VALUES = {
    snapshotVersion: SnapshotVersion.V3,
    budgetLeftVisibility: false,
    defaultRecurringExpensesAggregator: RecurringExpensesTimeFrequency.MONTHLY,
} as IGeneralSettings;

export class GeneralSettingsStore {
    @observable settings: IGeneralSettings = DEFAULT_VALUES;

    constructor() {
        makeObservable(this);

        const data = window.localStorage.getItem(GENERAL_SETTINGS_CACHE_KEY);
        if (!data) {
            return;
        }

        this.settings = {
            ...DEFAULT_VALUES,
            ...JSON.parse(data),
        };
    }

    @action
    save(settings: IGeneralSettings) {
        this.settings = settings;

        window.localStorage.setItem(GENERAL_SETTINGS_CACHE_KEY, JSON.stringify(settings));
    }
}
