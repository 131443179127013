import * as React from "react";
import {ITransaction} from "../../../../config/transactions";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {deleteTransaction} from "../../../../stores/transactions";
import {SnackbarStore} from "../../../../stores/snackbar";

interface IDeleteActionProps {
    transaction: ITransaction;
    component(loading: boolean, onClick: () => void): React.ReactElement;
    onDelete?(transaction: ITransaction): void;
}

export const DeleteAction = ({transaction, component, onDelete}: IDeleteActionProps) => {
    const { snackbarStore, transactionStore, refreshStore, searchStore } = useGlobalStores();

    const [loading, setLoading] = React.useState(false);

    const handleDelete = () => {
        if (loading) {
            return;
        }

        if (!window.confirm('Are you sure to delete this transaction? It cannot be undone.')) {
            return;
        }

        setLoading(true);

        deleteTransaction(transaction.transaction_id).then(() => {
            setLoading(false);

            snackbarStore.open(SnackbarStore.SUCCESS, 'Transaction deleted successfully!');
            transactionStore.removeTransaction(transaction);

            // no need to refresh the transaction list because we remove it from the list
            if (searchStore.isDefault) {
                refreshStore.refreshDashboard();
            }
            refreshStore.refreshDashboardSnapshot();

            if (onDelete) {
                onDelete(transaction);
            }
        });
    };

    return component(loading, handleDelete);
};
