import * as React from "react";
import {Link} from "react-router-dom";
import {styled} from "@mui/material";

const StyledLink = styled(Link)(({theme}) => ({
    color: theme.palette.primary.main,
    textDecoration: 'underline',
    '&:hover': {
        textDecoration: 'none',
    },
}));

interface ITagsProps {
    tags: string[];
}

export const TagsField = ({tags}: ITagsProps) => {
    if (!tags.length) {
        return null;
    }

    const length = tags.length - 1;

    return (
        <>
            {tags.map((tag, i) => {
                return (
                    <React.Fragment key={i}>
                        <StyledLink to={`/transactions?tag=${tag}`}>
                            #{tag}
                        </StyledLink>
                        {i < length && " • "}
                    </React.Fragment>
                )
            })}
        </>
    )
};
