import * as React from "react";
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import { ILineItem, LineItemsDisplayScope } from "./LineItemsField";
import { TableHead, styled, useMediaQuery, useTheme } from "@mui/material";
import { AddAction } from "./AddAction";
import "../../../../../../../../utils/number_extensions";

const HeaderTableCell = styled(TableCell)(({theme}) => ({
    fontWeight: 'bold',
    textAlign: 'center',
    whiteSpace: 'nowrap',
    padding: 5,

    [theme.breakpoints.down('md')]: {
        padding: 2,
    },
}));

const DescriptionHeaderTableCell = styled(HeaderTableCell)(({theme}) => ({
    textAlign: 'left',
}));

interface IDataTableHeaderProps {
    scope: LineItemsDisplayScope;
    onAdd(value: ILineItem): void;
}

export const DataTableHeader = ({scope, onAdd}: IDataTableHeaderProps) => {
    const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('md'));

    return (
        <TableHead>
            <TableRow>
                {scope === LineItemsDisplayScope.FORM && (
                    <HeaderTableCell>
                        <AddAction onChange={onAdd} />
                    </HeaderTableCell>
                )}
                <DescriptionHeaderTableCell>{isSmallScreen ? 'Desc.' : 'Description'}</DescriptionHeaderTableCell>
                <HeaderTableCell>{isSmallScreen ? 'Qty' : 'Quantity'}</HeaderTableCell>
                <HeaderTableCell>{isSmallScreen ? 'Unit' : 'Unit price'}</HeaderTableCell>
                <HeaderTableCell>{isSmallScreen ? 'Tot' : 'Total amount'}</HeaderTableCell>
            </TableRow>
        </TableHead>
    );
}
