import * as React from "react";
import {IIncome, IIncomeRequest} from "../../../../config/income";
import {FreeTextField} from "../../../Components/Form/FreeTextField";
import {MoneyField} from "../../../Components/Form/MoneyField";
import {toFormData} from "../../../../helpers/income_helpers";
import {convertAmountToFloat} from "../../../../helpers/transaction_helpers";
import { useForm } from "../../../../hooks/use_form_v2";
import { Form } from "../../../Components/Form/Form/Form";

export interface IIncomeState {
    name: string;
    amount: string;
}

interface IIncomeFormProps {
    income?: IIncome;
    onSubmit(data: IIncomeRequest): Promise<any>;
}

export const IncomeForm = ({income, onSubmit}: IIncomeFormProps) => {
    const form = useForm<IIncomeState>(income ? toFormData(income) : {});
    const { register } = form;

    const handleSubmit = (values: IIncomeState): Promise<any> => {
        return onSubmit({
            name: values.name,
            amount: convertAmountToFloat(values.amount),
        });
    };

    return (
        <Form form={form} onSubmit={handleSubmit}>
            <Form.Field type={FreeTextField} {...register('name')} />
            <Form.Field type={MoneyField} {...register('amount')} />
        </Form>
    );
};
