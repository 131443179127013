import * as React from "react";
import {useNavigate} from "react-router-dom";
import {ITransaction} from "../../../../config/transactions";

interface IEditActionProps {
    transaction: ITransaction;
    component(onClick: () => void): React.ReactElement;
}

export const EditAction = ({transaction, component}: IEditActionProps) => {
    const navigate = useNavigate();

    const handleEdit = () => {
        navigate(`/transactions/${transaction.transaction_id}/edit`);
    };

    return component(handleEdit);
};
