import * as React from "react";
import {IStatsDayOfTheWeek} from "../../../config/stats";
import {prefillDaysOfTheWeek} from "../../../helpers/dashboard_charts_helpers";
import "../../../utils/number_extensions";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {BarChart, legendClasses} from "@mui/x-charts";

interface IDaysDistributionChartProps {
    days: IStatsDayOfTheWeek[];
    chartHeight: number;
}

export const DaysDistributionChart = ({days, chartHeight}: IDaysDistributionChartProps) => {
    const { currencyStore } = useGlobalStores();

    const dataset = prefillDaysOfTheWeek(days) as any[];

    return (
        <BarChart
            dataset={dataset}
            series={[
                {
                    type: 'bar',
                    dataKey: 'total',
                    label: 'Amount',
                    valueFormatter: (v): string => {
                        return v!.toCurrency(currencyStore.currentCurrency, {});
                    },
                },
            ]}
            axisHighlight={{
                y: 'none',
            }}
            yAxis={[
                {
                    dataKey: 'weekday_code',
                    scaleType: 'band',
                },
            ]}
            grid={{vertical: true}}
            layout="horizontal"
            borderRadius={3}
            height={chartHeight}
            margin={{top: 0, left: 75}}
            slotProps={{
                legend: {
                    direction: 'row',
                    position: { vertical: 'bottom', horizontal: 'middle' },
                    padding: 0,
                    itemMarkWidth: 10,
                    itemMarkHeight: 10,
                    markGap: 5,
                    itemGap: 15,
                },
            }}
            sx={{
                '&&': {
                    touchAction: 'auto',
                },
                [`& .${legendClasses.mark}`]: {
                    ry: 10,
                },
            }}
        />
    );
};
