import * as React from "react";
import {useGlobalStores} from "../../../../hooks/use_global_stores";

interface ICategoryFieldProps {
    categoryId: number | null;
}

export const CategoryField = ({categoryId}: ICategoryFieldProps) => {
    const { categoryStore } = useGlobalStores();

    if (!categoryId) {
        return null;
    }

    const category = categoryStore.categoriesMap.get(categoryId);
    if (!category) {
        return null;
    }

    return (<span>{category.name.replace(/^[-]*\s?(.+)$/, '$1')}</span>);
};
