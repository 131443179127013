import * as React from "react";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';

export const EditAction = ({onClick}: any) => {
    return (
        <IconButton edge="end" color="primary" onClick={onClick} size="large">
            <EditIcon />
        </IconButton>
    );
};
