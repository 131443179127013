import {
    IUserPaymentMethod,
    IUserPaymentMethodCreate,
    IUserPaymentMethodUpdate
} from "../config/user_payment_method";
import {action, computed, makeObservable, observable} from "mobx";
import {fetchDelete, fetchPost, fetchPut} from "../services/api";
import "../utils/array_extentions";
import {convertFromResponse} from "../validators/user_payment_methods";

const USER_PAYMENT_METHODS_CACHE_KEY = 'em:user:payment_methods';

export class UserPaymentMethodStore {
    static readonly SORT_NEW_PAYMENT_METHOD = true;

    @observable paymentMethods: IUserPaymentMethod[] = [];

    constructor() {
        makeObservable(this);

        const data = window.localStorage.getItem(USER_PAYMENT_METHODS_CACHE_KEY);
        if (!data) {
            return;
        }

        this.paymentMethods = JSON.parse(data);
    }

    @action
    load(paymentMethods: IUserPaymentMethod[]) {
        this.paymentMethods = paymentMethods;

        this.updateLocalStorage();
    }

    @action
    addPaymentMethod(paymentMethod: IUserPaymentMethod, sort: boolean = false) {
        this.paymentMethods.push(paymentMethod);

        if (sort) {
            const sorted = this.paymentMethods.slice() as any;
            sorted.sortArrayOfObjectsAsc('name');

            (this.paymentMethods as any).replace(sorted);
        }

        this.updateLocalStorage();
    }

    @action
    removePaymentMethod(paymentMethod: IUserPaymentMethod) {
        this.paymentMethods = this.paymentMethods.filter(p => p.id !== paymentMethod.id);

        this.updateLocalStorage();
    }

    @action
    replacePaymentMethod(paymentMethod: IUserPaymentMethod) {
        const index = this.paymentMethods.findIndex(p => p.id === paymentMethod.id);

        if (index === -1) {
            return;
        }

        this.paymentMethods[index] = paymentMethod;

        this.updateLocalStorage();
    }

    @computed get paymentMethodsMap(): Map<number, IUserPaymentMethod> {
        return (this.paymentMethods as any).toMap('id');
    }

    getById(id: number): IUserPaymentMethod | null {
        return this.paymentMethods.find(item => item.id === id) || null;
    }

    private updateLocalStorage() {
        window.localStorage.setItem(USER_PAYMENT_METHODS_CACHE_KEY, JSON.stringify(this.paymentMethods));
    }
}

const createUserPaymentMethod = (data: IUserPaymentMethodCreate): Promise<IUserPaymentMethod> => {
    return fetchPost( `/ego/payment_methods`, data)
        .then((response: any) => {
            if (response.status !== 201) {
                return response.json().then((reason: any) => {
                    if (reason.errors === undefined) {
                        return Promise.reject(reason);
                    }

                    return Promise.reject(convertFromResponse(reason.errors));
                });
            }

            return Promise.resolve({
                id: parseInt(response.headers.get('Location').replace(/.*\/([0-9]+)$/, '$1')),
                is_active: true,
                name: data.name,
                payment_method_id: data.payment_method_id,
            });
        });
};

const updateUserPaymentMethod = (id: number, data: IUserPaymentMethodUpdate): Promise<IUserPaymentMethod> => {
    return fetchPut( `/ego/payment_methods/${id}`, data)
        .then((response: any) => {
            if (response.status !== 200) {
                return Promise.reject(response);
            }

            return response.json();
        })
        .then(data => data.data);
};

const deleteUserPaymentMethod = (id: number): Promise<boolean> => {
    return fetchDelete(`/ego/payment_methods/${id}`);
};

export {createUserPaymentMethod, updateUserPaymentMethod, deleteUserPaymentMethod}
