import moment from "moment";
import JwtHelper from "../services/jwt_helper";

export enum Level {
    BASIC = 'BASIC',
    NO_ADS = 'NO_ADS',
    PRO = 'PRO',
    PLATINUM = 'PLATINUM',
}

export const LEVELS = [Level.BASIC, Level.NO_ADS, Level.PRO, Level.PLATINUM];

const INTERNAL_LEVEL = {
    [Level.BASIC]: 1,
    [Level.NO_ADS]: 2,
    [Level.PRO]: 3,
    [Level.PLATINUM]: 4,
};

export const LEVEL_LABELS = {
    [Level.BASIC]: 'Free',
    [Level.NO_ADS]: 'No Ads',
    [Level.PRO]: 'Pro',
    [Level.PLATINUM]: 'Platinum',
};

export class AccessToken {
    private level: Level = Level.BASIC;
    private exp: moment.Moment = moment();
    private roles: string[] = [];

    constructor(s: string) {
        const jwtHelper = new JwtHelper();
        try {
            const token = jwtHelper.decodeToken(s);

            this.level = token.level;
            this.exp = moment.unix(token.exp);
            this.roles = token.roles;
        } catch (error) {
        }
    }

    hasAccess(level: Level): boolean {
        return INTERNAL_LEVEL[this.level] >= INTERNAL_LEVEL[level];
    }

    isExpired(): boolean {
        return this.exp > moment();
    }

    getLevel(): Level {
        return this.level;
    }

    getRoles(): string[] {
        return this.roles;
    }
}

export interface IAccessToken {
    level: Level;
    exp: moment.Moment;
    roles: string[];
}
