import {Currency} from "./currencies";
import {TransactionDetailFields} from "./transaction_details";
import moment from "moment/moment";
import {IAmountDetails} from "../components/Transactions/Components/Form/Fields/AmountDetailsField";
import {
    SplitMethod
} from "../components/Transactions/Components/Form/Fields/ShareWithFriends/FriendSelector/FriendSelector";
import {ILocation} from "./location";
import { IAllowanceTransaction } from "./allowance";
import { IVehicleForHireDetails } from "./vehicle_for_hire";
import { ILineItem } from "../components/Transactions/Components/Form/Fields/Details/Fields/LineItems/LineItemsField";
import { IFuelDetails } from "./fuel";

export const TRANSACTIONS_FETCH_LIMIT = 50;

export interface ITransaction {
    transaction_id: string;
    amount: number;
    original_amount: number;
    original_currency: Currency;
    purchased_at: moment.Moment;
    description: string | null;
    is_recurring: boolean;
    payment_method_id: number | null;
    payment_method: string | null;
    category_id: number | null;
    category: string | null;
    location: ILocation | null;
    main_category_id: number | null;
    main_category: string | null;
    company_id: number | null;
    company: string | null;
    details?: ITransactionDetails | ITransactionDetailsData;
    splits?: ITransactionSplit[];
    tags?: string[] | null;
    allowances?: IAllowanceTransaction[] | null;
    budget_id?: number | null;
}

export interface ITransactionSplit {
    id?: number;
    name: string;
    split_method: SplitMethod;
    amount?: number;
    percentage?: number;
}

export interface ITransactionDetails {
    [key: string]: any;
}

export interface ITransactionDetailsData {
    [TransactionDetailFields.COMMISSION_FEE]: string;
    [TransactionDetailFields.FLIGHT_NUMBER]: string;
    [TransactionDetailFields.FUEL_DETAILS]: IFuelDetails;
    [TransactionDetailFields.MERCHANT_TRANSACTION_ID]: string;
    [TransactionDetailFields.ORDER_ID]: string;
    [TransactionDetailFields.ORDERED_ON]: string;
    // [TransactionDetailFields.PAID_VIA]: PaymentProvider;
    [TransactionDetailFields.AMOUNT_DETAILS]: IAmountDetails;
    [TransactionDetailFields.VEHICLE_FOR_HIRE]: IVehicleForHireDetails;
    [TransactionDetailFields.LINE_ITEMS]: ILineItem[];
}

export interface ITransactionsResult {
    transactions: ITransaction[];
    total: number;
    count: number;
}

export interface INewTransaction {
    currency: Currency;
    amount: number;
    purchased_at: string;
    payment_method_id: number | null;
    category_id: number | null;
    company_id: number | null;
    description: string | null;
    is_visible: boolean;
    details?: object;
    splits?: ITransactionSplit[];
    tags?: string[] | null;
    location?: ILocation | null;
    allowances?: IAllowanceTransaction[] | null;
    budget_id: number | null;
}

export interface IUpdateTransaction {
    currency: Currency;
    amount: number;
    amountDetails?: object;
    purchased_at: string;
    payment_method_id?: number | null;
    category_id?: number | null;
    // company_id?: number | null;
    description?: string | null;
    is_visible?: boolean;
    details?: object;
    splits?: ITransactionSplit[];
    tags?: string[] | null;
    location?: ILocation | null;
    allowances?: IAllowanceTransaction[] | null;
    budget_id: number | null;
}

export interface IUpdateBulk {
    transaction_ids: string[];
    payment_method_id?: number | null;
    category_id?: number | null;
    location?: ILocation | null;
    description?: string | null;
    tags: string[] | null;
    budget_id?: number | null;
}

export interface ITransactionsSearchQuery {
    from: string;
    to: string;
    exclude_recurring_expenses?: string;
    exclude_withdrawals?: string;
    exclude_weekdays_lunch?: string;
    exclude_transactions?: string;
    include_only_payment_methods?: string;
    include_tags?: string;
    query?: string;
    tag?: string;
    budget_ids?: string;
}

export interface IExportPreRequest {
    format: string;
    transaction_ids?: string[];
    include_csv_header?: boolean;
    include_fields?: string[];
}

export interface IExportRequest {
    transaction_ids?: string;
    include_csv_header?: boolean;
    include_fields?: string;
}

export interface IReceiptData {
    amount: number;
    currency: Currency;
    purchased_at: string;
    category_id?: string | number | null;
    locations?: ILocation[] | null;
    details?: ITransactionDetails | null;
}
