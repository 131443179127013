export enum AttachmentType {
    RECEIPT = "RECEIPT",
    INVOICE = "INVOICE",
    MAP = "MAP",
    OTHER = "OTHER",
}

export interface IAttachment {
    id: number;
    full_path: string;
    type: AttachmentType;
    name: string | null;
    description: string | null;
}

export interface INewAttachment {
    file: File;
    type: AttachmentType;
    name?: string;
    description?: string;
}

export interface IDeleteAttachment {
    fileId: number;
}

export interface IAttachmentChanges {
    newAttachments?: INewAttachment[];
    deletedAttachments?: number[];
}

export const typesOptions = [
    {
        key: AttachmentType.RECEIPT,
        label: 'Receipt',
    },
    {
        key: AttachmentType.INVOICE,
        label: 'Invoice',
    },
    {
        key: AttachmentType.MAP,
        label: 'Map',
    },
    {
        key: AttachmentType.OTHER,
        label: 'Other',
    },
] as {
    key: AttachmentType;
    label: string;
}[];
