import * as React from "react";
import {Link} from "react-router-dom";
import {styled} from "@mui/material";

const StyledLink = styled(Link)(({ theme }) => ({
    display: 'inline-block',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
    borderRadius: 5,
    paddingTop: 2,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: 2,
    marginRight: theme.spacing(1),
    textDecoration: 'none',

    '&:hover': {
        backgroundColor: theme.palette.primary.dark,
    },
}));

interface ITagsProps {
    value: string[];
}

const TagItem = ({tag}: { tag: string; }) => (
    <StyledLink to={`/transactions?tag=${tag}`}>
        #{tag}
    </StyledLink>
);

export const Tags = ({value}: ITagsProps) => {
    return (
        <>{value.map(tag => <TagItem tag={tag} key={tag} />)}</>
    )
};
