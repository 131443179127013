import * as React from "react";
import {ICompany} from "../../../config/company";
import {searchCompanies} from "../../../stores/companies";
import {Autocomplete, debounce} from "@mui/material";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";

interface ICompanyFieldAsyncProps {
    value: ICompany | null | undefined;
    onChange: any;
}

export const CompanyFieldAsync = ({value, onChange}: ICompanyFieldAsyncProps) => {
    const [loading, setLoading] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<ICompany[]>(value ? [value] : []);
    const [company,] = React.useState(value);

    const searchCompaniesDebounced = debounce((query: string) => {
        searchCompanies(query).then(companies => {
            setLoading(false);
            setOptions(companies || []);
        });
    }, 300);

    const handleInputChange = (event: object, value: string, reason: string) => {
        if (reason === 'reset') {
            return;
        }

        if (!loading) {
            setLoading(true);
        }

        if (value.trim() === '') {
            return;
        }

        searchCompaniesDebounced(value);
    };

    return (
        <Autocomplete
            options={options}
            defaultValue={company}
            getOptionLabel={(option: ICompany) => option.name || ''}
            onChange={onChange}
            multiple={false}
            onInputChange={handleInputChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="Company"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="primary" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};
