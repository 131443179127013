import * as React from "react";
import {ICategory} from "../../../../config/category";
import {List, ListItem, styled} from "@mui/material";
import {grey} from "@mui/material/colors";

const StyleListItem = styled(ListItem)(() => ({
    display: 'block',
}));

const Subcategories = styled('div')(({theme}) => ({
    paddingLeft: theme.spacing(2),
}));

const GlobalItem = styled('div')(() => ({
    cursor: 'default',
    color: grey[400],
}));

const Item = styled('div')(() => ({
    fontWeight: 500,
}));

const ItemWithActions = styled('div')(() => ({
    display: 'flex',
    alignItems: 'center',
}));

interface IListItemsProps {
    categories: ICategory[];
}

export const ListItems = ({categories}: IListItemsProps) => (
    <List dense>
        {categories.map(c => {
            return (
                <StyleListItem key={c.id}>
                    {c.is_global
                        ? <GlobalItem>{c.name}</GlobalItem>
                        : <ItemWithActions>
                            <Item>{c.name}</Item>
                            {/*<ActionsContainer>*/}
                            {/*    <EditAction />*/}
                            {/*    <DeleteAction />*/}
                            {/*</ActionsContainer>*/}
                        </ItemWithActions>
                    }
                    <Subcategories>
                        {!!c.subcategories?.length && <ListItems categories={c.subcategories} />}
                    </Subcategories>
                </StyleListItem>
            )
        })}
    </List>
);
