import * as React from "react";
import {Button, styled} from "@mui/material";
import { EditAction } from "../../Components/Actions/EditAction";
import { DeleteAction } from "../../Components/Actions/DeleteAction";
import { ITransaction } from "../../../../config/transactions";
import { useNavigate } from "react-router-dom";

const Box = styled('div')(({ theme }) => ({
    display: 'inline-block',
    marginRight: 10,
}));

interface ITagsProps {
    transaction: ITransaction;
}

const EditButton = (onClick: () => void) => <Button variant="contained" onClick={onClick}>Edit</Button>;
const DeleteButton = (_: boolean, onClick: () => void) => <Button variant="contained" onClick={onClick}>Delete</Button>;

export const Actions = ({transaction}: ITagsProps) => {
    const navigate = useNavigate();

    const handleDelete = () => {
        navigate('/transactions');
    };

    return (
        <>
            <Box>
                <EditAction transaction={transaction} component={EditButton} />
            </Box>

            <Box>
                <DeleteAction transaction={transaction} component={DeleteButton} onDelete={handleDelete} />
            </Box>
        </>
    )
};
