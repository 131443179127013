import * as React from "react";
import {useEffect, useState} from "react";
import Drawer from "@mui/material/Drawer";
import {Grid, styled} from "@mui/material";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from '@mui/icons-material/Close';
import {useNavigate, useParams} from "react-router-dom";
import {IEventDetails} from "../../../config/event";
import {EventDetailsCards} from "./EventDetailsCards";
import {fetchEventDetails} from "../../../stores/events";
import {TransactionsListField} from "../../Transactions/Components/List/config/config";
import {TransactionsList} from "../../Transactions/Components/List/TransactionsList";

const StyledDrawer = styled(Drawer)(({theme}) => ({
    zIndex: theme.zIndex.drawer + 1,

    "& .MuiDrawer-paper": {
        width: '75%',
        [theme.breakpoints.down('lg')]: {
            width: '100%',
        },

        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
}));

const Title = styled(DialogTitle)(({theme}) => ({
    paddingRight: 0,
    "& span": {
        fontWeight: 'bold',

        [theme.breakpoints.down('md')]: {
            display: 'inline-block',
            maxWidth: 200,
            overflow: 'hidden',
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
        },
    }
}));

const Container = styled('div')(({theme}) => ({
    marginTop: theme.spacing(8),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(3),
}));

export const EventView = () => {
    const {id} = useParams();
    const navigate = useNavigate();

    const [event, setEvent] = useState<IEventDetails>();
    const [, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);

    const handleClose = () => {
        navigate('/events');
    };

    useEffect(() => {
        fetchEventDetails(id!)
            .then(res => setEvent(res))
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    }, [id]);

    if (!event && error) {
        return <div>oops</div>
    }

    if (!event) {
        return <div>oh no</div>
    }

    return (
        <StyledDrawer variant="temporary" anchor="right" open={true} onClose={handleClose}>
            {!error && <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={0}
                sx={{position: 'absolute'}}
            >
                <Grid item>
                    <Title>
                        <span>{event?.name}</span>
                    </Title>
                </Grid>
                <Grid item sx={{paddingLeft: 0}}>
                    <IconButton onClick={handleClose} sx={{marginRight: (theme) => theme.spacing(1)}} size="large">
                        <CloseIcon/>
                    </IconButton>
                </Grid>
            </Grid>}

            <Container>
                <EventDetailsCards event={event!} />

                {event?.stats.count > 0 && <TransactionsList
                    hideFooter
                    staticLoader={event?.transactions}
                    fields={[
                        TransactionsListField.AMOUNT,
                        TransactionsListField.PURCHASED_AT,
                        TransactionsListField.DESCRIPTION,
                        TransactionsListField.CATEGORY,
                        TransactionsListField.LOCATION,
                        TransactionsListField.PAYMENT_METHOD,
                    ]}
                    actions={[]}
                />}
            </Container>
        </StyledDrawer>
    );
}
