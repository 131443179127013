import * as React from "react";
import {DashboardItemsContainer} from "../../Components/DashboardItemsContainer";
import {Grid, Skeleton} from "@mui/material";

export const SnapshotLoadingPlaceholders = () => {
    return (
        <DashboardItemsContainer>
            <Grid item xs={12} md={6} lg={3}>
                <Skeleton variant="rectangular" height={200} />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <Skeleton variant="rectangular" height={200} />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <Skeleton variant="rectangular" height={200} />
            </Grid>
            <Grid item xs={12} md={6} lg={3}>
                <Skeleton variant="rectangular" height={200} />
            </Grid>
        </DashboardItemsContainer>
    );
};
