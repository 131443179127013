import * as React from "react";
import {Avatar, styled, Typography} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

const AvatarContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
}));

const StyledAvatar = styled(Avatar)(({theme}) => ({
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
}));

export const Header = () => (
    <div>
        <AvatarContainer>
            <StyledAvatar>
                <LockOutlinedIcon />
            </StyledAvatar>
        </AvatarContainer>
        <Typography component="h1" variant="h5">
            Sign in
        </Typography>
    </div>
);
