import * as React from "react";
import {
    TransactionValidationErrors, TransactionValidationField
} from "../../../../../../../../validators/transactions";
import {TimeField} from "../../../../../../../Components/Form/TimeField";

interface IPickupAddressProps {
    value?: string | null;
    onChange(value: any): void;
    errors?: TransactionValidationErrors;
}

export const PickupTime = ({value, onChange, errors}: IPickupAddressProps) => (
    <TimeField
        label="Pick up time"
        value={value || ''}
        onChange={onChange}
        errors={errors?.get(TransactionValidationField.VEHICLE_FOR_HIRE_PICKUP_TIME)}
    />
)
