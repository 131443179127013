import * as React from "react";
import { useGlobalStores } from "../../hooks/use_global_stores";
import { FullPageLoader } from "../Layout/Components/FullPageLoader";
import { observer } from "mobx-react";

interface IRefresherProps {
    children: React.ReactNode;
}

export const Refresher = observer(({children}: IRefresherProps) => {
    const { globalRefreshStore } = useGlobalStores();

    if (!globalRefreshStore.isAknowledged()) {
        globalRefreshStore.refresh().then(() => {
            globalRefreshStore.aknowledge();
        });

        return (
            <FullPageLoader />
        );
    }

    return (
        <>{children}</>
    );
});
