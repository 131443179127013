import {styled} from "@mui/material";

// Utilizing the endAdornment property of the text field, results in the fields in the modal
// not functioning properly.
// Any text field loses focus on click because the endAdornment belongs to a text field itself.
// This hack prevents this issue.
export const EndAdornmentContainerHack = styled('div', {
    shouldForwardProp: (prop) => prop !== 'hasValue',
})<{hasValue: boolean}>(({theme, hasValue}) => ({
    position: 'absolute',
    top: 11,
    right: 0,
    bottom: 11,
    // marginRight: theme.spacing(!hasValue ? 4 : 8),
    marginRight: theme.spacing(4),
}));
