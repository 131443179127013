import * as React from "react";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Popper from "@mui/material/Popper";
import Button from "@mui/material/Button";
import SortIcon from '@mui/icons-material/Sort';
import {CompanySortBy, sortByLabels} from "../../../../config/company";
import {ButtonGroup, styled} from "@mui/material";
import {useState} from "react";

const StyledButtonGroup = styled(ButtonGroup)(({theme}) => ({
    marginLeft: theme.spacing(1),
}));

const StyledButton = styled(Button)(() => ({
    padding: 7,
    backgroundColor: '#fff',
}));

interface ISortByActionProps {
    defaultSort?: CompanySortBy;
    onSelect(sort: CompanySortBy): void;
}

export const SortByAction = ({defaultSort, onSelect}: ISortByActionProps) => {
    const anchorRef = React.useRef<HTMLElement>(null);

    const [open, setOpen] = useState(false);
    const [sort, setSort] = useState<CompanySortBy | null>(defaultSort !== undefined ? defaultSort : null);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    const handleSelect = (option: CompanySortBy) => (event: any) => {
        setSort(option);
        onSelect(option);

        handleClose(event);
    };

    const options = [CompanySortBy.NAME, CompanySortBy.TRANSACTIONS_COUNT, CompanySortBy.TOTAL_AMOUNT];

    return (
        <>
            <StyledButtonGroup size="small">
                <StyledButton
                    ref={anchorRef as any}
                    onClick={handleOpen}
                >
                    <SortIcon/> {null === sort ? 'Sort by' : <span>Sort by <strong>{sortByLabels[sort]}</strong></span>}
                </StyledButton>
            </StyledButtonGroup>

            <Popper open={open} anchorEl={anchorRef.current} transition disablePortal>
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                        }}
                    >
                        <Paper>
                            <ClickAwayListener onClickAway={handleClose as any}>
                                <MenuList>
                                    {options.map(option => (
                                        <MenuItem
                                            key={option}
                                            onClick={handleSelect(option)}
                                        >
                                            {sortByLabels[option]}
                                        </MenuItem>
                                    ))}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </>
    );
};
