import {action, makeObservable, observable} from "mobx";
import {TransactionStore} from "./transactions";
import {SnapshotStore} from "./snapshot";
import {DashboardStore} from "./dashboard";

export class RefreshStore {
    private transactionStore: TransactionStore;
    private snapshotStore: SnapshotStore;
    private dashboardStore: DashboardStore;

    @observable dashboard: string = '';
    @observable dashboardSnapshot: string = '';
    @observable transactionsList: string = '';
    @observable extraIncomes: string = '';
    @observable categories: string = '';

    constructor(
        transactionStore: TransactionStore,
        dashboardStore: DashboardStore,
        snapshotStore: SnapshotStore
    ) {
        makeObservable(this);

        this.transactionStore = transactionStore;
        this.dashboardStore = dashboardStore;
        this.snapshotStore = snapshotStore;
    }

    refreshDashboard() {
        this.dashboardStore.stats = null;
        this.dashboard = this.generateRandomChars();
    }

    refreshDashboardSnapshot() {
        this.snapshotStore.snapshotTimeline = null;
        this.dashboardSnapshot = this.generateRandomChars();
    }

    refreshTransactionsList() {
        this.transactionStore.shouldBePopulated = true;
        this.transactionsList = this.generateRandomChars();
    }

    @action
    refreshCategories() {
        this.categories = this.generateRandomChars();
    }

    refreshAll() {
        this.refreshDashboard();
        this.refreshDashboardSnapshot();
        this.refreshTransactionsList();
    }

    refreshExtraIncomes() {
        this.extraIncomes = this.generateRandomChars();
    }

    private generateRandomChars(): string {
        return Math.random().toString(36);
    }
}
