import * as React from "react";
import {List as MuiList, useMediaQuery, useTheme} from "@mui/material";
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import {styled} from "@mui/material";

const StyledMuiList = styled(MuiList, {
    shouldForwardProp: (prop) => prop !== 'width',
})<{width?: number}>(({width = DEFAULT_MAX_WIDTH, theme}) => ({
    [theme.breakpoints.up('lg')]: {
        maxWidth: width,
    },
}));

export const StyledListItem = styled(ListItem)(({theme}) => ({
    [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
    },
}));

const DEFAULT_MAX_WIDTH = 600;
const EMPTY_ACTIONS = (_: any) => [];

interface IListProps<T> {
    items: T[];
    primaryGetter?(item: T): string | React.ReactNode;
    secondaryGetter?(item: T): string | React.ReactNode;
    iconGetter?(item: T): any;
    width?: number;
    actions?(item: T): React.ReactElement[] | any[] | any[][];
    onClick?(item: T): () => void;
}

export const List = <T extends object>({
    items,
    primaryGetter,
    secondaryGetter,
    iconGetter,
    width = DEFAULT_MAX_WIDTH,
    actions = EMPTY_ACTIONS,
    onClick,
}: IListProps<T>) => {
    const dense = useMediaQuery(useTheme().breakpoints.down('sm'));

    return (
        <StyledMuiList dense={dense} width={width}>
            {items.map((item, index) => {
                const primary = !!primaryGetter ? primaryGetter(item) : undefined;
                const secondary = !!secondaryGetter ? secondaryGetter(item) : undefined;
                const icon = !!iconGetter ? iconGetter(item) : undefined;

                return (
                    <StyledListItem key={index}>
                        <ListItemAvatar>
                            <Avatar>
                                {icon}
                            </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                            primary={primary}
                            secondary={secondary}
                            primaryTypographyProps={!!onClick ? {
                                sx: (theme) => {
                                    return {
                                        cursor: 'pointer',
                                        '&:hover': {
                                            color: theme.palette.primary.main,
                                        },

                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '75%',
                                        }
                                    };
                                }
                            } : {}}
                            secondaryTypographyProps={!!onClick ? {
                                sx: (theme) => {
                                    return {
                                        [theme.breakpoints.down('sm')]: {
                                            maxWidth: '75%',
                                        }
                                    };
                                }
                            } : {}}
                            onClick={!!onClick ? onClick(item) : undefined}
                        />

                        <ListItemSecondaryAction sx={{marginRight:-2, padding: 0}}>
                            {actions(item).map((action, index) => {
                                let Action: React.ReactElement | any;
                                let props: any = {};
                                if (Array.isArray(action) && action.length > 1) {
                                    [Action, props] = action;
                                } else {
                                    Action = action;
                                }

                                return (
                                    <Action key={index} item={item} {...props} />
                                );
                            })}
                        </ListItemSecondaryAction>
                    </StyledListItem>
                )
            })}
        </StyledMuiList>
    );
};
