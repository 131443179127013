import * as React from "react";
import { useGlobalStores } from "../../../hooks/use_global_stores";
import { IconButton } from "@mui/material";
import { useModal } from "../../../hooks/use_modal";
import { FeaturesModal } from "./Components/FeaturesModal";

export const ProIconButton = ({min, text, feature, children, ...props}: any) => {
    const { authStore } = useGlobalStores();
    const { open, handleModalOpen, handleModalClose } = useModal();

    if (!min || !authStore.accessToken?.hasAccess(min)) {
        return (
            <>
                <IconButton {...props} onClick={handleModalOpen}>
                    {children}
                </IconButton>
                <FeaturesModal open={open} onClose={handleModalClose} selectedFeature={feature} />
            </>
        );
    }

    return (
        <IconButton {...props}>
            {children}
        </IconButton>
    );
};
