import * as React from "react";
import {useEffect, useState} from "react";
import {TransactionsList, TransactionsListLoader} from "../Components/List/TransactionsList";
import {
    ITransaction,
    ITransactionsResult,
    ITransactionsSearchQuery,
    TRANSACTIONS_FETCH_LIMIT
} from "../../../config/transactions";
import {fetchTransactions} from "../../../stores/transactions";
import {useSearchParams} from "react-router-dom";
import {BulkAction, BulkContainer} from "../Components/List/BulkContainer";
import {getDefaultListTransactionsQueryRequest} from "../../../helpers/search_helpers";
import {IReactionDisposer, reaction} from "mobx";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {TagSearchAlert} from "./Alerts/TagSearchAlert";
import {SearchAlert} from "./Alerts/SearchAlert";
import {NoTransactions} from "./NoTransactions";
import Box from "@mui/material/Box";
import {Badge, IconButton, styled} from "@mui/material";
import { TransactionsListField } from "../Components/List/config/config";
import { isIphone } from "../../../services/user_agent_helper";
import { CheckBox } from "@mui/icons-material";
import { BudgetSearchAlert } from "./Alerts/BudgetSearchAlert";

const ListContainer = styled(Box, {
    shouldForwardProp: (prop) => prop !== "count",
})<{count: number}>(({count, theme}) => ({
    // display: count !== 0 ? 'block' : 'none',
    backgroundColor: 'white',
    borderBottom: 'solid 1px',
    borderBottomColor: theme.palette.divider,
    
    [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(3),
    },
}));

export const List = () => {
    const { layoutStore, searchStore } = useGlobalStores();
    const [searchParams,] = useSearchParams();

    const [result, setResult] = useState<ITransactionsResult>();
    const [selected, setSelected] = useState<string[]>([]);
    const [query, setQuery] = useState<ITransactionsSearchQuery>();
    const [loader, setLoader] = useState<TransactionsListLoader>();
    const [hideSelectCheckbox, setHideSelectCheckbox] = useState<boolean>(false);
    
    const handleHideSelectCheckbox = () => {
        setHideSelectCheckbox(prev => !prev);
    };

    const handleLoad = (data: ITransactionsResult) => {
        setResult(data);
    };

    const handleSelect = (transactions: ITransaction[]) => {
        setSelected(transactions.map(t => t.transaction_id));
    };

    const handleBulkActionSuccess = (action: BulkAction) => {
        if (action === BulkAction.DELETE) {
            setLoader(() => {
                return (page: number = 1) => {
                    return Promise.resolve({
                        total: result?.total! - selected.length,
                        transactions: result?.transactions.filter(t => selected.indexOf(t.transaction_id) === -1)!,
                        count: result?.count!,
                    });
                };
            });
        }

        setSelected([]);
    };

    useEffect(() => {
        let query: ITransactionsSearchQuery;
        if (searchStore.hasQuery) {
            query = searchStore.queryRequest;
        } else {
            query = getDefaultListTransactionsQueryRequest(searchParams);
            if (!!searchParams.get('query') || !!searchParams.get('tag')) {
                query.from = '1970-01-01';
            }
        }

        if (!!searchParams.get('query')) {
            query.query = searchParams.get('query')!;
        }
        if (!!searchParams.get('tag')) {
            query.tag = searchParams.get('tag')!;
        }

        setQuery(query);
    }, [searchStore.hasQuery, searchParams.get('query'), searchParams.get('tag'), searchParams.get('budget_id')]);

    let reloadReactionDisposer: IReactionDisposer;
    useEffect(() => {
        if (!reloadReactionDisposer) {
            reloadReactionDisposer = reaction(
                () => searchStore.queryFormData,
                () => {
                    setQuery(searchStore.queryRequest);
                },
            );
        }

        return () => {
            reloadReactionDisposer();
        }
    });

    useEffect(() => {
        if (isIphone) {
            setHideSelectCheckbox(true);

            if (!!result?.count) {
                layoutStore.setHeaderActions(
                    <IconButton color="inherit" size="large" onClick={handleHideSelectCheckbox}>
                        <Badge color="error" badgeContent={(!hideSelectCheckbox && selected.length) || undefined} overlap="circular">
                            <CheckBox />
                        </Badge>
                    </IconButton>
                );
            }
        }

        return () => {
            layoutStore.resetHeaderActions();
        };
    }, [result]);

    useEffect(() => {
        if (!query) {
            return;
        }

        setLoader(() => {
            return (page: number = 1) => {
                return fetchTransactions(query, TRANSACTIONS_FETCH_LIMIT, page * TRANSACTIONS_FETCH_LIMIT);
            };
        });
    }, [query]);

    return (
        <>
            {!searchStore.isDefault && <SearchAlert />}
            {!!searchParams.get('budget_id') && <BudgetSearchAlert budgetId={searchParams.get('budget_id')!!} />}
            {!!searchParams.get('tag') && <TagSearchAlert tag={searchParams.get('tag')!!} />}
            {!!selected.length && <BulkContainer transactionIds={selected} onSuccess={handleBulkActionSuccess} />}

            {result?.count === 0 && <NoTransactions />}
            <ListContainer count={result?.count || 0}>
                <TransactionsList
                    hideHeader={isIphone}
                    hideFooter={false}
                    hideSelectCheckbox={hideSelectCheckbox}
                    rowHeight={isIphone ? 'auto' : 62}
                    loader={loader}
                    onLoad={handleLoad}
                    onSelect={handleSelect}
                    selected={selected}
                    fields={isIphone ? [TransactionsListField.DESCRIPTIVE_TRANSACTION] : undefined}
                />
            </ListContainer>
        </>
    );
};
