import * as React from "react";
import {IMonthSnapshotItem} from "../../../../../config/snapshot";
import {buildCumulativeMonthsTotalSpentData} from "../../../../../helpers/dashboard_charts_helpers";
import {LineChart} from "@mui/x-charts";
import moment from "moment";
import {useGlobalStores} from "../../../../../hooks/use_global_stores";

interface ICurrentYearTotalSpentProps {
    data: IMonthSnapshotItem[];
}

export const CurrentYearOverview = ({data}: ICurrentYearTotalSpentProps) => {
    const { currencyStore } = useGlobalStores();

    const dataset = buildCumulativeMonthsTotalSpentData(data);

    return (
        <LineChart
            dataset={dataset}
            series={[
                {
                    dataKey: 'total',
                    showMark: false,
                    color: 'rgb(83 131 236)',
                    valueFormatter: (v): string => {
                        return v!.toCurrency(currencyStore.currentCurrency, {});
                    },
                },
            ]}
            leftAxis={null}
            bottomAxis={null}
            axisHighlight={{
                x: 'none',
            }}
            xAxis={[
                {
                    dataKey: 'date',
                    scaleType: 'time',
                    valueFormatter: (date: Date ) => {
                        return moment(date).format('MMMM');
                    },
                },
            ]}
            height={150}
            margin={{
                top: 5,
                left: 5,
                right: 5,
                bottom: 5,
            }}
        />
    );
};
