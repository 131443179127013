import * as React from "react";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import { SelectField } from "../../../Components/Form/SelectField";

export const DefaultPaymentMethodField = (props: any) => {
    const { userPaymentMethodStore } = useGlobalStores();

    return (
        <SelectField
            options={userPaymentMethodStore.paymentMethods}
            optionValueGetter={(p: any) => p.id}
            optionLabelGetter={(p: any) => p.name}
            {...props}
            label="Default Payment Method"
        />
    );
};
