import * as React from "react";
import {
    detailsFormFields as options,
    ITransactionDetailFormField,
    TransactionDetailFields
} from "../../../../../../config/transaction_details";
import {ITransactionDetails} from "../../../../../../config/transactions";
import {AddButton} from "./Components/AddButton";
import {FieldSelector} from "./Components/FieldSelector";
import {FormElementsList} from "./Components/FormElementsList";
import {useEffect} from "react";
import {TransactionFormScope} from "../../config/config";
import {TransactionValidationErrors} from "../../../../../../validators/transactions";

interface IDetailsFieldProps {
    scope: TransactionFormScope;
    value: ITransactionDetails;
    onChange(details: ITransactionDetails): void;
    errors?: TransactionValidationErrors;
}

export const DetailsField = ({scope, value, onChange, errors}: IDetailsFieldProps) => {
    const anchorRef = React.useRef<HTMLElement>(null);

    const [open, setOpen] = React.useState(false);
    const [selectedOptions, setSelectedOptions] = React.useState<TransactionDetailFields[]>([]);
    const [detailFields, setDetailFields] = React.useState<ITransactionDetailFormField[]>([]);

    useEffect(() => {
        setSelectedOptions(options.filter(o => value[o.code] !== undefined).map(o => o.code));
        setDetailFields(options.filter(o => value[o.code] !== undefined));
    }, [value]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = (event: MouseEvent | TouchEvent) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }

        setOpen(false);
    };

    const handleSelect = (option: ITransactionDetailFormField, event: any) => {
        selectedOptions.push(option.code);
        detailFields.push(option);

        handleClose(event);
    };

    const handleChange = (details: ITransactionDetails) => {
        onChange(details);
    };

    const handleDelete = (option: ITransactionDetailFormField) => {
        setSelectedOptions(selectedOptions.filter(o => o !== option.code));
        setDetailFields(detailFields.filter(d => d.code !== option.code));
    };

    return (
        <>
            <FormElementsList
                value={value}
                detailFields={detailFields}
                onDelete={handleDelete}
                onChange={handleChange}
                errors={errors}
            />

            {selectedOptions.length < options.length && <AddButton anchorRef={anchorRef} onOpen={handleOpen} />}

            <FieldSelector scope={scope}
                           open={open}
                           anchorRef={anchorRef}
                           onClose={handleClose}
                           onSelect={handleSelect}
                           selectedOptions={selectedOptions}
            />
        </>
    );
};
