import * as React from "react";
import {Currency} from "../../../../../../../config/currencies";
import {MoneyField} from "../../../../../../Components/Form/MoneyField";

interface ISplitMethodFixedFieldProps {
    value: string;
    onChange(rawValue: string, value: number): void;
    originalCurrency: Currency;
}

export const SplitMethodFixedField = ({value, onChange, originalCurrency}: ISplitMethodFixedFieldProps) => (
    <MoneyField
        value={value}
        currency={originalCurrency}
        onChange={onChange}
    />
);
