import * as React from "react";
import {ITransactionsSearchFormOutputData} from "../../../../config/search";
import {TimeFrame} from "../../../../config/time_frames";
import moment from "moment";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {Grid} from "@mui/material";
import Button from "@mui/material/Button";
import {observer} from "mobx-react";
import {TimePeriodFilter} from "./Filters/TimePeriodFilter/TimePeriodFilter";
import {FreeTextField} from "../../../Components/Form/FreeTextField";
import {CheckboxField} from "../../../Components/Form/CheckboxField";
import {ExcludeTransactionsFilter} from "./Filters/ExcludeTransactionsFilter";
import {PaymentMethodFilter} from "./Filters/PaymentMethodFilter";

interface ITransactionsSearchFormProps {
    onSubmit(query: ITransactionsSearchFormOutputData): void;
}

export const SearchTransactionsForm = observer(({onSubmit}: ITransactionsSearchFormProps) => {
    const { searchStore } = useGlobalStores();

    const [values, setValues] = React.useState<ITransactionsSearchFormOutputData>(searchStore.queryFormData);

    const handleChange = (prop: keyof ITransactionsSearchFormOutputData) => (value: any) => {
        setValues({ ...values, [prop]: value });
    };

    const handleTimePeriodChange = (timePeriod: TimeFrame, range: moment.Moment[]) => {
        setValues({ ...values, timePeriod: timePeriod, timeRange: range });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        onSubmit(values);
    };

    return (
        <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
            >
                <Grid item xs={12}>
                    <TimePeriodFilter timePeriod={values.timePeriod} timeRange={values.timeRange} onChange={handleTimePeriodChange}/>
                </Grid>

                <Grid item xs={12}>
                    <FreeTextField label="Query" value={values.query} onChange={handleChange('query')} />
                </Grid>

                <Grid item xs={12}>
                    <CheckboxField label="Exclude withdrawals" value={values.excludeWithdrawals} onChange={handleChange('excludeWithdrawals')} />
                    <CheckboxField label="Exclude weekdays lunch" value={values.excludeWeekdaysLunch} onChange={handleChange('excludeWeekdaysLunch')} />
                </Grid>

                <Grid item xs={12}>
                    <ExcludeTransactionsFilter value={values.excludedTransactions} onChange={handleChange('excludedTransactions')} />
                </Grid>

                <Grid item xs={12}>
                    <PaymentMethodFilter value={values.includePaymentMethods} onChange={handleChange('includePaymentMethods')} />
                </Grid>

                <Grid item xs={12}>
                    <Button variant="contained" color="primary" onClick={handleSubmit}>
                        Search
                    </Button>
                </Grid>
            </Grid>
        </form>
    );
});
