import * as React from "react";
import {IEventDetails} from "../../../config/event";
import "../../../utils/number_extensions";
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import {SnapshotCard, SnapshotCardColor} from "../../Components/SnapshotCard";
import Grid from '@mui/material/Grid';
import {Currency} from "../../../config/currencies";
import "../../../utils/number_extensions";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {EventDaysCount} from "./CardsComponents/EventDaysCount";
import {EventTimeRange} from "./CardsComponents/EventTimeRange";
import {EventBudgetUse} from "./CardsComponents/EventBudgetUse";
import {EventTransactionsCountDetails} from "./CardsComponents/EventTransactionsCount";

interface IEventDetailsCardsProps {
    event: IEventDetails;
}

export const EventDetailsCards = ({event}: IEventDetailsCardsProps) => {
    const { currencyStore } = useGlobalStores();
    const currency = currencyStore.currentCurrency;

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <SnapshotCard
                    condensed
                    title="Happened on"
                    icon={<DateRangeIcon />}
                    color={SnapshotCardColor.ORANGE}
                    value={<EventDaysCount event={event} />}
                    details={<EventTimeRange event={event} />}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SnapshotCard
                    condensed
                    title="Total spent"
                    icon={currency === Currency.EUR ? <EuroSymbolIcon /> : <AttachMoneyIcon />}
                    color={SnapshotCardColor.GREEN}
                    value={(event.stats.total as any).toCurrency(currency)}
                    details={<EventBudgetUse event={event} currency={currency} />}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SnapshotCard
                    condensed
                    title="Transactions count"
                    icon={<AccountBalanceWalletIcon />}
                    color={SnapshotCardColor.PURPLE}
                    value={event.stats.count}
                    details={<EventTransactionsCountDetails event={event} />}
                />
            </Grid>
        </Grid>
    );
};
