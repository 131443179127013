import * as React from "react";
import {Card, CardContent, Grid, Skeleton} from "@mui/material";

const CurrentMonthData = () => (
    <Grid container spacing={2} justifyContent="center" alignItems="center" flexDirection="column" sx={{
        marginTop: 0.5,
        marginBottom: 2,
    }}>
        <Grid item>
            <Skeleton variant="rectangular" width={100} height={60} />
        </Grid>

        <Grid item>
            <Skeleton variant="rectangular" width={225} height={14} />
        </Grid>
    </Grid>
);

const BudgetProgress = () => (
    <Card sx={(theme) => ({marginBottom: theme.spacing(2)})}>
        <CardContent>
            <Grid container spacing={1} justifyContent="space-between" sx={{paddingTop: 0.1}}>
                <Grid item>
                    <Skeleton variant="rectangular" width={90} height={14} />
                </Grid>
                <Grid item>
                    <Skeleton variant="rectangular" width={100} height={14} />
                </Grid>

                <Grid item xs={12} sx={{marginBottom: 0.4}}>
                    <Skeleton variant="rectangular" height={10} />
                </Grid>

                <Grid item xs={12}>
                    <Skeleton variant="rectangular" width={150} height={14} />
                </Grid>
            </Grid>
        </CardContent>
    </Card>
);

export const LoadingPlaceholders = () => (
    <div>
        <CurrentMonthData />
        <BudgetProgress />
        <BudgetProgress />
        <BudgetProgress />
        <BudgetProgress />
        <BudgetProgress />
    </div>
);
