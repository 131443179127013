import * as React from "react";
import {updateUserPaymentMethod} from "../../../../stores/user_payment_methods";
import {IUserPaymentMethod} from "../../../../config/user_payment_method";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {SnackbarStore} from "../../../../stores/snackbar";
import IconButton from "@mui/material/IconButton";
import {Pause, PlayArrow} from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";

interface IActiveToggleActionProps {
    paymentMethod: IUserPaymentMethod;
}

export const ActiveToggleAction = ({paymentMethod}: IActiveToggleActionProps) => {
    const { snackbarStore, userPaymentMethodStore } = useGlobalStores();
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleToggle = () => {
        if (loading) {
            return;
        }

        setLoading(true);

        updateUserPaymentMethod(paymentMethod.id, {
            payment_method_id: paymentMethod.payment_method_id,
            is_active: !paymentMethod.is_active,
            name: paymentMethod.name,
        })
            .then((paymentMethod: IUserPaymentMethod) => {
                setLoading(false);

                const action = paymentMethod.is_active ? 're-activated' : 'suspended';

                snackbarStore.open(SnackbarStore.SUCCESS, `Payment method ${action} successfully!`);
                userPaymentMethodStore.replacePaymentMethod(paymentMethod);
            });
    };

    return (
        <IconButton
            edge="end"
            color="primary"
            disabled={loading}
            onClick={handleToggle}
            size="large">
            {!loading ? (paymentMethod.is_active ? <Pause /> : <PlayArrow />) : <CircularProgress size={24} />}
        </IconButton>
    );
};
