import * as React from "react";
import {IIncome} from "../../../../config/income";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {observer} from "mobx-react";
import {useState} from "react";
import {NoResults} from "../../../Components/NoResults";
import {AccountBalance} from "@mui/icons-material";
import {PageItem} from "../../../Components/PageItem";
import {EditIncome} from "../Edit/EditIncome";
import {NewIncome} from "../New/NewIncome";
import {NewAction} from "../Actions/NewAction";
import { List } from "../../../Components/Admin/List/List";
import { DeleteAction } from "../Actions/DeleteAction";
import { EditAction } from "../Actions/EditAction";

export const IncomesList = observer(() => {
    const { currencyStore, incomeStore } = useGlobalStores();
    const incomes = incomeStore.incomes;

    const [newOpen, setNewOpen] = useState<boolean>(false);
    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [selected, setSelected] = useState<IIncome>();

    const handleNewVisibility = () => {
        setNewOpen(!newOpen);
    };

    const handleEditOpen = (income: IIncome) => () => {
        setEditOpen(true);
        setSelected(income);
    };

    const handleEditClose = () => {
        setEditOpen(false);
    };

    if (!incomes.length) {
        return (
            <>
                <NoResults
                    text="You haven't added any income yet :("
                    onClick={handleNewVisibility}
                    icon={<AccountBalance />}
                />
                <NewIncome open={newOpen} onClose={handleNewVisibility} />
            </>
        );
    }

    return (
        <PageItem
            title="Incomes"
            actions={<NewAction onClick={handleNewVisibility} />}
        >
            <List<IIncome>
                items={incomes}
                primaryGetter={(income) => (income.amount as any).toCurrency(currencyStore.currentCurrency)}
                secondaryGetter={(income) => income.name}
                iconGetter={(income) => income.name[0]}
                actions={(income) => [
                    [EditAction, {onClick: handleEditOpen(income)}],
                    DeleteAction,
                ]}
            />

            {newOpen && <NewIncome open={newOpen} onClose={handleNewVisibility} />}
            {editOpen && selected && <EditIncome open={editOpen} income={selected} onClose={handleEditClose} />}
        </PageItem>
    );
});
