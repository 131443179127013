import * as React from "react";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import {IRecurringExpense} from "../../../../config/recurring_expense";
import {useNavigate} from "react-router-dom";

interface IEditActionProps {
    recurringExpense: IRecurringExpense;
}

export const EditAction = ({recurringExpense}: IEditActionProps) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/recurring-expenses/${recurringExpense.id}/edit`);
    };

    return (
        <IconButton size="small" color="primary" onClick={handleClick}>
            <EditIcon/>
        </IconButton>
    )
};
