import * as React from "react";
import { PushAttachmentStore } from "../stores/attachments";

interface ITransactionFormStore {
    pushAttachmentStore: PushAttachmentStore;
}

export const createTransactionFormStore = (): ITransactionFormStore => {
    return {
        pushAttachmentStore: new PushAttachmentStore(),
    };
};

export const TransactionFormStoreContext = React.createContext<ITransactionFormStore | null>(null);
