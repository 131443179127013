import * as React from 'react';
import {useTranslation} from "react-i18next";
import {Button} from "@mui/material";

interface ISaveButtonProps {
    loading: boolean;
    onSubmit(e: any): void;
    label?: string;
}

export const SaveButton = ({loading, onSubmit, label}: ISaveButtonProps) => {
    const { t } = useTranslation();

    return (
        <Button
            type="submit"
            onClick={onSubmit}
            disabled={loading}
            variant="contained"
            color="primary"
        >
            {label || t('global.buttons.save')}
        </Button>
    );
}
