import * as React from "react";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {FormModal} from "../../../Components/FormModal";
import {SnackbarStore} from "../../../../stores/snackbar";
import { AllowanceForm } from "../Form/AllowanceForm";
import { updateAllowance } from "../../../../stores/allowances";
import { IAllowance, IAllowanceRequest } from "../../../../config/allowance";

interface IEditAllowanceProps {
    allowance: IAllowance;
    open: boolean;
    onClose(): void;
}

export const EditAllowance = ({allowance, open, onClose}: IEditAllowanceProps) => {
    const { allowanceStore, snackbarStore } = useGlobalStores();

    const handleSubmit = (data: IAllowanceRequest) => {
        return updateAllowance(allowance.id, data)
            .then(allowance => {
                snackbarStore.open(SnackbarStore.SUCCESS, 'Allowance updated successfully!');
                allowanceStore.replaceAllowance(allowance);
                onClose();
                return Promise.resolve();
            })
            .catch(reason => {
                snackbarStore.open(SnackbarStore.FAIL);
                return Promise.reject(reason);
            });
    };

    return (
        <FormModal title="Edit allowance" open={open} loading={false} onClose={onClose}>
            <AllowanceForm allowance={allowance} onSubmit={handleSubmit} />
        </FormModal>
    );
};
