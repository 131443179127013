import * as React from "react";
import {useTranslation} from "react-i18next";
import {Alert, AlertTitle, Grid} from "@mui/material";
import {Settings} from "@mui/icons-material";
import {DeleteAction} from "../../Bulk/Actions/DeleteAction";
import {EditAction} from "../../Bulk/Actions/EditAction";
import {ExportAction} from "../../Bulk/Actions/Export/ExportAction";
import {ListAlertContainer} from "../ListAlertContainer";
import { MergeAction } from "../../Bulk/Actions/MergeAction";

export enum BulkAction {
    EXPORT,
    EDIT,
    DELETE,
    MERGE,
}

interface IBulkContainerProps {
    transactionIds: string[];
    onSuccess(action: BulkAction): void;
}

export const BulkContainer = ({transactionIds, onSuccess}: IBulkContainerProps) => {
    const { t } = useTranslation();

    return (
        <ListAlertContainer>
            <Grid item xs={12}>
                <Alert
                    icon={<Settings fontSize="inherit" />}
                    severity="info"
                >
                    <AlertTitle>{t('bulk.actions_title')}</AlertTitle>
                    <Grid container spacing={2}>
                        <Grid item><ExportAction transactionIds={transactionIds} onSuccess={onSuccess} /></Grid>
                        <Grid item><EditAction transactionIds={transactionIds} onSuccess={onSuccess} /></Grid>
                        <Grid item><DeleteAction transactionIds={transactionIds} onSuccess={onSuccess} /></Grid>
                        <Grid item><MergeAction transactionIds={transactionIds} onSuccess={onSuccess} /></Grid>
                    </Grid>
                </Alert>
            </Grid>
        </ListAlertContainer>
    );
};
