import * as React from "react";
import Button from "@mui/material/Button";
import {Add} from "@mui/icons-material";

interface INewActionProps {
    onClick(): void;
}

export const NewAction = ({onClick}: INewActionProps) => (
    <Button
        size="small"
        startIcon={<Add />}
        onClick={onClick}
    >
        Add new income
    </Button>
)
