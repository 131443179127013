import { IAllowanceState } from "../components/Settings/Allowances/Form/AllowanceForm";
import { IAllowance, IAllowanceRequest, IAllowanceTransaction } from "../config/allowance";
import { convertAmountToFloat } from "./transaction_helpers";

export const toTransactionForm = (data: IAllowance, amount: number | string, currentId?: number | null): IAllowanceTransaction => {
    amount = convertAmountToFloat(amount);
    let finalAmount = data.amount;
    if (amount < finalAmount) {
        finalAmount = amount;
    }

    const res = {
        allowance_id: data.id,
        amount: finalAmount,
    } as IAllowanceTransaction;

    if (!!currentId) {
        res.id = currentId;
    }

    return res;
};

export const toForm = (data: IAllowanceState): IAllowanceRequest => {
    return {
        name: data.name,
        amount: convertAmountToFloat(data.amount),
        currency: data.currency,
        frequency: data.frequency,
        max_per_frequency: parseInt(data.max_per_frequency)
    }
};
