import {action, makeObservable, observable} from "mobx";

export class SnackbarStore {
    static readonly SUCCESS = true;
    static readonly FAIL = false;

    @observable isOpen: boolean = false;
    @observable isSuccessful: boolean = false;
    @observable successMessage?: string;
    @observable failMessage?: string;

    constructor() {
        makeObservable(this);
    }

    @action
    open(isSuccessful: boolean, successMessage?: string, failMessage?: string): void {
        this.isOpen = true;
        this.isSuccessful = isSuccessful;
        this.successMessage = successMessage;
        this.failMessage = failMessage;
    }

    @action
    close(): void {
        this.isOpen = false;
    }
}
