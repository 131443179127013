import * as React from "react";
import IconButton from "@mui/material/IconButton";
import LanguageIcon from "@mui/icons-material/Language";
import {FlagBadge} from "./FlagBadge";
import {LanguageSelector} from "./LanguageSelector";
import {defaultLanguage, Language} from "../../../../../config/i18n";
import {flags} from "../../../../../config/flags";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import CircularProgress from "@mui/material/CircularProgress";
import {useGlobalStores} from "../../../../../hooks/use_global_stores";
import {observer} from "mobx-react";
import {IReactionDisposer, reaction} from "mobx";

const LanguageSwitcherActionComponent = () => {
    const { i18n } = useTranslation();
    const { userStore } = useGlobalStores();

    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [value, setValue] = useState<Language>(userStore.currentUser?.default_language || defaultLanguage);

    const handleToggleVisibility = () => {
        setOpen(!open);
    };

    const handleSelect = (language: Language) => {
        setOpen(false);
        setValue(language);
    };

    const Flag = flags[value];

    useEffect(() => {
        setLoading(true);

        i18n.changeLanguage(value).then(() => {
            setLoading(false);
        });
    }, [value]);

    let userReactionDisposer: IReactionDisposer;
    React.useEffect(() => {
        if (!userReactionDisposer) {
            userReactionDisposer = reaction(
                () => userStore.currentUser,
                () => {
                    setValue(userStore.currentUser?.default_language!);
                }
            );
        }

        return () => {
            userReactionDisposer();
        }
    });

    if (loading) {
        return (
            <CircularProgress sx={{color: 'white'}} size={20} />
        );
    }

    return (
        <div style={{position: 'relative'}}>
            <IconButton color="inherit" onClick={handleToggleVisibility} size="large">
                <FlagBadge flag={<Flag />}>
                    <LanguageIcon />
                </FlagBadge>
            </IconButton>

            {open && <LanguageSelector language={value} onSelect={handleSelect} />}
        </div>
    );
};

export const LanguageSwitcherAction = observer(LanguageSwitcherActionComponent);
