export enum FuelUnit {
    GALLON = "gal",
    LITER = "L",
}

export const fuelUnitLabel = {
    [FuelUnit.GALLON]: "Gallons",
    [FuelUnit.LITER]: "Liters",
};

export const fuelUnits = [
    {
        unit: FuelUnit.GALLON,
        label: fuelUnitLabel[FuelUnit.GALLON],
    },
    {
        unit: FuelUnit.LITER,
        label: fuelUnitLabel[FuelUnit.LITER],
    },
];

export interface IFuelDetails {
    unit: FuelUnit;
    amount: number;
}
