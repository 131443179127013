import * as React from "react";
import {Button} from "@mui/material";

interface IRevertActionProps {
    onClick(): void;
}

export const RevertAction = ({onClick}: IRevertActionProps) => (
    <Button color="error" component="label" variant="contained" onClick={onClick}>
        Revert to default location
    </Button>
);
