import * as React from "react";
import {ReactNode} from "react";
import {Grid, GridSpacing} from "@mui/material";

interface IDashboardItemsContainerProps {
    spacing?: GridSpacing;
    children: ReactNode;
}

export const DashboardItemsContainer = ({spacing, children}: IDashboardItemsContainerProps) => (
    <Grid container spacing={spacing ? spacing : 3}>
        {children}
    </Grid>
);
