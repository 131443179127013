import * as React from "react";
import {ReactElement} from "react";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import {ConfirmationDialog} from "./ConfirmationDialog";

interface IConfirmationDialogWithTriggerActionsProps {
    closeDialog?(): void;
}

interface IConfirmationDialogWithTriggerProps {
    icon?: ReactElement;
    title?: string;
    description?: ReactElement | string;
    actions?(actions: IConfirmationDialogWithTriggerActionsProps): ReactElement[];
    loading?: boolean;
}

export const ConfirmationDialogWithTrigger = ({title = "Confirm operation", description, actions, loading, icon}: IConfirmationDialogWithTriggerProps) => {
    const [open, setOpen] = React.useState(false);

    const handleOpen = () => {
        if (loading) {
            return;
        }

        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            {
                icon &&
                <IconButton size="small" color="primary" disabled={loading} onClick={handleOpen}>
                    {!loading ? icon : <CircularProgress size={24} />}
                </IconButton>
            }
            {
                !icon &&
                <Button size="small" color="primary" disabled={loading} onClick={handleOpen}>
                    {!loading ? "Delete" : <CircularProgress size={24} />}
                </Button>
            }

            <ConfirmationDialog open={open}
                                onClose={handleClose}
                                title={title}
                                description={description}
                                actions={actions && actions({closeDialog: handleClose})}
            />
        </>
    );
};
