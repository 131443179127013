import * as React from "react";
import {Button, Grid, styled} from "@mui/material";
import {AddNewButton} from "./AddNewButton";
import {FriendsNameField} from "./FriendsNameField";
import {SplitMethodField} from "./SplitMethodField";
import {SplitMethodPercentageField} from "./SplitMethodPercentageField";
import {SplitMethodFixedField} from "./SplitMethodFixedField";
import {Details} from "./Details";
import {useTranslation} from "react-i18next";
import {Currency} from "../../../../../../../config/currencies";
import {ITransactionSplit} from "../../../../../../../config/transactions";

const Container = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: 'solid 3px',
    borderColor: theme.palette.primary.main,
    borderRadius: 8,
}));

interface IFriendSelectorProps {
    value?: ITransactionSplit;
    onChange(value: ITransactionSplit | null): void;
    originalAmount: string;
    originalCurrency: Currency;
}

export enum SplitMethod {
    PERCENTAGE = "PERCENTAGE",
    FIXED = "FIXED",
}

const DEFAULT_VALUE = {
    name: '',
    split_method: SplitMethod.PERCENTAGE,
    percentage: 50,
    amount: '',
} as any;

export const FriendSelector = ({value, onChange, originalAmount, originalCurrency}: IFriendSelectorProps) => {
    const { t } = useTranslation();

    const [open, setOpen] = React.useState(false);
    const [values, setValues] = React.useState<ITransactionSplit>(value || DEFAULT_VALUE);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (prop: keyof ITransactionSplit) => (value: any) => {
        setValues({ ...values, [prop]: value });
    };
    const handleAdd = () => {
        onChange(values);

        if (!value) {
            setValues(DEFAULT_VALUE);
        }

        handleClose();
    }
    const handleDelete = () => {
        onChange(null);
    };

    if (!!value && !open) {
        return (
            <Details
                value={value}
                originalCurrency={originalCurrency}
                onEdit={handleOpen}
                onDelete={handleDelete}
            />
        );
    }

    if (!open) {
        return (
            <AddNewButton onClick={handleOpen} />
        );
    }

    return (
        <Container>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
            >
                <Grid item xs={12}>
                    <FriendsNameField value={values.name} onChange={handleChange('name')} />
                </Grid>

                <Grid item xs={12} md={4}>
                    <SplitMethodField value={values.split_method} onChange={handleChange('split_method')} />
                </Grid>

                {values.split_method === SplitMethod.PERCENTAGE &&
                    <SplitMethodPercentageField
                        value={values.percentage!!}
                        onChange={handleChange('percentage')}
                        originalAmount={originalAmount}
                        originalCurrency={originalCurrency}
                    />
                }

                {values.split_method === SplitMethod.FIXED &&
                    <Grid item xs={12} md={8}>
                        <SplitMethodFixedField
                            value={values.amount?.toString() || ''}
                            onChange={handleChange('amount')}
                            originalCurrency={originalCurrency}
                        />
                    </Grid>
                }

                <Grid item>
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        sx={{marginRight: (theme) => theme.spacing(2)}}
                    >
                        Cancel
                    </Button>

                    <Button
                        onClick={handleAdd}
                        variant="contained"
                        color="primary"
                    >
                        {t('global.buttons.save')}
                    </Button>
                </Grid>
            </Grid>
        </Container>
    );
};
