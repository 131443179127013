import * as React from "react";
import { SelectField } from "../../../Components/Form/SelectField";
import { availableLanguages } from "../../../../config/i18n";

export const DefaultLanguageField = (props: any) => (
    <SelectField
        label="Default Language"
        options={availableLanguages}
        optionValueGetter={(l: any) => l.code}
        optionLabelGetter={(l: any) => l.label}
        {...props}
    />
);
