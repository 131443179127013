import * as React from "react";
import Button from "@mui/material/Button";
import {ReactNode} from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import Box from "@mui/material/Box";

interface IFieldWrapperProps {
    children: ReactNode;
    onDelete(): void;
}

export const FieldWrapper = ({onDelete, children}: IFieldWrapperProps) => (
    <>
        {children}

        <Box sx={{marginTop: (theme) => theme.spacing(1),}}>
            <Button
                color="primary"
                size="small"
                onClick={onDelete}
            >
                <DeleteIcon/> Remove
            </Button>
        </Box>
    </>
);
