import * as React from 'react'
import {ExtraIncomeStoreContext} from "../contexts/extra_income_store_context";

export const useExtraIncomeStore = () => {
    const store = React.useContext(ExtraIncomeStoreContext);
    if (!store) {
        throw new Error('useExtraIncomeStore must of type ExtraIncomeStore');
    }

    return store;
};
