import * as React from "react";
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import ListItemText from '@mui/material/ListItemText';
import Avatar from '@mui/material/Avatar';
import { DeleteAction } from "../Actions/DeleteAction";
import { EditAction } from "../Actions/EditAction";
import { IBudget } from "../../../../config/budget";
import { Currency } from "../../../../config/currencies";
import { useNavigate } from "react-router-dom";

interface IBudgetItemProps {
    budget: IBudget;
    currency: Currency;
    onEditClick(budget: IBudget): void;
}

export const BudgetItem = ({budget, currency, onEditClick}: IBudgetItemProps) => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate(`/settings/budgets/${budget.id}`);
    };

    return (
        <ListItem>
            <ListItemAvatar>
                <Avatar>
                    {budget.name[0]}
                </Avatar>
            </ListItemAvatar>

            <ListItemText
                primary={budget.name}
                primaryTypographyProps={{
                    sx: (theme) => {
                        return {
                            cursor: 'pointer',
                            '&:hover': {
                                color: theme.palette.primary.main,
                            },
                        };
                    }
                }}
                secondary={(budget.amount as any).toCurrency(currency)}
                onClick={handleClick}
            />

            <ListItemSecondaryAction>
                <EditAction onClick={() => onEditClick(budget)} />
                <DeleteAction budget={budget} />
            </ListItemSecondaryAction>
        </ListItem>
    );
};
