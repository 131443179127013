import * as React from "react";
import {FieldWrapper} from "./FieldWrapper";
import {Grid} from "@mui/material";
import {FuelUnit, fuelUnits, IFuelDetails} from "../../../../../../../config/fuel";
import {
    TransactionValidationErrors,
    TransactionValidationField
} from "../../../../../../../validators/transactions";
import {FreeTextField} from "../../../../../../Components/Form/FreeTextField";
import {SelectField} from "../../../../../../Components/Form/SelectField";

interface IFuelAmountFieldProps {
    value?: IFuelDetails;
    onChange(value: IFuelDetails): void;
    onDelete(): void;
    errors?: TransactionValidationErrors;
}

export const FuelDetailsField = ({value, onChange, onDelete, errors}: IFuelAmountFieldProps) => {
    const [unit, setUnit] = React.useState<FuelUnit>(value?.unit || FuelUnit.LITER);
    const [amount, setAmount] = React.useState<string>(value?.amount?.toString() || '');

    React.useEffect(() => {
        if (!value) {
            return;
        }
        setUnit(value?.unit);
        setAmount(value?.amount?.toString());
    }, [value]);

    React.useEffect(() => {
        onChange({unit, amount: amount as any});
    }, [unit, amount]);

    const handleUnitChange = (value: any) => {
        setUnit(value);
    };

    const handleAmountChange = (value: any) => {
        setAmount(value);
    };

    return (
        <FieldWrapper onDelete={onDelete}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                spacing={2}
            >
                <Grid item xs={6} sm={2}>
                    <SelectField
                        label="Unit"
                        options={fuelUnits}
                        value={unit}
                        onChange={handleUnitChange}
                        optionValueGetter={o => o.unit}
                        optionLabelGetter={o => o.label}
                    />
                </Grid>

                <Grid item xs={6} sm={10}>
                        <FreeTextField
                            label="Amount"
                            value={amount}
                            onChange={handleAmountChange}
                            errors={errors?.get(TransactionValidationField.FUEL_AMOUNT)}
                        />
                </Grid>
            </Grid>
        </FieldWrapper>
    )
};
