import * as React from "react";
import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import IconButton from "@mui/material/IconButton";
import {styled, Tooltip} from "@mui/material";
import {useModal} from "../../../../hooks/use_modal";
import {FormModal} from "../../FormModal";
import {IGoogleMapsLocationFinderFieldProps} from "./GoogleMapsLocationFinderField";
import {IGoogleMapsLocation, MapView} from "./MapView";

const MapViewContainer = styled('div')(({theme}) => ({
    [theme.breakpoints.down('sm')]: {
        margin: -24,
    },
}));

export const GoogleMapsLocationFinder = ({onChange, position}: IGoogleMapsLocationFinderFieldProps) => {
    const { open, handleModalOpen, handleModalClose } = useModal();

    const handleSelect = (value: IGoogleMapsLocation) => {
        handleModalClose();
        onChange(value);
    };

    return (
        <>
            <Tooltip title="Map location" arrow tabIndex={-1}>
                <IconButton size="small" onClick={handleModalOpen}>
                    <TravelExploreIcon color="primary" />
                </IconButton>
            </Tooltip>

            {open && (
                <FormModal
                    title="Choose location"
                    loading={false}
                    open={open}
                    onClose={handleModalClose}
                >
                    <MapViewContainer>
                        <MapView onSelect={handleSelect} defaultPosition={position} />
                    </MapViewContainer>
                </FormModal>
            )}
        </>
    )
};
