import * as React from 'react'
import {UserStore} from "../stores/users";
import {PaymentMethodStore} from "../stores/payment_methods";
import {SnackbarStore} from "../stores/snackbar";
import {CurrencyStore} from "../stores/currency";
import {CategoryStore} from "../stores/categories";
import {UserPaymentMethodStore} from "../stores/user_payment_methods";
import {IncomeStore} from "../stores/incomes";
import {AuthStore} from "../stores/auth";
import {SearchStore} from "../stores/search";
import {SnapshotStore} from "../stores/snapshot";
import {DashboardStore} from "../stores/dashboard";
import {TransactionStore} from "../stores/transactions";
import {GeneralSettingsStore} from "../stores/general_settings";
import {RefreshStore} from "../stores/refresh_store";
import { AllowanceStore } from '../stores/allowances';
import { BudgetStore } from '../stores/budgets';
import { GeocodingStore } from '../stores/geocoding';
import { UserPaymentMethodCallbackStore } from '../stores/user_payment_methods_callback';
import { GlobalRefreshStore } from '../stores/global_refresh_store';
import { GlobalRefresher } from '../services/refresher';
import { LayoutStore } from '../stores/layout';

interface IGlobalStores {
    layoutStore: LayoutStore,
    snackbarStore: SnackbarStore;
    globalRefreshStore: GlobalRefreshStore;
    refreshStore: RefreshStore;
    generalSettings: GeneralSettingsStore;
    authStore: AuthStore;
    userStore: UserStore;
    paymentMethodStore: PaymentMethodStore;
    incomeStore: IncomeStore;
    allowanceStore: AllowanceStore;
    userPaymentMethodStore: UserPaymentMethodStore;
    userPaymentMethodCallbackStore: UserPaymentMethodCallbackStore;
    categoryStore: CategoryStore;
    currencyStore: CurrencyStore;
    searchStore: SearchStore;
    snapshotStore: SnapshotStore;
    dashboardStore: DashboardStore;
    transactionStore: TransactionStore;
    budgetStore: BudgetStore;
    geocodingStore: GeocodingStore;
}

const userStore = new UserStore();
const transactionStore = new TransactionStore();
const dashboardStore = new DashboardStore();
const snapshotStore = new SnapshotStore();

const paymentMethodStore = new PaymentMethodStore();
const incomeStore = new IncomeStore();
const allowanceStore = new AllowanceStore();
const userPaymentMethodStore = new UserPaymentMethodStore();
const userPaymentMethodCallbackStore = new UserPaymentMethodCallbackStore();
const categoryStore = new CategoryStore();
const budgetStore = new BudgetStore();

const globalRefresher = new GlobalRefresher(
    paymentMethodStore,
    incomeStore,
    userPaymentMethodStore,
    userPaymentMethodCallbackStore,
    categoryStore,
    allowanceStore,
    budgetStore
);

const globalStores = {
    layoutStore: new LayoutStore(),
    snackbarStore: new SnackbarStore(),
    globalRefreshStore: new GlobalRefreshStore(globalRefresher),
    refreshStore: new RefreshStore(transactionStore, dashboardStore, snapshotStore),
    generalSettings: new GeneralSettingsStore(),
    authStore: new AuthStore(),
    userStore: userStore,
    paymentMethodStore: paymentMethodStore,
    incomeStore: incomeStore,
    allowanceStore: allowanceStore,
    userPaymentMethodStore: userPaymentMethodStore,
    userPaymentMethodCallbackStore: userPaymentMethodCallbackStore,
    categoryStore: categoryStore,
    currencyStore: new CurrencyStore(userStore),
    searchStore: new SearchStore(),
    snapshotStore: snapshotStore,
    dashboardStore: dashboardStore,
    transactionStore: transactionStore,
    budgetStore: budgetStore,
    geocodingStore: new GeocodingStore(),
};

export const createGlobalStores = () => {
    return globalStores;
};

export const GlobalStoresContext = React.createContext<IGlobalStores>(globalStores);
