import * as React from "react";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import ImageIcon from "@mui/icons-material/Image";
import CircularProgress from "@mui/material/CircularProgress";
import IconButton from "@mui/material/IconButton";
import {useModal} from "../../../../hooks/use_modal";
import {Form} from "../../../Components/Form/Form/Form";
import {FormModal} from "../../../Components/FormModal";
import {useForm} from "../../../../hooks/use_form_v2";
import {FileField} from "../../../Components/Form/FileField";
import {ILocation} from "../../../../config/location";
import {uploadLogo} from "../../stores/locations";

interface IUploadLogoActionFormState {
    logo: File;
}

interface IUploadActionProps {
    location: ILocation;
    onChange(id: number, location: ILocation | null): void;
}

export const UploadLogoAction = ({ location, onChange }: IUploadActionProps) => {
    const { } = useGlobalStores();

    const { loading, open, handleModalOpen, handleModalClose } = useModal();
    const form = useForm<IUploadLogoActionFormState>();
    const { register } = form;

    const handleSubmit = async (data: IUploadLogoActionFormState): Promise<any> => {
        const formData = new FormData();
        formData.append('file', data.logo, data.logo.name);

        const res = await uploadLogo(location.id!, formData);
        location.logo = res.logo;

        onChange(location.id!, location);
        handleModalClose();

        return await Promise.resolve();
    };

    return (
        <>
            <IconButton size="small" color="primary" disabled={loading} onClick={handleModalOpen}>
                {!loading ? <ImageIcon /> : <CircularProgress size={24} />}
            </IconButton>

            <FormModal
                title="Upload Logo"
                loading={loading}
                open={open}
                onClose={handleModalClose}
            >
                <Form form={form} onSubmit={handleSubmit}>
                    <Form.Field
                        type={FileField}
                        {...register('logo')}
                        preview
                        label="Trip Map"
                        accept="image/*"
                    />
                </Form>
            </FormModal>
        </>
    );
};
