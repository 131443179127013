import * as React from "react";
import {useTranslation} from "react-i18next";
import {IBulkDryRunResult} from "../../../../../../config/bulk";
import {Button, styled} from "@mui/material";
import {useEffect} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {bulkCreate} from "../../../../../../stores/bulk";
import {INewTransaction} from "../../../../../../config/transactions";
import Typography from "@mui/material/Typography";
import {useGlobalStores} from "../../../../../../hooks/use_global_stores";
import {convertToNewTransaction} from "../../../../../../helpers/bulk_helpers";

const Container = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
}));

const Message = styled(Typography)(({theme}) => ({
    marginTop: theme.spacing(3),
}));

interface IImportStepProps {
    data: IBulkDryRunResult[];
    onDone(promise: Promise<any>): void;
}

export const ImportStep = ({data, onDone}: IImportStepProps) => {
    const { t } = useTranslation();

    const { snackbarStore } = useGlobalStores();

    const [, setLoading] = React.useState(false);
    const [hasError, setError] = React.useState(false);

    const buildNewTransactions = (): INewTransaction[] => {
        return data.map(d => convertToNewTransaction(d));
    }

    const execute = () => {
        setLoading(true);
        setError(false);
        snackbarStore.close();

        const transactions = buildNewTransactions();
        bulkCreate(transactions)
            .then(() => {
                setLoading(false);
                onDone(Promise.resolve(transactions.length));
            })
            .catch(reason => {
                setLoading(false);
                setError(true);

                snackbarStore.open(false);
            });
    };

    const handleRetry = () => {
        execute();
    };

    useEffect(() => {
        execute();
    }, []);

    if (hasError) {
        return (
            <Container>
                <Button
                    type="button"
                    onClick={handleRetry}
                    variant="contained"
                    color="primary"
                    size="small"
                >
                    {t('transactions.bulk.import.button_retry')}
                </Button>
            </Container>
        )
    }

    return (
        <Container>
            <CircularProgress size={80} />

            <Message variant="subtitle2">
                {t('transactions.bulk.import.message_do_not_close_window')}
            </Message>
        </Container>
    );
};
