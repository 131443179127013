import * as React from "react";
import {Button, styled} from "@mui/material";
import { IAllowance, IAllowanceTransaction, frequencies } from "../../../../../../../config/allowance";
import { useGlobalStores } from "../../../../../../../hooks/use_global_stores";
import { SelectField } from "../../../../../../Components/Form/SelectField";
import { AddNewButton } from "../Components/AddNewButton";
import { Details } from "./Details";
import { useForm } from "../../../../../../../hooks/use_form_v2";
import { Form } from "../../../../../../Components/Form/Form/Form";
import { useModal } from "../../../../../../../hooks/use_modal";
import { FreeTextField } from "../../../../../../Components/Form/FreeTextField";

const Container = styled('div')(({ theme }) => ({
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2),
    border: 'solid 3px',
    borderColor: theme.palette.primary.main,
    borderRadius: 8,
}));

interface IAllowanceSelectorProps {
    value?: IAllowanceTransaction;
    onChange(value?: IAllowanceTransaction | null): void;
    originalAmount: string;
}

export const AllowanceSelector = ({value, onChange, originalAmount}: IAllowanceSelectorProps) => {
    const { allowanceStore } = useGlobalStores();

    const { open, handleModalOpen, handleModalClose } = useModal();

    const form = useForm<IAllowanceTransaction>(value);
    const { change, register, watch, reset } = form;

    const handleSubmit = (value: IAllowanceTransaction) => {
        onChange(value);
        handleModalClose();
        reset();

        return Promise.resolve();
    }

    const handleDelete = () => {
        onChange(null);
    };

    React.useEffect(() => {
        if (!watch('allowance_id')) {
            return;
        }

        const allowance = allowanceStore.findById(watch('allowance_id'))!;
        change('amount')(allowance.amount);
    }, [watch('allowance_id')]);

    if (!!value && !open) {
        return (
            <Details
                value={value}
                onEdit={handleModalOpen}
                onDelete={handleDelete}
            />
        );
    }

    if (!open) {
        return (
            <AddNewButton onClick={handleModalOpen} />
        );
    }

    return (
        <Container>
            <Form form={form} onSubmit={handleSubmit}>
                <Form.Field
                    {...register('allowance_id')}
                    type={SelectField}
                    label="Allowance"
                    options={allowanceStore.allowances}
                    optionValueGetter={(o: IAllowance) => o.id}
                    optionLabelGetter={(o: IAllowance) => `${o.name} - ${(o.amount as any).toCurrency(o.currency)}/${frequencies[o.frequency]}`}
                />

                <Form.Field
                    {...register('amount')}
                    type={FreeTextField}
                />

                <Form.Buttons position="beforeSubmit">
                    <Button
                        onClick={handleModalClose}
                        variant="contained"
                        sx={{marginRight: (theme) => theme.spacing(2)}}
                    >
                        Cancel
                    </Button>
                </Form.Buttons>
            </Form>
        </Container>
    );
};
