import * as React from "react";
import {useSearchParams} from "react-router-dom";
import {useMediaQuery, useTheme} from "@mui/material";
import {
    categorizeIntoTimeFrequencies,
    convertQueryStringParamToTimeFrequency,
    convertTimeFrequencyToQueryStringParam,
    getFirstCategoryWithElements,
    IRecurringExpensesCategory,
} from "../../../helpers/recurring_expenses_helper";
import {useState} from "react";
import {RecurringExpensesTimeFrequency} from "../../../config/recurring_expense";
import {Container} from "./Components/Container";
import {FrequencyTabs} from "./FrequencyTabs";
import {DataTable} from "./DataTable";
import {useRecurringExpenseStore} from "../../../hooks/use_recurring_expense_store";
import {computed} from "mobx";
import {observer} from "mobx-react";

export const RecurringExpensesContainer = observer(() => {
    const store = useRecurringExpenseStore();

    const categories = computed((): IRecurringExpensesCategory[] => {
        return categorizeIntoTimeFrequencies(store.recurringExpenses);
    });

    const [, setSearchParams] = useSearchParams();
    const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('xl'));

    const [currentTab, setCurrentTab] = useState<RecurringExpensesTimeFrequency>(
        convertQueryStringParamToTimeFrequency() || getFirstCategoryWithElements(categories.get())?.code || RecurringExpensesTimeFrequency.DAILY
    );

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: RecurringExpensesTimeFrequency) => {
        setCurrentTab(newValue);
        setSearchParams((prev) => {
            prev.set('t', String(convertTimeFrequencyToQueryStringParam(newValue)));
            return prev;
        });
    };

    return (
        <Container smallScreen={isSmallScreen}>
            <FrequencyTabs categories={categories.get()} currentTab={currentTab} onChange={handleTabChange} isSmallScreen={isSmallScreen} />
            {<DataTable data={store.recurringExpenses.filter(e => e.time_frequency === currentTab)} timeFrequency={currentTab} />}
        </Container>
    )
});
