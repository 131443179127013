import * as React from "react";
import {Currency} from "../../../../../config/currencies";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import {LocalOffer} from "@mui/icons-material";
import {FormHelperText, Grid} from "@mui/material";
import {MoneyField} from "../../../../Components/Form/MoneyField";
import {SaveButton} from "../../../../Components/SaveButton";
import {convertAmountToFloat} from "../../../../../helpers/transaction_helpers";
import {FormModal} from "../../../../Components/FormModal";
import {useEffect} from "react";

interface IAmountDetailsFieldProps {
    currency: Currency;
    amount: string;
    value?: IAmountDetails | null;
    onChange(data: IAmountDetails): void;
}

export interface IAmountDetails {
    goodPrice: number | string;
    shippingFee: number | string;
    customsFee: number | string;
    otherFee: number | string;
}

interface IAmountDetailsFieldsInternal extends IAmountDetails {
    isNew: boolean;
}

export const AmountDetailsField = ({currency, amount, value = {} as any, onChange}: IAmountDetailsFieldProps) => {
    const [open, setOpen] = React.useState(false);
    const [values, setValues] = React.useState<IAmountDetailsFieldsInternal>({
        isNew: (value?.goodPrice || '') === '',
        goodPrice: value?.goodPrice || '',
        shippingFee: value?.shippingFee || '',
        customsFee: value?.customsFee || '',
        otherFee: value?.otherFee || '',
    });
    const [hasValues, setHasValues] = React.useState<boolean>(false);
    const [sum, setSum] = React.useState<number>(0);
    const [areValuesValid, setAreValuesValid] = React.useState<boolean>(false);


    useEffect(() => {
        if (values.isNew) {
            setValues({ ...values, goodPrice: amount });
        }
    }, [amount]);

    useEffect(() => {
        setSum(convertAmountToFloat(values.goodPrice?.toString() || '0') +
            convertAmountToFloat(values.shippingFee?.toString() || '0') +
            convertAmountToFloat(values.customsFee?.toString() || '0') +
            convertAmountToFloat(values.otherFee?.toString() || '0')
        );

        if ((values.goodPrice === '' || values.goodPrice === amount) &&
            values.shippingFee === '' &&
            values.customsFee === '' &&
            values.otherFee === ''
        ) {
            setHasValues(false);
            return;
        }

        setHasValues(true);
    }, [values]);

    useEffect(() => {
        setAreValuesValid(sum.toFixed(2) === convertAmountToFloat(amount).toFixed(2));
    }, [sum]);

    const handleOpen = () => {
        setOpen(true);
    };
    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (prop: keyof IAmountDetails) => (value: any) => {
        setValues({ ...values, [prop]: value });
    };

    const handleSubmit = (event: any) => {
        if (!hasValues || !areValuesValid) {
            return;
        }

        onChange({
            goodPrice: values.goodPrice !== '' ? convertAmountToFloat(values.goodPrice) : null as any,
            shippingFee: values.shippingFee !== '' ? convertAmountToFloat(values.shippingFee) : null as any,
            customsFee: values.customsFee !== '' ? convertAmountToFloat(values.customsFee) : null as any,
            otherFee: values.otherFee !== '' ? convertAmountToFloat(values.otherFee) : null as any,
        });
        handleClose();
    };

    const handleDelete = (event: any) => {
        setValues({
            isNew: false,
            goodPrice: amount,
            shippingFee: '',
            customsFee: '',
            otherFee: '',
        });

        onChange(null as any);
        handleClose();
    };

    return (
        <>

            <Box sx={{marginLeft: (theme) => theme.spacing(0.5)}}>
                <IconButton size="small" color="primary" onClick={handleOpen} disabled={amount === ''}>
                    <Badge invisible={!hasValues} color="error" variant="dot" overlap="rectangular">
                        <LocalOffer />
                    </Badge>
                </IconButton>
            </Box>

            <FormModal loading={false} open={open} onClose={handleDelete} title="Amount details">
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <MoneyField label="Good price" value={values.goodPrice?.toString() || ''} onChange={handleChange('goodPrice')} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MoneyField label="Shipping fee" value={values.shippingFee?.toString() || ''} onChange={handleChange('shippingFee')} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MoneyField label="Customs fee" value={values.customsFee?.toString() || ''} onChange={handleChange('customsFee')} />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <MoneyField label="Other fee" value={values.otherFee?.toString() || ''} onChange={handleChange('otherFee')} />
                    </Grid>
                    {
                        !areValuesValid &&
                        <Grid item xs={12}>
                            <FormHelperText error>
                                The sum of the details ({((convertAmountToFloat(sum)) as any).toCurrency(currency)})
                                doesn't match the amount ({((convertAmountToFloat(amount)) as any).toCurrency(currency)}).
                            </FormHelperText>
                        </Grid>
                    }
                    <Grid item xs={12}>
                        <SaveButton loading={!hasValues || !areValuesValid} onSubmit={handleSubmit} />
                    </Grid>
                </Grid>
            </FormModal>
        </>
    );
};
