import { ITransactionFormState } from "../../components/Transactions/Components/Form/TransactionForm";
import { TransactionFormField } from "../../components/Transactions/Components/Form/config/config";
import { CallbackAction, ICallbackResult, ICallbackAction } from "../../config/user_payment_method_callback";
import { shareWithFriendsPercentageCallback } from "./share_with_friends_percentage";

export const executeCallback = (callback: ICallbackAction): ICallbackResult | undefined => {
    switch(callback.action) {
        case CallbackAction.SHARE_WITH_FRIENDS_PERCENTAGE:
            return shareWithFriendsPercentageCallback(callback.payload);
    }
};

export const toForm = (results: (ICallbackResult | undefined)[]): ITransactionFormState | {} => {
    const result = {} as ITransactionFormState;

    results = results.filter(item => item);
    if (!results) {
        return result;
    }

    const shareWithFriends = results.filter(item => item?.fieldKey === TransactionFormField.AMOUNT_SHARING);
    if (!!shareWithFriends.length) {
        results = results.filter(item => item?.fieldKey !== TransactionFormField.AMOUNT_SHARING);

        result.splits = shareWithFriends.map(item => item?.data);
    }

    results.forEach(item => {
        (result[item!.field] as any) = item?.data;
    });

    return result;
};
