import {styled} from "@mui/material";

interface IProgressStyle {
    progress: number;
    animation?: boolean;
}
export const Progress = styled('div', {
    shouldForwardProp: (prop) => prop !== 'progress' && prop !== 'animation',
})<IProgressStyle>(({progress, animation, theme}) => ({
    position: 'relative',
    zIndex: 9,
    width: `${progress}%`,
    transition: !!animation ? theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: '1s',
    }) : undefined,
    height: '100%',
}));
