import * as React from "react";
import Paper from '@mui/material/Paper';
import {ReactNode} from "react";
import {styled} from "@mui/material";

const StyledPaper = styled(Paper)(({ theme }) => ({
    position: 'absolute',
    zIndex: theme.zIndex.drawer + 10,
    top: 0,
    right: theme.spacing(2),
    width: 400,
    maxHeight: 400,
    overflow: 'scroll',
}));

interface IWrapperProps {
    element: any | null;
    children: ReactNode;
}

export const Wrapper = ({element, children}: IWrapperProps) => {
    if (!element) {
        return null;
    }

    const rect = element.getBoundingClientRect();
    const rootPositionStyle = {
        marginTop: rect.top + rect.height + 10,
    };

    return (
        <StyledPaper elevation={3} style={rootPositionStyle}>
            {children}
        </StyledPaper>
    );
};
