import {styled} from "@mui/material";

interface IScreenSizeAware {
    isSmallScreen: boolean;
}

export const ActionsContainer = styled('div', {
    shouldForwardProp: (prop) => prop !== "isSmallScreen",
})<IScreenSizeAware>(({isSmallScreen, theme}) => ({
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(isSmallScreen ? 2 : 3),
}));
