import * as React from "react";
import {TotalChart} from "../../../Charts/TotalChart";
import {IMonthSnapshotItem} from "../../../../../config/snapshot";

interface IExtraIncomeProps {
    data: IMonthSnapshotItem[];
}

export const ExtraIncome = ({data}: IExtraIncomeProps) => {
    const total = data.map(v => v.total).reduce((prev, cur) => {
        return prev + cur;
    }, 0);

    return (
        <TotalChart total={total} size="medium" />
    );
};
