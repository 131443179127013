import * as React from "react";
import "../../../../../utils/number_extensions";
import {blueGrey} from "@mui/material/colors";
import {ITransaction} from "../../../../../config/transactions";
import {useGlobalStores} from "../../../../../hooks/use_global_stores";
import {Box, Grid, IconButton, styled} from "@mui/material";
import CallSplitIcon from '@mui/icons-material/CallSplit';
import { Item } from "../Components/Item";
import { SharedWith } from "./SharedWith";
import { AttachMoney, EuroSymbol, ReceiptLong, Redeem } from "@mui/icons-material";
import { AmountDetails } from "./AmountDetails";
import { Allowances } from "./Allowances";
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import { Currency } from "../../../../../config/currencies";

const DifferentCurrencyContainer = styled('div', {
    shouldForwardProp: (prop) => prop !== 'open',
})<{open: boolean}>(({theme, open}) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',

    paddingTop: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginBottom: '-1px',

    ...open ? {
        borderTopLeftRadius: 5,
        borderTopRightRadius: 5,
        border: 'solid 1px',
        borderColor: theme.palette.divider,
        borderBottomColor: 'transparent',
        backgroundColor: '#fff',
    } : {
        border: 'solid 1px transparent',
    },
}));

const OpenIcon = styled(ArrowDropDownCircleIcon, {
    shouldForwardProp: (prop) => prop !== 'open',
})<{open: boolean}>(({open, theme}) => ({
    transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.easeInOut,
        duration: '0.2s',
    }),

    ...open ? {
        transform: 'rotate(180deg)',
    } : {},
}));

const AmountDiv = styled('div')(({theme}) => ({
    fontSize: 40,
    fontWeight: 'bold',
    color: blueGrey[700],
}));

const ActualAmountContainer = styled('div')(() => ({
    marginTop: -8,
    color: blueGrey[500],
}));

const ActualAmount = styled('span')(() => ({
    fontSize: 16,
    fontWeight: 'bold',
}));

const DetailsContainer = styled(Box)(({theme}) => ({
    borderRadius: 5,
    border: 'solid 1px',
    borderColor: theme.palette.divider,
    backgroundColor: '#fff',
})); 

const ItemContainer = styled(Box)(({theme}) => ({
    borderBottom: 'solid 1px',
    borderBottomColor: theme.palette.divider,
    padding: theme.spacing(1.5),

    '&:last-of-type': {
        borderBottom: 0,
    },
}));

interface IAmountProps {
    transaction: ITransaction;
}

export const Amount = ({transaction}: IAmountProps) => {
    const { currencyStore } = useGlobalStores();
    const currency = currencyStore.currentCurrency;

    const [open, setOpen] = React.useState<boolean>(false);

    const handleVisibility = () => {
        setOpen(prev => !prev);
    };

    const showExtraDetailsButton = !!transaction.allowances?.length || transaction.splits?.length || transaction.details?.amount_details;

    return (
        <Box>
            <Grid container justifyContent="center" alignItems="center" spacing={1}>
                <Grid item>
                    <DifferentCurrencyContainer open={open}>
                        {
                            currency === transaction.original_currency ?
                            (
                                <AmountDiv>
                                    {transaction.amount.toCurrency(currency, {})}
                                </AmountDiv>
                            ) :
                            (
                                <>
                                    <AmountDiv>
                                        {transaction.original_amount.toCurrency(transaction.original_currency, {})}
                                    </AmountDiv>

                                    <ActualAmountContainer>
                                        <ActualAmount>{transaction.original_amount.toCurrency(currency, {})}</ActualAmount>
                                    </ActualAmountContainer>
                                </>
                            )
                        }

                        {showExtraDetailsButton && (
                            <IconButton color="primary" size="large" onClick={handleVisibility}>
                                <OpenIcon open={open} fontSize="inherit" />
                            </IconButton>
                        )}
                    </DifferentCurrencyContainer>
                </Grid>
            </Grid>

            {open && (
                <DetailsContainer>
                    {(!!transaction.splits?.length || !!transaction.allowances?.length) && (
                        <ItemContainer>
                            <Item
                                title="Original paid amount"
                                value={transaction.original_amount.toCurrency(transaction.original_currency, {})}
                                icon={transaction.original_currency === Currency.EUR ? <EuroSymbol /> : <AttachMoney />}
                            />
                        </ItemContainer>
                    )}

                    {!!transaction.details?.amount_details && <ItemContainer>
                        <Item
                            title="Amount details"
                            value={<AmountDetails transaction={transaction} />}
                            icon={<ReceiptLong />}
                        />
                    </ItemContainer>}
                    
                    {!!transaction.splits?.length && <ItemContainer>
                        <Item
                            title="Shared with"
                            value={(<SharedWith transaction={transaction} />)}
                            icon={<CallSplitIcon />}
                        />
                    </ItemContainer>}

                    {!!transaction.allowances?.length && <ItemContainer>
                        <Item title="Allowances" value={<Allowances transaction={transaction} />} icon={<Redeem />} />
                    </ItemContainer>}
                </DetailsContainer>
            )}
        </Box>
    );
};
