import * as React from "react";
import {IStatsDay} from "../../../config/stats";
import {useState} from "react";
import moment from "moment";
import {BarChart, legendClasses} from "@mui/x-charts";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {prefillDays} from "../../../helpers/dashboard_charts_helpers";
import {BarItemIdentifier} from "@mui/x-charts/models";
import {DayDetailChart} from "./DaysChart/DayDetailChart";

interface IDaysChartProps {
    days: IStatsDay[];
    chartHeight: number;
}

export const DaysChart = ({days, chartHeight}: IDaysChartProps) => {
    const { currencyStore, searchStore } = useGlobalStores();

    const [dataset, _ ] = useState<any[]>(prefillDays(days, searchStore.hasQuery));
    const [selectedDay, setSelectedDay] = useState<moment.Moment | undefined>();

    const handleDaySelect = (_: any, {dataIndex}: BarItemIdentifier) => {
        if (!!selectedDay) {
            // setHighlightedItem(null);
            setSelectedDay(undefined);
            return;
        }

        // setHighlightedItem({dataIndex});
        setSelectedDay(moment(dataset[dataIndex].day));
    };

    return (
        <>
            <BarChart
                dataset={dataset}
                series={[
                    {
                        type: 'bar',
                        dataKey: 'total',
                        label: 'Amount',
                        valueFormatter: (v): string => {
                            return v!.toCurrency(currencyStore.currentCurrency, {});
                        },
                        highlightScope: {
                            highlight: 'item',
                            fade: 'series',
                        },
                    },
                ]}
                axisHighlight={{
                    x: 'none',
                }}
                xAxis={[
                    {
                        dataKey: 'day',
                        scaleType: 'band',
                        valueFormatter: (v: string): string => {
                            return moment(v).format('Do');
                        },
                    },
                ]}
                grid={{horizontal: true}}
                borderRadius={3}
                height={chartHeight}
                margin={{top: 5}}
                slotProps={{
                    legend: {
                        direction: 'row',
                        position: { vertical: 'bottom', horizontal: 'middle' },
                        padding: 0,
                        itemMarkWidth: 10,
                        itemMarkHeight: 10,
                        markGap: 5,
                        itemGap: 15,
                    },
                }}
                onItemClick={handleDaySelect}
                sx={{
                    '&&': {
                        touchAction: 'auto',
                    },
                    [`& .${legendClasses.mark}`]: {
                        ry: 10,
                    },
                }}
            />
            {selectedDay && <DayDetailChart day={selectedDay} />}
        </>
    );
};
