import * as React from "react";
import {calculateAverage} from "../../../../helpers/recurring_expenses_helper";
import {
    RecurringExpensesTimeFrequency,
    RecurringExpensesTimeFrequencyLabels
} from "../../../../config/recurring_expense";
import {useRecurringExpenseStore} from "../../../../hooks/use_recurring_expense_store";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {styled} from "@mui/material";
import Paper from "@mui/material/Paper";
import {observer} from "mobx-react";

const Container = styled(Paper)(({theme}) => ({
    marginBottom: theme.spacing(3),
    padding: theme.spacing(1.5),
    fontSize: theme.typography.fontSize,
}));

export const AverageRecurringExpenses = observer(() => {
    const { currencyStore, generalSettings } = useGlobalStores();
    const recurringExpenseStore = useRecurringExpenseStore();

    const currency = currencyStore.currentCurrency;

    const stats = recurringExpenseStore.stats;
    if (stats === undefined) {
        return null;
    }

    return (
        <Container elevation={3}>
            Actual {RecurringExpensesTimeFrequencyLabels[generalSettings.settings.defaultRecurringExpensesAggregator].toLowerCase()} expense aggregation:
            {' '}
            <strong>{(calculateAverage(stats, RecurringExpensesTimeFrequency[generalSettings.settings.defaultRecurringExpensesAggregator]) as any).toCurrency(currency)}</strong>
        </Container>
    );
});
