import * as React from "react";
import Grid from "@mui/material/Grid";
import {DashboardItem} from "../../Components/DashboardItem";
import {CurrentYearOverview} from "./Items/CurrentYearOverview";
import {ISingleYear} from "../../../../config/snapshot";
import {DashboardItemsContainer} from "../../Components/DashboardItemsContainer";
import {Currency} from "../../../../config/currencies";
import {CurrentYearTotalSpent} from "./Items/CurrentYearTotalSpent";
import {MonthlyAverage} from "./Items/MonthlyAverage";
import {CurrentYearMonths} from "./Items/CurrentYearMonths";
import {ExtraIncome} from "./Items/ExtraIncome";
import {gridClasses} from "@mui/material";
import {pieArcLabelClasses} from "@mui/x-charts";

interface IYearlyViewProps {
    data: ISingleYear;
    currency: Currency;
}

export const YearlyView = ({data, currency}: IYearlyViewProps) => (
    <DashboardItemsContainer>
        <Grid item xs={12} md={3}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="stretch"
                spacing={3}
            >
                <DashboardItem title="Total spent" fullWidth>
                    <CurrentYearTotalSpent data={data.transactions} />

                    <CurrentYearOverview data={data.transactions} />
                </DashboardItem>

                <DashboardItem title="Monthly average" fullWidth>
                    <MonthlyAverage data={data.transactions} />
                </DashboardItem>

                <DashboardItem title="Extra income" fullWidth>
                    <ExtraIncome data={data.extra_incomes} />
                </DashboardItem>
            </Grid>
        </Grid>
        <Grid item xs={12} md={9} sx={(theme) => ({
            [`& > .${gridClasses.root}`]: {
                height: '100%',
                [theme.breakpoints.down('sm')]: {
                    height: '400px',
                },
            },
        })}>
            <CurrentYearMonths data={data.transactions} currency={currency} />
        </Grid>
    </DashboardItemsContainer>
);
