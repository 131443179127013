import * as React from "react";
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import {grey} from "@mui/material/colors";
import AddCircleOutlineRoundedIcon from '@mui/icons-material/AddCircleOutlineRounded';
import {styled, SvgIcon} from "@mui/material";

const Container = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
}));

const StyledPaper = styled(Paper)(({theme}) => ({
    width: 500,
    padding: theme.spacing(5),

    [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
        maxHeight: '100%',
    },
}));

const IconContainer = styled('div')(({theme}) => ({
    marginBottom: theme.spacing(1),
    textAlign: 'center',
}));

const StyledIcon = styled(SvgIcon)(() => ({
    fontSize: 70,
    color: grey[400],
}));

const TextContainer = styled('div')(({theme}) => ({
    marginBottom: theme.spacing(5),
    textAlign: 'center',
    fontSize: 18,
}));

interface INoResultsProps {
    text: string;
    onClick(): void;
    icon?: React.ReactNode;
}

export const NoResults = ({text, onClick, icon}: INoResultsProps) => (
    <Container>
        <StyledPaper elevation={3}>
            {icon &&
                <IconContainer>
                    <StyledIcon>{icon}</StyledIcon>
                </IconContainer>
            }

            <TextContainer>
                {text}
            </TextContainer>

            <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                startIcon={<AddCircleOutlineRoundedIcon />}
                onClick={onClick}
            >
                Add your first one now!
            </Button>
        </StyledPaper>
    </Container>
);
