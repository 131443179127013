import * as React from "react";
import {TotalChart} from "../../../Charts/TotalChart";
import {IMonthSnapshotItem} from "../../../../../config/snapshot";

interface IMonthlyAverageProps {
    data: IMonthSnapshotItem[];
}

export const MonthlyAverage = ({data}: IMonthlyAverageProps) => {
    const total = data.reduce((prev, cur) => {
        return prev + cur.total;
    }, 0);

    return (
        <TotalChart total={total ? total / data.length : 0} size="medium" />
    );
};
