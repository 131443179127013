import * as React from "react";
import {ITransaction, ITransactionSplit} from "../../../../config/transactions";
import {convertAmountToFloat} from "../../../../helpers/transaction_helpers";
import {SplitMethod} from "../../Components/Form/Fields/ShareWithFriends/FriendSelector/FriendSelector";
import {Item, Label as Friend, ListContainer, Value as Amount} from "./Components/Lists";
import {styled} from "@mui/material";

const Percentage = styled('div')(() => ({
    width: 40,
    fontSize: '65%',
}));

interface IValueProps {
    transaction: ITransaction;
    split: ITransactionSplit;
}

const Value = ({transaction, split}: IValueProps) => {
    if (split.split_method === SplitMethod.PERCENTAGE) {
        return (
            <>
                <Amount>
                    {((convertAmountToFloat(transaction.original_amount) * split.percentage!! / 100) as any).toCurrency(transaction.original_currency)}
                </Amount>
                <Percentage>
                    ({split.percentage}%)
                </Percentage>
            </>
        )
    } else if (split.split_method === SplitMethod.FIXED) {
        return (
            <>
                <Amount>
                    {(convertAmountToFloat(split.amount!) as any).toCurrency(transaction.original_currency)}
                </Amount>
                <Percentage />
            </>
        )
    }

    return null;
};

interface ISharedWithProps {
    transaction: ITransaction;
}

export const SharedWith = ({transaction}: ISharedWithProps) => {
    return (
        <ListContainer>
            {transaction.splits?.map(s => {
                return (
                    <Item key={s.id}>
                        <Friend>{s.name}</Friend>
                        <Value transaction={transaction} split={s} />
                    </Item>
                )
            })}
        </ListContainer>
    )
};
