import * as React from 'react'
import {EventStoreContext} from "../contexts/event_store_context";

export const useEventStore = () => {
    const store = React.useContext(EventStoreContext);
    if (!store) {
        throw new Error('useEventStore must of type EventStore');
    }

    return store;
};
