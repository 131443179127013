import * as React from "react";
import {Currency, CurrencySymbol} from "../../../config/currencies";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {ReactNode, useEffect, useState} from "react";
import {convertToTranslationKeys, ValidationError} from "../../../services/validator";
import {useTranslation} from "react-i18next";
import {convertAmountToFloat} from "../../../helpers/transaction_helpers";
import {FormControl, FormHelperText, InputAdornment, InputLabel, OutlinedInput} from "@mui/material";

interface IMoneyFieldProps {
    value: string;
    onChange(rawValue: string, value: number): void;
    currency?: Currency;
    autoFocus?: boolean;
    label?: string;
    endAdornment?: ReactNode;
    errors?: ValidationError[];
}

export const MoneyField = ({value, onChange, currency, autoFocus = false, label = 'Amount', endAdornment, errors}: IMoneyFieldProps) => {
    const { t } = useTranslation();
    const { currencyStore } = useGlobalStores();

    if (!currency) {
        currency = currencyStore.currentCurrency;
    }

    const [amount, setAmount] = useState<string>(value);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setAmount(event.target.value);
    };

    useEffect(() => {
        onChange(amount, convertAmountToFloat(amount));
    }, [amount]);

    return (
        <FormControl fullWidth variant="outlined" error={!!errors && !!errors.length}>
            <InputLabel>{label}</InputLabel>
            <OutlinedInput
                // type="number"
                // inputProps={{ min: 0, step: 0.01, pattern: '[0-9]*', inputMode: 'decimal' }}
                // can't be "number" cause safari does not support the "comma" in the field and due to localization,
                // a use could have a non-en_US localization
                type="text"
                inputProps={{ pattern: '[0-9]+([.,][0-9]{1,2})?', inputMode: 'decimal' }}
                value={value}
                onChange={handleChange}
                startAdornment={<InputAdornment position="start">{CurrencySymbol[currency]}</InputAdornment>}
                endAdornment={endAdornment}
                label={label}
                autoFocus={autoFocus}
            />
            {!!errors && !!errors.length &&
                <FormHelperText error>
                    {convertToTranslationKeys(errors).map(e => t(e)).join(', ')}
                </FormHelperText>
            }
        </FormControl>
    );
};
