export const toArray = <T>(v: T | T[]): T[] => {
    if (v instanceof Array) {
        return v
    }

    if (typeof v === 'string' && v === '') {
        return [];
    }

    return [v];
}
