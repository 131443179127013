import * as React from "react";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {SplitMethod} from "./FriendSelector";

interface ISplitMethodFieldProps {
    value: SplitMethod;
    onChange(method: SplitMethod): void;
}

export const SplitMethodField = ({value, onChange}: ISplitMethodFieldProps) => {
    const handleChange = (event: any) => {
        onChange(event.target.value);
    };

    return (
        <FormControl variant="outlined" fullWidth>
            <InputLabel>Split method</InputLabel>
            <Select
                variant="outlined"
                value={value}
                label="Split method"
                onChange={handleChange}
                input={<OutlinedInput label="Split method"/>}
            >
                <MenuItem value={SplitMethod.PERCENTAGE}>Percentage</MenuItem>
                <MenuItem value={SplitMethod.FIXED}>Fixed</MenuItem>
            </Select>
        </FormControl>
    );
};
