import * as React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Paper from '@mui/material/Paper';
import {Box, Grid, Typography, styled} from "@mui/material";
import { CallbackAction, ICallbackAction, labels } from "../../../../../config/user_payment_method_callback";
import { ShareWithFriendsPercentage } from "./Details/ShareWithFriendsPercentage";

const StyledPaper = styled(Paper)(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    marginTop: theme.spacing(2),
}));

interface IDetailsProps {
    value: ICallbackAction;
    onEdit(): void;
    onDelete(): void;
}

export const Details = ({value, onEdit, onDelete}: IDetailsProps) => (
    <Grid item xs={12}>
        <StyledPaper elevation={2}>
            <div>
                <Typography>{labels[value.action]}</Typography>
                <Box>
                    {value.action === CallbackAction.SHARE_WITH_FRIENDS_PERCENTAGE && <ShareWithFriendsPercentage value={value} />}
                </Box>
            </div>

            <div>
                <IconButton onClick={onEdit} size="small" color="primary">
                    <EditIcon />
                </IconButton>
                <IconButton onClick={onDelete}  size="small" color="error">
                    <DeleteIcon />
                </IconButton>
            </div>
        </StyledPaper>
    </Grid>
);
