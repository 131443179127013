import * as React from "react";
import { IBudgetProgress } from "../../../../../config/budget";
import { Grid, Typography } from "@mui/material";
import { Currency } from "../../../../../config/currencies";
import "../../../../../utils/number_extensions"
import { ProgressBar } from "../../../../Components/ProgressBar/ProgressBar";
import {styled} from "@mui/material";
import { useNavigate } from "react-router-dom";

const BudgetName = styled(Typography)(() => ({
    fontWeight: 'bold',
    lineHeight: 0,
    cursor: 'pointer',

    '&:hover': {
        textDecoration: 'underline',
    },
}));

const AmountLeft = styled(Typography)(() => ({
    lineHeight: 0
}));

const AmountUsedContainer = styled(Typography)(() => ({
    lineHeight: 0
}));

const AmountUsed = styled('span')(() => ({
    fontWeight: 'bold',
}));

const AmountTotal = styled('span')(() => ({
    fontSize: 10,
}));

interface IBudgetProgressCardProps {
    budget: IBudgetProgress;
    currency: Currency;
}

export const BudgetProgressCard = ({budget, currency}: IBudgetProgressCardProps) => {
    const navigate = useNavigate();
    const handleClick = (budgetId: number) => () => {
        navigate(`/transactions?budget_id=${budgetId}`);
    };

    return (
        <Grid container spacing={0.5}>
            <Grid item xs={6}>
                <BudgetName variant="button" onClick={handleClick(budget.id)}>{budget.name}</BudgetName>
            </Grid>
            <Grid item xs={6} style={{textAlign: 'right'}}>
                <AmountLeft variant="overline">
                    {budget.amount_left > 0 &&
                        <>
                            {(budget.amount_left as any).toCurrency(currency)} left
                        </>
                    }
                    {budget.amount_left <= 0 &&
                        <>
                            nothing left
                            ({(budget.amount_left as any).toCurrency(currency)})
                        </>
                    }
                </AmountLeft>
            </Grid>

            <Grid item xs={12}>
                <ProgressBar
                    value={budget.amount_used}
                    total={budget.amount}
                    width="fullwidth"
                    height={10}
                    animation
                />
            </Grid>

            <Grid item xs={12}>
                <AmountUsedContainer variant="overline">
                    <AmountUsed>{(budget.amount_used as any).toCurrency(currency)}</AmountUsed>
                    <AmountTotal>{' of '}{(budget.amount as any).toCurrency(currency)}</AmountTotal>
                </AmountUsedContainer>
            </Grid>
        </Grid>
    );
};
