import { DockSection } from "./config";

export const getCurrentSelection = (pathname: string): DockSection => {
    if (
        !!pathname.match(/^\/transactions\/[A-Za-z0-9-]+$/) ||
        !!pathname.match(/^\/transactions\/[A-Za-z0-9-]+\/edit$/)
    ) {
        return DockSection.TRANSACTIONS;
    }

    switch(pathname) {
        case '/':
        case '/dashboard':
            return DockSection.HOME;
        case '/transactions':
            return DockSection.TRANSACTIONS;
        case '/settings':
        case '/settings/general':
            return DockSection.SETTINGS;
    }

    return DockSection.HOME;
};
