import * as React from "react";
import AddIcon from '@mui/icons-material/Add';
import IconButton from "@mui/material/IconButton";
import {NewTransaction} from "../../Transactions/New/NewTransaction";

const NEW_TRANSACTION_LOCATION_HASH = '#newtrns';

interface INewTransactionActionProps {
    component?: React.ReactElement | any;
}

export interface INewTransactionComponentProps {
    onClick(): void;
}

const DefaultComponent = ({onClick}: INewTransactionComponentProps) => (
    <IconButton
        className="new-transaction-button"
        color="inherit"
        onClick={onClick}
        size="large"
    >
        <AddIcon />
    </IconButton>
);

export const NewTransactionAction = ({component}: INewTransactionActionProps) => {
    const [open, setOpen] = React.useState(window.location.hash === NEW_TRANSACTION_LOCATION_HASH);

    const handleOpen = () => {
        setOpen(true);

        window.location.hash = NEW_TRANSACTION_LOCATION_HASH;
    };

    let Button: React.ReactNode | any;
    if (!component) {
        Button = DefaultComponent;
    } else {
        Button = component;
    }

    return (
        <>
            <Button
                className="new-transaction-button"
                onClick={handleOpen}
            />

            <NewTransaction open={open} onClose={() => {setOpen(false)}} />
        </>
    );
};
