export enum TransactionsListField {
    ACTIONS,
    PURCHASED_AT,
    AMOUNT,
    PAYMENT_METHOD,
    MAIN_CATEGORY,
    CATEGORY,
    COMPANY, // deprecated, use LOCATION instead
    LOCATION,
    DESCRIPTION,
    TRANSACTION_ID,
    DESCRIPTIVE_TRANSACTION,
}

export enum TransactionsListAction {
    SELECT,
    EDIT,
    DELETE,
    VIEW,
}
