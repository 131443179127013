import * as React from "react";
import {List} from "@mui/material";
import PlaceIcon from '@mui/icons-material/Place';
import LanguageIcon from '@mui/icons-material/Language';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {ListItemLink} from "../Layout/LeftSideBar/MenuList";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";

interface IAtlasMenuListProps {
    onItemClick: any;
}

export const AtlasMenuList = ({onItemClick}: IAtlasMenuListProps) => (
    <List>
        <ListItemLink label="Atlas" icon={<AdminPanelSettingsIcon />} to="/atlas" onClick={onItemClick} />
        {/*<ListItemLink label="Categories" icon={<SegmentIcon />} to="/atlas/categories" onClick={onItemClick} />*/}
        <ListItemLink label="I18n" icon={<LanguageIcon />} to="/atlas/i18n" onClick={onItemClick} />
        <ListItemLink label="Locations" icon={<PlaceIcon />} to="/atlas/locations" onClick={onItemClick} />
        {/*<ListItemLink label="Payment Methods" icon={<PaymentIcon />} to="/atlas/payments" onClick={onItemClick} />*/}
        {/*<ListItemLink label="Users" icon={<PeopleIcon />} to="/atlas/users" onClick={onItemClick} />*/}
        <ListItemLink label="Back to app" icon={<ArrowBackIcon />} to="/" onClick={onItemClick} />
    </List>
)
