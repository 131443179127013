import * as React from "react";
import "../../../../../utils/number_extensions"
import Chart from "react-google-charts";
import moment from "moment";
import { IBudgetDetails } from "../../../../../config/budget";
import { fillMonths, getChartMaxHeight } from "../../../../../helpers/budget_helpers";
import { useGlobalStores } from "../../../../../hooks/use_global_stores";

interface IBudgetDetailsUsagesProps {
    budget: IBudgetDetails;
}

export const BudgetDetailsUsages = ({budget}: IBudgetDetailsUsagesProps) => {
    const { currencyStore } = useGlobalStores();

    const CHART_HEADER = [
        {type: 'date', label: 'Month'},
        {type: 'number', label: 'Spent'},
        {type: 'number', label: 'Budget amount'},
    ];

    const data = fillMonths(budget, moment()).map((item: any) => {
        const [date, amountUsed, maxAmount] = item;
        return [
            {
                v: date.toDate(),
                f: date.format('MMM YYYY'),
            },
            {
                v: amountUsed,
                f: (amountUsed as any).toCurrency(currencyStore.currentCurrency),
            },
            {
                v: maxAmount,
                f: (maxAmount as any).toCurrency(currencyStore.currentCurrency),
            },
        ];
    });

    const options = {
        vAxes: {
            0: {
                title: 'Amount spent',
                viewWindow: {
                    min: 0,
                    max: getChartMaxHeight(budget),
                }
            }
        },
        seriesType: 'bars',
        series: {
            1: {
                type: 'line',
            },
        },
        titlePosition: 'none',
        legend: {
            position: 'none',
        },
        bar: {
            groupWidth: '10%',
        },
    };
    
    return (
        <div>
            <Chart
                chartType="ComboChart"
                width="100%"
                height="500px"
                data={[CHART_HEADER, ...data]}
                options={options}
            />
        </div>
    )
};
