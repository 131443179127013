import * as React from "react";
import {ValidationError} from "../../../services/validator";
import { FreeTextField } from "./FreeTextField";

interface IPasswordFieldProps {
    label: string;
    value?: string | null;
    onChange: any;
    errors?: ValidationError[];
    inputProps?: object;
}

export const PasswordField = ({value, onChange, label, errors, inputProps}: IPasswordFieldProps) => 
    <FreeTextField
        type="password"
        value={value}
        onChange={onChange}
        label={label}
        errors={errors}
        inputProps={inputProps}
    />
