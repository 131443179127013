import * as React from "react";
import {styled} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";

const Container = styled('div')(({theme}) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
}));

const StyledDiv = styled('div')(({theme}) => ({
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 400,
}));

export const Wrapper = ({children}: { children: React.ReactNode }) => (
    <Container>
        <CssBaseline />
        <StyledDiv>
            {children}
        </StyledDiv>
    </Container>
);
