import moment from "moment";
import {
    IExportPreRequest,
    IExportRequest,
    INewTransaction,
    ITransaction, IUpdateBulk,
    IUpdateTransaction
} from "../config/transactions";
import {Currency} from "../config/currencies";
import {ITransactionFormOutputData} from "../components/Transactions/Components/Form/config/models";
import {convertSplitsFromFormData} from "./transaction_splits_helpers";
import {TransactionFormField} from "../components/Transactions/Components/Form/config/config";
import { CategoryType } from "../config/category";

const LAST_PURCHASED_AT_KEY = 'em:new_transaction:last_purchased_at';
//
// export const buildCompanyFromTransaction = (transaction: ITransaction): ICompany | null => {
//     if (!transaction.company_id || !transaction.company) {
//         return null;
//     }
//
//     return {
//         id: transaction.company_id,
//         name: transaction.company,
//     };
// };

interface ITransactionParsedAmount {
    isSameCurrency: boolean;
    amount: string;
    originalAmount: string;
}

export const parseTransactionAmount = (transaction: ITransaction, currency: Currency): ITransactionParsedAmount => {
    const amount = (transaction.amount as any).toCurrency(currency);

    if (currency === transaction.original_currency) {
        return {
            isSameCurrency: true,
            amount,
            originalAmount: amount,
        };
    }

    const originalAmount = (transaction.original_amount as any).toCurrency(transaction.original_currency);

    return {
        isSameCurrency: true,
        amount,
        originalAmount,
    };
};

// export const convertTransactionToFormInputData = (transaction: ITransaction): ITransactionFormInputData => {
//     return {
//         currency: transaction.original_currency,
//         amount: transaction.original_amount as any,
//         amountDetails: transaction.details?.amount_details || {} as any,
//         purchasedAt: transaction.purchased_at,
//         paymentMethodId: transaction.payment_method_id || '' as any,
//         company: buildCompanyFromTransaction(transaction) || '' as any,
//         categoryId: transaction.category_id || '' as any,
//         description: transaction.description || '',
//         isVisible: true, // missing
//         details: transaction.details,
//         splits: (transaction.splits || []).map(convertSplitsToFormData),
//         tags: transaction.tags || [],
//     };
// };

export const convertFormOutputDataToTransactionForCreate = (data: ITransactionFormOutputData): INewTransaction => {
    return {
        currency: data.currency,
        amount: data.amount,
        purchased_at: data.purchasedAt,
        payment_method_id: data.paymentMethodId,
        category_id: data.categoryId,
        company_id: data.companyId,
        location: data.location,
        description: data.description,
        is_visible: data.isVisible,
        details: data.details,
        splits: data.splits.map(convertSplitsFromFormData),
        tags: data.tags,
        allowances: data.allowances,
        budget_id: data.budgetId,
    };
};

export const convertFormOutputDataToTransactionForUpdate = (data: ITransactionFormOutputData): IUpdateTransaction => {
    return {
        currency: data.currency,
        amount: data.amount,
        purchased_at: data.purchasedAt,
        payment_method_id: data.paymentMethodId,
        category_id: data.categoryId,
        // company_id: data.companyId,
        location: data.location,
        description: data.description,
        is_visible: data.isVisible,
        details: data.details,
        splits: data.splits.map(convertSplitsFromFormData),
        tags: data.tags,
        allowances: data.allowances,
        budget_id: data.budgetId,
    };
};

export const convertFormOutputDataToBulkUpdate = (transactionIds: string[], data: ITransactionFormOutputData, fields: TransactionFormField[]): IUpdateBulk => {
    const res = {
        transaction_ids: transactionIds,
    } as IUpdateBulk;

    if (fields.indexOf(TransactionFormField.PAYMENT_METHOD) > -1) {
        res.payment_method_id = data.paymentMethodId || null;
    }
    if (fields.indexOf(TransactionFormField.CATEGORY) > -1) {
        res.category_id = data.categoryId || null;
    }
    if (fields.indexOf(TransactionFormField.LOCATION) > -1) {
        res.location = data.location || null;
    }
    if (fields.indexOf(TransactionFormField.DESCRIPTION) > -1) {
        res.description = data.description || '';
    }
    if (fields.indexOf(TransactionFormField.DETAILS) > -1) {
        res.tags = !!data.tags?.length ? data.tags : [];
    }
    if (fields.indexOf(TransactionFormField.BUDGET) > -1) {
        res.budget_id = data.budgetId || null;
    }

    return res;
};

export const convertExportPreRequestToExportRequest = (input: IExportPreRequest): IExportRequest => {
    const v = {} as IExportRequest;

    if (input.transaction_ids !== undefined) {
        v.transaction_ids = input.transaction_ids.join(',');
    }

    if (input.include_csv_header !== undefined) {
        v.include_csv_header = input.include_csv_header;
    }

    if (input.include_fields !== undefined) {
        v.include_fields = input.include_fields.join(',');
    }

    return v;
};

export const storeLastPurchasedAt = (date: moment.Moment): void => {
    window.sessionStorage.setItem(LAST_PURCHASED_AT_KEY, date.format('YYYY-MM-DD'));
};

export const getStoredLastPurchasedAt = (): moment.Moment | null => {
    const data = window.sessionStorage.getItem(LAST_PURCHASED_AT_KEY);
    if (!data) {
        return null;
    }

    return moment(data);
};

export const getPage = (locationSearch: string = window.location.search): number => {
    const params = new URLSearchParams(locationSearch);

    if (!params.get('page') || params.get('page') === '') {
        return 1;
    }

    return parseInt(params.get('page') as string) || 1;
};

export const convertAmountToFloat = (amount: string | number): number => {
    if (typeof amount === 'number') {
        return amount;
    }

    if (amount == '') {
        return 0.0;
    }

    return parseFloat(amount.toString().replace(',', '.'));
};

export const getDefaultPurchasedAtDate = (): moment.Moment => {
    const lastPurchasedAt = getStoredLastPurchasedAt();
    if (lastPurchasedAt) {
        return lastPurchasedAt;
    }

    return moment();
}

export const optimizeTransactionItem = (item: any) => {
    item.purchased_at = moment(item.purchased_at);
    return item;
};

export const getDisplayLabel = (transaction: ITransaction): string => {
    if (!!transaction.category_id && transaction.category_id in CategoryType) {
        switch (transaction.category_id) {
            case CategoryType.CAR_WASH:
                return `Car wash${!!transaction.location ? ` at ${transaction.location.name}` : ''}`;
        }
    }

    if (!!transaction.location && !!transaction.category_id && transaction.category_id in CategoryType) {
        switch (transaction.category_id) {
            case CategoryType.BREAKFAST:
            case CategoryType.LUNCH:
            case CategoryType.DINNER:
            case CategoryType.SNACKS:
            case CategoryType.DRINKS:
                return `${transaction.category} at ${transaction.location.name}`;

            case CategoryType.ONLINE_SHOPPING:
                return `${transaction.category} on ${transaction.location.name}`;

            case CategoryType.VEHICLE_FOR_HIRE:
                return `Ride with ${transaction.location.name}`;
        }

        return `${transaction.category} at ${transaction.location.name}`;
    }

    if (transaction.company_id && transaction.category_id && transaction.category_id in CategoryType) {
        if (transaction.category_id === CategoryType.ONLINE_SHOPPING) {
            return `${transaction.category} on ${transaction.company}`;
        }

        return `${transaction.category} at ${transaction.company}`;
    }

    if (!!transaction.description) {
        return transaction.description;
    }

    if (!!transaction.tags && !!transaction.tags.length) {
        return transaction.tags.map(t => `#${t}`).join(', ');
    }

    return `Transaction ${transaction.transaction_id}`;
};
