import * as React from "react";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import { Form } from "../../../Components/Form/Form/Form";
import { SelectField } from "../../../Components/Form/SelectField";

export const IncomeField = (props: any) => {
    const { incomeStore } = useGlobalStores();

    return (
        <Form.Field
            type={SelectField}
            options={incomeStore.incomes}
            optionValueGetter={(p: any) => p.id}
            optionLabelGetter={(p: any) => p.name}
            {...props}
            label="Income"
        />
    );
};
