import * as React from "react";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {styled} from "@mui/material";
import "../../../utils/number_extensions";

interface ITotalChartProps {
    total: number;
    size: "medium" | "large";
}

interface IContainer {
    size: "medium" | "large";
}
const Container = styled('div')<IContainer>(({size}) => ({
    fontSize: size === "medium" ? 28 : 46,
}));

export const TotalChart = ({total, size}: ITotalChartProps) => {
    const { currencyStore } = useGlobalStores();

    return (
        <Container size={size}>
            {(total as any).toCurrency(currencyStore.currentCurrency)}
        </Container>
    );
};
