import * as React from "react";
import {Avatar, styled} from "@mui/material";
import Box from "@mui/material/Box";
import {deepPurple} from "@mui/material/colors";

const Container = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    ...theme.mixins.toolbar,
}));

const StyledAvatar = styled(Avatar)(({ theme }) => ({
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
    textTransform: 'uppercase',
}));

interface IUserAvatarProps {
    user: string;
}

export const UserAvatar = ({user}: IUserAvatarProps) => (
    <Container>
        <StyledAvatar>{user}</StyledAvatar>
    </Container>
)
