import * as React from "react";
import {Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, styled} from "@mui/material";
import {ItemsSection} from "./Components/ItemsSection";
import { IAttachment } from "../../../../config/attachments";
import { Icon } from "../../Components/Form/Fields/Attachments/Icon";

const StyledListItem = styled(ListItem)(() => ({
    '&:last-of-type': {
        paddingBottom: 0,
    },
}));


interface IAttachmentsProps {
    attachments: IAttachment[];
}

export const Attachments = ({attachments}: IAttachmentsProps) => {
    const openAttachment = (filePath: string) => () => {
        window.open(filePath, '_blank', 'noreferrer');
    };

    return (
        <Grid item xs={12}>
            <ItemsSection title="Attachments">
                <List sx={{marginLeft: 1}}>
                    {attachments.map((a, index) => {
                        return (
                            <StyledListItem disablePadding key={index}>
                                <ListItemButton onClick={openAttachment(a.full_path)}>
                                    <ListItemIcon sx={{minWidth: 0, paddingRight: 1.5}}>
                                        <Icon type={a.type} />
                                    </ListItemIcon>
                                    <ListItemText primary={`Attachment #${a.name || index + 1}`} />
                                </ListItemButton>
                            </StyledListItem>
                        );
                    })}
                </List>
            </ItemsSection>
        </Grid>
    );
}
