import * as React from "react";
import {RecurringExpensesTimeFrequencies} from "../../../../config/recurring_expense";
import {ValidationError} from "../../../../services/validator";
import {SelectField} from "../../../Components/Form/SelectField";

interface ITimeFrequencyFieldProps {
    value: string;
    onChange: any;
    errors?: ValidationError[];
}

export const TimeFrequencyField = ({value, onChange, errors}: ITimeFrequencyFieldProps) => (
    <SelectField
        label="Frequency"
        options={RecurringExpensesTimeFrequencies}
        value={value}
        onChange={onChange}
        optionValueGetter={o => o.code}
        optionLabelGetter={o => o.name}
        errors={errors}
    />
);
