import React, { useState } from "react";
import {observer} from "mobx-react";
import CssBaseline from '@mui/material/CssBaseline';
import Box from "@mui/material/Box";
import {ConfirmationSnackbar} from "../Components/ConfirmationSnackbar";
import {alpha, styled, useMediaQuery, useTheme} from "@mui/material";
import { grey } from "@mui/material/colors";
import { isIphone } from "../../services/user_agent_helper";
import { AppBarSpacer } from "./Components/AppBarSpacer";
import { Header } from "./Header/Header";
import { Dock } from "./Dock/Dock";
import { LeftSideBar } from "./LeftSideBar/LeftSideBar";

interface IRootContainerProps {
    children: React.ReactNode;
}

interface IContainer {
    isIphone: boolean;
}
const Container = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'isIphone',
})<IContainer>(({ theme, isIphone }) => ({
    display: 'flex',
    backgroundColor: alpha(grey[100], 0.6),

    [theme.breakpoints.down('sm')]: {
        marginBottom: theme.spacing(8),
    },

    ...isIphone? {
        '*': {
            WebkitUserSelect: 'none',
        },
    } : {},
}));

interface IMain {
    mobile: boolean;
}
const Main = styled('main')<IMain>(({theme, mobile}) => ({
    flexGrow: 1,
    overflow: 'auto',
    width: '100%',
    height: !mobile ? '100vh' : '-webkit-fill-available',
}));

const RootContainerComponent = ({children}: IRootContainerProps) => {
    const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('sm'));

    const [menuOpen, setMenuOpen] = useState<boolean>(false);

    const handleMenuOpen = () => {
        setMenuOpen(true);
    };

    const handleMenuClose = () => {
        setMenuOpen(false);
    };

    return (
        <Container isIphone={isIphone}>
            <CssBaseline />

            <Header mobile={isIphone} onMenuOpen={handleMenuOpen} isSmallScreen={isSmallScreen} />
            <LeftSideBar open={menuOpen} onClose={handleMenuClose} />

            <Main mobile={isIphone}>
                <AppBarSpacer />

                {children}
            </Main>
            <Dock onMenuOpen={handleMenuOpen} />

            <ConfirmationSnackbar />
        </Container>
    );
};

export const RootContainer = observer(RootContainerComponent);
