import * as React from "react";
import {Button} from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface IAddNewButtonProps {
    onClick(): void;
}

export const AddNewButton = ({onClick}: IAddNewButtonProps) => (
    <Button
        fullWidth={false}
        variant="contained"
        color="primary"
        startIcon={<AddCircleIcon />}
        onClick={onClick}
        sx={{marginRight: (theme) => theme.spacing(1)}}
    >
        Add new allowance
    </Button>
);
