import * as React from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import {
    deleteRecurringExpense,
    toggleActiveRecurringExpense
} from "../../../../stores/recurring_expense";
import {IRecurringExpense} from "../../../../config/recurring_expense";
import {SnackbarStore} from "../../../../stores/snackbar";
import Button from "@mui/material/Button";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {useRecurringExpenseStore} from "../../../../hooks/use_recurring_expense_store";
import {ConfirmationDialogWithTrigger} from "../../../Components/ConfirmationDialogWithTrigger";

enum DeleteActionMode {
    DELETE,
    SUSPEND,
}

interface IDeleteActionProps {
    recurringExpense: IRecurringExpense;
}

export const DeleteAction = ({recurringExpense}: IDeleteActionProps) => {
    const { snackbarStore } = useGlobalStores();
    const recurringExpenseStore = useRecurringExpenseStore();

    const [loading, setLoading] = React.useState(false);

    const handleSubmit = (mode: DeleteActionMode, closeDialog: any) => () => {
        closeDialog();
        setLoading(true);

        const successfulCallbackForDelete = () => {
            snackbarStore.open(SnackbarStore.SUCCESS, 'Recurring Expense deleted successfully');
            recurringExpenseStore.removeRecurringExpense(recurringExpense);
            recurringExpenseStore.shouldStatsBeReloaded = true;

            setLoading(false);
        };

        const successfulCallbackForSuspend = (recurringExpense: IRecurringExpense) => {
            snackbarStore.open(SnackbarStore.SUCCESS, 'Recurring Expense suspended successfully');
            recurringExpenseStore.replaceRecurringExpense(recurringExpense);
            recurringExpenseStore.shouldStatsBeReloaded = true;

            setLoading(false);
        };

        const unsuccessfulCallback = () => {
            setLoading(false);

            snackbarStore.open(SnackbarStore.FAIL);
        };

        if (mode === DeleteActionMode.DELETE) {
            deleteRecurringExpense(recurringExpense.id)
                .then(successfulCallbackForDelete)
                .catch(unsuccessfulCallback);
        } else if (mode === DeleteActionMode.SUSPEND) {
            toggleActiveRecurringExpense(recurringExpense.id, false)
                .then(successfulCallbackForSuspend)
                .catch(unsuccessfulCallback);
        }
    };

    return (
        <ConfirmationDialogWithTrigger
            icon={<DeleteIcon />}
            loading={loading}
            title="Delete recurring expense"
            description={<React.Fragment>
                Are you sure to delete this recurring expense?<br />
                <strong>The operation cannot be undone.</strong><br /><br />
                How about suspending it? When a recurring expense is suspended it's counted anymore but can resume it any time you want.
            </React.Fragment>}
            actions={({closeDialog}) => (
                [
                    <Button onClick={handleSubmit(DeleteActionMode.SUSPEND, closeDialog)} color="primary" key="suspend">
                        Suspend it
                    </Button>,
                    <Button onClick={handleSubmit(DeleteActionMode.DELETE, closeDialog)} color="error" key="delete">
                        Delete it
                    </Button>
                ]
            )}
        />
    );
};
