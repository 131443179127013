import * as React from "react";
import {Button, Grid} from "@mui/material";
import AddCircleIcon from '@mui/icons-material/AddCircle';

interface IAddNewButtonProps {
    onClick(): void;
}

export const AddNewButton = ({onClick}: IAddNewButtonProps) => (
    <Grid item xs={12}>
        <Button
            fullWidth={false}
            variant="contained"
            color="primary"
            startIcon={<AddCircleIcon />}
            onClick={onClick}
        >
            Add new automation
        </Button>
    </Grid>
);
