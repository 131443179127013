import {styled} from "@mui/material";

export const PageOuterContainer = styled('div')(({theme}) => ({
    padding: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
    },
}));

export const PageOuterContainerCancelled = styled('div')(({theme}) => ({
    margin: theme.spacing(-3),

    [theme.breakpoints.down('sm')]: {
        margin: theme.spacing(-1),
    },
}));
