import {ITransaction, ITransactionDetailsData} from "../../../../../config/transactions";
import {ITransactionFormState} from "../TransactionForm";
import {convertAmountToFloat, getDefaultPurchasedAtDate} from "../../../../../helpers/transaction_helpers";
import {DetailsLocalStore} from "../../../../../helpers/transaction_details_helpers";
import {Currency} from "../../../../../config/currencies";
import {IAttachmentsFormRequest, ITransactionFormOutputData} from "./models";
import {IUser} from "../../../../../config/user";
import {IAmountDetails} from "../Fields/AmountDetailsField";
import {TransactionDetailFields} from "../../../../../config/transaction_details";
import { IAttachmentChanges } from "../../../../../config/attachments";
import { toExport } from "../../../../../helpers/transaction_details_vehicle_for_hire_helpers";

export const toForm = (user: IUser, t?: ITransaction): ITransactionFormState => {
    if (!t) {
        return {
            currency: user.default_currency || Currency.EUR,
            amount: '',
            purchasedAt: getDefaultPurchasedAtDate(),
            paymentMethodId: user.default_payment_method_id?.toString() || '',
            categoryId: '',
            allowances: [],
            splits: [],
            location: undefined,
        };
    }

    return {
        currency: t.original_currency,
        amount: t.original_amount?.toString() || '',
        purchasedAt: t.purchased_at,
        amountDetails: t.details?.amount_details || undefined,
        paymentMethodId: t.payment_method_id?.toString() || '',
        company: t.company && t.company_id ? {
            id: t.company_id,
            name: t.company,
        } : undefined,
        location: t.location || undefined,
        categoryId: t.category_id?.toString() || '',
        description: t.description || '',
        isVisible: true,
        details: DetailsLocalStore.Init(t.details || {}).withTags(t.tags || []).get(),
        splits: t.splits || [],
        tags: t.tags || [],
        allowances: t.allowances || [],
        budgetId: t.budget_id?.toString() || '',
    };
};

export const toFormRequest = (input: ITransactionFormState): ITransactionFormOutputData => {
    // get only the details that are not null or undefined. if there are only null or undefined, a null is returned
    const getDetails = () => {
        const data = {} as any;

        const details = input.details || {} as ITransactionDetailsData | any;
        if (!!details.tags) {
            delete details.tags;
        }

        Object.keys(details)
            .filter(k => (
                details[k] !== null ||
                details[k] !== undefined ||
                (typeof details[k] === 'object' && Object.keys(details[k]).length > 0)
            )).forEach(k => {
            data[k] = details[k];
        });

        if (hasAmountDetails(input.amountDetails || null)) {
            data[TransactionDetailFields.AMOUNT_DETAILS] = input.amountDetails;
        }

        if (!!input.details?.fuel_details) {
            const v = input.details!.fuel_details!;
            v.amount = convertAmountToFloat(v.amount);

            data[TransactionDetailFields.FUEL_DETAILS] = v
        }

        if (!!input.details?.vehicle_for_hire) {
            const v = input.details!.vehicle_for_hire!;

            data[TransactionDetailFields.VEHICLE_FOR_HIRE] = toExport(v);
        }

        return Object.keys(data).length ? data : {};
    };

    return {
        currency: input.currency,
        amount: convertAmountToFloat(input.amount),
        purchasedAt: input.purchasedAt.format("YYYY-MM-DD"),
        paymentMethodId: input.paymentMethodId ? parseInt(input.paymentMethodId, 10) : null,
        categoryId: input.categoryId ? parseInt(input.categoryId, 10) : null,
        companyId: input.company?.id || null,
        location: input.location || null,
        description: input.description || null,
        isVisible: input.isVisible || true,
        details: getDetails(),
        splits: input.splits || [],
        tags: input.tags || null,
        allowances: input.allowances?.map(v => ({
            id: v.id,
            allowance_id: v.allowance_id,
            amount: convertAmountToFloat(v.amount),
        })) || null,
        budgetId: input.budgetId ? parseInt(input.budgetId, 10) : null,
    };
};

export const toAttachmentsFormRequest = (input: IAttachmentChanges): IAttachmentsFormRequest => {
    const data = {} as IAttachmentsFormRequest;

    if (!!input.newAttachments) {
        data.newAttachments = input.newAttachments;
    }

    if (!!input.deletedAttachments) {
        data.deleteAttachments = input.deletedAttachments.map(id => {
            return {
                fileId: id,
            };
        });
    }

    return data;
};

const hasAmountDetails = (values: IAmountDetails | null): boolean => {
    return !!values && JSON.stringify(values) !== '{}';
}
