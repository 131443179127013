import * as React from "react";
import {ITransaction} from "../../../../../config/transactions";
import { DataTable } from "../../../../Components/DataTable";
import { TableCell, styled } from "@mui/material";

const NameCell = styled(TableCell)(() => ({
    paddingLeft: 0,
    width: 130,
}));

const AmountCell = styled(TableCell)(() => ({
}));

interface IAmountDetailsProps {
    transaction: ITransaction;
}

const labels = {
    goodPrice: 'Good Price',
    shippingFee: 'Shipping Fees',
    customsFee: 'Customs Fees',
    otherFee: 'Other Fees',
} as any;

export const AmountDetails = ({transaction}: IAmountDetailsProps) => {
    return (
        <DataTable
            hideHeader
            size="small"
            items={Object.keys(transaction.details?.amount_details).map(item => ({
                name: labels[item],
                amount: transaction.details?.amount_details[item],
            }))}
            config={[
                {
                    title: 'Name',
                    fieldName: 'name',
                    component: NameCell,
                },
                {
                    title: 'Amount',
                    fieldName: 'amount',
                    component: AmountCell,
                    render(value: number, item: ITransaction): any {
                        return (value.toCurrency(transaction.original_currency, {}));
                    }
                },
            ]}
        />
    )
};
