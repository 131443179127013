import {IErrorResponseItem, ValidationError, ValidationErrors} from "../services/validator";

export enum ChangePasswordValidationField {
    CURRENT_PASSWORD = "UpdatePassword.CurrentPassword",
    NEW_PASSWORD = "UpdatePassword.NewPassword",
    NEW_PASSWORD_CONFIRMATION = "UpdatePassword.PasswordConfirmation",
}

export type ChangePasswordValidation = {
    [key in ChangePasswordValidationField]: ValidationError[];
};

export const createRecurringExpensesValidation = (): ValidationErrors<ChangePasswordValidation, ChangePasswordValidationField> => {
    const v = new ValidationErrors<ChangePasswordValidation, ChangePasswordValidationField>();
    v.setMap({
        current_password: ChangePasswordValidationField.CURRENT_PASSWORD,
        new_password: ChangePasswordValidationField.NEW_PASSWORD,
        new_password_confirmation: ChangePasswordValidationField.NEW_PASSWORD_CONFIRMATION,
    })
    v.setKeyParser((key) => {
        return key
            .replace('NewRecurringExpense', 'RecurringExpense')
            .replace('UpdateRecurringExpense', 'RecurringExpense');
    });

    return v;
};

export const convertFromResponse = (errors: IErrorResponseItem[]): ValidationErrors<ChangePasswordValidation, ChangePasswordValidationField> => {
    const data = createRecurringExpensesValidation();

    errors.forEach(e => {
        data.add(e.key as any, e.validator as any);
    });

    return data;
};
