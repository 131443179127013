import * as React from "react";
import { Section } from "../Components/Section";
import { ITransaction } from "../../../../../config/transactions";
import { Business, Place } from "@mui/icons-material";

interface ILocationProps {
    transaction: ITransaction;
}

export const Location = ({transaction}: ILocationProps) => (
    <Section title="Location" sections={[
        {
            title: 'Name',
            value: transaction.location!.name,
            icon: <Business />,
        },
        ...!!transaction.location!.address ? [
            {
                title: 'Address',
                value: transaction.location!.address,
                icon: <Place />,
            },
        ] : []
    ]} />
);
