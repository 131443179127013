import {fetchEgo, UserStore} from "../stores/users";
import {AuthStore, authUser, IAuthRequest, IAuthSuccessfulResponse} from "../stores/auth";
import { invalidate } from "../stores/global_refresh_store";
import { RefreshItem } from "../config/global_refresh";

export class LoginService {
    private authStore: AuthStore;
    private userStore: UserStore;

    constructor(
        authStore: AuthStore,
        userStore: UserStore,
    ) {
        this.authStore = authStore;
        this.userStore = userStore;
    }

    auth(data: IAuthRequest, rememberMe: boolean): Promise<boolean | Response> {
        return authUser(data)
            .then((response: IAuthSuccessfulResponse) => {
                this.authStore.setRememberMe(rememberMe);
                this.authStore.setAccessToken(response.access_token);

                return Promise.all([
                    fetchEgo({include: []}),
                ]);
            })
            .then((result: any) => {
                const [user] = result;
                
                this.userStore.setUser(user);

                return Promise.resolve(true);
            });
    }
}

export const logout = (): Promise<() => void> => {
    return invalidate(RefreshItem.ALL).then(() => {
        return () => {
            window.localStorage.clear();
            window.location.href = '/';    
        };
    });
};
