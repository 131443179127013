import * as React from 'react'
import { TransactionFormStoreContext } from '../contexts/transaction_form_store_context copy';

export const useTransactionFormStore = () => {
    const store = React.useContext(TransactionFormStoreContext);
    if (!store) {
        throw new Error('useTransactionFormStore must be of type ITransactionFormStore');
    }

    return store;
};
