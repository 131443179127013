import * as React from "react";
import {FieldWrapper} from "./FieldWrapper";
import {FreeTextField} from "../../../../../../Components/Form/FreeTextField";

interface IOrderedOnPlatformFieldProps {
    value: string;
    onChange(value: string): void;
    onDelete(): void;
}

export const OrderedOnPlatformField = ({value, onChange, onDelete}: IOrderedOnPlatformFieldProps) => (
    <FieldWrapper onDelete={onDelete}>
        <FreeTextField value={value} onChange={onChange} label="Ordered on" />
    </FieldWrapper>
);
