import * as React from "react";
import {Button} from "@mui/material";
import {useState} from "react";
import {FormModal} from "../../Components/FormModal";
import {CombinedView} from "./CombinedView";
import {IStatsCategory} from "../../../config/stats";

interface ICategoriesDetailsProps {
    label?: string;
    categories: IStatsCategory[];
}

export const CategoriesDetails = ({categories, label}: ICategoriesDetailsProps) => {
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button
                size="small"
                onClick={handleOpen}
            >
                {label} Details
            </Button>

            <FormModal maxWidth="xl" title="Categories details" open={open} loading={false} onClose={handleClose}>
                <CombinedView categories={categories} />
            </FormModal>
        </>
    );
};
