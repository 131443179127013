import * as React from "react";
import {styled} from "@mui/material";
import {ITransaction} from "../../../../../config/transactions";
import {TagsField} from "./TagsField";

const Container = styled('div')(({theme}) => ({
    [theme.breakpoints.down('xs')]: {
        display: 'none',
    },
}));

const Separator = styled('div')(({theme}) => ({
    paddingBottom: theme.spacing(0.5),
}));

interface IDescriptionFieldProps {
    transaction: ITransaction;
}

export const DescriptionField = ({transaction}: IDescriptionFieldProps) => {
    return (
        <Container>
            {!!transaction.description && transaction.description}
            {!!transaction.description && !!transaction.tags && <Separator />}
            {!!transaction.tags && <TagsField tags={transaction.tags} />}
        </Container>
    );
};
