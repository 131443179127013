import * as React from "react";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {Add} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {FormModal} from "../../../Components/FormModal";
import {FreeTextField} from "../../../Components/Form/FreeTextField";
import {SelectField} from "../../../Components/Form/SelectField";
import {transformToMuiSelectFormat} from "../../../../helpers/payment_method_helpers";
import {createUserPaymentMethod} from "../../../../stores/user_payment_methods";
import {SnackbarStore} from "../../../../stores/snackbar";
import {toFormRequest} from "../../../../helpers/user_payment_method_helpers";
import {IPaymentMethod} from "../../../../config/payment_method";
import { Form } from "../../../Components/Form/Form/Form";
import { useModal } from "../../../../hooks/use_modal";
import { useForm } from "../../../../hooks/use_form_v2";

export interface INewPaymentMethodState {
    paymentMethodId?: string;
    name?: string;
}

interface INewActionProps {
    ctaRef?: React.Ref<any>;
    hideCta?: boolean;
}

export const NewAction = ({ctaRef, hideCta = false}: INewActionProps) => {
    const { snackbarStore, paymentMethodStore, userPaymentMethodStore } = useGlobalStores();

    const { open, handleModalOpen, handleModalClose } = useModal();
    const form = useForm<INewPaymentMethodState>();
    const { register } = form;

    const handleSubmit = (values: INewPaymentMethodState) => {
        return createUserPaymentMethod(toFormRequest(values!))
            .then((res) => {
                userPaymentMethodStore.addPaymentMethod(
                    {
                        ...res,
                        type: (paymentMethodStore.getById(res.payment_method_id) as IPaymentMethod).code || 'OTHER',
                    },
                    true
                );

                snackbarStore.open(SnackbarStore.SUCCESS, 'New payment method added successfully!');
                handleModalClose();
            })
            .catch(reason => {
                snackbarStore.open(SnackbarStore.FAIL);
                return Promise.reject(reason);
            });
    };

    return (
        <>
            <FormModal
                title="Add new payment method"
                open={open}
                loading={false}
                onClose={handleModalClose}
            >
                <Form form={form} onSubmit={handleSubmit}>
                    <Form.Field
                        type={SelectField}
                        {...register('paymentMethodId')}
                        options={transformToMuiSelectFormat(paymentMethodStore.paymentMethods)}
                        optionValueGetter={(o: any) => o.value}
                        optionLabelGetter={(o: any) => o.label}
                        label="Type"
                    />
                    <Form.Field type={FreeTextField} {...register('name')} />
                </Form>
            </FormModal>

            <Button
                sx={{display: hideCta ? 'none' : 'auto'}}
                ref={ctaRef}
                size="small"
                startIcon={<Add />}
                onClick={handleModalOpen}
            >
                Add new payment method
            </Button>
        </>
    );
};
