import * as React from "react";
import FormControl from "@mui/material/FormControl";
import {MuiChipsInput} from "mui-chips-input";

interface ITransactionFilterProps {
    value: string[];
    onChange(excludedTransactions: string[]): void;
}

export const ExcludeTransactionsFilter = ({value, onChange}: ITransactionFilterProps) => {
    return (
        <FormControl fullWidth>
            <MuiChipsInput
                value={value}
                onChange={onChange}
                variant="outlined"
                label="Exclude transactions (ID)"
                fullWidth
            />
        </FormControl>
    );
}
