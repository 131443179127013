import * as React from "react";
import { Box, Button, Typography, alpha } from "@mui/material";
import { LEVELS, LEVEL_LABELS, Level } from "../../../../config/auth";
import {styled} from "@mui/material";
import { Feature, features } from "../../../../config/features";
import { useGlobalStores } from "../../../../hooks/use_global_stores";
import { Close, Done } from "@mui/icons-material";
import { Currency } from "../../../../config/currencies";
import { LevelSelection } from "./LevelSelection";
import { featureNotSelected, featuredSelected } from "../../../../helpers/pro";

const Container = styled(Box)(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',

    border: 'solid 4px',
    borderColor: theme.palette.primary.main,
    borderRadius: 8,
}));

const Line = styled(Box)(({ theme }) => ({
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    padding: theme.spacing(1.5),
}));


const LineItem = styled(Line, {
    shouldForwardProp: (prop) => prop !== 'selected',
})<{selected?: boolean;}>(({selected, theme}) => ({
    padding: theme.spacing(1),
    
    ...!!selected ? {
        backgroundColor: alpha(theme.palette.warning.light, 0.15),
        border: 'solid 1px',
        borderColor: theme.palette.warning.light,
        margin: 2,
    
        '&:hover': {
            backgroundColor: alpha(theme.palette.warning.light, 0.3),
        },
    } : {
        '&:nth-of-type(odd)': {
            backgroundColor: theme.palette.grey[100],
        },
    
        '&:hover': {
            backgroundColor: alpha(theme.palette.primary.main, 0.1),
        },
    },
}));

const ListItems = styled(Box)(({ theme }) => ({
    height: 500,
    overflowX: 'scroll',
}));

const Header = styled(Line)(({ theme }) => ({
    backgroundColor: theme.palette.primary.main,
}));

const Item = styled(Box)(({ theme }) => ({
    width: 240,
    textAlign: 'right',
}));

const Value = styled(Box)(({ theme }) => ({
    width: 150,
    textAlign: 'center',
    cursor: 'default',
}));

const Price = styled(Typography)(({ theme }) => ({
    display: 'block',
    fontSize: 30,
    fontWeight: 'bold',
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
}));

const FeatureName = styled(Typography)(({ theme }) => ({
    display: 'block',
    paddingRight: theme.spacing(2),
}));

const Title = styled(Typography)(({ theme }) => ({
    marginTop: theme.spacing(-1),
    display: 'block',
    fontSize: 12,
    color: theme.palette.primary.contrastText,
    textAlign: 'center',
}));

interface IFeaturesListProps {
    selectedFeature?: Feature;
}

export const FeaturesList = ({selectedFeature}: IFeaturesListProps) => {
    const { authStore } = useGlobalStores();

    const [containerElement, setContainerElement] = React.useState<HTMLElement>();
    const [levelElement, setLevelElement] = React.useState<HTMLElement>();

    React.useEffect(() => {
        const containerElements = document.getElementsByClassName('features-list-container');
        if (!!containerElements.length) {
            setContainerElement(containerElements[0] as HTMLElement);
        }

        const titleElements = document.getElementsByClassName(`features-list-title-${authStore.accessToken?.getLevel()}`);
        if (!!titleElements.length) {
            setLevelElement(titleElements[0] as HTMLElement);
        }
    }, []);

    return (
        <Container className="features-list-container">
            {!!containerElement && !!levelElement && (
                <LevelSelection
                    left={levelElement.offsetLeft}
                    height={containerElement.offsetHeight}
                />
            )}

            <Header>
                <Item />
    
                <Value className={`features-list-title-${Level.BASIC}`}>
                    <Price>FREE</Price>
                    <Title variant="button">{LEVEL_LABELS[Level.BASIC]}</Title>
                </Value>
    
                <Value className={`features-list-title-${Level.NO_ADS}`}>
                    <Price>{(0.99).toCurrency(Currency.EUR, {})}</Price>
                    <Title variant="button">{LEVEL_LABELS[Level.NO_ADS]}</Title>
                </Value>
    
                <Value className={`features-list-title-${Level.PRO}`}>
                    <Price>{(2.99).toCurrency(Currency.EUR, {})}</Price>
                    <Title variant="button">{LEVEL_LABELS[Level.PRO]}</Title>
                </Value>
    
                <Value className={`features-list-title-${Level.PLATINUM}`}>
                    <Price>{(4.99).toCurrency(Currency.EUR, {})}</Price>
                    <Title variant="button">{LEVEL_LABELS[Level.PLATINUM]}</Title>
                </Value>
            </Header>

            <ListItems>
                {[
                    ...(!!selectedFeature ? Object.values(features).filter(featuredSelected(selectedFeature)) : []),
                    ...Object.values(features).filter(featureNotSelected(selectedFeature)),
                ].map((feature: any, index: any) => (
                    <LineItem key={index} selected={!!selectedFeature ? index === 0 : false}>
                        <Item>
                            <FeatureName>{feature.label}</FeatureName>
                        </Item>
        
                        {LEVELS.map((level, index) => (
                            <Value key={index}>
                                {!!feature.amounts ?
                                    (feature.amounts[level]) :
                                    (feature.levels?.indexOf(level) > -1 ? <Done sx={(theme) => ({color: theme.palette.primary.main})} /> : <Close sx={(theme) => ({color: theme.palette.grey[400]})} />)
                                }
                            </Value>
                        ))}
                    </LineItem>
                ))}

                <Line>
                    <Item />
        
                    {LEVELS.map((level, index) => (
                        <React.Fragment key={index}>
                            {level === authStore.accessToken?.getLevel() ?
                                (
                                    <Value />
                                ) :
                                (
                                    <Value>
                                        <Button variant="contained" color="primary">Choose</Button>
                                    </Value>
                                )
                            }
                        </React.Fragment>
                    ))}
                </Line>
            </ListItems>
        </Container>
    );    
}