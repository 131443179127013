import {IErrorResponseItem, ValidationError, ValidationErrors} from "../services/validator";
import {CurrenciesAllowed} from "../config/currencies";
import {ITransactionFormOutputData} from "../components/Transactions/Components/Form/config/models";

export enum TransactionValidationField {
    AMOUNT = "Transaction.Amount",
    CURRENCY = "Transaction.Currency",
    PURCHASED_AT = "Transaction.PurchasedAt",
    FUEL_AMOUNT = "Transaction.Details.Fuel.Amount",
    VEHICLE_FOR_HIRE_DISTANCE_UNIT = "Transaction.Details.VehicleForHire.DistanceUnit",
    VEHICLE_FOR_HIRE_DISTANCE = "Transaction.Details.VehicleForHire.Distance",
    VEHICLE_FOR_HIRE_PICKUP_ADDRESS = "Transaction.Details.VehicleForHire.PickupAddress",
    VEHICLE_FOR_HIRE_PICKUP_TIME = "Transaction.Details.VehicleForHire.PickupTime",
    VEHICLE_FOR_HIRE_DROP_OFF_TIME = "Transaction.Details.VehicleForHire.DropOffTime",
    VEHICLE_FOR_HIRE_DROP_OFF_ADDRESS = "Transaction.Details.VehicleForHire.DropOffAddress",
}

export type TransactionValidation = {
    [key in TransactionValidationField]?: ValidationError[];
};

export type TransactionValidationErrors = ValidationErrors<TransactionValidation, TransactionValidationField>;

export const transactionsValidationFactory = (errors: IErrorResponseItem[]): TransactionValidationErrors => {
    const v = new ValidationErrors<TransactionValidation, TransactionValidationField>();
    v.setMap({
        amount: TransactionValidationField.AMOUNT,
        currency: TransactionValidationField.CURRENCY,
        purchasedAt: TransactionValidationField.PURCHASED_AT,
    });
    v.setKeyParser((key) => {
        return key
            .replace('NewTransaction', 'Transaction')
            .replace('UpdateTransaction', 'Transaction');
    });
    v.setErrors(errors)

    return v;
};

export const validateTransactionForm = (data: ITransactionFormOutputData): TransactionValidationErrors => {
    const errors = transactionsValidationFactory([]);

    if (CurrenciesAllowed.indexOf(data.currency) === -1) {
        errors.add(TransactionValidationField.CURRENCY, ValidationError.CURRENCY_NOT_ALLOWED);
    }
    if (!data.amount || isNaN(data.amount)) {
        errors.add(TransactionValidationField.AMOUNT, ValidationError.REQUIRED);
    }
    if (data.purchasedAt === '') {
        errors.add(TransactionValidationField.PURCHASED_AT, ValidationError.REQUIRED);
    }

    return errors;
};
