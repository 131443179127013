export enum TransactionFormScope {
    NEW,
    EDIT,
    BULK_EDIT,
}

export enum TransactionFormField {
    UPLOAD_RECEIPT,
    CURRENCY,
    AMOUNT,
    AMOUNT_SHARING,
    AMOUNT_DETAILS,
    AMOUNT_ALLOWANCES,
    DATE,
    PAYMENT_METHOD,
    COMPANY,
    CATEGORY,
    DESCRIPTION,
    VISIBILITY,
    DETAILS,
    LOCATION,
    BUDGET,
    ATTACHMENTS,
}
