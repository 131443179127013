import {action, makeObservable, observable} from "mobx";
import {IPaymentMethod} from "../config/payment_method";
import {fetchGet} from "../services/api";

const PAYMENT_METHODS_CACHE_KEY = 'em:payment_methods:payment_methods';

export class PaymentMethodStore {
    @observable paymentMethods: IPaymentMethod[] = [];
    @observable shouldBePopulated: boolean = true;

    constructor() {
        makeObservable(this);

        const data = window.localStorage.getItem(PAYMENT_METHODS_CACHE_KEY);
        if (!data) {
            return;
        }

        this.paymentMethods = JSON.parse(data);
    }

    @action
    load(paymentMethods: IPaymentMethod[]) {
        this.paymentMethods = paymentMethods;
        this.shouldBePopulated = false;

        window.localStorage.setItem(PAYMENT_METHODS_CACHE_KEY, JSON.stringify(this.paymentMethods));
    }

    getById(id: number): IPaymentMethod | null {
        return this.paymentMethods.find(item => item.id === id) || null;
    }

    getByCode(code: string): IPaymentMethod | null {
        return this.paymentMethods.find(item => item.code === code) || null;
    }
}

const fetchPaymentMethods = (props: any = {}): Promise<IPaymentMethod[]> => {
    return fetchGet( '/payment_methods', {}, props)
        .then(response => response.json())
        .then(data => data.data);
};

export {fetchPaymentMethods}
