import * as React from "react";
import {useState} from "react";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton";
import {TransactionFormField} from "../../../Components/Form/config/config";
import {styled} from "@mui/material";

const fields = [
    {
        field: TransactionFormField.PAYMENT_METHOD,
        label: "Payment Method",
    },
    {
        field: TransactionFormField.LOCATION,
        label: "Location",
    },
    {
        field: TransactionFormField.CATEGORY,
        label: "Category",
    },
    {
        field: TransactionFormField.BUDGET,
        label: "Budget",
    },
    {
        field: TransactionFormField.DESCRIPTION,
        label: "Description",
    },
    {
        field: TransactionFormField.DETAILS,
        label: "Extra Details",
    },
];

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({theme}) => ({
    marginTop: theme.spacing(-5),
    marginBottom: theme.spacing(3),
}));

interface IFieldsSelectorProps {
    onSelect(fields: TransactionFormField[]): void;
}

export const FieldsSelector = ({onSelect}: IFieldsSelectorProps) => {
    const [selected, setSelected] = useState<TransactionFormField[]>([]);

    const handleChange = (event: React.MouseEvent<HTMLElement>, selected: TransactionFormField[]) => {
        setSelected(selected);
        onSelect(selected);
    };

    return (
        <StyledToggleButtonGroup
            color="primary"
            value={selected}
            onChange={handleChange}
        >
            {fields.map(f => <ToggleButton key={f.field} value={f.field}>{f.label}</ToggleButton>)}
        </StyledToggleButtonGroup>
    );
};
