import * as React from "react";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import {useEffect} from "react";
import { OutlinedContainer } from "./OutlinedContainer";
import { ValidationError, convertToTranslationKeys } from "../../../services/validator";
import { FormHelperText } from "@mui/material";
import { t } from "i18next";
import {styled} from "@mui/material";
import { convertBase64 } from "../../../utils/file_upload";

const Image = styled('img')(() => ({
    display: 'block',
    width: 'auto',
    maxHeight: 100,
}));

interface IFileFieldProps {
    label: string;
    onChange(value?: File | null): void;
    accept?: string;
    preview?: boolean;
    errors?: ValidationError[];
}

export const FileField = ({label, onChange, accept, preview, errors}: IFileFieldProps) => {
    const [file, setFile] = React.useState<File | null>();
    const [previewData, setPreviewData] = React.useState<string>('');

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!!event.target.files!![0]) {
            setFile(event.target.files!![0]);

            if (!!preview) {
                convertBase64(event.target.files!![0]).then((res: string) => {
                    setPreviewData(res);
                });
            }
        }
    };

    const handleDelete = () => {
        setFile(null);
    };

    useEffect(() => {
        onChange(file);
    }, [file]);

    return (
        <FormControl fullWidth>
            {!!file &&
                <OutlinedContainer label={label}>
                    {!!preview && previewData !== '' && <Image src={previewData} />}

                    <Button
                        color="primary"
                        size="small"
                        onClick={handleDelete}
                    >
                        <DeleteIcon/> Delete file
                    </Button>
                </OutlinedContainer>}

            {!file && <TextField
                onChange={handleChange}
                label={label}
                variant="outlined"
                type="file"
                inputProps={{
                    accept: accept,
                }}
                InputLabelProps={{ shrink: true }}
                error={!!errors && !!errors.length}
            />}

            {!!errors && !!errors.length &&
                <FormHelperText error>
                    {convertToTranslationKeys(errors).map(e => t(e)).join(', ')}
                </FormHelperText>
            }
        </FormControl>
    );
};
