import * as React from "react";
import {ITransaction} from "../../../../../config/transactions";
import {Currency} from "../../../../../config/currencies";
import Box from "@mui/material/Box";
import {parseTransactionAmount} from "../../../../../helpers/transaction_helpers";

interface IAmountProps {
    transaction: ITransaction;
    currency: Currency;
}

export const Amount = ({transaction, currency}: IAmountProps) => (
    <Box sx={{fontSize: 12}}>
        <strong>Amount</strong>: {parseTransactionAmount(transaction, currency).originalAmount}
    </Box>
);
