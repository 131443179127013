import * as React from "react";
import {IEventDetails} from "../../../../config/event";
import {ITransaction} from "../../../../config/transactions";

interface IEventTransactionsCountDetailsProps {
    event: IEventDetails;
}

const getRefundTransactionsCount = (transactions: ITransaction[]): number => {
    return transactions.filter(t => t.amount < 0).length;
};

export const EventTransactionsCountDetails = ({event}: IEventTransactionsCountDetailsProps) => {
    const refundsCount = getRefundTransactionsCount(event.transactions);

    if (!refundsCount) {
        return (
            <span>&nbsp;</span>
        );
    }

    return (
        <span>Including {refundsCount === 1 ? 'one refund' : `${refundsCount} refunds`}</span>
    );
};
