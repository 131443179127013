import * as React from "react";
import {CircularProgress, styled} from "@mui/material";

const OuterContainer = styled('div')(() => ({
    position: 'absolute',
    top: 0,
    left: 0,
    flexGrow: 1,
    overflow: 'hidden',
    width: '100%',
    height: '100%',
}));

const Container = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
}));

export const FullPageLoader = () => (
    <OuterContainer>
        <Container>
            <CircularProgress size={100} />
        </Container>
    </OuterContainer>
);
