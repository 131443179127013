import {fetchDelete, fetchGet, fetchPatch, fetchPost} from "../services/api";
import {
    IEvent,
    IEventDetails,
    IEventsFilterQuery,
    IEventTransactionRequest,
    INewEvent,
    IUpdateEvent
} from "../config/event";
import moment from "moment";
import {action, makeObservable, observable} from "mobx";
import { eventsValidationFactory } from "../validators/events";

export class EventStore {
    @observable events: IEvent[] = [];
    @observable shouldBePopulated: boolean = true;
    @observable shouldReloadEventDetails: boolean = false;

    constructor() {
        makeObservable(this);
    }

    @action
    load(events: IEvent[]) {
        this.events = events;
        this.shouldBePopulated = false;
    }

    @action
    addEvent(event: IEvent) {
        this.events.push(event);
    }

    @action
    replaceEvent(event: IEvent) {
        const index = this.events.findIndex(e => e.id === event.id);

        if (index === -1) {
            return;
        }

        this.events[index] = event;
    }

    @action
    removeEvent(event: IEvent) {
        this.events = this.events.filter(e => e.id !== event.id)
    }

    getSize(): number {
        return this.events.length;
    }

    findById(id: number | string): IEvent | null {
        return this.events.find(e => e.id == id) || null;
    }

    filterBy(query?: IEventsFilterQuery): IEvent[] {
        if (!query) {
            return this.events;
        }

        if (query.query === '') {
            return this.events;
        }

        return this.events.filter((event: IEvent) => {
            const queryMatch = new RegExp(`(${query.query})`, 'i');

            return queryMatch.test(event.name)
                || (event.from && queryMatch.test(event.from.format('MMMM')))
                || (event.to && queryMatch.test(event.to.format('MMMM')));
        });
    }
}

export const createStore = () => {
    return new EventStore();
};

const parseEvent = (item: any): IEvent => {
    if (item.from === '' || item.from === null) {
        item.from = null;
    } else {
        item.from = moment(item.from);
    }

    if (item.to === '' || item.to === null) {
        item.to = null;
    } else {
        item.to = moment(item.to);
    }

    return item;
};

const fetchEvents = (): Promise<IEvent[]> => {
    return fetchGet( '/events')
        .then(response => response.json())
        .then(data => data.data.map((item: any) => parseEvent(item)));
};

const fetchEventDetails = (id: number | string): Promise<IEventDetails> => {
    return fetchGet( `/events/${id}`)
        .then(response => response.json())
        .then(data => {
            const item = parseEvent(data.data) as IEventDetails;
            item.transactions = item.transactions.map((item: any) => {
                item.purchased_at = moment(item.purchased_at);

                return item;
            });

            return item;
        });
};

const createEvent = (event: INewEvent): Promise<IEvent> => {
    return fetchPost('/events', event).then((response) => {
        if (response.status !== 201) {
            return response.json().then((reason: any) => {
                if (reason.errors === undefined) {
                    return Promise.reject(reason);
                }

                return Promise.reject(eventsValidationFactory(reason.errors));
            });
        }

        return Promise.resolve({
            id: parseInt(response.headers.get('Location').replace(/.*\/([0-9]+)$/, '$1')),
            name: event.name,
            budget: event.budget as any,
            from: moment(event.from),
            to: moment(event.to),
        });
    });
};

const updateEvent = (eventId: number | string, data: IUpdateEvent): Promise<Response | IEvent> => {
    return fetchPatch(`/events/${eventId}`, data)
        .then((response: any) => {
            if (response.status !== 200) {
                return response.json().then((reason: any) => {
                    if (reason.errors === undefined) {
                        return Promise.reject(reason);
                    }
    
                    return Promise.reject(eventsValidationFactory(reason.errors));
                });
            }

            return response.json();
        })
        .then(data => parseEvent(data.data));
};

const deleteEvent = (id: number): Promise<boolean> => {
    return fetchDelete(`/events/${id}`);
};

const createEventTransactions = (eventId: string | number, data: IEventTransactionRequest[]): Promise<boolean> => {
    return fetchPost(`/events/${eventId}/transactions`, {transactions: data}).then((response) => {
        if (response.status !== 204) {
            return Promise.reject(false);
        }

        return Promise.resolve(true);
    });
};

const deleteEventTransaction = (eventId: number, transactionId: string): Promise<boolean> => {
    return fetchDelete(`/events/${eventId}/transactions/${transactionId}`);
};

export {fetchEvents, fetchEventDetails, createEvent, updateEvent, deleteEvent, createEventTransactions, deleteEventTransaction}
