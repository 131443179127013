import * as React from "react";
import {useEffect, useState} from "react";
import moment from "moment";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import {OutlinedInput, styled} from "@mui/material";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {ChevronRight} from "@mui/icons-material";

const StyledDatePicker = styled(DatePicker<moment.Moment>)(() => ({
    width: 150,
}));

const RangeContainer = styled('div')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    marginTop: -43,
}));

interface IDateRangePickerProps {
    from: moment.Moment;
    to: moment.Moment;
    onChange(from: moment.Moment, to: moment.Moment): void;
}

export const DateRangePicker = ({from, to, onChange}: IDateRangePickerProps) => {
    const [values, setValues] = useState({from, to});

    const handleChange = (prop: 'from' | 'to') => (value: moment.Moment | null) => {
        setValues({ ...values, [prop]: value });
    };

    useEffect(() => {
        onChange(values.from, values.to)
    }, [values]);

    return (
        <FormControl variant="outlined" fullWidth sx={{marginTop: (theme) => theme.spacing(2), marginBottom: (theme) => theme.spacing(1)}}>
            <InputLabel>
                Custom range
            </InputLabel>
            <OutlinedInput label="Custom range" value={" "} />

            <LocalizationProvider dateAdapter={AdapterMoment}>
                <RangeContainer>
                    <StyledDatePicker
                        format="DD/MM/YYYY"
                        value={values.from}
                        onChange={handleChange('from')}
                        slotProps={{textField: {variant: 'standard', }}}
                    />

                    <ChevronRight />

                    <StyledDatePicker
                        format="DD/MM/YYYY"
                        value={values.to}
                        minDate={values.from}
                        onChange={handleChange('to')}
                        slotProps={{textField: {variant: 'standard'}}}
                    />
                </RangeContainer>
            </LocalizationProvider>
        </FormControl>
    );
};
