import * as React from "react";
import {Link} from 'react-router-dom';
import {ListItemText, ListItemIcon, List, ListItemButton} from "@mui/material";
import BarChartIcon from '@mui/icons-material/BarChart';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import TodayIcon from '@mui/icons-material/Today';
import HistoryIcon from '@mui/icons-material/History';
import SettingsIcon from '@mui/icons-material/Settings';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import {Logout} from "../../Logout/Logout";
import {useTranslation} from "react-i18next";

interface ListItemLinkProps {
    icon?: React.ReactElement;
    inset?: boolean;
    label: string;
    to: string;
    onClick: any;
}

export const ListItemLink = ({icon, inset, label, to, onClick}: ListItemLinkProps) => {
    return (
        <ListItemButton component={Link} to={to} onClick={onClick}>
            {icon && <ListItemIcon>{icon}</ListItemIcon>}
            <ListItemText primary={label} inset={inset === true} />
        </ListItemButton>
    );
}

interface IMenuListProps {
    isAdmin: boolean;
    onItemClick: any;
}

const MenuList = ({isAdmin, onItemClick}: IMenuListProps) => {
    const { t } = useTranslation();

    return (
        <List>
            <ListItemLink label={t('sidebar.dashboard_title')} icon={<BarChartIcon />} to="/dashboard" onClick={onItemClick} />
            <ListItemLink label={t('sidebar.charts_title')} to="/dashboard/charts" onClick={onItemClick} inset />
            <ListItemLink label={t('sidebar.years_details_title')} to={`/dashboard/years/${(new Date()).getFullYear()}`} onClick={onItemClick} inset />
            <ListItemLink label={t('sidebar.transactions_title')} icon={<CreditCardIcon />} to="/transactions" onClick={onItemClick} />
            <ListItemLink label={t('sidebar.transactions_bulk_title')} to="/transactions/bulk" onClick={onItemClick} inset />
            <ListItemLink label={t('sidebar.extra_income_title')} icon={<AccountBalanceWalletIcon />} to="/extra-incomes" onClick={onItemClick} />
            <ListItemLink label={t('sidebar.events_title')} icon={<TodayIcon />} to="/events" onClick={onItemClick} />
            <ListItemLink label={t('sidebar.recurring_expenses_title')} icon={<HistoryIcon />} to="/recurring-expenses" onClick={onItemClick} />
            <ListItemLink label={t('sidebar.settings_title')} icon={<SettingsIcon/>} to="/settings" onClick={onItemClick}/>
            {isAdmin && <ListItemLink label={t('sidebar.atlas')} icon={<AdminPanelSettingsIcon />} to="/atlas" onClick={onItemClick} />}
            <Logout />
        </List>
    );
};

export default MenuList;
