import * as React from "react";
import {createDashboardStores, DashboardStoresContext} from "../../contexts/dashboard_stores_context";
import {PageOuterContainer} from "../Components/PageOuterContainer";
import {Outlet} from "react-router-dom";

const store = createDashboardStores();

export const Dashboard = () => {
    return (
        <DashboardStoresContext.Provider value={store}>
            <PageOuterContainer>
                <Outlet />
            </PageOuterContainer>
        </DashboardStoresContext.Provider>
    );
};
