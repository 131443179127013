import * as React from "react";
import {observer} from "mobx-react";
import { lighten } from "@mui/material/styles";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {grey} from "@mui/material/colors";
import {ClickAwayListener, styled} from "@mui/material";
import {SearchAutocomplete} from "../../../Transactions/Components/SearchAutocomplete/SearchAutocomplete";
import {useNavigate, useSearchParams} from "react-router-dom";

interface IStyledContainer {
    active: boolean;
}

const Container = styled('div')<IStyledContainer>(({ active, theme }) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
    [theme.breakpoints.down('md')]: {
        marginLeft: 20,
    },

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginRight: 20,
    padding: 8,
    borderRadius: 5,
    backgroundColor: active ? 'white' : theme.palette.primary.light,
    transition: theme.transitions.create('background-color', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.leavingScreen,
    }),

    '&:hover': {
        backgroundColor: active ? 'white' : lighten(theme.palette.primary.light, 0.2),
    },
}));

const StyledInput = styled('input')(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
        width: 80,
    },

    margin: 0,
    padding: 0,
    border: 0,
    width: 100,
    fontSize: 16,
    backgroundColor: 'inherit',
    color: lighten(theme.palette.primary.light, 0.5),
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.leavingScreen,
    }),

    '&:focus': {
        outline: 'none',
        color: grey[800],
        width: 200,
    },

    '&::placeholder': {
        color: lighten(theme.palette.primary.light, 0.5),
    },
    '&:focus::placeholder': {
        color: grey[600],
    },
}));

const FreeTextSearchComponent = () => {
    const { refreshStore } = useGlobalStores();
    const [searchParams,] = useSearchParams();

    const [open, setOpen] = React.useState<boolean>(false);
    const [hasFocus, setHasFocus] = React.useState<boolean>(false);
    const [query, setQuery] = React.useState<string>(searchParams.get('query') || '');
    const searchContainerElementRef = React.useRef<any>();

    const navigate = useNavigate();

    // reaction(
    //     () => searchStore.queryRequest,
    //     (queryRequest) => setQuery(queryRequest.query || ''),
    // );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setQuery(event.target.value as any);
    };
    const handleClear = () => {
        setQuery('');
        setOpen(false);
    };
    const handleKeyUp = (e: any) => {
        if (e.which === 27) { // esc
            handleClear();

            handleBlur();
        }
    };
    const handleFocus = () => {
        setHasFocus(true);
        setOpen(true);
    };
    const handleBlur = () => {
        setHasFocus(false);
        setOpen(false);
    };
    const handleSubmit = (e: any) => {
        e.preventDefault();

        if (query.trim() === '') {
            return;
        }

        setOpen(false);

        refreshStore.refreshDashboard();
        refreshStore.refreshTransactionsList();

        navigate(`/transactions?query=${query}`);
    };

    const handleClickAway = () => {
        setOpen(false);
    };
    const handleTransactionClick = () => {
        handleBlur();
        setOpen(false);
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <div>
                <Container active={hasFocus} ref={searchContainerElementRef}>
                    <form onSubmit={handleSubmit}>
                        <StyledInput
                            type="text"
                            placeholder="Search..."
                            value={query}
                            onChange={handleChange}
                            onKeyUp={handleKeyUp}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                        />
                    </form>

                    {/*{query && <ClearButton active={hasFocus} onClick={handleClear} />}*/}
                </Container>

                {open && query && <SearchAutocomplete element={searchContainerElementRef.current}
                                                      query={query}
                                                      onTransactionClick={handleTransactionClick} />
                }
            </div>
        </ClickAwayListener>
    );
};

export const FreeTextSearch = observer(FreeTextSearchComponent);
