import * as React from "react";
import {Grid} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import {Currency} from "../../../../../../../config/currencies";
import {convertAmountToFloat} from "../../../../../../../helpers/transaction_helpers";
import { OutlinedContainer } from "../../../../../../Components/Form/OutlinedContainer";

interface ISplitMethodPercentageFieldProps {
    value: number;
    onChange(event: any): void;
    originalAmount: string;
    originalCurrency: Currency;
}

export const SplitMethodPercentageField = ({value, onChange, originalAmount, originalCurrency}: ISplitMethodPercentageFieldProps) => {
    const handleChange = (event: any) => {
        onChange(event.target.value);
    };

    return (
        <>
            <Grid item xs={6} md={3}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel>Percentage</InputLabel>
                    <Select
                        variant="outlined"
                        value={value}
                        label="Percentage"
                        onChange={handleChange}
                        input={<OutlinedInput label="Percentage" />}
                    >
                        {Array.from({length: 100}, (_, i) => i + 1).map((i) => (
                            <MenuItem key={i} value={i} selected={i === value}>{i}%</MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={6} md={5}>
                <OutlinedContainer label="Amount">
                    <strong>
                        {((convertAmountToFloat(originalAmount) * value / 100) as any).toCurrency(originalCurrency)}
                    </strong>
                </OutlinedContainer>
            </Grid>
        </>
    );
}
