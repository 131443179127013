import * as React from "react";
import {Grid} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import {useEffect} from "react";
import { FormLabel } from '@mui/material';
import {useTranslation} from "react-i18next";

interface IIncludeFieldsProps {
    onChange(fields: string[]): void;
}

const fields = [
    'transaction_id',
    'purchased_at',
    'amount',
    'original_amount',
    'currency',
    'category_id',
    'category_name',
    'payment_method_id',
    'payment_method_name',
    'company_id',
    'company_name',
    'description',
    // location fields ?
];

export const IncludeFields = ({onChange}: IIncludeFieldsProps) => {
    const { t } = useTranslation();

    const [checked, setChecked] = React.useState<{[key: string]: boolean}>({});

    const handleChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked((prev) => {
            return {
                ...prev,
                [field]: event.target.checked,
            }
        });
    };

    useEffect(() => {
        onChange(Object.keys(checked).filter(f => checked[f]));
    }, [checked]);

    return (
        <Grid item xs={12}>
            <FormLabel>
                {t('bulk.action.export.form.format')}
            </FormLabel>
            {fields.map((field) => (
                <div key={field}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={checked[field] || false}
                                onChange={handleChange(field)}
                                color="primary"
                            />
                        }
                        label={field}
                    />
                </div>
            ))}
        </Grid>
    );
};
