import * as React from "react";
import { IconButton } from "@mui/material";
import { ILineItem } from "./LineItemsField";
import { useModal } from "../../../../../../../../hooks/use_modal";
import { FormModal } from "../../../../../../../Components/FormModal";
import { useForm } from "../../../../../../../../hooks/use_form_v2";
import { FreeTextField } from "../../../../../../../Components/Form/FreeTextField";
import { convertAmountToFloat } from "../../../../../../../../helpers/transaction_helpers";
import { Form } from "../../../../../../../Components/Form/Form/Form";

interface ILineItemFormProps {
    title: string;
    value?: ILineItem;
    onChange(value: ILineItem): void;
    trigger: React.ReactNode;
}

export const LineItemForm = ({title, value, onChange, trigger}: ILineItemFormProps) => {
    const {open, handleModalOpen, handleModalClose} = useModal();
    const form = useForm<ILineItem>(value || {
        description: '',
        quantity: '' as any,
        total_amount: '' as any,
        unit_price: '' as any,
    });
    const { change, register, watch } = form;

    const handleSubmit = (values: ILineItem) => {
        onChange({
            description: values.description,
            quantity: convertAmountToFloat(values.quantity),
            total_amount: convertAmountToFloat(values.total_amount),
            unit_price: convertAmountToFloat(values.unit_price),
        });
        handleModalClose();

        return Promise.resolve();
    };

    React.useEffect(() => {
        if ((watch('quantity') as any) === '' || (watch('unit_price') as any) === '') {
            return;
        }

        change('total_amount')((convertAmountToFloat(watch('quantity')) * convertAmountToFloat(watch('unit_price'))).toString());
    }, [watch('quantity'), watch('unit_price')]);

    return (
        <>
            <FormModal title={title} open={open} loading={false} onClose={handleModalClose}>
                <Form form={form} onSubmit={handleSubmit}>
                    <Form.Field type={FreeTextField} {...register('description')} />
                    <Form.Field type={FreeTextField} {...register('quantity')} gridProps={{xs: 12, md: 4}} />
                    <Form.Field type={FreeTextField} {...register('unit_price')} gridProps={{xs: 12, md: 4}} />
                    <Form.Field type={FreeTextField} {...register('total_amount')} gridProps={{xs: 12, md: 4}} />
                </Form>
            </FormModal>

            <IconButton size="small" color="primary" onClick={handleModalOpen}>
                {trigger}
            </IconButton>
        </>
    );    
};
