import * as React from "react";
import {ITransaction} from "../../../../config/transactions";
import {Item, Label, ListContainer, Value} from "./Components/Lists";

interface ISharedWithProps {
    transaction: ITransaction;
}

export const AmountDetails = ({transaction}: ISharedWithProps) => {
    return (
        <ListContainer>
            {!!transaction.details?.amount_details?.goodPrice &&
                <Item>
                    <Label>Good price</Label>
                    <Value>{(transaction.details?.amount_details?.goodPrice as any).toCurrency(transaction.original_currency)}</Value>
                </Item>
            }

            {!!transaction.details?.amount_details?.shippingFee &&
                <Item>
                    <Label>Shipping Fees</Label>
                    <Value>{(transaction.details?.amount_details?.shippingFee as any).toCurrency(transaction.original_currency)}</Value>
                </Item>
            }

            {!!transaction.details?.amount_details?.customsFee &&
                <Item>
                    <Label>Customs fees</Label>
                    <Value>{(transaction.details?.amount_details?.customsFee as any).toCurrency(transaction.original_currency)}</Value>
                </Item>
            }

            {!!transaction.details?.amount_details?.otherFee &&
                <Item>
                    <Label>Other fees</Label>
                    <Value>{(transaction.details?.amount_details?.otherFee as any).toCurrency(transaction.original_currency)}</Value>
                </Item>
            }
        </ListContainer>
    )
};
