import * as React from "react";
import * as moment from "moment";
import {IEventDetails} from "../../../../config/event";
import {styled} from "@mui/material";

const Container = styled('div')(({theme}) => ({
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
}));

interface IEventTimeRangeProps {
    event: IEventDetails;
}

export const EventTimeRange = ({event}: IEventTimeRangeProps) => {
    if (event.from === null || event.to === null) {
        return null;
    }

    const from = event.from as unknown as moment.Moment;
    const to = event.to as unknown as moment.Moment;

    if (from.isSame(to)) {
        return (
            <Container>{from.format('DD/MM')}</Container>
        );
    }

    return (
        <Container>{from.format('DD/MM')} - {to.format('DD/MM')}</Container>
    );
};
