import moment from "moment/moment";

const TIME_FRAME_TODAY = {
    key: 'TODAY',
    label: 'Today',
    getRange: () => {
        const today = moment();
        return [today, today];
    },
};
const TIME_FRAME_YESTERDAY = {
    key: 'YESTERDAY',
    label: 'Yesterday',
    getRange: () => {
        const yesterday = moment().subtract(1, 'day')
        return [yesterday, yesterday];
    },
};
const TIME_FRAME_THIS_WEEK = {
    key: 'THIS_WEEK',
    label: 'This week',
    getRange: () => {
        return [
            moment().startOf('isoWeek'),
            moment().endOf('isoWeek'),
        ];
    },
};
const TIME_FRAME_LAST_WEEK = {
    key: 'LAST_WEEK',
    label: 'Last week',
    getRange: () => {
        return [
            moment().subtract(1, 'week').startOf('isoWeek'),
            moment().subtract(1, 'week').endOf('isoWeek'),
        ];
    },
};
const TIME_FRAME_THIS_MONTH = {
    key: 'THIS_MONTH',
    label: 'This month',
    getRange: () => {
        return [
            moment().startOf('month'),
            moment().endOf('month'),
        ];
    },
};
const TIME_FRAME_LAST_MONTH = {
    key: 'LAST_MONTH',
    label: 'Last month',
    getRange: () => {
        return [
            moment().subtract(1, 'month').startOf('month'),
            moment().subtract(1, 'month').endOf('month'),
        ];
    },
};
const TIME_FRAME_THIS_YEAR = {
    key: 'THIS_YEAR',
    label: 'This year',
    getRange: () => {
        return [
            moment().startOf('year'),
            moment().endOf('year'),
        ];
    },
};
const TIME_FRAME_LAST_YEAR = {
    key: 'LAST_YEAR',
    label: 'Last year',
    getRange: () => {
        return [
            moment().subtract(1, 'year').startOf('year'),
            moment().subtract(1, 'year').endOf('year'),
        ];
    },
};
const TIME_FRAME_CUSTOM = {
    key: 'CUSTOM',
    label: 'Custom',
    getRange: () => {
        return [];
    },
};
const TIME_FRAME_ALL_TIME = {
    key: 'ALL_TIME',
    label: 'No time constraint',
    getRange: () => {
        return [
            moment('1970-01-01'),
            moment(),
        ];
    },
};

export interface ITimeFrame {
    key: string;
    label: string;
    getRange(): [moment.Moment, moment.Moment];
}

export enum TimeFrame {
    TODAY,
    YESTERDAY,
    THIS_WEEK,
    LAST_WEEK,
    THIS_MONTH,
    LAST_MONTH,
    THIS_YEAR,
    LAST_YEAR,
    CUSTOM,
    ALL_TIME,
}

export const TimeFramesCollection = {
    [TimeFrame.TODAY]: TIME_FRAME_TODAY,
    [TimeFrame.YESTERDAY]: TIME_FRAME_YESTERDAY,
    [TimeFrame.THIS_WEEK]: TIME_FRAME_THIS_WEEK,
    [TimeFrame.LAST_WEEK]: TIME_FRAME_LAST_WEEK,
    [TimeFrame.THIS_MONTH]: TIME_FRAME_THIS_MONTH,
    [TimeFrame.LAST_MONTH]: TIME_FRAME_LAST_MONTH,
    [TimeFrame.THIS_YEAR]: TIME_FRAME_THIS_YEAR,
    [TimeFrame.LAST_YEAR]: TIME_FRAME_LAST_YEAR,
    [TimeFrame.CUSTOM]: TIME_FRAME_CUSTOM,
    [TimeFrame.ALL_TIME]: TIME_FRAME_ALL_TIME,
} as any;
