import * as React from "react";
import "../../../../utils/number_extensions";
import {IEventDetails} from "../../../../config/event";
import {Currency} from "../../../../config/currencies";

interface IEventBudgetUseProps {
    event: IEventDetails;
    currency: Currency;
}

const getBudgetPercentageUsage = (event: IEventDetails): string => {
    if (!event.budget) {
        return '';
    }
    const percentage = Math.floor(((event.stats.total / event.budget) * 100).toFixed(2) as any);

    return `~ ${(percentage > 100) ? '+' : ''}${percentage}%`;
};

export const EventBudgetUse = ({event, currency}: IEventBudgetUseProps) => {
    if (!event.budget) {
        return (
            <span>No budget</span>
        );
    }

    const budget = (new Intl.NumberFormat('en-US', { style: 'currency', currency: currency, minimumFractionDigits: 0 }).format(event.budget));

    if (!event.stats.total) {
        return (
            <span>No money spent of your <strong>{budget}</strong> budget</span>
        );
    }

    return (
        <span>
            {getBudgetPercentageUsage(event)} of your {budget} budget
        </span>
    )
};
