import * as React from "react";
import RedeemIcon from "@mui/icons-material/Redeem";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import {FormModal} from "../../../../../Components/FormModal";
import {SaveButton} from "../../../../../Components/SaveButton";
import Box from "@mui/material/Box";
import {Tooltip} from "@mui/material";
import { useGlobalStores } from "../../../../../../hooks/use_global_stores";
import { IAllowanceTransaction } from "../../../../../../config/allowance";
import { AllowanceSelector } from "./AllowanceSelector/AllowanceSelector";

interface IAllowancesFieldProps {
    value: IAllowanceTransaction[];
    onChange(data: IAllowanceTransaction[]): void;
    amount: string;
}

export const AllowancesField = ({value, onChange, amount}: IAllowancesFieldProps) => {
    const { allowanceStore } = useGlobalStores();

    const [open, setOpen] = React.useState(false);
    const [values, setValues] = React.useState(value);

    if (!allowanceStore.allowances.length) {
        return null
    }

    const handleOpen = () => {
        setOpen(true);
    };
    
    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (index: number) => (data: IAllowanceTransaction | null) => {
        setValues(prev => {
            if (data === null) {
                return prev.filter((d, i) => i !== index);
            }

            const newData = prev.slice();
            newData[index] = data

            return newData;
        });
    };

    const handleAdd = (data: IAllowanceTransaction) => {
        setValues(currentData => [...currentData, data]);
    };

    const handleSave = () => {
        onChange(values);
        handleClose();
    };

    return (
        <>
            <Box sx={{marginLeft: (theme) => theme.spacing(0.5)}}>
                <IconButton size="small" color="primary" onClick={handleOpen} disabled={amount === ''}>
                    <Badge invisible={!value.length} color="error" variant="dot" overlap="rectangular">
                        <Tooltip title="Manage Allowances" arrow>
                            <RedeemIcon />
                        </Tooltip>
                    </Badge>
                </IconButton>
            </Box>

            <FormModal loading={false} open={open} onClose={handleClose} title="Allowances">
                <>
                    {values.map((item, i) => (
                        <AllowanceSelector
                            key={i}
                            value={item}
                            onChange={handleChange(i)}
                            originalAmount={amount}
                        />
                    ))}
                    <AllowanceSelector onChange={handleAdd} originalAmount={amount} />

                    {(!!values.length || !!value.length) &&
                        <SaveButton loading={false} onSubmit={handleSave} />
                    }
                </>
            </FormModal>
        </>
    );
};
