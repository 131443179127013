import * as React from "react";
import {ExtraIncomeForm} from "../Form/ExtraIncomeForm";
import {FormModal} from "../../Components/FormModal";
import {IExtraIncomeFormData} from "../../../config/extra_income";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {createExtraIncome} from "../../../stores/extra_income";
import {SnackbarStore} from "../../../stores/snackbar";
import {useNavigate} from "react-router-dom";

export const NewExtraIncome = () => {
    const { refreshStore, snackbarStore } = useGlobalStores();

    const navigate = useNavigate();

    const [loading, setLoading] = React.useState(false);

    const handleSubmit = (data: IExtraIncomeFormData) => {
        setLoading(true);

        createExtraIncome(data)
            .then(() => {
                setLoading(false);

                refreshStore.refreshExtraIncomes();
                snackbarStore.open(SnackbarStore.SUCCESS, 'Extra income added successfully!');

                handleClose();
            })
            .catch(() => {
                setLoading(false);

                snackbarStore.open(SnackbarStore.FAIL);

                handleClose();
            });
    };

    const handleClose = () => {
        navigate('/extra-incomes');
    };

    return (
        <FormModal
            title="New extra income"
            loading={loading}
            open={true}
            onClose={handleClose}
        >
            <ExtraIncomeForm loading={loading} onSubmit={handleSubmit} />
        </FormModal>
    );
};
