import moment from "moment";
import {action, makeObservable, observable} from "mobx";
import {
    IExportPreRequest,
    INewTransaction,
    IReceiptData,
    ITransaction,
    ITransactionsResult,
    ITransactionsSearchQuery, IUpdateBulk, IUpdateTransaction,
    TRANSACTIONS_FETCH_LIMIT
} from "../config/transactions";
import {convertExportPreRequestToExportRequest, optimizeTransactionItem} from "../helpers/transaction_helpers";
import {fetchDelete, fetchFormPost, fetchGet, fetchPost, fetchPut} from "../services/api";
import {transactionsValidationFactory, TransactionValidation} from "../validators/transactions";

export class TransactionStore {
    @observable transactions: ITransaction[] = [];
    @observable shouldBePopulated: boolean = true;
    @observable totalResultsCount: number = 0;

    constructor(transactions?: ITransaction[]) {
        makeObservable(this);

        if (transactions !== undefined) {
            this.populate(transactions);
        }
    }

    @action
    populate(transactions: ITransaction[], totalResultsCount: number | null = null) {
        this.transactions = transactions;
        this.totalResultsCount = totalResultsCount || transactions.length;
        this.shouldBePopulated = false;
    }

    @action
    addTransaction(transaction: ITransaction) {
        this.transactions.push(transaction);
    }

    @action
    removeTransaction(transaction: ITransaction) {
        this.transactions = this.transactions.filter(t => t.transaction_id !== transaction.transaction_id)
    }

    @action
    replaceTransaction(transaction: ITransaction) {
        const index = this.transactions.findIndex(t => t.transaction_id === transaction.transaction_id);

        if (index === -1) {
            return;
        }

        this.transactions[index] = transaction;
    }

    findTransactionById(transactionId: string): ITransaction | null {
        return this.transactions.find(t => t.transaction_id === transactionId) || null;
    }

    findTransactionsByDate(date: moment.Moment): ITransaction[] {
        return this.transactions.filter(t => t.purchased_at.format('YYYY-MM-DD') === date.format('YYYY-MM-DD'));
    }
}

export const createTransaction = (data: INewTransaction): Promise<void | Response | TransactionValidation | {id: string}> => {
    return fetchPost('/transactions', data).then((response: any) => {
        if (response.status !== 201) {
            return response.json().then((reason: any) => {
                if (reason.errors === undefined) {
                    return Promise.reject(reason);
                }

                return Promise.reject(transactionsValidationFactory(reason.errors));
            });
        }

        return Promise.resolve({
            id: response.headers.get('Location').replace(/.*\/(.+)$/, '$1'),
        });
    });
};

export const updateTransaction = (transactionId: string, data: IUpdateTransaction): Promise<Response | void> => {
    return fetchPut(`/transactions/${transactionId}`, data).then((response: any) => {
        if (response.status !== 204) {
            return response.json().then((reason: any) => {
                if (reason.errors === undefined) {
                    return Promise.reject(reason);
                }

                return Promise.reject(transactionsValidationFactory(reason.errors));
            });
        }

        return Promise.resolve();
    });
};

export const fetchTransactions = (query: ITransactionsSearchQuery, limit: number = TRANSACTIONS_FETCH_LIMIT, offset: number = 0): Promise<ITransactionsResult> => {
    return fetchGet( '/transactions', {...query, limit, offset})
        .then(response => response.json())
        .then(data => {
            const transactions = data.data.transactions.map(optimizeTransactionItem);

            return {
                transactions,
                total: data.data.meta.total,
                count: data.data.meta.count,
            };
        });
};

export const fetchTransaction = (transactionId: string): Promise<ITransaction> => {
    return fetchGet( `/transactions/${transactionId}`)
        .then(response => response.json())
        .then(data => {
            return optimizeTransactionItem(data.data);
        });
};

export const deleteTransaction = (transactionId: string): Promise<any> => {
    return fetchDelete(`/transactions/${transactionId}`);
};

export const updateBulk = (data: IUpdateBulk): Promise<void | Response | TransactionValidation> => {
    return fetchPost('/transactions/bulk/edit', data).then((response: any) => {
        if (response.status !== 200) {
            return response.json().then((reason: any) => {
                if (reason.errors === undefined) {
                    return Promise.reject(reason);
                }

                return Promise.reject(transactionsValidationFactory(reason.errors));
            });
        }

        return Promise.resolve();
    });
};

export const deleteBulk = (transactionIds: string[]): Promise<void | Response | TransactionValidation> => {
    return fetchPost('/transactions/bulk/delete', {
        transaction_ids: transactionIds,
    }).then((response: any) => {
        if (response.status !== 200) {
            return response.json().then((reason: any) => {
                if (reason.errors === undefined) {
                    return Promise.reject(reason);
                }

                return Promise.reject(transactionsValidationFactory(reason.errors));
            });
        }

        return Promise.resolve();
    });
};

export const merge = (mainTransactionId: string, transactionIds: string[]): Promise<void | Response | TransactionValidation> => {
    return fetchPost(`/transactions/${mainTransactionId}/merge`, {
        transaction_ids: transactionIds,
    }).then((response: any) => {
        if (response.status !== 200) {
            return response.json().then((reason: any) => {
                if (reason.errors === undefined) {
                    return Promise.reject(reason);
                }

                return Promise.reject(transactionsValidationFactory(reason.errors));
            });
        }

        return Promise.resolve();
    });
};

export const exportTransactions = (request: IExportPreRequest): Promise<Blob> => {
    return fetchGet( `/transactions/export.${request.format}`, convertExportPreRequestToExportRequest(request))
        .then((response: any) => {
            if (response.status !== 200) {
                return response.json().then((reason: any) => {
                    if (reason.errors === undefined) {
                        return Promise.reject(reason);
                    }

                    return Promise.reject(transactionsValidationFactory(reason.errors));
                });
            }

            return response.blob();
        });
};

export const analyzeReceipt = (formData: FormData): Promise<void | Response | IReceiptData> => {
    return fetchFormPost('/transactions/receipt', formData).then((response: any) => {
        if (response.status !== 200) {
            return response.json().then((reason: any) => {
                return Promise.reject(reason);
            });
        }

        return response;
    })
    .then(response => response.json())
    .then(data => {
        return data.data;
    });
};
