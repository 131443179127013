import * as React from "react";

interface IFlagProps {
    countryCode: string;
}

export const Flag = ({countryCode}: IFlagProps) => (
    <img
        loading="lazy"
        width="20"
        srcSet={`https://flagcdn.com/w40/${countryCode.toLowerCase()}.png 2x`}
        src={`https://flagcdn.com/w20/${countryCode.toLowerCase()}.png`}
        alt=""
    />
);
