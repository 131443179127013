import {action, makeObservable, observable} from "mobx";
import {ITransactionsSearchQuery} from "../config/transactions";
import {IStats} from "../config/stats";
import {apiV1} from "../services/api";

export class DashboardStore {
    @observable stats: IStats | null = null;

    constructor() {
        makeObservable(this);
    }

    @action
    setStats(stats: IStats) {
        this.stats = stats;
    }
}

export const fetchTransactionsStats = (query: ITransactionsSearchQuery): Promise<IStats> => {
    return apiV1.GET( '/stats', query)
        .then(response => response.json())
        .then(data => data.data);
};
