import * as React from "react";
import {ISnapshotTimeline} from "../../../../config/snapshot";
import {periodPercentageComparison} from "../../../../helpers/snapshot_helpers";
import MoneyIcon from "@mui/icons-material/Money";
import {Trend} from "../../../Components/Icons/TrendIcon";
import {SnapshotCard, SnapshotCardColor} from "../../../Components/SnapshotCard";

interface ITransactionsCountItemProps {
    snapshotTimeline: ISnapshotTimeline;
}

export const TransactionsCountItem = ({snapshotTimeline}: ITransactionsCountItemProps) => (
    <SnapshotCard
        title="Transactions"
        icon={<MoneyIcon />}
        color={SnapshotCardColor.PINK}
        trend={snapshotTimeline.current.transactions_count > snapshotTimeline.previous.transactions_count ? Trend.UP : Trend.DOWN}
        value={snapshotTimeline.current.transactions_count}
        details={`${periodPercentageComparison(snapshotTimeline, 'transactions_count')} from last month`}
        target="/transactions"
    />
);
