export interface IPaymentMethod {
    id: number;
    name: string;
    code: string;
}

export enum PaymentMethodType {
    AMEX = "AMEX",
    EC = "EC",
    VISA = "VISA",
    CASH = "CASH",
    WIRE = "WIRE",
    MASTER_CARD = "MASTER_CARD",
}
