import * as React from "react";
import {Avatar, Box, styled, Typography} from "@mui/material";
import { Business } from "@mui/icons-material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

const Container = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
}));

const MainContent = styled(Box)(({theme}) => ({
    flexGrow: 1,
    overflow: 'hidden',
}));

const ArrowRightContainer = styled(Box)(({theme}) => ({
    marginRight: theme.spacing(-1),
    color: theme.palette.primary.main,
    lineHeight: 0,
}));

const Title = styled(Typography)(({
    fontSize: 10,
}));

const Value = styled('div', {
    shouldForwardProp: (prop) => prop !== 'withTitle',
})<{withTitle: boolean}>(({withTitle}) => ({
    marginTop: withTitle ? -8 : 0,
    fontSize: 19,
}));

interface IItemProps {
    title?: string;
    value: any;
    icon?: React.ReactElement;
    noIcon?: boolean;
    clickable?: boolean;
}

export const Item = ({title, value, icon, noIcon = false, clickable = false}: IItemProps) => {
    return (
        <Container>
            {noIcon !== true && (
                <Box sx={(theme) => ({marginRight: theme.spacing(1.5)})}>
                    <Avatar>
                        {icon || <Business />}
                    </Avatar>
                </Box>
            )}
            <MainContent>
                {!!title && <Title variant="overline" display="block">{title}</Title>}
                <Value withTitle={!!title}>{value}</Value>
            </MainContent>
            {!!clickable && (
                <ArrowRightContainer>
                    <ChevronRightIcon sx={{fontSize: 40}} />
                </ArrowRightContainer>
            )}
        </Container>
    );
};
