import {styled} from "@mui/material";
import { blue, orange, red } from '@mui/material/colors';
import { ProgressStatus } from './ProgressBar';

interface IContainerStyle {
    width?: number | 'fullwidth';
    height?: number;
    status: ProgressStatus;
}
export const Container = styled('div', {
    shouldForwardProp: (prop) => prop !== 'width' && prop !== 'height' && prop !== 'status',
})<IContainerStyle>(({width, height, status}) => {
    let barBackground, barBackgroundColor;
    switch (status) {
        case ProgressStatus.ALERT:
            barBackground = red[50];
            barBackgroundColor = red[500];
            break
        case ProgressStatus.WARNING:
            barBackground = orange[50];
            barBackgroundColor = orange[200];
            break
        default:
            barBackground = blue[50];
            barBackgroundColor = blue[200];
    }

    return {
        position: 'relative',
        width: !!width ? (width === 'fullwidth' ? '100%' : width) : 300,
        height: height || 50,
        backgroundColor: barBackground,
    
        '.progress-bar-progress-item': {
            backgroundColor: barBackgroundColor,
        }
    }
});
