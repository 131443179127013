import * as React from "react";
import {Button, Grid} from "@mui/material";
import { EditAction } from "../../../Components/Actions/EditAction";
import { DeleteAction } from "../../../Components/Actions/DeleteAction";
import { ITransaction } from "../../../../../config/transactions";
import { useNavigate } from "react-router-dom";
import { Delete, Edit } from "@mui/icons-material";

interface ITagsProps {
    transaction: ITransaction;
}

const EditButton = (onClick: () => void) => <Button variant="outlined" onClick={onClick} startIcon={<Edit />}>Edit</Button>;
const DeleteButton = (_: boolean, onClick: () => void) => <Button variant="outlined" onClick={onClick} startIcon={<Delete />}>Delete</Button>;

export const Actions = ({transaction}: ITagsProps) => {
    const navigate = useNavigate();

    const handleDelete = () => {
        navigate('/transactions');
    };

    return (
        <Grid container justifyContent="center" spacing={1.5}>
            <Grid item>
                <EditAction transaction={transaction} component={EditButton} />
            </Grid>
            <Grid item>
                <DeleteAction transaction={transaction} component={DeleteButton} onDelete={handleDelete} />
            </Grid>
        </Grid>
    )
};
