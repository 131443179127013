import * as React from "react";
import FormControl from "@mui/material/FormControl";
import {Checkbox, FormControlLabel} from "@mui/material";
import {useEffect, useState} from "react";

interface ICheckboxFieldProps {
    label: string;
    value: boolean;
    onChange(checked: boolean): void;
}

export const CheckboxField = ({label, value, onChange}: ICheckboxFieldProps) => {
    // value === true it's because sometimes "value" happens not to be a boolean, this trick, fixes the problem.
    const [checked, setChecked] = useState<boolean>(value === true);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    useEffect(() => {
        onChange(checked);
    }, [checked]);

    return (
        <FormControl fullWidth>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        onChange={handleChange}
                        color="primary"
                    />
                }
                label={label}
            />
        </FormControl>
    );
}
