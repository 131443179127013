import {IErrorResponseItem, ValidationError, ValidationErrors} from "../services/validator";

export enum UserPaymentMethodsValidationField {
    TYPE = "UserPaymentMethod.PaymentMethodID",
    NAME = "UserPaymentMethod.Name",
}

export type UserPaymentMethodsValidation = {
    [key in UserPaymentMethodsValidationField]: ValidationError[];
};

export const createRecurringExpensesValidation = (): ValidationErrors<UserPaymentMethodsValidation, UserPaymentMethodsValidationField> => {
    const v = new ValidationErrors<UserPaymentMethodsValidation, UserPaymentMethodsValidationField>();
    v.setMap({
        type: UserPaymentMethodsValidationField.TYPE,
        name: UserPaymentMethodsValidationField.NAME,
    })
    v.setKeyParser((key) => {
        return key
            .replace('NewUserPaymentMethodRequest', 'UserPaymentMethod')
            .replace('UpdateUserPaymentMethodRequest', 'RecurringExpense');
    });

    return v;
};

export const convertFromResponse = (errors: IErrorResponseItem[]): ValidationErrors<UserPaymentMethodsValidation, UserPaymentMethodsValidationField> => {
    const data = createRecurringExpensesValidation();

    errors.forEach(e => {
        data.add(e.key as any, e.validator as any);
    });

    return data;
};
