import * as React from "react";
import {Grid} from "@mui/material";

export const Field = (props: any) => {
    const {type: Type, gridProps, conditional, noWrapper, prop, ...rest} = props;

    if (conditional !== undefined && !conditional) {
        return null;
    }

    if (!!noWrapper) {
        return (
            <Type {...rest} />
        );
    }

    return (
        <Grid item xs={12} {...gridProps}>
            <Type {...rest} />
        </Grid>
    );
};
