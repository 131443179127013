import * as React from "react";
import {useGlobalStores} from "../../../../../hooks/use_global_stores";
import {SelectField} from "../../../../Components/Form/SelectField";
import { transformToMuiSelectFormat } from "../../../../../helpers/budget_helpers";

interface IBudgetFieldProps {
    value: string;
    onChange(value: any): void;
}

export const BudgetField = ({value, onChange}: IBudgetFieldProps) => {
    const { budgetStore } = useGlobalStores();

    const options = transformToMuiSelectFormat(budgetStore.budgets);

    return (
        <SelectField
            label="Budget"
            options={options}
            value={value || ''}
            onChange={onChange}
            optionValueGetter={o => o.value}
            optionLabelGetter={o => o.label}
        />
    );
};
