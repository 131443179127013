import * as React from "react";
import {IVehicleForHireDetails} from "../../../../config/vehicle_for_hire";
import moment from "moment/moment";
import {styled} from "@mui/material";
import {Item} from "./Components/Item";

export const Container = styled('div')(() => ({
    maxWidth: 500,

    '.details-list-item-label': {
        width: 120,
    },

    '.details-list-item-value': {
        width: 400,
        backgroundColor: 'red',
    },
}));

interface IVehicleForHireProps {
    label: string;
    value: IVehicleForHireDetails;
}

export const VehicleForHire = ({label, value}: IVehicleForHireProps) => {
    let pickupTime;
    if (!!value.pickupTime) {
        pickupTime = moment(value.pickupTime, 'hh:mm');
    }

    let dropoffTime;
    if (!!value.dropoffTime) {
        dropoffTime = moment(value.dropoffTime, 'hh:mm');
    }

    return (
        <>
            {value.addressFrom && !!pickupTime && <Item label="Pick up" value={`${value.addressFrom}, at ${pickupTime.format('HH:mm')}`} xs={12} md={6} lg={5} />}
            {value.addressTo && !!dropoffTime && <Item label="Drop-off" value={`${value.addressTo}, at ${dropoffTime.format('HH:mm')}`} xs={12} md={6} lg={5} />}
            {value.distanceUnit && value.distance && <Item label="Distance" value={`${value.distance} ${value.distanceUnit}`} xs={12} md={6} lg={1} />}
            {/*{value.tripMap && <Item label="Trip Map" value={<img style={{display: 'block'}} alt="Trip Map" src={value.tripMap} />} xs={12} md={6} lg={1} />}*/}
            {value.driverName && <Item label="Your Driver" value={value.driverName} xs={12} md={6} lg={1} />}
        </>
    );
};
