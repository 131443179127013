import * as React from "react";
import {createStore} from "../../stores/recurring_expense";
import {RecurringExpenseStoreContext} from "../../contexts/recurring_expense_store_context";
import {Outlet} from "react-router-dom";

export const RecurringExpenses = () => (
    <RecurringExpenseStoreContext.Provider value={createStore()}>
        <Outlet />
    </RecurringExpenseStoreContext.Provider>
);
