import { ITransaction } from "../../../../../config/transactions";
import { convertAmountToFloat } from "../../../../../helpers/transaction_helpers";

interface IExtraCommissionFeeProps {
    value: number | string;
    transaction: ITransaction;
}

export const ExtraCommissionFee = ({value, transaction}: IExtraCommissionFeeProps) => (
    convertAmountToFloat(value).toCurrency(transaction.original_currency, {})
);
