import * as React from "react";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { INewAttachment, typesOptions } from "../../../../../../config/attachments";
import { useModal } from "../../../../../../hooks/use_modal";
import { FormModal } from "../../../../../Components/FormModal";
import { useForm } from "../../../../../../hooks/use_form_v2";
import { FreeTextField } from "../../../../../Components/Form/FreeTextField";
import { SelectField } from "../../../../../Components/Form/SelectField";
import { FileField } from "../../../../../Components/Form/FileField";
import { attachmentsStaticValidationFactory } from "../../../../../../validators/attachments";
import { Form } from "../../../../../Components/Form/Form/Form";
import { ProIconButton } from "../../../../../Pro/Components/ProIconButton";
import { Level } from "../../../../../../config/auth";
import { Feature } from "../../../../../../config/features";

interface IAddAttachmentProps {
    onSubmit(value: INewAttachment): void;
}

export const AddAttachment = ({onSubmit}: IAddAttachmentProps) => {
    const {open, handleModalOpen, handleModalClose} = useModal();

    const form = useForm<INewAttachment>();
    const { register } = form;

    const handleSubmit = (values: INewAttachment) => {
        const validation = attachmentsStaticValidationFactory(values);
        if (!validation.isValid()) {
            return Promise.reject(validation);
        }

        handleModalClose();
        onSubmit(values);

        return Promise.resolve();
    };

    return (
        <>
            <FormModal title="Add new attachment" open={open} loading={false} onClose={handleModalClose}>
                <Form form={form} onSubmit={handleSubmit}>
                    <Form.Field type={FileField} {...register('file')} accept={"image/*"} />
                    <Form.Field
                        type={SelectField}
                        {...register('type')}
                        options={typesOptions}
                        optionValueGetter={(o: any) => o.key}
                        optionLabelGetter={(o: any) => o.label}
                    />
                    <Form.Field type={FreeTextField} {...register('name')} />
                    <Form.Field type={FreeTextField} {...register('description')} />
                </Form>
            </FormModal>

            <ProIconButton min={Level.PLATINUM} feature={Feature.TRANSACTIONS_ATTACHMENTS} size="small" color="primary" onClick={handleModalOpen}>
                <UploadFileIcon />
            </ProIconButton>
        </>
    );
};
