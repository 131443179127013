import * as React from "react";
import {ITransaction} from "../../../../config/transactions";
import {styled} from "@mui/material";
import {Logo} from "./Logo";

const Container = styled('div')(({theme}) => ({
}));

interface ICompanyLogoProps {
    transaction: ITransaction;
}

export const LocationLogo = ({transaction}: ICompanyLogoProps) => {
    if (!transaction?.location?.logo) {
        return null;
    }

    return (
        <Container>
            <Logo logo={transaction.location.logo} />
        </Container>
    )
};
