import * as React from "react";
import { AttachmentType } from "../../../../../../config/attachments";
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import DescriptionIcon from '@mui/icons-material/Description';
import MapIcon from '@mui/icons-material/Map';
import AttachmentIcon from '@mui/icons-material/Attachment';

interface IIconProps {
    type: AttachmentType;
}

export const Icon = ({type}: IIconProps) => {
    switch(type) {
        case AttachmentType.RECEIPT: return <ReceiptLongIcon />;
        case AttachmentType.INVOICE: return <DescriptionIcon />;
        case AttachmentType.MAP: return <MapIcon />;
    }

    return <AttachmentIcon />
};
