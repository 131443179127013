import * as React from "react";
import {useNavigate, useParams} from "react-router-dom";
import {ITransaction} from "../../../config/transactions";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {useEffect, useState} from "react";
import {fetchTransaction, updateTransaction} from "../../../stores/transactions";
import {PageLoader} from "../../Components/PageLoader";
import Error from "../../Components/Error";
import {ITransactionFormSubmit} from "../Components/Form/config/models";
import {convertFormOutputDataToTransactionForUpdate} from "../../../helpers/transaction_helpers";
import {SnackbarStore} from "../../../stores/snackbar";
import {PageOuterContainer} from "../../Components/PageOuterContainer";
import {PageItem} from "../../Components/PageItem";
import {TransactionForm} from "../Components/Form/TransactionForm";
import {TransactionFormScope} from "../Components/Form/config/config";
import { createAttachment, deleteAttachment } from "../../../stores/attachments";

export const EditTransaction = () => {
    const { transactionStore, snackbarStore } = useGlobalStores();

    const [loading, setLoading] = useState(false);
    const [transaction, setTransaction] = useState<ITransaction>();

    const navigate = useNavigate();
    const {transaction_id} = useParams() as any;

    useEffect(() => {
        // const findTransaction = transactionStore.findTransactionById(transaction_id);
        // if (null !== findTransaction) {
        //     setTransaction(findTransaction);
        //     return;
        // }

        (async () => {
            setLoading(true);

            try {
                const transaction = await fetchTransaction(transaction_id);
                setTransaction(transaction);
            } catch (error) {
            }

            setLoading(false);
        })();
    }, [transaction_id]);

    if (loading) {
        return (
            <PageLoader />
        );
    }

    if (!transaction) {
        return (
            <Error message="Transaction not found" />
        );
    }

    const handleSubmit = (output: ITransactionFormSubmit): Promise<any> => {
        return updateTransaction(transaction_id, convertFormOutputDataToTransactionForUpdate(output.data))
            .then(() => {
                return Promise.all([
                    ...(output.newAttachments?.map(data => createAttachment(transaction_id, data)) || []),
                    ...(output.deleteAttachments?.map(data => deleteAttachment(transaction_id, data.fileId)) || []),
                ]);
            });
    };

    const handleSuccessfulCallback = () => {
        snackbarStore.open(SnackbarStore.SUCCESS, 'Transaction modified successfully!');
        transactionStore.removeTransaction(transaction_id);

        navigate('/transactions');
    };

    const handleUnsuccessfulCallback = (x: any) => {
        snackbarStore.open(SnackbarStore.FAIL);
    };

    return (
        <PageOuterContainer>
            <PageItem
                title="Edit Transaction"
                subtitle={`Unique ID: ${transaction.transaction_id}`}
            >
                <TransactionForm
                    scope={TransactionFormScope.EDIT}
                    transaction={transaction}
                    onSubmit={handleSubmit}
                    successfulCallback={handleSuccessfulCallback}
                    unsuccessfulCallback={handleUnsuccessfulCallback}
                />
            </PageItem>
        </PageOuterContainer>
    )
};
