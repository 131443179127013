export interface ICategory {
    id: number;
    name: string;
    code: string;
    is_global: boolean;
    parent_category_id?: number | null;
    subcategories?: ICategory[];
}

export enum CategoryType {
    BREAKFAST = 10,
    CAR_WASH = 39,
    DINNER = 12,
    DRINKS = 31,
    GROCERY = 2,
    LUNCH = 11,
    FUEL = 36,
    ONLINE_SHOPPING = 30,
    SHOPPING = 33,
    SNACKS = 13,
    VEHICLE_FOR_HIRE = 41,
}

export interface IFormData {
    name: string;
    code: string;
    is_global: boolean;
    parent_category_id?: number | null;
}
