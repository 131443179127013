import * as React from "react";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {Edit} from "@mui/icons-material";

export const GridEditAction = (onClick: any) => (
    <GridActionsCellItem
        sx={{padding: 0, margin: 0}}
        icon={<Edit sx={{fontSize: 24}} color="primary"/>}
        onClick={onClick}
        label="View"
    />
);
