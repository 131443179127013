import * as React from "react";
import {ISnapshotTimeline} from "../../../../config/snapshot";
import {periodPercentageComparison} from "../../../../helpers/snapshot_helpers";
import {Currency} from "../../../../config/currencies";
import "../../../../utils/number_extensions";
import {SnapshotCard, SnapshotCardColor} from "../../../Components/SnapshotCard";
import {Trend} from "../../../Components/Icons/TrendIcon";
import TimelineIcon from "@mui/icons-material/Timeline";

interface IAverageSpentPerDayItemProps {
    snapshotTimeline: ISnapshotTimeline;
    currency: Currency;
}

export const AverageSpentPerDayItem = ({snapshotTimeline, currency}: IAverageSpentPerDayItemProps) => {
    return (
        <SnapshotCard
            title="Average per day"
            icon={<TimelineIcon />}
            color={SnapshotCardColor.ORANGE}
            trend={snapshotTimeline.current.average_spent_per_day > snapshotTimeline.previous.average_spent_per_day ? Trend.UP : Trend.DOWN}
            value={(snapshotTimeline.current.average_spent_per_day as any).toCurrency(currency, {minimumFractionDigits: 0})}
            details={`${periodPercentageComparison(snapshotTimeline, 'average_spent_per_day')} from last month`}
            target="/dashboard/charts"
        />
    );
};
