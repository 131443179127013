import {Typography, styled} from "@mui/material";

export const PreviousPeriodContainer = styled('div')(({theme}) => ({
    marginTop: -10,
    display: 'flex',
}));

export const PreviousPeriodContent = styled(Typography)(({theme}) => ({
    color: '#000',
    textShadow: `0px 0px 10px #fff`,
}));

export const Amount = styled('span')(({theme}) => ({
    fontWeight: 'bold',
}));

export const PreviousPeriodIcon = styled('div')(({theme}) => ({
    marginRight: theme.spacing(1),
    transform: 'rotate(90deg)',
    lineHeight: 0,

    [theme.breakpoints.down('md')]: {
        color: 'white',
    },
}));
