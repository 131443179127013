import * as React from "react";
import * as moment from "moment";
import {fetchTransactions} from "../../../../stores/transactions";
import {TransactionsList, TransactionsListLoader} from "../../../Transactions/Components/List/TransactionsList";
import {styled} from "@mui/material";
import {TransactionsListAction} from "../../../Transactions/Components/List/config/config";
import {useEffect, useState} from "react";
import {ITransactionsSearchQuery, TRANSACTIONS_FETCH_LIMIT} from "../../../../config/transactions";

const Container = styled('div')(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginLeft: theme.spacing(-2),
    marginRight: theme.spacing(-2),
}));

interface IDayDetailChartProps {
    day: moment.Moment;
}

export const DayDetailChart = ({day}: IDayDetailChartProps) => {
    const [loader, setLoader] = useState<TransactionsListLoader>();

    useEffect(() => {
        const query = {
            from: day.format('YYYY-MM-DD'),
            to: day.format('YYYY-MM-DD'),
        } as ITransactionsSearchQuery;

        const request = () => {
            return (page: number = 1) => {
                return fetchTransactions(query, TRANSACTIONS_FETCH_LIMIT, page * TRANSACTIONS_FETCH_LIMIT);
            };
        };

        setLoader(request);
    }, [day]);

    return (
        <Container>
            <TransactionsList hideFooter loader={loader} actions={[TransactionsListAction.VIEW]} />
        </Container>
    )
};
