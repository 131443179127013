import * as React from "react";
import {EventStoreContext} from "../../contexts/event_store_context";
import {createStore} from "../../stores/events";
import {Outlet} from "react-router-dom";
import { PageOuterContainer } from "../Components/PageOuterContainer";

const eventStore = createStore();

export const Events = () => (
    <EventStoreContext.Provider value={eventStore}>
        <PageOuterContainer>
            <Outlet />
        </PageOuterContainer>
    </EventStoreContext.Provider>
);
