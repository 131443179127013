import * as React from "react";
import {ITransaction} from "../../../../../config/transactions";
import {Currency} from "../../../../../config/currencies";

interface IAmountFieldProps {
    transaction: ITransaction;
    currency: Currency;
}

export const AmountField = ({transaction, currency}: IAmountFieldProps) => {
    const amount = (transaction.amount as any).toCurrency(currency);

    if (currency === transaction.original_currency) {
        return (
            <div>
                {amount}
            </div>
        );
    }

    const originalAmount = (transaction.original_amount as any).toCurrency(transaction.original_currency);

    return (
        <div>
            {originalAmount}<br />
            <small>({amount})</small>
        </div>
    );
};
