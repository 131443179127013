import * as React from "react";
import { action, makeObservable, observable } from "mobx";

export class LayoutStore {
    @observable headerActions: React.ReactElement | any = null;

    constructor() {
        makeObservable(this);
    }

    @action
    setHeaderActions(headerActions: React.ReactElement | any) {
        this.headerActions = headerActions;
    }

    @action
    resetHeaderActions() {
        this.headerActions = null;
    }
}
