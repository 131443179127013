import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Flag } from '../Icons/Flag';
import { ICountry, countries } from '../../../config/countries';
import { FormHelperText } from '@mui/material';
import { ValidationError, convertToTranslationKeys } from '../../../services/validator';
import { t } from 'i18next';

interface ICountryFieldProps {
    value?: ICountry;
    onChange(country?: ICountry): void;
    errors?: ValidationError[];
}

export const CountryField = ({onChange, value, errors}: ICountryFieldProps) => {
    const [country, setCountry] = React.useState<ICountry | undefined>(value);
    const [inputValue, setInputValue] = React.useState('');

    const handleInputChange = (event: any, newInputValue: string) => {
        setInputValue(newInputValue);
    };

    const handleChange = (event: any, newValue: ICountry) => {
        setCountry(newValue);
    };

    React.useEffect(() => {
        setCountry(value);
    }, [value]);

    React.useEffect(() => {
        onChange(country);
    }, [country]);

    return (
        <>
            <Autocomplete
                value={country || null}
                options={countries}
                inputValue={inputValue}
                onInputChange={handleInputChange}
                onChange={handleChange as any}
                autoHighlight
                getOptionLabel={(option) => option.label}
                renderOption={(props, option) => (
                    <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                        <Flag countryCode={option.code} />
                        {option.label} ({option.code}) +{option.phone}
                    </Box>
                )}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="Choose a country"
                        inputProps={{
                            ...params.inputProps,
                            autoComplete: 'new-password', // disable autocomplete and autofill
                        }}
                    />
                )}
            />

            {!!errors && !!errors.length &&
                <FormHelperText error>
                    {convertToTranslationKeys(errors).map(e => t(e)).join(', ')}
                </FormHelperText>
            }
        </>
    );
}
