import {useLocation} from "react-router-dom";
import {getTitleByLocation} from "../services/header_title_provider";
import {useTranslation} from "react-i18next";

export const usePageTitle = () => {
    const { t } = useTranslation();
    const location = useLocation();

    const { sectionTitle, pageTitle, params } = getTitleByLocation(location.pathname);

    return {
        sectionTitle: !!sectionTitle ? t(sectionTitle, params || {}) : null,
        pageTitle: t(pageTitle),
    };
};
