import * as React from "react";
import "../../../../utils/number_extensions";
import {IYearSnapshotItem} from "../../../../config/snapshot";
import CardContent from "@mui/material/CardContent";
import Card from "@mui/material/Card";
import {Currency} from "../../../../config/currencies";
import {useEffect, useState} from "react";
import {styled} from "@mui/material";
import {LinkableCardActionArea} from "../../../Components/LinkableCardActionArea";
import {useParams} from "react-router-dom";

const Container = styled('div')(({theme}) => ({
    marginRight: theme.spacing(3),
}));

const Year = styled('span')(({theme}) => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(2),
    textAlign: 'center',
    fontSize: 46,
}));

const Total = styled('div')(() => ({
    textAlign: 'center',
}));

interface IYearCardProps {
    year: IYearSnapshotItem;
    currency: Currency;
}

export const YearCard = ({year, currency}: IYearCardProps) => {
    const { year: yearParam } = useParams();
    const [active, setActive] = useState<boolean>(false);

    useEffect(() => {
        setActive(yearParam === year.year);
    }, [yearParam]);

    return (
        <Container>
            <Card sx={(!active ? {} : {
                backgroundColor: (theme) => theme.palette.primary.main,
                color: '#fff',
            })}>
                <LinkableCardActionArea target={active ? undefined : `/dashboard/years/${year.year}`}>
                    <CardContent>
                        <Year>{year.year}</Year>
                        <Total>{(year.total as any).toCurrency(currency)}</Total>
                    </CardContent>
                </LinkableCardActionArea>
            </Card>
        </Container>
    );
};
