import * as React from "react";
import "../../../utils/array_extentions";
import {IStatsPaymentMethod} from "../../../config/stats";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {PieChart} from "../../Components/Charts/PieChart";

interface IPaymentMethodsChartProps {
    paymentMethods: IStatsPaymentMethod[];
    chartHeight: number;
}

export const PaymentMethodsChart = ({paymentMethods, chartHeight}: IPaymentMethodsChartProps) => {
    const { currencyStore } = useGlobalStores();

    let data = paymentMethods as any;
    data = data.removeNegativeValuesFromObject('total');
    data.sortArrayOfObjectsDesc('total');

    return (
        <PieChart
            dataset={data.map((v: any) => ({
                value: v.total,
                label: v.name === '___NA___' ? 'Others...' : v.name,
            }))}
            size={chartHeight}
            valueFormatter={(v: any) => {
                return v.value.toCurrency(currencyStore.currentCurrency, {});
            }}
        />
    );
};
