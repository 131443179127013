import * as React from "react";
import { ILineItem } from "./LineItemsField";
import { Add } from "@mui/icons-material";
import { LineItemForm } from "./LineItemForm";

interface IDeleteActionProps {
    onChange(value: ILineItem): void;
}

export const AddAction = ({onChange}: IDeleteActionProps) => {
    return (
        <LineItemForm
            title="Add new line item"
            onChange={onChange}
            trigger={<Add sx={{fontSize: 18}} />}
        />
    );    
};
