import * as React from "react";
import {FieldWrapper} from "./FieldWrapper";
import {PaymentProviders} from "../../../../../../../config/payment_providers";
import { SelectField } from "../../../../../../Components/Form/SelectField";

export const PaidViaField = ({value, onChange, onDelete}: any) => (
    <FieldWrapper onDelete={onDelete}>
        <SelectField
            value={value}
            onChange={onChange}
            label="Paid via"
            options={PaymentProviders}
            optionLabelGetter={o => o.label}
            optionValueGetter={o => o.code}
        />
    </FieldWrapper>
);
