import * as React from "react";
import { SaveButton } from "../../SaveButton";

interface ISubmitProps {
    loading: boolean;
    onSubmit(e: any): void;
}

export const Submit = ({loading, onSubmit}: ISubmitProps) => {
    return (
        <SaveButton loading={loading} onSubmit={onSubmit} />
    );
};
