import {fetchFormPost, fetchGet, fetchPost} from "../services/api";
import {IBulkConfig, IBulkDryRunResult} from "../config/bulk";
import moment from "moment";
import {INewTransaction} from "../config/transactions";

export const getBulkConfig = (): Promise<IBulkConfig> => {
    return fetchGet('/transactions/bulk/config')
        .then(response => response.json())
        .then(data => {
            return data.data;
        });
}

export const bulkUpload = (data: FormData): Promise<IBulkDryRunResult[]> => {
    return fetchFormPost('/transactions/bulk/upload', data)
        .then((response: any) => {
            if (response.status !== 200) {
                return Promise.reject(response.json());
            }

            return response.json();
        })
        .then(data => {
            return data.data.map((item: any) => {
                item.date = moment(item.date);

                return item;
            });
        });
}

export const bulkCreate = (data: INewTransaction[]): Promise<Response | void> => {
    return fetchPost('/transactions/bulk', data).then((response: any) => {
        if (response.status !== 200) {
            return Promise.reject(response);
        }

        return Promise.resolve();
    });
};
