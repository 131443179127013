import * as React from "react";
import "../../../utils/array_extentions";
import {IStatsCategory} from "../../../config/stats";
import {getTopCategories} from "../../../helpers/dashboard_charts_helpers";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {PieChart} from "../../Components/Charts/PieChart";

interface ICategoriesChartProps {
    categories: IStatsCategory[];
    chartHeight: number;
    showTopCategories?: number;
    showLegend?: boolean;
    callbacks?: {
        onMouseOver(category: IStatsCategory): void;
        onMouseOut(): void;
    };
}

export const CategoriesChart = React.memo(({categories, chartHeight, showTopCategories, showLegend = true, callbacks}: ICategoriesChartProps) => {
    const { currencyStore } = useGlobalStores();

    let data = categories as any;
    data = data.removeNegativeValuesFromObject('total');
    data.sortArrayOfObjectsDesc('total');

    if (showTopCategories) {
        data = getTopCategories(data, showTopCategories);
    }

    return (
        <PieChart
            dataset={data.map((v: any) => ({
                value: v.total,
                label: v.name === '___NA___' ? 'Others...' : v.name,
            }))}
            size={chartHeight}
            margin={{bottom: 65}}
            valueFormatter={(v: any) => {
                return v.value.toCurrency(currencyStore.currentCurrency, {});
            }}
        />
    );
}, (prevProps, nextProps) => {
    return prevProps.categories === nextProps.categories;
});
