import * as React from "react";
import {FieldWrapper} from "../FieldWrapper";
import {IVehicleForHireDetails, distances} from "../../../../../../../../config/vehicle_for_hire";
import {styled} from "@mui/material";
import {grey} from "@mui/material/colors";
import {TransactionValidationErrors, TransactionValidationField} from "../../../../../../../../validators/transactions";
import {TripMap} from "./TripMap";
import {PickupTime} from "./PickupTime";
import {useEffect} from "react";
import { useForm } from "../../../../../../../../hooks/use_form_v2";
import { useTransactionFormStore } from "../../../../../../../../hooks/use_transaction_form_store copy";
import { AttachmentType } from "../../../../../../../../config/attachments";
import { toForm } from "../../../../../../../../helpers/transaction_details_vehicle_for_hire_helpers";
import { Form } from "../../../../../../../Components/Form/Form/Form";
import { FreeTextField } from "../../../../../../../Components/Form/FreeTextField";
import { SelectField } from "../../../../../../../Components/Form/SelectField";

const Container = styled('div')(({theme}) => ({
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    border: 'solid 1px transparent',
    borderLeftStyle: 'solid',
    borderLeftWidth: theme.spacing(1),
    borderLeftColor: grey[300],
}));

interface IVehicleForHireField {
    value?: IVehicleForHireDetails;
    onChange(value: IVehicleForHireDetails): void;
    onDelete(): void;
    errors?: TransactionValidationErrors;
}

export const VehicleForHireField = ({value, onChange, onDelete, errors}: IVehicleForHireField) => {
    const { pushAttachmentStore } = useTransactionFormStore();

    const [values, setValues] = React.useState(toForm(value));

    const listener = (values: IVehicleForHireDetails) => {
        setValues(values);
    };

    const form = useForm<IVehicleForHireDetails>(values, {listener: listener});
    const { register } = form;

    useEffect(() => {
        if (!values.tripMapFile) {
            return;
        }

        pushAttachmentStore.addNewAttachment({
            type: AttachmentType.MAP,
            file: values.tripMapFile,
        });
    }, [values.tripMapFile]);

    useEffect(() => {
        onChange(values);
    }, [values]);

    return (
        <FieldWrapper onDelete={onDelete}>
            <Form form={form} component={Container} withSubmitButton={false} onSubmit={(values: any) => {return Promise.resolve()}}>
                <Form.Field
                    type={FreeTextField}
                    {...register('addressFrom')}
                    errors={errors?.get(TransactionValidationField.VEHICLE_FOR_HIRE_PICKUP_ADDRESS)}
                    gridProps={{md: 6}}
                />
                <Form.Field
                    type={PickupTime}
                    {...register('pickupTime')}
                    errors={errors}
                    gridProps={{md: 6}}
                />

                <Form.Field
                    type={FreeTextField}
                    {...register('addressTo')}
                    errors={errors?.get(TransactionValidationField.VEHICLE_FOR_HIRE_DROP_OFF_ADDRESS)}
                    gridProps={{md: 6}}
                />
                <Form.Field
                    type={PickupTime}
                    {...register('dropoffTime')}
                    errors={errors}
                    gridProps={{md: 6}}
                />

                <Form.Field
                    type={SelectField}
                    {...register('distanceUnit')}
                    gridProps={{sm: 2}}
                    options={distances}
                    optionValueGetter={(o: any) => o.unit}
                    optionLabelGetter={(o: any) => o.label}
                    errors={errors?.get(TransactionValidationField.VEHICLE_FOR_HIRE_DISTANCE_UNIT)}
                />
                <Form.Field
                    type={FreeTextField}
                    {...register('distance')}
                    errors={errors?.get(TransactionValidationField.VEHICLE_FOR_HIRE_DISTANCE)}
                    gridProps={{sm: 10}}
                />

                <Form.Field
                    type={TripMap}
                    {...register('tripMapFile')}
                />

                <Form.Field
                    type={FreeTextField}
                    {...register('driverName')}
                />
            </Form>
        </FieldWrapper>
    );
};
