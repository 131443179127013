export enum SnapshotVersion {
    LEGACY = 1,
    V3 = 2,
}

export const snapshotVersions = [
    SnapshotVersion.LEGACY,
    SnapshotVersion.V3,
];

export const snapshotVersionLabels = {
    [SnapshotVersion.LEGACY]: 'Legacy',
    [SnapshotVersion.V3]: 'V3',
} as any;

export interface ISnapshot {
    transactions_count: number;
    average_spent_per_day: number;
    amount: number;
    budget_left: number;
}

export interface ISnapshotV3 {
    income_total: number;
    income_left: number;
    amount: number;
    amount_difference: number;
}

export interface ISnapshotTimeline {
    current: ISnapshot;
    previous: ISnapshot;
}

export interface IYearSnapshotItem {
    year: string;
    total: number;
}

export interface IMonthSnapshotItem {
    month: string;
    total: number;
}

export interface ISingleYear {
    transactions: IMonthSnapshotItem[];
    extra_incomes: IMonthSnapshotItem[];
}
