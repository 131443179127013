import * as React from "react";
import "../../../utils/array_extentions";
import {DashboardItemsContainer} from "../Components/DashboardItemsContainer";
import {useDashboardStores} from "../../../hooks/use_dashboard_stores";
import Grid from "@mui/material/Grid";
import {CompanyCard} from "./BoxView/CompanyCard";
import {useEffect, useState} from "react";
import {PageLoader} from "../../Components/PageLoader";
import {fetchTransactionsStats} from "../../../stores/dashboard";
import Error from "../../Components/Error";
import {computed} from "mobx";
import {IStatsLocation} from "../../../config/stats";
import {CompanyAppearance, CompanySortBy} from "../../../config/company";
import {ListView} from "./ListView/ListView";
import {AppearanceAction} from "./Actions/AppearanceAction";
import {SortByAction} from "./Actions/SortByAction";
import {NoCompanies} from "./Components/NoCompanies";
import {useMediaQuery, useTheme} from "@mui/material";
import {ActionsContainer} from "./Components/ActionsContainer";

export const Locations = () => {
    const isSmallScreen = useMediaQuery(useTheme().breakpoints.down('md'));
    const { dashboardStore, searchStore } = useDashboardStores();

    const defaultViewType = isSmallScreen ? CompanyAppearance.LIST : CompanyAppearance.BOXES;

    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [viewType, setViewType] = useState<CompanyAppearance>(defaultViewType);
    const [sortBy, setSortBy] = useState<CompanySortBy>(CompanySortBy.NAME);

    const locations = computed((): IStatsLocation[] => {
        if (!dashboardStore.stats) {
            return [];
        }

        const data = dashboardStore.stats.locations.slice() as any;

        switch (sortBy) {
            case CompanySortBy.NAME: data.sortArrayOfObjectsAsc('name'); break;
            case CompanySortBy.TRANSACTIONS_COUNT: data.sortArrayOfObjectsDesc('count'); break;
            case CompanySortBy.TOTAL_AMOUNT: data.sortArrayOfObjectsDesc('total'); break;
        }

        return data;
    });

    const handleAppearanceChange = (type: CompanyAppearance) => {
        setViewType(type);
    };

    const handleSortByChange = (sortBy: CompanySortBy) => {
        setSortBy(sortBy);
    };

    useEffect(() => {
        if (!!dashboardStore.stats) {
            return;
        }

        setLoading(true);
        setError(false);
        fetchTransactionsStats(searchStore.queryRequest)
            .then(stats => dashboardStore.stats = stats)
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    }, []);

    if (loading) {
        return (
            <PageLoader />
        );
    }

    if (error) {
        return (
            <Error />
        );
    }

    if (!locations.get().length) {
        return (
            <NoCompanies />
        );
    }

    return (
        <>
            <ActionsContainer isSmallScreen={isSmallScreen}>
                <AppearanceAction defaultView={defaultViewType} onSelect={handleAppearanceChange} />
                <SortByAction defaultSort={CompanySortBy.NAME} onSelect={handleSortByChange}/>
            </ActionsContainer>

            {viewType === CompanyAppearance.BOXES &&
                <DashboardItemsContainer spacing={2}>
                    {locations.get().map(company => (
                        <Grid item key={company.name} xs={12} sm={4} md={3} lg={2} xl={2}>
                            <CompanyCard location={company}/>
                        </Grid>
                    ))}
                </DashboardItemsContainer>
            }

            {viewType === CompanyAppearance.LIST &&
                <ListView smallScreen={isSmallScreen} companies={locations.get()} />
            }
        </>
    );
};
