import * as React from "react";
import {Autocomplete, debounce} from "@mui/material";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import { search } from "../../../../stores/geocoding";
import { IGeocodingCity, IGeocodingRequest } from "../../../../config/geocoding";
import { useGlobalStores } from "../../../../hooks/use_global_stores";

interface IGeocodingLocationFieldAsyncProps {
    countryCode: string;
    onChange(city: IGeocodingCity): void;
    useCache?: boolean;
}

export const GeocodingLocationFieldAsync = ({countryCode, onChange, useCache}: IGeocodingLocationFieldAsyncProps) => {
    const { geocodingStore } = useGlobalStores();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [options, setOptions] = React.useState<IGeocodingCity[]>([]);

    const searchDebounced = debounce((query: IGeocodingRequest) => {
        search(query).then(result => {
            setLoading(false);
            setOptions(result || []);
        });
    }, 500);

    const handleInputChange = (event: object, value: string, reason: string) => {
        if (reason === 'reset') {
            return;
        }

        if (!loading) {
            setLoading(true);
        }

        if (value.trim() === '') {
            return;
        }

        searchDebounced({country_code: countryCode, city: value});
    };

    const handleChange = (event: any, city: IGeocodingCity | null) => {
        if (!city) {
            return;
        }

        if (useCache) {
            geocodingStore.add(city);
        }

        onChange(city);
    };

    React.useEffect(() => {
        if (!useCache) {
            return;
        }

        if (!countryCode) {
            return;
        }

        setOptions(geocodingStore.getAllByCountryCode(countryCode));
    }, [countryCode]);

    return (
        <Autocomplete
            options={options}
            getOptionLabel={(o: IGeocodingCity) => `${o.city}${!!o.state ? `, ${o.state}` : ''}`}
            onChange={handleChange}
            multiple={false}
            onInputChange={handleInputChange}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label="City"
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {loading ? <CircularProgress color="primary" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    );
};
