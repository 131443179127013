import * as React from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { ILineItem, LineItemsDisplayScope } from "./LineItemsField";
import { styled } from "@mui/material";
import { DeleteAction } from "./DeleteAction";
import { EditAction } from "./EditAction";
import { AddAction } from "./AddAction";
import "../../../../../../../../utils/number_extensions";
import { Currency } from "../../../../../../../../config/currencies";
import { DataTableHeader } from "./DataTableHeader";

const StyledTableCell = styled(TableCell)(({theme}) => ({
    textAlign: 'center',
}));

const DescriptionTableCell = styled(TableCell)(({theme}) => ({
    padding: 5,
    textAlign: 'left',
}));

const ActionsTableCell = styled(TableCell)(({theme}) => ({
    width: 60,
    margin: 0,
    padding: 0,
}));

interface IDataTableProps {
    scope: LineItemsDisplayScope;
    value: ILineItem[];
    onChange(value: ILineItem[]): void;
    currency?: Currency;
}

export const DataTable = ({scope, value, onChange, currency = Currency.EUR}: IDataTableProps) => {
    const [values, setValues] = React.useState<ILineItem[]>(value);

    const handleAdd = (value: ILineItem) => {
        setValues(oldArray => [...oldArray, value]);
    };

    const handleDelete = (value: number) => () => {
        setValues(oldArray => oldArray.filter((_, index) => index !== value));
    };

    const handleEdit = (index: number) => (value: ILineItem) => {
        setValues(oldArray => {
            return oldArray.map((item, i) => {
                if (index === i) {
                    return value;
                } else {
                    return item;
                }
            });
        });
    };

    // React.useEffect(() => {
    //     setValues(value);
    // }, [value]);

    React.useEffect(() => {
        onChange(values);
    }, [values]);

    if (!values.length) {
        if (scope === LineItemsDisplayScope.TRANSACTION_VIEW) {
            return null;
        }

        return (
            <div>
                <AddAction onChange={handleAdd} />
            </div>
        );
    }

    return (
        <TableContainer>
            <Table size="small">
                    <DataTableHeader scope={scope} onAdd={handleAdd} />
                    <TableBody>
                        {values.map((row, index) => (
                            <TableRow
                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                {scope === LineItemsDisplayScope.FORM && <ActionsTableCell>
                                    <EditAction value={row} onChange={handleEdit(index)} />
                                    <DeleteAction onDelete={handleDelete(index)} />
                                </ActionsTableCell>}
                                <DescriptionTableCell component="th" scope="row">{row.description}</DescriptionTableCell>
                                <StyledTableCell>{row.quantity}</StyledTableCell>
                                <StyledTableCell>
                                    {(row.unit_price as any).toCurrency(currency)}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {(row.total_amount as any).toCurrency(currency)}
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
    );
}
