import * as React from "react";
import {LoginForm, ILoginFormOutputData} from "./LoginForm";
import {Wrapper} from "./Components/Wrapper";
import {Header} from "./Components/Header";
import {useTranslation} from "react-i18next";
import {useGlobalStores} from "../../hooks/use_global_stores";
import {LoginService} from "../../services/login";

export const Login = () => {
    const { t, i18n } = useTranslation();
    const { authStore, userStore } = useGlobalStores();

    const [error, setError] = React.useState('');
    const [loading, setLoading] = React.useState(false);

    const loginService = new LoginService(authStore, userStore);

    const handleSubmit = (data: ILoginFormOutputData, rememberMe: boolean) => {
        setLoading(true);
        setError('');

        loginService.auth(data, rememberMe)
            .then(() => {
                i18n.changeLanguage(userStore.currentUser?.default_language);

                // setLoading(false);
            })
            .catch(response => {
                setLoading(false);
                setError(response.status === 400 ? response.error : t('global.errors.unknown'));
            });
    };

    return (
        <Wrapper>
            <Header />
            <LoginForm loading={loading} error={error} onSubmit={handleSubmit}/>
        </Wrapper>
    );
};
