import * as React from "react";
import {useState} from "react";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {sleep} from "../../../../utils/sleep";
import {useTranslation} from "react-i18next";
import {BulkAction} from "../../Components/List/BulkContainer";
import {FormModal} from "../../../Components/FormModal";
import {TransactionForm} from "../../Components/Form/TransactionForm";
import {TransactionFormField, TransactionFormScope} from "../../Components/Form/config/config";
import {ITransactionFormSubmit} from "../../Components/Form/config/models";
import {convertFormOutputDataToBulkUpdate} from "../../../../helpers/transaction_helpers";
import {updateBulk} from "../../../../stores/transactions";
import {FieldsSelector} from "./Edit/FieldsSelector";
import { Level } from "../../../../config/auth";
import { ProButton } from "../../../Pro/Components/ProButton";
import { Feature } from "../../../../config/features";

interface IEditActionProps {
    transactionIds: string[];
    onSuccess(action: BulkAction): void;
}

export const EditAction = ({transactionIds, onSuccess}: IEditActionProps) => {
    const { t } = useTranslation();

    const { snackbarStore, refreshStore } = useGlobalStores();

    const [open, setOpen] = useState<boolean>(false);
    const [loading, ] = useState<boolean>(false);
    const [fields, setFields] = useState<TransactionFormField[]>([]);

    const handleEdit = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSuccessfulCallback = () => {
        sleep(250).then(() => {
            setOpen(false);
            snackbarStore.open(true);
            refreshStore.refreshDashboard();
            refreshStore.refreshTransactionsList();

            onSuccess(BulkAction.EDIT);
        });
    };

    const handleFieldSelected = (fields: TransactionFormField[]) => {
        setFields(fields);
    };

    const handleUnsuccessfulCallback = () => {
        snackbarStore.open(false);
    };

    const handleSubmit = (output: ITransactionFormSubmit): Promise<any> => {
        if (!fields.length) {
            return Promise.reject();
        }

        return updateBulk(convertFormOutputDataToBulkUpdate(transactionIds, output.data, fields));
    };

    return (
        <>
            <FormModal title={t('bulk.action.edit.title')} open={open} loading={loading} onClose={handleClose}>
                <>
                    <FieldsSelector onSelect={handleFieldSelected} />
                    <TransactionForm
                        scope={TransactionFormScope.BULK_EDIT}
                        fields={fields}
                        onSubmit={handleSubmit}
                        successfulCallback={handleSuccessfulCallback}
                        unsuccessfulCallback={handleUnsuccessfulCallback}
                    />
                </>
            </FormModal>

            <ProButton min={Level.PRO} feature={Feature.TRANSACTIONS_BULK_EDIT} text={t('global.buttons.edit')} onClick={handleEdit} variant="contained" color="primary">
                {t('global.buttons.edit')}
            </ProButton>
        </>
    );
};
