import * as React from "react";
import * as moment from "moment";
import FormControl from '@mui/material/FormControl';
import FormHelperText from "@mui/material/FormHelperText";
import {convertToTranslationKeys, ValidationError} from "../../../../../services/validator";
import {useTranslation} from "react-i18next";
import {DatePicker, LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

interface IPurchaseDateFieldProps {
    value: moment.Moment;
    onChange(value: moment.Moment | null): void;
    errors?: ValidationError[];
}

export const PurchaseDateField = ({value, onChange, errors}: IPurchaseDateFieldProps) => {
    const { t } = useTranslation();

    return (
        <LocalizationProvider dateAdapter={AdapterMoment}>
            <FormControl fullWidth variant="outlined">
                <DatePicker
                    label="Purchased date"
                    format="MMMM Do YYYY"
                    value={value}
                    onChange={onChange}
                />
                {!!errors && !!errors.length &&
                    <FormHelperText error>
                        {convertToTranslationKeys(errors).map((e: any) => t(e)).join(', ')}
                    </FormHelperText>
                }
            </FormControl>
        </LocalizationProvider>
    );
}
