import * as React from "react";
import IconButton from '@mui/material/IconButton';
import PauseIcon from '@mui/icons-material/Pause';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import {IRecurringExpense} from "../../../../config/recurring_expense";
import {SnackbarStore} from "../../../../stores/snackbar";
import {toggleActiveRecurringExpense} from "../../../../stores/recurring_expense";
import {CircularProgress} from "@mui/material";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {useRecurringExpenseStore} from "../../../../hooks/use_recurring_expense_store";
import {runInAction} from "mobx";

interface IActiveToggleActionProps {
    recurringExpense: IRecurringExpense;
}

export const ActiveToggleAction = ({recurringExpense}: IActiveToggleActionProps) => {
    const { snackbarStore } = useGlobalStores();
    const recurringExpenseStore = useRecurringExpenseStore();

    const [loading, setLoading] = React.useState(false);

    const handleToggle = () => {
        if (loading) {
            return;
        }

        setLoading(true);

        toggleActiveRecurringExpense(recurringExpense.id, !recurringExpense.active)
            .then((recurringExpense: IRecurringExpense) => {
                setLoading(false);

                const action = recurringExpense.active ? 'suspended' : 'resumed';

                snackbarStore.open(SnackbarStore.SUCCESS, `Recurring Expense ${action} successfully!`);
                recurringExpenseStore.replaceRecurringExpense(recurringExpense);
                runInAction(() => {
                    recurringExpenseStore.shouldStatsBeReloaded = true;
                });
            });
    };

    return (
        <IconButton key="delete-button" size="small" color="primary" disabled={loading} onClick={handleToggle}>
            {!loading ? (recurringExpense.active ? <PauseIcon /> : <PlayArrowIcon />) : <CircularProgress size={24} />}
        </IconButton>
    );
};
