import {action, computed, makeObservable, observable} from "mobx";
import {IUser} from "../config/user";
import {fetchGet, fetchPatch, fetchPost} from "../services/api";
import {IUserPaymentMethod} from "../config/user_payment_method";
import {defaultLanguage} from "../config/i18n";
import {IProfileFormOutputData} from "../components/Settings/Profile/Profile";
import {IChangePasswordState} from "../components/Settings/ChangePassword/ChangePassword";
import {convertFromResponse} from "../validators/change_password";

const USER_CACHE_KEY = 'em:user:profile';

export class UserStore {
    @observable private user: IUser | undefined;

    constructor() {
        makeObservable(this);

        const data = window.localStorage.getItem(USER_CACHE_KEY);
        if (!data) {
            return;
        }

        this.user = JSON.parse(data);
    }

    @action
    setUser(user: IUser) {
        this.user = user;

        const data = {
            id: user.id,
            email: user.email,
            default_currency: user.default_currency,
            default_payment_method_id: user.default_payment_method_id,
            default_language: user.default_language || defaultLanguage,
        };

        window.localStorage.setItem(USER_CACHE_KEY, JSON.stringify(data));
    }

    @action
    setDefaultPaymentMethod(paymentMethod: IUserPaymentMethod) {
        if (!this.user) {
            return;
        }

        this.user.default_payment_method_id = paymentMethod.id;

        this.setUser(this.user);
    }

    @computed get currentUser(): IUser | null {
        return this.user || null;
    }
}

export enum EgoFetchInclude {
    PAYMENT_METHODS = "payment_methods",
    INCOMES = "incomes",
}

interface IFetchMeRequestParams {
    include: EgoFetchInclude[];
}

export const fetchEgo = ({include}: IFetchMeRequestParams): Promise<IUser> => {
    return fetchGet( '/ego', {include: include.join(',')})
        .then(response => response.json())
        .then(data => data.data);
};

export const updateEgo = (data: IProfileFormOutputData): Promise<IUser> => {
    return fetchPatch( '/ego', data)
        .then((response: any) => {
            if (response.status !== 200) {
                return Promise.reject(response);
            }

            return response.json();
        })
        .then(data => data.data);
};

export const updatePassword = (data: IChangePasswordState): Promise<Boolean> => {
    return fetchPost( '/ego/change_password', data)
        .then((response: any) => {
            if (response.status !== 200) {
                return response.json().then((reason: any) => {
                    if (reason.errors === undefined) {
                        return Promise.reject(reason);
                    }

                    return Promise.reject(convertFromResponse(reason.errors));
                });
            }

            return response.json();
        })
        .then(data => data.data);
};
