import {AuthStore} from "../stores/auth";

export class Api {
    private readonly base: string;

    constructor(base: string) {
        this.base = base;
    }

    public GET(url: string, query: object | string[][] | Record<string, string> | string | URLSearchParams = {}, props: RequestInit = {}): Promise<any> {
        return fetch(this.base + url + '?' + new URLSearchParams(query as any), {
            mode: 'cors',
            headers: {
                ...this.API_REQUEST_HEADERS(),
            },
            ...props
        });
    }

    public POST(url: string, data: object | FormData = {}): Promise<any> {
        return this.post(url, 'POST', data);
    }

    public PUT(url: string, data: object | FormData = {}): Promise<any> {
        return this.post(url, 'PUT', data);
    }

    public PATCH(url: string, data: object | FormData = {}): Promise<any> {
        return this.post(url, 'PATCH', data);
    }

    public DELETE(url: string, data: object = {}) {
        return this.post(url, 'DELETE', data);
    }

    public post(url: string, method: 'POST' | 'PUT' | 'PATCH' | 'DELETE', data: object | FormData = {}): Promise<any> {
        const init = {
            mode: 'cors',
            headers: {
                ...this.API_REQUEST_HEADERS(),
            },
            method: method,
        } as RequestInit | any;

        if (data instanceof FormData) {
            init.body = data;
        } else {
            init.headers['Content-Type'] = 'application/json';
            init.body = JSON.stringify(data);
        }

        return fetch(this.base + url, init);
    }

    private API_REQUEST_HEADERS(): HeadersInit {
        return {
            Authorization: `Bearer ${AuthStore.getStoredAccessToken()}`,
        };
    };
}
