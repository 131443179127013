import {action, makeObservable, observable} from "mobx";
import { fetchDelete, fetchGet, fetchPost } from "../services/api";
import { RefreshItem } from "../config/global_refresh";
import { GlobalRefresher } from "../services/refresher";

export class GlobalRefreshStore {
    @observable private aknowledged: boolean = false;

    private refresher: GlobalRefresher;
    
    constructor(refresher: GlobalRefresher) {
        makeObservable(this);

        this.refresher = refresher;
    }

    async refresh(): Promise<true> {
        return this.refresher.refresh();
    }

    @action
    aknowledge() {
        this.aknowledged = true;
    }

    isAknowledged(): boolean {
        return this.aknowledged;
    }
}

export const refreshStatus = (): Promise<number> => {
    return fetchGet( '/refresh')
        .then(response => response.json())
        .then(data => data.data);
};

export const invalidate = (item: RefreshItem): Promise<boolean> => {
    return fetchPost( `/refresh`, {item})
        .then((response: any) => {
            if (response.status !== 204) {
                return Promise.reject(response);
            }

            return true;
        });
};

export const aknowledge = (items: RefreshItem[]): Promise<boolean> => {
    return fetchDelete( `/refresh`, {items})
        .then((response: any) => {
            if (response.status !== 204) {
                return Promise.reject(response);
            }

            return true;
        });
};
