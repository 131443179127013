import * as React from "react";
import { Edit } from "@mui/icons-material";
import { ILineItem } from "./LineItemsField";
import { LineItemForm } from "./LineItemForm";

interface IDeleteActionProps {
    value: ILineItem;
    onChange(value: ILineItem): void;
}

export const EditAction = ({value, onChange}: IDeleteActionProps) => {
    return (
        <LineItemForm
            title="Add new line item"
            value={value}
            onChange={onChange}
            trigger={<Edit sx={{fontSize: 18}} />}
        />
    );    
};
