import * as React from "react";
import {Link, Outlet} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {AppBar, styled, Tabs, Tab} from "@mui/material";
import {
    AccountBalance,
    AccountBalanceWallet,
    Person,
    VpnKey,
    Settings as SettingsIcon,
    Segment,
    Redeem
} from "@mui/icons-material";
import {PageOuterContainer} from "../Components/PageOuterContainer";
import { isIphone } from "../../services/user_agent_helper";

const StyledAppBar = styled(AppBar)(({theme}) => ({
    zIndex: theme.zIndex.appBar - 1,
}));

const StyledTabs = styled(Tabs)(() => ({
    "& .MuiTabs-indicator": {
        backgroundColor: '#f50057',
    },

    ".Mui-selected": {
        color: 'black!important',
    },
}));

const getTabIndexByRoute = (): number => {
    switch (window.location.pathname) {
        case '/settings/general': return 0;
        case '/settings/profile': return 1;
        case '/settings/change_password': return 2;
        case '/settings/payment_methods':
        case '/settings/payment_methods/new': return 3;
        case '/settings/incomes':
        case '/settings/incomes/new': return 4;
        case '/settings/allowances': return 5;
        case '/settings/budgets': return 6;
        case '/settings/custom_categories': return 7;
        default: return 0;
    }
};

export const Settings = () => {
    const { t } = useTranslation();

    const [value, setValue] = React.useState(getTabIndexByRoute());

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setValue(newValue);
    };

    return (
        <>
            <StyledAppBar position="relative" color="default">
                <StyledTabs
                    variant={isIphone ? "scrollable" : "fullWidth"}
                    value={value}
                    onChange={handleChange}
                    scrollButtons="auto"
                    allowScrollButtonsMobile
                >
                    <Tab label={t('settings.menu.general_settings')} icon={<SettingsIcon />} component={Link} to="/settings/general" />
                    <Tab label={t('settings.menu.profile')} icon={<Person />} component={Link} to="/settings/profile" />
                    <Tab label={t('settings.menu.password')} icon={<VpnKey />} component={Link} to="/settings/change_password" />
                    <Tab label={t('settings.menu.payment_methods')} icon={<AccountBalanceWallet />} component={Link} to="/settings/payment_methods" />
                    <Tab label={t('settings.menu.incomes')} icon={<AccountBalance />} component={Link} to="/settings/incomes" />
                    <Tab label={t('settings.menu.allowances')} icon={<Redeem />} component={Link} to="/settings/allowances" />
                    <Tab label={t('settings.menu.budgets')} icon={<Redeem />} component={Link} to="/settings/budgets" />
                    <Tab label="Custom Categories" icon={<Segment />} component={Link} to="/settings/custom_categories" />
                </StyledTabs>
            </StyledAppBar>

            <PageOuterContainer>
                <Outlet />
            </PageOuterContainer>
        </>
    );
}
