import * as React from "react";
import {Grid, GridProps, styled, Typography} from "@mui/material";

const Container = styled('div')(({theme}) => ({
    paddingBottom: theme.spacing(1),
    borderBottom: 'solid 1px',
    borderBottomColor: theme.palette.divider,
    height: '100%',
}));

const Value = styled('div')(() => ({
    fontSize: '130%',
}));

interface IItemProps extends GridProps {
    label: string;
    conditional?: boolean;
    value?: any;
    children?: any;
}

export const Item = (props: IItemProps) => {
    if (props.conditional === false) {
        return null;
    }

    return (
        <Grid item {...props}>
            <Container>
                <Typography variant="overline" display="block">{props.label}</Typography>
                <Value>{props.value || props.children}</Value>
            </Container>
        </Grid>
    );
};
