import * as React from "react";
import FormControl from "@mui/material/FormControl";
import {useTranslation} from "react-i18next";
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import FormHelperText from '@mui/material/FormHelperText';
import {useState} from "react";

interface IFileFieldProps {
    formats: string[];
    selectedFile: File;
    onChange(file: File): void;
}

const FORMAT_LABELS = {
    'csv': 'CSV',
} as any;

export const FileField = ({formats, selectedFile, onChange}: IFileFieldProps) => {
    const { t } = useTranslation();

    const [value, setValue] = useState<File>(selectedFile);

    const accept = formats.map(format => `.${format}`).join(', ');

    const handleChange = (event: any) => {
        setValue(event.target.files[0]);
        onChange(event.target.files[0]);
    };

    return (
        <FormControl fullWidth variant="outlined">
            <input
                type="file"
                accept={accept}
                onChange={handleChange}
                id="bulk-import-file"
                style={{display: 'none'}}
            />
            <label htmlFor="bulk-import-file">
                <Button variant="contained" color="primary" component="span" startIcon={<CloudUploadIcon />}>
                    {t('transactions.bulk.upload_form.upload_button')}
                </Button>

                {!!value &&
                    <span style={{marginLeft: 10}}>{t('transactions.bulk.upload_form.file_description', {file: value.name})}</span>
                }
            </label>

            <FormHelperText>{t('transactions.bulk.upload_form.formats_supported', {formats: formats.map(f => FORMAT_LABELS[f]).join(', ')})}</FormHelperText>
        </FormControl>
    );
};
