import * as React from "react";
import {fetchEvents} from "../../../stores/events";
import Error from "../../Components/Error";
import {computed} from "mobx";
import {IEvent, IEventsFilterQuery} from "../../../config/event";
import {useEventStore} from "../../../hooks/use_event_store";
import {useEffect, useState} from "react";
import {PageLoader} from "../../Components/PageLoader";
import {Outlet, useNavigate} from "react-router-dom";
import {SearchForm} from "./SearchForm";
import {NoResults} from "../../Components/NoResults";
import {Today} from "@mui/icons-material";
import { PageItem } from "../../Components/PageItem";
import { List } from "../../Components/Admin/List/List";
import { TimeRange } from "./TimeRange";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { EditAction } from "./Actions/EditAction";
import { DeleteAction } from "./Actions/DeleteAction";
import { NewEvent } from "../New/NewEvent";
import { observer } from "mobx-react";
import { EditEvent } from "../Edit/EditEvent";

export const EventsList = observer(() => {
    const eventStore = useEventStore();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [query, setQuery] = useState<IEventsFilterQuery>();
    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [selected, setSelected] = useState<IEvent>();

    const handleEditOpen = (event: IEvent) => () => {
        setEditOpen(true);
        setSelected(event);
    };

    const handleEditClose = () => {
        setEditOpen(false);
    };

    const handleNavigate = (event: IEvent) => () => {
        navigate(`/events/${event.id}`);
    };

    useEffect(() => {
        if (!eventStore.shouldBePopulated) {
            setLoading(false);
            return;
        }

        (async () => {
            try {
                const events = await fetchEvents();
                eventStore.load(events);
            } catch (error) {
                setError(true);
            }

            setLoading(false);
        })();
    });

    const events = computed<IEvent[]>(() => {
        return eventStore.filterBy(query);
    });

    const handleSearch = (query: IEventsFilterQuery) => {
        setQuery(query);
    };

    if (loading) {
        return (
            <PageLoader />
        );
    }

    if (error) {
        return (
            <Error />
        );
    }

    if (!eventStore.getSize()) {
        return (
            <NoResults
                icon={<Today />}
                text="You haven't created any event yet :("
                onClick={() => navigate('/events/new')}
            />
        );
    }

    return <>
        <PageItem title="Events" actions={<NewEvent />}>
            <SearchForm onSubmit={handleSearch} />

            <List<IEvent>
                items={events.get()}
                primaryGetter={e => e.name}
                secondaryGetter={e => <TimeRange event={e} />}
                iconGetter={e => <CalendarMonthIcon />}
                actions={e => [
                    [EditAction, {onClick: handleEditOpen(e)}],
                    DeleteAction,
                ]}
                width={700}
                onClick={handleNavigate}
            />

            {editOpen && selected && <EditEvent event={selected} onClose={handleEditClose} />}
        </PageItem>

        <Outlet />
    </>;
});
