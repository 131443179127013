import {fetchDelete, fetchGet, fetchPatch, fetchPost} from "../services/api";
import {action, makeObservable, observable} from "mobx";
import { IAllowance, IAllowanceRequest } from "../config/allowance";
import { allowancesValidationFactory } from "../validators/allowances";

const ALLOWANCES_CACHE_KEY = 'em:allowances:allowances';

export class AllowanceStore {
    @observable allowances: IAllowance[] = [];

    constructor() {
        makeObservable(this);

        const data = window.localStorage.getItem(ALLOWANCES_CACHE_KEY);
        if (!data) {
            return;
        }

        this.allowances = JSON.parse(data) || [];
    }

    @action
    load(allowances: IAllowance[]) {
        this.allowances = allowances;

        this.updateLocalStorage();
    }

    @action
    addAllowance(allowance: IAllowance) {
        this.allowances.push(allowance);

        this.updateLocalStorage();
    }

    @action
    replaceAllowance(allowance: IAllowance) {
        const index = this.allowances.findIndex(p => p.id === allowance.id);

        if (index === -1) {
            return;
        }

        this.allowances[index] = allowance;

        this.updateLocalStorage();
    }

    @action
    removeAllowance(allowance: IAllowance) {
        this.allowances = this.allowances.filter(b => b.id !== allowance.id);

        this.updateLocalStorage();
    }

    findById(id: number): IAllowance | null {
        return this.allowances.find(t => t.id === id) || null;
    }

    private updateLocalStorage() {
        window.localStorage.setItem(ALLOWANCES_CACHE_KEY, JSON.stringify(this.allowances));
    }
}

export const fetchAllowances = (): Promise<IAllowance[]> => {
    return fetchGet( '/ego/allowances')
        .then(response => response.json())
        .then(data => data.data);
};

export const createAllowance = (data: IAllowanceRequest): Promise<IAllowance> => {
    return fetchPost( `/ego/allowances`, data)
        .then((response: any) => {
            if (response.status !== 201) {
                return response.json().then((reason: any) => {
                    if (reason.errors === undefined) {
                        return Promise.reject(reason);
                    }

                    return Promise.reject(allowancesValidationFactory(reason.errors));
                });
            }

            return Promise.resolve({
                id: parseInt(response.headers.get('Location').replace(/.*\/([0-9]+)$/, '$1')),
                ...data
            });
        });
};

export const updateAllowance = (id: number, data: IAllowanceRequest): Promise<IAllowance> => {
    return fetchPatch( `/ego/allowances/${id}`, data)
        .then((response: any) => {
            if (response.status !== 200) {
                return response.json().then((reason: any) => {
                    if (reason.errors === undefined) {
                        return Promise.reject(reason);
                    }

                    return Promise.reject(allowancesValidationFactory(reason.errors));
                });
            }

            return response.json();
        })
        .then((data) => data.data);
};

export const deleteAllowance = (id: number): Promise<boolean> => {
    return fetchDelete(`/ego/allowances/${id}`);
};
