import * as React from "react";
import {PageItem} from "../../Components/PageItem";
import {SnackbarStore} from "../../../stores/snackbar";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {updatePassword} from "../../../stores/users";
import { useForm } from "../../../hooks/use_form_v2";
import { Form } from "../../Components/Form/Form/Form";
import { PasswordField } from "../../Components/Form/PasswordField";

export interface IChangePasswordState {
    current_password: string;
    new_password: string;
    new_password_confirmation: string;
}

export const ChangePassword = () => {
    const { snackbarStore } = useGlobalStores();

    const form = useForm<IChangePasswordState>();
    const { register, reset } = form;

    const handleSubmit = (values: IChangePasswordState) => {
        return updatePassword(values)
            .then(() => {
                reset();
                snackbarStore.open(SnackbarStore.SUCCESS, 'Password updated successfully!');
            })
            .catch(reason => {
                snackbarStore.open(SnackbarStore.FAIL);
                return Promise.reject(reason);
            });
    };

    return (
        <PageItem title="Change your Password">
            <Form form={form} onSubmit={handleSubmit}>
                <Form.Field type={PasswordField} {...register('current_password')} />
                <Form.Field type={PasswordField} {...register('new_password')} />
                <Form.Field type={PasswordField} {...register('new_password_confirmation')} label="Confirm password" />
            </Form>
        </PageItem>
    )
};
