import * as React from "react";
import "../../../../utils/number_extensions";
import {blueGrey} from "@mui/material/colors";
import {ITransaction} from "../../../../config/transactions";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {styled} from "@mui/material";

const DifferentCurrencyContainer = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
}));

const AmountDiv = styled('div')(() => ({
    fontSize: 36,
    fontWeight: 'bold',
    color: blueGrey[700],
}));

const ActualAmountContainer = styled('div')(() => ({
    marginTop: -8,
    color: blueGrey[500],
}));

const ActualAmount = styled('span')(() => ({
    fontSize: 16,
    fontWeight: 'bold',
}));

interface IAmountProps {
    transaction: ITransaction;
}

export const Amount = ({transaction}: IAmountProps) => {
    const { currencyStore } = useGlobalStores();
    const currency = currencyStore.currentCurrency;

    if (currency === transaction.original_currency) {
        return (
            <AmountDiv>
                {(transaction.amount as any).toCurrency(currency)}
            </AmountDiv>
        );
    }

    return (
        <DifferentCurrencyContainer>
            <AmountDiv>
                {(transaction.original_amount as any).toCurrency(transaction.original_currency)}
            </AmountDiv>

            <ActualAmountContainer>
                <ActualAmount>{(transaction.amount as any).toCurrency(currency)}</ActualAmount>
            </ActualAmountContainer>
        </DifferentCurrencyContainer>
    );
};
