import * as React from "react";
import Box from "@mui/material/Box";
import {Hidden, Typography} from "@mui/material";
import {usePageTitle} from "../../../hooks/use_page_title";

export const HeaderTitle = () => {
    const {sectionTitle, pageTitle} = usePageTitle();

    return (
        <Box sx={{flexGrow: 1}}>
            <Hidden smDown>
                {sectionTitle &&
                    <>
                        <Typography component="span" variant="body2" color="inherit" noWrap>
                            {sectionTitle}
                        </Typography>
                        <Typography sx={{
                            paddingLeft: (theme) => theme.spacing(1),
                            paddingRight: (theme) => theme.spacing(1) }
                        } component="span" variant="body2" color="inherit" noWrap>
                            |
                        </Typography>
                    </>
                }
            </Hidden>

            <Typography component="span" variant="h6" color="inherit" noWrap>
                {pageTitle}
            </Typography>
        </Box>
    );
}
