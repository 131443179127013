import * as React from "react";
import {ReactNode} from "react";
import {IRecurringExpensesCategory} from "../../../helpers/recurring_expenses_helper";
import {RecurringExpensesTimeFrequency} from "../../../config/recurring_expense";
import {AppBar, Tabs, Tab, styled} from "@mui/material";
import Badge from "@mui/material/Badge";
import {grey} from "@mui/material/colors";

interface IStyledSmallScreenAware {
    smallScreen: boolean;
}

const StyledTabs = styled(Tabs, {
    shouldForwardProp: (prop) => prop !== "smallScreen",
})<IStyledSmallScreenAware>(({smallScreen, theme}) => ({
    ...(!smallScreen ? {
        width: 200,
    } : {}),

    borderTop: 'solid 1px',
    borderTopColor: theme.palette.grey.A400,

    "& .MuiTabs-indicator": {
        width: 5,
    },
}));

const StyledTab = styled(Tab, {
    shouldForwardProp: (prop) => prop !== "smallScreen",
})<IStyledSmallScreenAware>(({smallScreen}) => ({
    ...(!smallScreen ? {
        textAlign: 'right',
        borderBottom: 'solid 1px',
        borderBottomColor: grey[400],
    } : {}),
}));

const StyledBadge = styled(Badge)(({theme}) => ({
    position: 'absolute',
    right: theme.spacing(3),

    [theme.breakpoints.down('md')]: {
        display: 'none',
    },
}));

const Wrapper = ({isSmallScreen, children}: IWrapperProps) => {
    if (isSmallScreen) {
        return (
            <AppBar position="relative" color="default" sx={{marginBottom: (theme) => theme.spacing(2)}}>
                {children}
            </AppBar>
        )
    }

    return (
        <div>{children}</div>
    )
};

interface IFrequencyTabsProps {
    categories: IRecurringExpensesCategory[];
    currentTab: RecurringExpensesTimeFrequency;
    onChange(event: React.ChangeEvent<{}>, newValue: RecurringExpensesTimeFrequency): any;
    isSmallScreen: boolean;
}

interface IWrapperProps {
    isSmallScreen: boolean;
    children: ReactNode;
}

export const FrequencyTabs = ({categories, currentTab, onChange, isSmallScreen}: IFrequencyTabsProps) => (
    <Wrapper isSmallScreen={isSmallScreen}>
        <StyledTabs
            smallScreen={isSmallScreen}
            orientation={!isSmallScreen ? "vertical" : "horizontal"}
            variant={!isSmallScreen ? "standard" : "fullWidth"}
            value={currentTab}
            onChange={onChange}
        >
            {categories.map(category => (
                <StyledTab
                    smallScreen={isSmallScreen}
                    key={category.code}
                    value={category.code}
                    label={<>
                        {category.name}
                        <StyledBadge
                            color="error"
                            overlap="rectangular"
                            badgeContent={category.count}
                        />
                    </>}
                />
            ))}
        </StyledTabs>
    </Wrapper>
);
