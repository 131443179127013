import * as React from "react";
import "../../../../utils/number_extensions";
import moment from "moment";
import {ITransaction} from "../../../../config/transactions";

interface IPurchasedAtProps {
    transaction: ITransaction;
}

export const PurchasedAt = ({transaction}: IPurchasedAtProps) => {
    return (
        <>
            {moment(transaction.purchased_at).format('dddd,')}<br />
            {moment(transaction.purchased_at).format('MMMM DD YYYY')}
        </>
    );
};
