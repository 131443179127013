import * as React from "react";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import {NoResults} from "../../Components/NoResults";
import {useNavigate} from "react-router-dom";

export const NoExtraIncomes = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/extra-incomes/new');
    };

    return (
        <NoResults
            icon={<AccountBalanceWalletIcon />}
            text="You haven't added any extra income yet :("
            onClick={handleClick}
        />
    );
}
