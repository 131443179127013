import * as React from "react";
import {ListAlertContainer} from "../../Components/ListAlertContainer";
import {Alert, AlertTitle} from "@mui/material";
import {LocalOffer} from "@mui/icons-material";
import { useGlobalStores } from "../../../../hooks/use_global_stores";

interface IBudgetSearchAlertPros {
    budgetId: string;
}

export const BudgetSearchAlert = ({budgetId}: IBudgetSearchAlertPros) => {
    const { budgetStore } = useGlobalStores();
    const budget = budgetStore.findBudget(parseInt(budgetId));

    return (
        <ListAlertContainer>
            <Alert
                icon={<LocalOffer fontSize="inherit" />}
                severity="warning"
            >
                <AlertTitle>Budget search</AlertTitle>
                <div>
                    You're seeing results for the budget <strong>{budget?.name}</strong>!
                </div>
            </Alert>
        </ListAlertContainer>
    );
}
