import { IGeocodingCity, IGeocodingRequest } from "../config/geocoding";
import {fetchGet} from "../services/api";

const GEOCODING_CITIES_CACHE_KEY = 'geocoding:cities'

export class GeocodingStore {
    cities: IGeocodingCity[] = [];

    constructor() {
        const data = window.localStorage.getItem(GEOCODING_CITIES_CACHE_KEY);
        if (!data) {
            return;
        }

        this.cities = JSON.parse(data);
    }

    add(city: IGeocodingCity) {
        if (this.hasCity(city)) {
            return;
        }

        this.cities.push(city);

        window.localStorage.setItem(GEOCODING_CITIES_CACHE_KEY, JSON.stringify(this.cities));
    }

    getAllByCountryCode(countryCode: string): IGeocodingCity[] {
        return this.cities.filter(item => item.country_code === countryCode);
    }

    private hasCity(city: IGeocodingCity): boolean {
        return !!this.cities.find(item => {
            return item.country_code === city.country_code && 
                   item.state === city.state && 
                   item.city === city.city;
        });
    }
}

export const search = ({city, country_code}: IGeocodingRequest): Promise<IGeocodingCity[]> => {
    return fetchGet( '/geocoding', {city, country_code})
        .then(response => response.json())
        .then(data => data.data);
};
