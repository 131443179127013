import {ITransactionsSearchQuery} from "../config/transactions";
import moment from "moment";
import {ITransactionsSearchFormOutputData} from "../config/search";
// import {ITransactionsSearchFormOutputData} from "../components/SearchTransactions/SearchTransactionsForm";

export const getDefaultListTransactionsQueryRequest = (searchParams: URLSearchParams): ITransactionsSearchQuery => {
    const query = {
        from: moment().startOf('month').format('YYYY-MM-DD'),
        to: moment().endOf('month').format('YYYY-MM-DD'),
        include_tags: 'true',
    } as ITransactionsSearchQuery;

    if (!!searchParams.get('query')) {
        query.query = searchParams.get('query')!;
    }
    if (!!searchParams.get('tag')) {
        query.tag = searchParams.get('tag')!;
    }
    if (!!searchParams.get('budget_id')) {
        query.budget_ids = searchParams.get('budget_id')!;
    }

    return query;
};

export const toFormRequest = (values: ITransactionsSearchFormOutputData): ITransactionsSearchQuery => {
    return {
        from: values.timeRange[0].format('YYYY-MM-DD'),
        to: values.timeRange[1].format('YYYY-MM-DD'),
        exclude_recurring_expenses: values.excludeRecurringExpenses ? '1' : '0',
        exclude_withdrawals: values.excludeWithdrawals ? '1' : '0',
        exclude_weekdays_lunch: values.excludeWeekdaysLunch ? '1' : '0',
        exclude_transactions: values.excludedTransactions.join(','),
        include_only_payment_methods: values.includePaymentMethods.map(v => v.id).join(','),
        include_tags: values.includeTags ? '1' : '0',
        query: values.query,
        tag: '',
    };
};
