import * as React from "react";
import {AppBar, Hidden, IconButton, styled} from "@mui/material";
import {Toolbar} from "@mui/material";
import {HeaderTitle} from "./HeaderTitle";
import {LanguageSwitcherAction} from "./Actions/LanguageSwitcher/LanguageSwitcherAction";
import {SearchAction} from "../Actions/SearchAction";
import {NewTransactionAction} from "../Actions/NewTransactionAction";
import {FreeTextSearch} from "./Actions/FreeTextSearch";
import { Menu } from "@mui/icons-material";
import { useGlobalStores } from "../../../hooks/use_global_stores";
import { observer } from "mobx-react";

const StyledAppBar = styled(AppBar)(({ theme }) => ({
    [theme.breakpoints.up('md')]: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
}));

interface IHeaderProps {
    menuOpen?: boolean;
    onMenuOpen(): void;
    isSmallScreen: boolean;
    mobile: boolean;
}

export const Header = observer(({menuOpen, onMenuOpen, mobile: isMobile, isSmallScreen = false}: IHeaderProps) => {
    const { layoutStore } = useGlobalStores();

    return (
        <StyledAppBar position={isSmallScreen ? "absolute" : "fixed"} sx={menuOpen ? {
            transition: (theme) => theme.transitions.create(['width', 'margin'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            })} : {}}
        >
            <Toolbar>
                {!isMobile && <Hidden lgUp>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={onMenuOpen}
                        sx={{ marginRight: (theme) => theme.spacing(2) }}
                    >
                        <Menu />
                    </IconButton>
                </Hidden>}

                <HeaderTitle />
                
                {layoutStore.headerActions}

                {!isSmallScreen && <FreeTextSearch />}
                <LanguageSwitcherAction />
                {!isSmallScreen && <NewTransactionAction />}
                <SearchAction />
            </Toolbar>
        </StyledAppBar>
    );
});
