import * as React from "react";
import {LocationOff, LocationOn} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {useEffect, useState} from "react";
import {Tooltip} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { GeocodingField } from "../GeocodingField/GeocodingField";

export interface IPosition {
    lat: number;
    lon: number;
}

interface IGeoLocationActionProps {
    onChange(active: boolean, position?: IPosition): void;
}

export const GeoLocationAction = ({onChange}: IGeoLocationActionProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [active, setActive] = useState<boolean>(false);
    const [denied, setDenied] = useState<boolean>(false);
    const [position, setPosition] = useState<IPosition>();
    const [defaultPosition, setDefaultPosition] = useState<IPosition>();

    const handleActivation = () => {
        if (loading) {
            return;
        }

        if (active) {
            setActive(false);
            setPosition(undefined);
            return;
        }

        setLoading(true);

        navigator.geolocation.getCurrentPosition(
            handlePositionChange,
            handlePositionUnavailable,
            {
                maximumAge: 60000,
                timeout: 5000,
                enableHighAccuracy: true,
            }
        );
    };

    const handlePositionChange = (position: GeolocationPosition) => {
        setActive(true);
        setPosition({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
        });
        setDefaultPosition({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
        });
        setDenied(false);
        setLoading(false);
    };

    const handlePositionUnavailable = (error: GeolocationPositionError) => {
        setActive(false);
        setPosition(undefined);
        setDefaultPosition(undefined);
        setDenied(true);
        setLoading(false);
    };

    const handleManuallyPositionChange = (position?: IPosition) => {
        setPosition(position);
    };

    useEffect(() => {
        onChange(active, position);
    }, [active, position]);

    if (!('geolocation' in navigator)) {
        return null;
    }

    if (loading) {
        return (
            <CircularProgress color="primary" size={20} />
        );
    }

    if (denied) {
        return (
            <Tooltip title="Geo location denied. Allow it and refresh the page!" arrow>
                <LocationOff color="error" />
            </Tooltip>
        )
    }

    return (
        <>
            <Tooltip title={active && !!position ? 'Geo search is active' : 'Activate geo search'} arrow>
                <IconButton size="small" onClick={handleActivation}>
                    <LocationOn color={active ? 'primary' : 'action'} />
                </IconButton>
            </Tooltip>

            {<GeocodingField defaultPosition={defaultPosition} onChange={handleManuallyPositionChange} />}
        </>
    );
};
