import {action, makeObservable, observable} from "mobx";
import { IAttachment, INewAttachment } from "../config/attachments";
import { fetchDelete, fetchFormPost, fetchGet } from "../services/api";
import { attachmentsValidationFactory } from "../validators/attachments";

export class PushAttachmentStore {
    @observable newAttachments: INewAttachment[] = [];

    constructor() {
        makeObservable(this);
    }

    @action
    addNewAttachment(value: INewAttachment) {
        this.newAttachments.push(value);
    }

    @action
    cleanUp() {
        this.newAttachments = [];
    }
}

export const createAttachment = (transactionId: string, data: INewAttachment): Promise<void | Response> => {
    const formData = new FormData();
    formData.append('file', data.file, data.file.name);
    formData.append('type', data.type);

    if (!!data.name) {
        formData.append('name', data.name);
    }
    if (!!data.description) {
        formData.append('description', data.description);
    }

    return fetchFormPost(`/transactions/${transactionId}/attachments`, formData).then((response: any) => {
        if (response.status !== 204) {
            return response.json().then((reason: any) => {
                if (reason.errors === undefined) {
                    return Promise.reject(reason);
                }

                return Promise.reject(attachmentsValidationFactory(reason.errors));
            });
        }

        return response;
    });
};

export const fetchAttachments = (transactionId: string): Promise<IAttachment[]> => {
    return fetchGet(`/transactions/${transactionId}/attachments`, {})
        .then(response => response.json())
        .then(data => data.data || []);
};

export const deleteAttachment = (transactionId: string, id: number): Promise<any> => {
    return fetchDelete(`/transactions/${transactionId}/attachments/${id}`);
};
