import * as React from "react";
import * as moment from "moment";
import {IEventDetails} from "../../../../config/event";

interface IEventDaysCountProps {
    event: IEventDetails;
}

export const EventDaysCount = ({event}: IEventDaysCountProps) => {
    if (event.from === null || event.to === null) {
        return (
            <span>?</span>
        );
    }

    const from = event.from as unknown as moment.Moment;
    const to = event.to as unknown as moment.Moment;

    if (from.isSame(to)) {
        return (
            <span>1 day</span>
        );
    }

    return (
        <span>{to.diff(from, 'day') + 1} days</span>
    );
};
