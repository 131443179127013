import * as React from "react";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from "@mui/material/CircularProgress";
import {SnackbarStore} from "../../../../stores/snackbar";
import {deleteIncome} from "../../../../stores/incomes";
import {IIncome} from "../../../../config/income";
import {useGlobalStores} from "../../../../hooks/use_global_stores";

interface IDeleteActionProps {
    item: IIncome;
}

export const DeleteAction = ({item: income}: IDeleteActionProps) => {
    const { incomeStore, snackbarStore } = useGlobalStores();
    const [loading, setLoading] = React.useState(false);

    const handleClick = () => {
        if (loading) {
            return;
        }

        if (!window.confirm('Are you sure to delete this income? It cannot be undone.')) {
            return;
        }

        setLoading(true);

        deleteIncome(income.id)
            .then(() => {
                setLoading(false);

                snackbarStore.open(SnackbarStore.SUCCESS, 'Income deleted successfully!');
                incomeStore.removeIncome(income);
            })
            .catch(() => {
                setLoading(false);

                snackbarStore.open(SnackbarStore.FAIL);
            });
    };

    return (
        <IconButton edge="end" onClick={handleClick} color="primary" size="large">
            {!loading ? <DeleteIcon /> : <CircularProgress size={24} />}
        </IconButton>
    );
};
