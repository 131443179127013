import {ITransactionSplit} from "../config/transactions";

export const convertSplitsFromFormData = (input: ITransactionSplit | any): ITransactionSplit => {
    const res = {
        name: input.name,
        split_method: input.split_method,
    } as ITransactionSplit;

    if (!!input.id) {
        res.id = input.id;
    }

    if (!!input.amount && input.amount !== '') {
        res.amount = parseFloat(input.amount as any);
    }

    if (!!input.percentage && input.percentage !== '') {
        res.percentage = parseInt(input.percentage);
    }

    return res;
};
