import * as React from "react";
import * as moment from "moment";
import {Button} from "@mui/material";
import {ITransaction} from "../../../config/transactions";
import {SearchTransactions} from "./SearchTransactions";
import {useState} from "react";

interface ISearchTransactionsActionProps {
    value?: ITransaction[];
    onChange(transactions: ITransaction[]): void;
    dateRange?: [moment.Moment, moment.Moment] | null;
}

export const SearchTransactionsAction = ({value, dateRange, onChange}: ISearchTransactionsActionProps) => {
    const [open, setOpen] = useState<boolean>(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleTransactionsSelectedChange = (transactions: ITransaction[]) => {
        onChange(transactions);
    };

    return (
        <>
            <Button
                fullWidth
                onClick={handleOpen}
                variant="contained"
                color="primary"
            >
                Select Transactions {value ? `(${value.length} selected)` : ''}
            </Button>

            <SearchTransactions
                selected={value}
                dateRange={dateRange}
                open={open}
                onClose={handleClose}
                onTransactionSelect={handleTransactionsSelectedChange}
            />
        </>
    );
};
