export enum PaymentProvider {
    APPLE_PAY = 'APPLE_PAY',
    PAYPAL = 'PAYPAL',
}

export const PaymentProviderLabel = {
    [PaymentProvider.APPLE_PAY]: 'Apple Pay',
    [PaymentProvider.PAYPAL]: 'PayPal',
};

export const PaymentProviders = [
    {
        code: PaymentProvider.APPLE_PAY,
        label: PaymentProviderLabel[PaymentProvider.APPLE_PAY],
    },
    {
        code: PaymentProvider.PAYPAL,
        label: PaymentProviderLabel[PaymentProvider.PAYPAL],
    },
];
