import * as React from "react";
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from "@mui/material/CircularProgress";
import {SnackbarStore} from "../../../../stores/snackbar";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import { IBudget } from "../../../../config/budget";
import { deleteBudget } from "../../../../stores/budgets";

interface IDeleteActionProps {
    budget: IBudget;
}

export const DeleteAction = ({budget}: IDeleteActionProps) => {
    const { budgetStore, snackbarStore } = useGlobalStores();
    const [loading, setLoading] = React.useState(false);

    const handleClick = () => {
        if (loading) {
            return;
        }

        if (!window.confirm('Are you sure to delete this budget? It cannot be undone.')) {
            return;
        }

        setLoading(true);

        deleteBudget(budget.id)
            .then(() => {
                setLoading(false);

                snackbarStore.open(SnackbarStore.SUCCESS, 'Budget deleted successfully!');
                budgetStore.removeBudget(budget);
            })
            .catch(() => {
                setLoading(false);

                snackbarStore.open(SnackbarStore.FAIL);
            });
    };

    return (
        <IconButton edge="end" onClick={handleClick} color="primary" size="large">
            {!loading ? <DeleteIcon /> : <CircularProgress size={24} />}
        </IconButton>
    );
};
