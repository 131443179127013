import * as React from "react";
import {IUser} from "../../../config/user";
import {Currency} from "../../../config/currencies";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {updateEgo} from "../../../stores/users";
import {SnackbarStore} from "../../../stores/snackbar";
import {PageItem} from "../../Components/PageItem";
import {useForm} from "../../../hooks/use_form_v2";
import { Form } from "../../Components/Form/Form/Form";
import { DefaultCurrencyField } from "./Fields/DefaultCurrencyField";
import { EmailReadonlyField } from "./Fields/EmailReadonlyField";
import { DefaultLanguageField } from "./Fields/DefaultLanguageField";
import { DefaultPaymentMethodField } from "./Fields/PaymentMethodField";

interface IProfileState {
    default_currency: Currency;
    default_payment_method_id: number | null;
    default_language: string;
}

export interface IProfileFormOutputData {
    default_currency: Currency;
    default_payment_method_id: number | null;
    default_language: string;
}

export const Profile = () => {
    const { userStore, snackbarStore } = useGlobalStores();
    const user = userStore.currentUser!;

    const form = useForm<IProfileState>({
        default_currency: user.default_currency,
        default_payment_method_id: user.default_payment_method_id,
        default_language: user.default_language,
    });
    const { register } = form;

    const handleSubmit = (values: IProfileState) => {
        return updateEgo(values as IProfileFormOutputData)
            .then((user: IUser) => {
                snackbarStore.open(SnackbarStore.SUCCESS, 'Profile updated successfully!');
                userStore.setUser(user);
            })
            .catch((reason) => {
                snackbarStore.open(SnackbarStore.FAIL);
                return Promise.reject(reason);
            });
    };

    return (
        <PageItem title="Change your Profile">
            <Form form={form} onSubmit={handleSubmit}>
                <Form.Field type={EmailReadonlyField} email={user.email} />
                <Form.Field type={DefaultCurrencyField} {...register('default_currency')} />
                <Form.Field type={DefaultPaymentMethodField} {...register('default_payment_method_id')} />
                <Form.Field type={DefaultLanguageField} {...register('default_language')} />
            </Form>
        </PageItem>
    )
};
