import * as React from "react";
import "../../../utils/number_extensions";
import {IStatsWeek} from "../../../config/stats";
import {prefillWeeks} from "../../../helpers/dashboard_charts_helpers";
import "../../../utils/number_extensions";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {BarChart, legendClasses} from "@mui/x-charts";

interface IWeeksChartProps {
    weeks: IStatsWeek[];
    chartHeight: number;
}

export const WeeksChart = ({weeks, chartHeight}: IWeeksChartProps) => {
    const { currencyStore } = useGlobalStores();

    const dataset = prefillWeeks(weeks) as any[];

    return (
        <BarChart
            dataset={dataset}
            series={[
                {
                    type: 'bar',
                    stack: 'total',
                    dataKey: 'weekdays',
                    label: 'Weekdays',
                    valueFormatter: (v): string => {
                        return v!.toCurrency(currencyStore.currentCurrency, {});
                    },
                },
                {
                    type: 'bar',
                    stack: 'total',
                    dataKey: 'weekend',
                    label: 'Weekend',
                    valueFormatter: (v): string => {
                        return v!.toCurrency(currencyStore.currentCurrency, {});
                    },
                },
            ]}
            axisHighlight={{
                x: 'none',
            }}
            xAxis={[
                {
                    dataKey: 'week',
                    scaleType: 'band',
                    valueFormatter: (v): string => {
                        return `${v.toOrdinal()} week`;
                    },
                },
            ]}
            grid={{horizontal: true}}
            borderRadius={3}
            height={chartHeight}
            margin={{top: 5}}
            slotProps={{
                legend: {
                    direction: 'row',
                    position: { vertical: 'bottom', horizontal: 'middle' },
                    padding: 0,
                    itemMarkWidth: 10,
                    itemMarkHeight: 10,
                    markGap: 5,
                    itemGap: 15,
                },
            }}
            sx={{
                '&&': {
                    touchAction: 'auto',
                },
                [`& .${legendClasses.mark}`]: {
                    ry: 10,
                },
            }}
        />
    );
};
