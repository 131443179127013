import * as React from "react";
import {useState} from "react";
import {ITransaction} from "../../../config/transactions";
import {IEventSearchTransactionsQuery, SearchTransactionsForm} from "./SearchTransactionsForm";
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import {PageOuterContainer} from "../../Components/PageOuterContainer";
import {fetchTransactions} from "../../../stores/transactions";
import * as moment from "moment";
import {styled} from "@mui/material";
import {TransactionsList} from "../../Transactions/Components/List/TransactionsList";
import {TransactionsListAction} from "../../Transactions/Components/List/config/config";
import {PageLoader} from "../../Components/PageLoader";

const Title = styled(Typography)(({theme}) => ({
    marginLeft: theme.spacing(2),
    flex: 1,
}));

interface ISearchTransactionsProps {
    selected?: ITransaction[];
    dateRange?: [moment.Moment, moment.Moment] | null;
    open: boolean;
    onClose(): void;
    onTransactionSelect(transactions: ITransaction[]): void;
}

export const SearchTransactions = ({selected, dateRange, open, onClose, onTransactionSelect}: ISearchTransactionsProps) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [transactions, setTransactions] = useState<ITransaction[]>(selected || []);
    const [selectedTransactions, setSelectedTransactions] = useState<ITransaction[]>(selected || []);

    const handleClose = () => {
        setSelectedTransactions([]);
        onClose();
    };

    const handleSelect = () => {
        onTransactionSelect(selectedTransactions);
        onClose();
    };

    const handleSubmit = (requestData: IEventSearchTransactionsQuery) => {
        const request = {
            query: requestData.query,
        } as any;
        if (requestData.useEventDateRange && dateRange) {
            request.from = dateRange[0].format('YYYY-MM-DD');
            request.to = dateRange[1].format('YYYY-MM-DD');
        }

        setLoading(true);
        fetchTransactions(request)
            .then(res => {
                setTransactions([
                    ...selected || [],
                    ...res.transactions.filter(t => selected?.map(t => t.transaction_id).indexOf(t.transaction_id) === -1),
                ]);
                setLoading(false);
            });
    };

    const handleTransactionSelect = (transactions: ITransaction[]) => {
        setSelectedTransactions(transactions);
    };

    return (
        <Dialog fullScreen open={open} onClose={handleClose}>
            <AppBar sx={{position: 'relative'}}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        size="large">
                        <CloseIcon />
                    </IconButton>
                    <Title variant="h6">
                        Search Transactions
                    </Title>
                    <Button color="inherit" onClick={handleSelect}>
                        Select
                    </Button>
                </Toolbar>
            </AppBar>

            <PageOuterContainer>
                <SearchTransactionsForm dateRange={dateRange} loading={false} onSubmit={handleSubmit} />
                {loading && <PageLoader />}
                <TransactionsList
                    hideFooter
                    pageSize={transactions.length}
                    staticLoader={transactions}
                    actions={[
                        TransactionsListAction.SELECT,
                    ]}
                    onSelect={handleTransactionSelect}
                    selected={selectedTransactions.map(t => t.transaction_id)}
                />
            </PageOuterContainer>
        </Dialog>
    );
};
