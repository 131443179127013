import * as React from 'react';
import { Container } from './Container';
import { Middle } from './Middle';
import { Progress } from './Progress';

export enum ProgressStatus {
    OK,
    WARNING,
    ALERT,
}

interface IProgressBarProps {
    value: number;
    total: number;
    width?: number | 'fullwidth';
    height?: number;
    animation?: boolean;
    steps?: number;
}

export const ProgressBar = ({value, total, width, height, animation, steps = 1}: IProgressBarProps) => {
    const [status, setStatus] = React.useState<ProgressStatus>(ProgressStatus.OK);
    const [progress, setProgress] = React.useState<number>(0);

    React.useEffect(() => {
        let status = ProgressStatus.OK;
        let progress = value * 100 / total;
        if (progress > 100) {
            status = ProgressStatus.ALERT;
            progress = 100;
        } else if (progress > 75) {
            status = ProgressStatus.WARNING;
        }

        setStatus(status);
        setProgress(progress);
    }, []);

    return (
        <Container width={width} height={height} status={status}>
            {Array.from(Array(steps).keys()).map((i) => <Middle key={i} sx={{left: `${100/(steps+1)*(i+1)}%`}}/>)}
            <Progress className="progress-bar-progress-item" progress={progress} animation={animation} />
        </Container>
    );
};
