import * as React from 'react'
import {RecurringExpenseStoreContext} from "../contexts/recurring_expense_store_context";

export const useRecurringExpenseStore = () => {
    const store = React.useContext(RecurringExpenseStoreContext);
    if (!store) {
        throw new Error('RecurringExpenseStoreContext must of type RecurringExpenseStore');
    }

    return store;
};
