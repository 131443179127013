import * as React from "react";
import LocationCityIcon from '@mui/icons-material/LocationCity';
import {grey} from "@mui/material/colors";
import {styled} from "@mui/material";

const Container = styled('div')(() => ({
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
}));

const Headline = styled('div')(({theme}) => ({
    marginTop: theme.spacing(1),
    fontWeight: 500,
    fontSize: 20,
    lineHeight: 0,
}));

const Icon = styled(LocationCityIcon)(({theme}) => ({
    fontSize: 300,
    color: grey[200],
}));

export const NoCompanies = () => (
    <Container>
        <Icon />

        <Headline>No companies</Headline>
    </Container>
);
