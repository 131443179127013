import * as React from "react";
import {useEffect} from "react";
import {sleep} from "../../../../utils/sleep";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {FormHelperText} from "@mui/material";
import {FormModal} from "../../../Components/FormModal";
import {merge} from "../../../../stores/transactions";
import {BulkAction} from "../../Components/List/BulkContainer";
import { useModal } from "../../../../hooks/use_modal";
import { useForm } from "../../../../hooks/use_form_v2";
import { SelectField } from "../../../Components/Form/SelectField";
import { Form } from "../../../Components/Form/Form/Form";
import { Level } from "../../../../config/auth";
import { Feature } from "../../../../config/features";
import { ProButton } from "../../../Pro/Components/ProButton";

interface IMergeActionState {
    main: string;
}

interface IMergeActionProps {
    transactionIds: string[];
    onSuccess(action: BulkAction): void;
}

export const MergeAction = ({transactionIds, onSuccess}: IMergeActionProps) => {
    const { refreshStore, snackbarStore } = useGlobalStores();

    const {open, handleModalOpen, handleModalClose} = useModal();
    const form = useForm<IMergeActionState>();
    const { register } = form;

    const [value, setValue] = React.useState<string[]>([]);

    const handleSubmit = ({main}: IMergeActionState) => {
        return merge(main, value.filter(id => id !== main))
            .then(() => {
                sleep(250).then(() => {
                    handleModalClose();
                    snackbarStore.open(true);
                    refreshStore.refreshTransactionsList();

                    onSuccess(BulkAction.MERGE);
                });
            })
            .catch(() => {
                snackbarStore.open(false);
            });
    };

    useEffect(() => {
        setValue(transactionIds);
    }, [transactionIds]);

    return (
        <>
            <FormModal title="Merge transactions" open={open} loading={false} onClose={handleModalClose}>
                <Form form={form} onSubmit={handleSubmit}>
                    <Form.Field
                        type={SelectField}
                        {...register('main')}
                        label="Main transaction"
                        options={value}
                        optionLabelGetter={(o: any) => o}
                        optionValueGetter={(o: any) => o}
                    />
                    <Form.Field type={FormHelperText}>
                        The operation will sum the <strong>amounts</strong>, merge the <strong>attachments</strong> (if any), the <strong>details</strong> will be lost.<br />
                        This is a distruptive operation, the non-main transactions will be hard deleted.
                    </Form.Field>
                </Form>
            </FormModal>

            <ProButton min={Level.PRO} feature={Feature.TRANSACTIONS_MERGE} text="Merge"  onClick={handleModalOpen} variant="contained" color="primary">
                Merge
            </ProButton>
        </>
    );
};
