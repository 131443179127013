import { RefreshItem } from "../config/global_refresh";
import { AllowanceStore, fetchAllowances } from "../stores/allowances";
import { BudgetStore, fetchBudgets } from "../stores/budgets";
import { CategoryFetchMode, CategoryStore, fetchCategories } from "../stores/categories";
import { aknowledge, refreshStatus } from "../stores/global_refresh_store";
import { IncomeStore } from "../stores/incomes";
import { PaymentMethodStore, fetchPaymentMethods } from "../stores/payment_methods";
import { UserPaymentMethodStore } from "../stores/user_payment_methods";
import { UserPaymentMethodCallbackStore, fetchPaymentMethodsCallbacks } from "../stores/user_payment_methods_callback";
import { EgoFetchInclude, fetchEgo } from "../stores/users";

export class GlobalRefresher {
    private paymentMethodStore: PaymentMethodStore;
    private incomeStore: IncomeStore;
    private userPaymentMethodStore: UserPaymentMethodStore;
    private userPaymentMethodCallbackStore: UserPaymentMethodCallbackStore;
    private categoryStore: CategoryStore;
    private allowanceStore: AllowanceStore;
    private budgetStore: BudgetStore;

    constructor(
        paymentMethodStore: PaymentMethodStore,
        incomeStore: IncomeStore,
        userPaymentMethodStore: UserPaymentMethodStore,
        userPaymentMethodCallbackStore: UserPaymentMethodCallbackStore,
        categoryStore: CategoryStore,
        allowanceStore: AllowanceStore,
        budgetStore: BudgetStore
    ) {
        this.paymentMethodStore = paymentMethodStore;
        this.incomeStore = incomeStore;
        this.userPaymentMethodStore = userPaymentMethodStore;
        this.userPaymentMethodCallbackStore = userPaymentMethodCallbackStore;
        this.categoryStore = categoryStore;
        this.allowanceStore = allowanceStore;
        this.budgetStore = budgetStore;
    }

    async refresh(): Promise<true> {
        const res = await refreshStatus();

        if (res === 0) {
            return true;
        }

        const promises = [];
        const items = [];

        if (res & RefreshItem.PAYMENT_METHODS) {
            promises.push(fetchPaymentMethods().then(res => {
                this.paymentMethodStore.load(res || []);
            }));
            items.push(RefreshItem.PAYMENT_METHODS);
        }
        
        if (res & RefreshItem.CATEGORIES) {
            promises.push(fetchCategories({ mode: CategoryFetchMode.INDENTED }).then(res => {
                this.categoryStore.load(res || []);
            }));
            items.push(RefreshItem.CATEGORIES);
        }

        if (res & RefreshItem.ALLOWANCES) {
            promises.push(fetchAllowances().then(res => {
                this.allowanceStore.load(res || []);
            }));
            items.push(RefreshItem.ALLOWANCES);
        }

        if (res & RefreshItem.BUDGETS) {
            promises.push(fetchBudgets().then(res => {
                this.budgetStore.load(res || []);
            }));
            items.push(RefreshItem.BUDGETS);
        }

        if (res & RefreshItem.USER_PAYMENT_METHODS_CALLBACKS) {
            promises.push(fetchPaymentMethodsCallbacks().then(res => {
                this.userPaymentMethodCallbackStore.load(res || []);
            }));
            items.push(RefreshItem.USER_PAYMENT_METHODS_CALLBACKS);
        }

        if (res & RefreshItem.USER_PAYMENT_METHODS || res & RefreshItem.INCOMES) {
            const include = [] as EgoFetchInclude[];

            if (res & RefreshItem.USER_PAYMENT_METHODS) {
                include.push(EgoFetchInclude.PAYMENT_METHODS);
                items.push(RefreshItem.USER_PAYMENT_METHODS);
            }
            if (res & RefreshItem.INCOMES) {
                include.push(EgoFetchInclude.INCOMES);
                items.push(RefreshItem.INCOMES);
            }

            promises.push(fetchEgo({ include }).then(res => {
                this.userPaymentMethodStore.load(res.payment_methods || []);
                this.incomeStore.load(res.incomes || []);
            }));
        }

        await Promise.all(promises);
        await aknowledge(items);
        
        return true;
    }
}
