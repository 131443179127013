import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import {styled} from "@mui/material";

const StyledTableHead = styled(TableHead)(() => ({
    "& .MuiTableCell-root.MuiTableCell-head": {
        fontWeight: 'bold',
    }
}));

export const DataTableHeader = () => (
    <StyledTableHead>
        <TableRow>
            <TableCell sx={{width: 100}}>Actions</TableCell>
            <TableCell sx={{width: 100}}>Date</TableCell>
            <TableCell sx={{width: 120}}>Amount</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Payment Method</TableCell>
        </TableRow>
    </StyledTableHead>
);
