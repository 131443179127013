import * as React from "react";
import { CurrencyField } from "../../../Components/Form/CurrencyField";
import { t } from "i18next";

export const DefaultCurrencyField = (props: any) => (
    <CurrencyField
        optionRender={(option : any) => {
            return `${option.symbol} ${t(`global.currencies.${option.code}`)}`;
        }}
        {...props}
    />
)
