import * as React from "react";
import {useEffect} from "react";
import {sleep} from "../../../../utils/sleep";
import {useTranslation} from "react-i18next";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {FormControl, FormLabel, Grid} from "@mui/material";
import {SaveButton} from "../../../Components/SaveButton";
import {FormModal} from "../../../Components/FormModal";
import {deleteBulk} from "../../../../stores/transactions";
import {BulkAction} from "../../Components/List/BulkContainer";
import { ProButton } from "../../../Pro/Components/ProButton";
import { Level } from "../../../../config/auth";
import { Feature } from "../../../../config/features";

interface IDeleteActionProps {
    transactionIds: string[];
    onSuccess(action: BulkAction): void;
}

export const DeleteAction = ({transactionIds, onSuccess}: IDeleteActionProps) => {
    const { refreshStore, snackbarStore } = useGlobalStores();
    const { t } = useTranslation();

    const [value, setValue] = React.useState<string[]>([]);
    const [open, setOpen] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleDelete = () => {
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        setLoading(true);

        deleteBulk(value)
            .then(() => {
                sleep(250).then(() => {
                    setLoading(false);
                    setOpen(false);
                    snackbarStore.open(true);
                    refreshStore.refreshDashboard();
                    refreshStore.refreshTransactionsList();

                    onSuccess(BulkAction.DELETE);
                });
            })
            .catch(() => {
                setLoading(false);
                snackbarStore.open(false);
            });
    };

    useEffect(() => {
        setValue(transactionIds);
    }, [transactionIds]);

    return (
        <>
            <FormModal title={t('bulk.action.delete.title')} open={open} loading={loading} onClose={handleClose}>
                <>
                    <Grid item xs={12}>
                        <FormControl fullWidth variant="outlined">
                            <FormLabel sx={{marginBottom: (theme) => theme.spacing(2)}}>
                                {t('bulk.action.delete.confirmation_message', {count: transactionIds.length})}
                            </FormLabel>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                        <SaveButton loading={loading} onSubmit={handleSubmit} label={t('global.buttons.delete')!} />
                    </Grid>
                </>
            </FormModal>

            <ProButton min={Level.PRO} feature={Feature.TRANSACTIONS_BULK_DELETE} text={t('global.buttons.delete')} onClick={handleDelete} variant="contained" color="primary">
                {t('global.buttons.delete')}
            </ProButton>
        </>
    );
};
