export interface ICompany {
    id: number;
    name: string;
    default_category_id?: number | null;
    icon?: string | null;
}

export enum CompanyAppearance {
    BOXES,
    LIST,
}

export enum CompanySortBy {
    NAME,
    TRANSACTIONS_COUNT,
    TOTAL_AMOUNT,
}
export const sortByLabels = {
    [CompanySortBy.NAME]: 'Name',
    [CompanySortBy.TRANSACTIONS_COUNT]: 'Transactions Count',
    [CompanySortBy.TOTAL_AMOUNT]: 'Total Amount',
};
