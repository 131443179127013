import * as React from "react";
import { useForm } from "../../../../../../hooks/use_form_v2";
import { SplitMethod } from "../../../../../Transactions/Components/Form/Fields/ShareWithFriends/FriendSelector/FriendSelector";
import { FreeTextField } from "../../../../../Components/Form/FreeTextField";
import { Form } from "../../../../../Components/Form/Form/Form";
import { Grid } from "@mui/material";

interface IShareWithFriendsPercentageProps {
    value?: any;
    onChange(payload: ShareWithFriendsPercentageFormState): void;
}

interface ShareWithFriendsPercentageFormState {
    name: string;
    split_method: SplitMethod;
    percentage: string;
    amount: string;
}

export const ShareWithFriendsPercentage = ({value, onChange}: IShareWithFriendsPercentageProps) => {
    const form = useForm<ShareWithFriendsPercentageFormState>(value || {
        split_method: SplitMethod.PERCENTAGE,
        name: '',
        percentage: '',
        amount: '',
    });
    const { register, getValues } = form;

    React.useEffect(() => {
        onChange(getValues());
    }, [getValues()]);

    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="start"
            spacing={2}
        >
            <Form.Field type={FreeTextField} {...register('name')} />
            <Form.Field type={FreeTextField} {...register('percentage')} inputProps={{type: 'number', min: 1, max: 100}} />
        </Grid>
    )
};
