import { INewAttachment } from "../config/attachments";
import {IErrorResponseItem, ValidationError, ValidationErrors} from "../services/validator";

export enum AttachmentsValidationField {
    FILE = "FileUploadRequest.File",
    TYPE = "FileUploadRequest.Type",
}

export type AttachmentsValidation = {
    [key in AttachmentsValidationField]?: ValidationError[];
};

export type AttachmentValidationErrors = ValidationErrors<AttachmentsValidation, AttachmentsValidationField>;

export const attachmentsValidationFactory = (errors: IErrorResponseItem[]): AttachmentValidationErrors => {
    const v = new ValidationErrors<AttachmentsValidation, AttachmentsValidationField>();
    v.setMap({
        file: AttachmentsValidationField.FILE,
        type: AttachmentsValidationField.TYPE,
    });
    v.setErrors(errors);

    return v;
};

export const attachmentsStaticValidationFactory = (value: INewAttachment): AttachmentValidationErrors => {
    const v = attachmentsValidationFactory([]);

    if (!value.file) {
        v.add(AttachmentsValidationField.FILE, ValidationError.REQUIRED);
    }
    if (!value.type) {
        v.add(AttachmentsValidationField.TYPE, ValidationError.REQUIRED);
    }

    return v;
};
