import * as React from "react";
import {Grid} from "@mui/material";
import {IExtraIncome, IExtraIncomeFormData} from "../../../config/extra_income";
import {FreeTextField} from "../../Components/Form/FreeTextField";
import FormControl from "@mui/material/FormControl";
import {AmountField} from "./Fields/AmountField";
import {Currency} from "../../../config/currencies";
import moment from "moment/moment";
import {FormWrapper} from "../../Components/Form/FormWrapper";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DateField, LocalizationProvider} from "@mui/x-date-pickers";
import {SaveButton} from "../../Components/SaveButton";

interface IExtraIncomeFormProps {
    extraIncome?: IExtraIncome;
    loading: boolean;
    onSubmit(data: IExtraIncomeFormData): void;
}

export interface IExtraIncomeState {
    description: string;
    date: moment.Moment;
    currency: Currency;
    amount: string;
}

const DEFAULT_STATE = {
    description: '',
    date: moment(),
    currency: Currency.EUR,
    amount: '' as any,
};

export const ExtraIncomeForm = ({extraIncome, loading, onSubmit}: IExtraIncomeFormProps) => {
    const [values, setValues] = React.useState<IExtraIncomeState>(extraIncome || DEFAULT_STATE);

    const handleChange = (prop: keyof IExtraIncomeState) => (value: any) => {
        setValues({ ...values, [prop]: value });
    };

    const handleAmountChange = (currency: Currency, amount: string) => {
        setValues({ ...values, currency, amount});
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        onSubmit({
            description: values.description,
            date: values.date.format('YYYY-MM-DD'),
            currency: values.currency as string,
            amount: parseFloat(values.amount),
        });
    };

    return (
        <FormWrapper onSubmit={handleSubmit} loading={loading}>
            <AmountField value={values} onSubmit={handleAmountChange} />

            <Grid item xs={12}>
                <FormControl fullWidth variant="outlined">
                    <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateField format="MMMM Do YYYY" fullWidth label="Purchased date" value={values.date} onChange={handleChange('date')} />
                    </LocalizationProvider>
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <FreeTextField label="Description" value={values.description} onChange={handleChange('description')} />
            </Grid>

            <Grid item xs={12}>
                <SaveButton loading={loading} onSubmit={handleSubmit} />
            </Grid>
        </FormWrapper>
    );
};
