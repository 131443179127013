import {Dispatch, SetStateAction, useEffect, useState} from "react";

export interface IForm<V> {
    register(prop: keyof V): {
        value: V;
        onChange(value: keyof V): void;
        errors: any; 
    };
    reset(): void;
    watch(prop: keyof V): any;
    getValues(): any;
    setErrors: Dispatch<SetStateAction<any>>;
    errors: any;
    setLoading: Dispatch<SetStateAction<boolean>>;
    isLoading(): boolean;
}

interface IFormOptions<V> {
    listener?(values: V): void;
}

export const useForm = <V extends object>(defaultValue: V | any = {} as V, options: IFormOptions<V> = {}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [values, setValues] = useState<V>(defaultValue);
    const [errors, setErrors] = useState<any>();

    const handleChange = (prop?: keyof V) => (value: any) => {
        setValues((prevState: V) => {
            if (!!prop) {
                return {
                    ...prevState,
                    [prop]: value,
                };
            }

            if (!prop && typeof value === 'object') {
                return {
                    ...prevState,
                    ...value,
                };
            }

            return prevState;
        });
    };

    const register = (prop: keyof V) => {
        return {
            label: generateLabelForProp(prop as string),
            value: values[prop] as any || '',
            onChange: handleChange(prop),
            errors: errors?.getFromMap(prop),
        };
    };

    useEffect(() => {
        if (!options.listener) {
            return;
        }
        options.listener(values);
    }, [values]);

    const reset = () => {
        const data = {} as any;
        Object.keys(values).forEach(key => {
            data[key] = '';
        });

        handleChange()({...data, ...defaultValue});
    };

    const watch = (prop: keyof V): any => {
        return values[prop];
    };

    const isLoading = (): boolean => {
        return loading;
    }

    const getValues = (): V => {
        return values;
    }

    return {
        register,
        reset,
        change: handleChange,
        watch, getValues,
        setErrors, errors,
        setLoading, isLoading,
    };
};

const generateLabelForProp = (prop: string): string => {
    const label = prop.replaceAll('_', ' ');
    return label[0].toUpperCase() + label.slice(1);
};
