import * as React from "react";
import FormControl from "@mui/material/FormControl";
import {FormControlLabel, FormHelperText, FormLabel, Radio, RadioGroup} from "@mui/material";
import {ReactNode, useEffect, useState} from "react";
import {convertToTranslationKeys, ValidationError} from "../../../services/validator";
import {useTranslation} from "react-i18next";

interface IRadioFieldProps {
    label: string;
    options: any[];
    value: any;
    onChange(value: any): void;
    optionValueGetter(option: any): any;
    optionLabelGetter(option: any): string | ReactNode;
    optionDisabledGetter?(option: any): boolean;
    errors?: ValidationError[];
}

export const RadioField = ({label, options, value, onChange, optionValueGetter, optionLabelGetter, optionDisabledGetter = () => false, errors}: IRadioFieldProps) => {
    const { t } = useTranslation();

    const [selected, setSelected] = useState<any>(value);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSelected(event.target.value);
    };

    useEffect(() => {
        setSelected(value);
    }, [value]);

    useEffect(() => {
        onChange(selected);
    }, [selected]);

    return (
        <FormControl fullWidth variant="outlined" error={!!errors && !!errors.length}>
            <FormLabel>{label}</FormLabel>
            <RadioGroup color="primary" value={selected} onChange={handleChange}>
                {options.map((option, i) => (
                    <FormControlLabel
                        key={i}
                        value={optionValueGetter(option)}
                        label={optionLabelGetter(option)}
                        control={<Radio />}
                    />
                ))}
            </RadioGroup>

            {!!errors && !!errors.length &&
                <FormHelperText error>
                    {convertToTranslationKeys(errors).map(e => t(e)).join(', ')}
                </FormHelperText>
            }
        </FormControl>
    );
}
