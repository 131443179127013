export enum LocationVendor {
    LOCAL_COMPANY = "LOCAL_COMPANY",
    GOOGLE_MAPS = "GOOGLE_MAPS",
    IMPORT = "IMPORT",
    MANUAL = "MANUAL",
}

export interface ILocation {
    id?: number;
    name: string;
    vendor: LocationVendor;
    vendor_id: string;
    default_category_id?: number;
    address?: string | null;
    logo?: string | null;
}

export interface ILocationSearchRequest {
    query: string;
    language?: string;
    lat?: number;
    lon?: number;
    session_token?: string;
}

export const LOCATION_VENDOR = {
    [LocationVendor.LOCAL_COMPANY]: 'My companies',
    [LocationVendor.IMPORT]: 'Import',
    [LocationVendor.GOOGLE_MAPS]: 'From Google Places',
    [LocationVendor.MANUAL]: 'Manual',
};

export interface ILocationLogo {
    [key: number]: string;
}
