import {ISnapshot, ISnapshotTimeline} from "../config/snapshot";
import {SnapshotCardColor} from "../components/Components/SnapshotCard";
import {green, orange, pink, purple} from "@mui/material/colors";

export const periodPercentageComparison = (snapshotTimeline: ISnapshotTimeline, key: keyof ISnapshot): string => {
    let percentage = Math.round(
        (snapshotTimeline.previous[key] as any).percentageDifferenceAgainst(snapshotTimeline.current[key])
    );

    if (percentage === Infinity) {
        percentage = 100;
    }

    return `${percentage > 0 ? '+' : ''}${percentage}%`;
};

export const periodPercentageComparisonV3 = (percentage: number): string => {
    return `${percentage > 0 ? '+' : ''}${Math.round(percentage)}%`;
};

export const colorToMuiColor = (color: SnapshotCardColor) => {
    switch (color) {
        case SnapshotCardColor.GREEN: return green;
        case SnapshotCardColor.PINK: return pink;
        case SnapshotCardColor.ORANGE: return orange;
        case SnapshotCardColor.PURPLE: return purple;
    }
}
