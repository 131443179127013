import * as React from "react";
import {Outlet} from "react-router-dom";
import {PageOuterContainer} from "../Components/PageOuterContainer";

export const Atlas = () => {
    return (
        <PageOuterContainer>
            <Outlet />
        </PageOuterContainer>
    );
};
