import * as React from "react";
import {Grid} from "@mui/material";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {SelectField} from "../../../../../Components/Form/SelectField";

interface IFormatProps {
    onChange(format: string): void;
}

const formats = [
    'csv',
];

export const Format = ({onChange}: IFormatProps) => {
    const { t } = useTranslation();

    const [value, setValue] = useState<string>('');

    const handleChange = (value: any) => {
        setValue(value);
    };

    useEffect(() => {
        onChange(value);
    }, [value]);

    return (
        <Grid item xs={12}>
            <SelectField
                label={t('bulk.action.export.form.fields_to_export')}
                options={formats}
                value={value}
                onChange={handleChange}
                optionValueGetter={format => format}
                optionLabelGetter={format => t(`global.formats.${format}`)}
            />
        </Grid>
    );
};
