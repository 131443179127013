import * as React from 'react';
import Box from '@mui/material/Box';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Chip from '@mui/material/Chip';
import {useGlobalStores} from "../../../../../hooks/use_global_stores";
import {IUserPaymentMethod} from "../../../../../config/user_payment_method";
import {useEffect, useState} from "react";

interface IPaymentMethodFilterProps {
    value: IUserPaymentMethod[];
    onChange(userPaymentMethodIds: IUserPaymentMethod[]): void;
}

export const PaymentMethodFilter = ({value, onChange}: IPaymentMethodFilterProps) => {
    const { userPaymentMethodStore } = useGlobalStores();

    const [selected, setSelected] = useState<string[]>(value.map(v => v?.id.toString()));

    const handleChange = (event: SelectChangeEvent<typeof selected>) => {
        const value = event.target.value;
        setSelected(typeof value === 'string' ? value.split(',') : value);
    };

    useEffect(() => {
        onChange(selected.map(id => userPaymentMethodStore.getById(parseInt(id))!));
    }, [selected]);

    return (
        <div>
            <FormControl fullWidth>
                <InputLabel>Include only payment methods</InputLabel>
                <Select
                    multiple
                    value={selected}
                    onChange={handleChange}
                    input={<OutlinedInput label="Include only payment methods" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((id) => {
                                const label = userPaymentMethodStore.getById(parseInt(id))?.name || id
                                return <Chip key={id} label={label} />
                            })}
                        </Box>
                    )}
                >
                    {userPaymentMethodStore.paymentMethods.map((p) => (
                        <MenuItem key={p.id} value={p.id.toString()}>
                            {p.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </div>
    );
};
