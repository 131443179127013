import * as React from "react";
import {SplitMethod} from "./FriendSelector";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Paper from '@mui/material/Paper';
import {Currency} from "../../../../../../../config/currencies";
import {styled} from "@mui/material";
import {convertAmountToFloat} from "../../../../../../../helpers/transaction_helpers";
import {ITransactionSplit} from "../../../../../../../config/transactions";

const StyledPaper = styled(Paper)(({theme}) => ({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
}));

interface IDetailsProps {
    value: ITransactionSplit;
    originalCurrency: Currency;
    onEdit(): void;
    onDelete(): void;
}

export const Details = ({value, originalCurrency, onEdit, onDelete}: IDetailsProps) => (
    <StyledPaper elevation={2}>
        <div>
            <Typography sx={{fontSize: '120%'}} variant="body2">
                {value.name}:{' '}

                <strong>
                    {value.split_method === SplitMethod.PERCENTAGE &&
                        (value.percentage + "%")
                    }

                    {value.split_method === SplitMethod.FIXED &&
                        (convertAmountToFloat(value.amount!) as any).toCurrency(originalCurrency)
                    }
                </strong>
            </Typography>
        </div>

        <div>
            <IconButton onClick={onEdit} size="small" color="primary">
                <EditIcon />
            </IconButton>
            <IconButton onClick={onDelete}  size="small" color="error">
                <DeleteIcon />
            </IconButton>
        </div>
    </StyledPaper>
);
