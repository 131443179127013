import {alpha, styled} from "@mui/material";

export const ListAlertContainer = styled('div')(({theme}) => ({
    padding: theme.spacing(2),
    borderBottom: 'solid 1px',
    borderBottomColor: theme.palette.divider,
    backgroundColor: alpha(theme.palette.divider, 0.0215),

    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
    },
}));
