import * as React from "react";
import {useEffect, useState} from "react";
import {convertToTranslationKeys, ValidationError} from "../../../services/validator";
import {FormControl, FormHelperText, InputLabel, OutlinedInput} from "@mui/material";
import {useTranslation} from "react-i18next";

interface IFreeTextFieldProps {
    name?: string;
    label: string;
    value?: string | null;
    onChange: any;
    type?: string;
    errors?: ValidationError[];
    inputProps?: object;
    helperText?: string;
}

export const FreeTextField = ({name, value, onChange, label, type = "text", errors, inputProps, helperText}: IFreeTextFieldProps) => {
    const { t } = useTranslation();

    const [text, setText] = useState<string>(value || '')

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setText(event.target.value);
    };

    useEffect(() => {
        onChange(text);
    }, [text]);

    useEffect(() => {
        setText(value || '');
    }, [value]);

    return (
        <FormControl fullWidth variant="outlined" error={!!errors && !!errors.length}>
            <InputLabel>{label}</InputLabel>
            <OutlinedInput
                name={name}
                value={text}
                label={label}
                onChange={handleChange}
                type={type}
                inputProps={inputProps}
            />

            {!!errors && !!errors.length &&
                <FormHelperText error>
                    {convertToTranslationKeys(errors).map(e => t(e)).join(', ')}
                </FormHelperText>
            }
            {!!helperText &&
                <FormHelperText>{helperText}</FormHelperText>
            }
        </FormControl>
    );
};
