import * as React from "react";
import {useState} from "react";
import {IUserPaymentMethod} from "../../../../config/user_payment_method";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {updateUserPaymentMethod} from "../../../../stores/user_payment_methods";
import {SnackbarStore} from "../../../../stores/snackbar";
import IconButton from "@mui/material/IconButton";
import {Done, Edit} from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";

interface IEditActionComponentProps {
    paymentMethod: IUserPaymentMethod;
    onEdit(isEditing: boolean): void;
    value: string;
}

export const EditAction = ({paymentMethod, onEdit, value}: IEditActionComponentProps) => {
    const { userPaymentMethodStore, snackbarStore } = useGlobalStores();

    const [loading, setLoading] = useState(false);
    const [editing, setEditing] = useState(false);

    const handleClick = () => {
        if (loading) {
            return;
        }

        if (editing) {
            if (value === paymentMethod.name) {
                setEditing(false);
                onEdit(false);
                return;
            }

            setEditing(false);
            setLoading(true);

            updateUserPaymentMethod(paymentMethod.id, {
                payment_method_id: paymentMethod.payment_method_id,
                is_active: paymentMethod.is_active,
                name: value,
            })
                .then((updatedPaymentMethod) => {
                    setLoading(false);
                    onEdit(false);

                    snackbarStore.open(SnackbarStore.SUCCESS, 'Payment method modified successfully!');
                    userPaymentMethodStore.replacePaymentMethod(updatedPaymentMethod);
                })
                .catch(() => {
                    setLoading(false);
                    onEdit(false);

                    snackbarStore.open(SnackbarStore.FAIL);
                });

            return;
        }

        setEditing(true);
        onEdit(true);
    };

    return (
        <IconButton
            edge="end"
            onClick={handleClick}
            color="primary"
            id={`edit-user-payment-method-button-${paymentMethod.id}`}
            size="large">
            {!loading ? (!editing ? <Edit /> : <Done />) : <CircularProgress size={24} />}
        </IconButton>
    );
};
