import {action, computed, makeObservable, observable} from "mobx";
import {
    IRecurringExpense,
    IRecurringExpenseCreate,
    IRecurringExpensesStats,
    IRecurringExpenseUpdate
} from "../config/recurring_expense";
import {fetchDelete, fetchGet, fetchPatch, fetchPost} from "../services/api";
import {convertFromResponse} from "../validators/recurring_expenses";

export class RecurringExpenseStore {
    @observable recurringExpenses: IRecurringExpense[] = [];
    @observable stats: IRecurringExpensesStats = {} as IRecurringExpensesStats;
    @observable shouldBePopulated: boolean = true;
    @observable shouldStatsBeReloaded: boolean = true;

    constructor() {
        makeObservable(this);
    }

    @action
    load(recurringExpenses: IRecurringExpense[]) {
        this.recurringExpenses = recurringExpenses;
        this.shouldBePopulated = false;
    }

    @action
    loadStats(stats: IRecurringExpensesStats) {
        this.stats = stats;
        this.shouldStatsBeReloaded = false;
    }

    @action
    addRecurringExpense(recurringExpense: IRecurringExpense) {
        this.recurringExpenses.push(recurringExpense);
    }

    @action
    removeRecurringExpense(recurringExpense: IRecurringExpense) {
        this.recurringExpenses = this.recurringExpenses.filter(e => e.id !== recurringExpense.id);
    }

    @action
    replaceRecurringExpense(recurringExpense: IRecurringExpense) {
        const index = this.recurringExpenses.findIndex(e => e.id === recurringExpense.id);

        if (index === -1) {
            return;
        }

        this.recurringExpenses[index] = recurringExpense;
    }

    findById(id: number | string): IRecurringExpense | null {
        return this.recurringExpenses.find(e => e.id == id) || null;
    }

    @computed get count(): number {
        return this.recurringExpenses.length;
    }
}

export const createStore = () => {
    return new RecurringExpenseStore();
};

const fetchRecurringExpensesStats = (): Promise<IRecurringExpensesStats> => {
    return fetchGet( '/recurring_expenses/stats')
        .then(response => response.json())
        .then(data => data.data);
};

const fetchRecurringExpenses = (): Promise<IRecurringExpense[]> => {
    return fetchGet( '/recurring_expenses')
        .then(response => response.json())
        .then(data => data.data);
};

const createRecurringExpense = (data: IRecurringExpenseCreate): Promise<number> => {
    return fetchPost( `/recurring_expenses`, data)
        .then((response: any) => {
            if (response.status !== 201) {
                return response.json().then((reason: any) => {
                    if (reason.errors === undefined) {
                        return Promise.reject(reason);
                    }

                    return Promise.reject(convertFromResponse(reason.errors));
                });
            }

            return Promise.resolve({
                id: parseInt(response.headers.get('Location').replace(/.*\/([0-9]+)$/, '$1')),
                ...data
            });
        });
};

const updateRecurringExpense = (id: number | string, data: IRecurringExpenseUpdate): Promise<IRecurringExpense> => {
    return fetchPatch( `/recurring_expenses/${id}`, data)
        .then((response: any) => {
            if (response.status !== 200) {
                return Promise.reject(response);
            }

            return response.json();
        })
        .then(data => data.data);
};


const toggleActiveRecurringExpense = (id: number, active: boolean): Promise<IRecurringExpense> => {
    return fetchPatch( `/recurring_expenses/${id}/toggle_activation`, {active})
        .then((response: any) => {
            if (response.status !== 200) {
                return Promise.reject(response);
            }

            return response.json();
        })
        .then(data => data.data);
};

const deleteRecurringExpense = (id: number): Promise<boolean> => {
    return fetchDelete(`/recurring_expenses/${id}`);
};

export {fetchRecurringExpensesStats, fetchRecurringExpenses, createRecurringExpense, updateRecurringExpense, toggleActiveRecurringExpense, deleteRecurringExpense}
