import * as React from "react";
import {ReactNode} from "react";
import {Grid, styled} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const Form = styled('form')(() => ({
    position: 'relative',
}));

const Backdrop = styled('div')(() => ({
    top: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
}));

const LoadingSpinner = styled(CircularProgress)(() => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -30,
    marginLeft: -30,
}));

interface IFormWrapperProps {
    onSubmit: any;
    loading?: boolean;
    children: ReactNode;
}

export const FormWrapper = ({onSubmit, loading = false, children}: IFormWrapperProps) => {
    return (
        <Form noValidate autoComplete="off" onSubmit={onSubmit}>
            <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="start"
                spacing={2}
            >
                {children}
            </Grid>

            {loading && <Backdrop />}
            {loading && <LoadingSpinner size={60} />}
        </Form>
    );
}
