import * as React from "react";
import {FreeTextField} from "../../../Components/Form/FreeTextField";
import {MoneyField} from "../../../Components/Form/MoneyField";
import { AllowanceFrequency, IAllowance, IAllowanceRequest } from "../../../../config/allowance";
import { CurrencyField } from "../../../Components/Form/CurrencyField";
import { Currency } from "../../../../config/currencies";
import { useGlobalStores } from "../../../../hooks/use_global_stores";
import { toForm } from "../../../../helpers/allowance_helpers";
import { useForm } from "../../../../hooks/use_form_v2";
import { Form } from "../../../Components/Form/Form/Form";

export interface IAllowanceState {
    name: string;
    amount: string;
    currency: Currency;
    frequency: AllowanceFrequency;
    max_per_frequency: string;
}

interface IAllowanceFormProps {
    allowance?: IAllowance;
    onSubmit(data: IAllowanceRequest): Promise<any>;
}

export const AllowanceForm = ({allowance, onSubmit}: IAllowanceFormProps) => {
    const { currencyStore } = useGlobalStores();

    const form = useForm<IAllowanceState>(allowance || {
        currency: currencyStore.currentCurrency,
        frequency: AllowanceFrequency.DAILY,
    });
    const { register, watch } = form;

    const handleSubmit = (values: any) => {
        return onSubmit(toForm(values));
    };

    return (
        <Form form={form} onSubmit={handleSubmit}>
            <Form.Field type={FreeTextField} {...register('name')} />
            <Form.Field type={CurrencyField} {...register('currency')} />
            <Form.Field type={MoneyField} {...register('amount')} currency={watch('currency')} />
            <Form.Field type={FreeTextField} {...register('max_per_frequency')} label="Maximum repeat per frequency" />
        </Form>
    );
};
