import * as React from "react";
import {createRecurringExpense} from "../../../stores/recurring_expense";
import {SnackbarStore} from "../../../stores/snackbar";
import {IRecurringExpenseFormOutputData, RecurringExpenseForm} from "../Form/RecurringExpenseForm";
import {
    convertFormOutputDataToRecurringExpenseCreate
} from "../../../helpers/recurring_expenses_helper";
import {PageItem} from "../../Components/PageItem";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {PageOuterContainer} from "../../Components/PageOuterContainer";
import {useNavigate} from "react-router-dom";
import {IRecurringExpense} from "../../../config/recurring_expense";
import {useRecurringExpenseStore} from "../../../hooks/use_recurring_expense_store";

export const NewRecurringExpense = () => {
    const recurringExpensesStore = useRecurringExpenseStore();
    const { snackbarStore } = useGlobalStores();
    const navigate = useNavigate();

    const handleSubmit = (data: IRecurringExpenseFormOutputData): Promise<any> => {
        return createRecurringExpense(convertFormOutputDataToRecurringExpenseCreate(data));
    };

    const handleSuccess = (res: IRecurringExpense) => {
        recurringExpensesStore.addRecurringExpense(res);

        snackbarStore.open(SnackbarStore.SUCCESS, 'New recurring expense added successfully!');
        navigate('/recurring-expenses');
    };

    return (
        <PageOuterContainer>
            <PageItem title="New Recurring Expense">
                <RecurringExpenseForm onSubmit={handleSubmit} onSuccess={handleSuccess} />
            </PageItem>
        </PageOuterContainer>
    );
};
