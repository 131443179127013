import * as React from "react";
import { Section } from "../Components/Section";
import { ITransaction } from "../../../../../config/transactions";
import { CreditCard, Info, Category } from "@mui/icons-material";

interface IDetailsProps {
    transaction: ITransaction;
}

export const Details = ({transaction}: IDetailsProps) => {
    let category = [];
    if (!!transaction.main_category_id && transaction.category_id && transaction.main_category === transaction.category) {
        category.push({
            title: 'Category',
            value: transaction.category,
            icon: <Category />,
        });
    } else {
        if (!!transaction.category_id) {
            category.push({
                title: 'Category',
                value: transaction.category,
                icon: <Category />
            });
        }
        if (!!transaction.main_category_id) {
            category.push({
                title: 'Main Category',
                value: transaction.main_category,
                icon: <Category />
            });
        }
    }

    return (
        <Section title="Details" sections={[
            ...!!transaction.payment_method_id ? [
                {
                    title: 'Payment Method',
                    value: transaction.payment_method,
                    icon: <CreditCard />
                },
            ] : [],
    
            ...category,
    
            ...!!transaction.description ? [
                {
                    title: 'Description',
                    value: transaction.description,
                    icon: <Info />
                },
            ] : [],
        ]} />
    );
}
