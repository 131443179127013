import * as React from "react";
import {useModal} from "../../../../hooks/use_modal";
import {ILocation, LocationVendor} from "../../../../config/location";
import {createLocation} from "../../stores/locations";
import {Button} from "@mui/material";
import {LocationForm} from "../Components/Form";
import {toRequest} from "../helpers";

export interface ICreateActionFormState {
    name: string;
    vendor: LocationVendor;
    vendor_id: string;
    default_category_id?: number;
    address?: string | null;
}

interface ICreatedActionProps {
    onCreate(location: ILocation): void;
}

export const CreateAction = ({ onCreate }: ICreatedActionProps) => {
    const { loading, open, handleModalOpen, handleModalClose } = useModal();

    const handleSubmit = async (data: ICreateActionFormState): Promise<any> => {
        const res = await createLocation(toRequest(data));
        onCreate(res);

        handleModalClose();

        return await Promise.resolve();
    };

    return (
        <>
            <Button variant="contained" color="primary" onClick={handleModalOpen}>
                Create
            </Button>

            <LocationForm<ICreateActionFormState> loading={loading} open={open} onClose={handleModalClose} onSubmit={handleSubmit} />
        </>
    );
};
