import * as React from "react";
import {useGlobalStores} from "../../../../hooks/use_global_stores";

interface IPaymentMethodFieldProps {
    paymentMethodId: number | null;
}

export const PaymentMethodField = ({paymentMethodId}: IPaymentMethodFieldProps) => {
    const { userPaymentMethodStore } = useGlobalStores();

    if (!paymentMethodId) {
        return null;
    }

    const paymentMethod = userPaymentMethodStore.paymentMethodsMap.get(paymentMethodId);
    if (!paymentMethod) {
        return null;
    }

    return (
        <span>{paymentMethod.name}</span>
    )
};
