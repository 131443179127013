import * as React from "react";
import {Typography} from "@mui/material";
import {InvalidateCacheAction} from "./InvalidateCacheAction";

export const AtlasTranslations = () => {
    return (
        <div>
            <Typography variant="h4">I18n</Typography>

            <InvalidateCacheAction />
        </div>
    );
};
