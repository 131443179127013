import { Currency } from "./currencies";

export interface IAllowance {
    id: number;
    name: string;
    amount: number;
    currency: Currency;
    frequency: AllowanceFrequency;
    max_per_frequency: number;
    is_active: boolean;
}

export enum AllowanceFrequency {
    DAILY = 'DAILY',
}

export const frequencies = {
    [AllowanceFrequency.DAILY]: 'day',
} as any;

export interface IAllowanceTransaction {
    id?: number;
    allowance_id: number;
    amount: number;
    name?: string;
}

export interface IAllowanceRequest {
    name: string;
    amount: number;
    currency: Currency;
    frequency: AllowanceFrequency;
    max_per_frequency: number;
}
