(Array.prototype as any).sortArrayOfObjectsAsc = function(key: string) {
    this.sort((a: any, b: any) => (a[key] > b[key]) ? 1 : ((b[key] > a[key]) ? -1 : 0));
};

(Array.prototype as any).sortArrayOfObjectsDesc = function(key: string) {
    this.sort((a: any, b: any) => (a[key] > b[key]) ? -1 : ((b[key] > a[key]) ? 1 : 0));
};

(Array.prototype as any).removeNegativeValuesFromObject = function(key: string): Array<any> {
    return this.filter((obj: any) => obj[key] >= 0);
};

(Array.prototype as any).toMap = function(key: string): Map<any, any> {
    const map = new Map<any, any>();
    this.forEach((item: any) => {
        map.set(item[key], item);
    });

    return map;
};

export {}
