import * as React from "react";
import {useState} from "react";
import {FreeTextField} from "../Components/Form/FreeTextField";
import {Button, Checkbox, CircularProgress, FormControlLabel, styled} from "@mui/material";
import {UnsuccessfulResponse} from "./Components/UnsuccessfulResponse";
import Box from "@mui/material/Box";

const Container = styled('div')(({ theme }) => ({
    width: '100%',
    padding: theme.spacing(2),
}));

const Form = styled('form')(({ theme }) => ({
    marginTop: theme.spacing(1),
}));

const Backdrop = styled('div')(({ theme }) => ({
    top: 0,
    left: 0,
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.75)',
}));

const LoadingSpinner = styled(CircularProgress)(({theme}) => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -30,
    marginLeft: -30,
}));

export interface ILoginFormOutputData {
    email: string;
    password: string;
}

interface ILoginFormProps {
    loading: boolean;
    error: string;
    onSubmit(data: ILoginFormOutputData, rememberMe: boolean): void;
}

interface ILoginFormState {
    email: string;
    password: string;
    rememberMe: boolean;
}

export const LoginForm = ({loading, error, onSubmit}: ILoginFormProps) => {
    const [values, setValues] = useState<ILoginFormState>({
        email: '',
        password: '',
        rememberMe: true,
    });

    const handleChange = (prop: keyof ILoginFormState) => (value: string) => {
        setValues({ ...values, [prop]: value });
    };

    const handleCheckboxChange = (prop: keyof ILoginFormState) => (event: React.ChangeEvent<HTMLInputElement>) => {
        setValues({ ...values, [prop]: event.target.checked });
    };

    const handleSubmit = (e: any) => {
        e.preventDefault();

        if (values.email === '' || values.password === '') {
            return;
        }

        onSubmit({
            email: values.email,
            password: values.password,
        }, values.rememberMe);
    };

    return (
        <Container>
            {'' !== error && <UnsuccessfulResponse message={error} />}

            <Form noValidate onSubmit={handleSubmit}>
                <Box sx={{marginBottom: (theme) => theme.spacing(2)}}>
                    <FreeTextField name="email" label="Email Address" value={values.email} onChange={handleChange('email')} />
                </Box>

                <Box>
                    <FreeTextField name="password" label="Password" type="password" value={values.password} onChange={handleChange('password')} />
                </Box>

                <FormControlLabel
                    control={
                        <Checkbox value="remember"
                                  color="primary"
                                  checked={values.rememberMe}
                                  onChange={handleCheckboxChange('rememberMe')}
                        />}
                    label="Remember me"
                />
                <Button
                    type="submit"
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    sx={{margin: (theme) => theme.spacing(3, 0, 2),}}
                >
                    Sign In
                </Button>

                {loading && <Backdrop />}
            </Form>

            {loading && <LoadingSpinner size={60} />}
        </Container>
    );
}
