import * as React from "react";
import IconButton from '@mui/material/IconButton';
import StarIcon from '@mui/icons-material/Star';
import StarBorderIcon from '@mui/icons-material/StarBorder';
import {IUserPaymentMethod} from "../../../../config/user_payment_method";
import CircularProgress from "@mui/material/CircularProgress";
import {SnackbarStore} from "../../../../stores/snackbar";
import {updateEgo} from "../../../../stores/users";
import {yellow} from "@mui/material/colors";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {observer} from "mobx-react";

interface IFavoriteActionProps {
    paymentMethod: IUserPaymentMethod;
}

export const FavoriteAction = observer(({paymentMethod}: IFavoriteActionProps) => {
    const { userStore, snackbarStore } = useGlobalStores();

    const [loading, setLoading] = React.useState(false);

    const isFavorite = () => {
        return userStore.currentUser?.default_payment_method_id === paymentMethod.id;
    };

    const handleClick = () => {
        if (loading) {
            return;
        }

        setLoading(true);

        updateEgo({
            default_currency: userStore.currentUser?.default_currency!,
            default_language: userStore.currentUser?.default_language!,
            default_payment_method_id: paymentMethod.id
        })
            .then(() => {
                setLoading(false);

                snackbarStore.open(SnackbarStore.SUCCESS, 'Default payment method set successfully!');
                userStore.setDefaultPaymentMethod(paymentMethod);
            })
            .catch(() => {
                setLoading(false);

                snackbarStore.open(SnackbarStore.FAIL);
            });
    };

    return (
        <IconButton
            edge="end"
            onClick={handleClick}
            sx={isFavorite() ? {color: yellow[900]} : {}}
            size="large">
            {!loading ? (isFavorite() ? <StarIcon /> : <StarBorderIcon />) : <CircularProgress size={24} />}
        </IconButton>
    );
});
