import * as React from "react";
import {FieldWrapper} from "../FieldWrapper";
import { OutlinedContainer } from "../../../../../../../Components/Form/OutlinedContainer";
import { DataTable } from "./DataTable";

export enum LineItemsDisplayScope {
    FORM,
    TRANSACTION_VIEW,
}

export interface ILineItem {
    description: string;
    quantity: number;
    total_amount: number;
    unit_price: number;
}

interface ILineItemsFieldProps {
    value?: ILineItem[];
    onChange(value: ILineItem[]): void;
    onDelete(): void;
}

export const LineItemsField = ({value, onChange, onDelete}: ILineItemsFieldProps) => {
    return (
        <FieldWrapper onDelete={onDelete}>
            <OutlinedContainer label="Line items">
                <DataTable
                    scope={LineItemsDisplayScope.FORM}
                    value={value || []}
                    onChange={onChange}
                />
            </OutlinedContainer>
        </FieldWrapper>
    );
}
