import * as React from "react";
import { Avatar, Box, Typography, styled } from "@mui/material";
import { ITransaction } from "../../../../../config/transactions";
import { getDisplayLabel } from "../../../../../helpers/transaction_helpers";
import { Business } from "@mui/icons-material";
import { Logo } from "./Logo";

const Container = styled(Box)(({theme}) => ({
    display: 'flex',
    marginTop: theme.spacing(0.5),
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
}));

const LogoContainer = styled(Box)(({theme}) => ({
}));

const TitleContainer = styled(Box)(({theme}) => ({
    marginLeft: theme.spacing(1.5),
}));

const LogoWrapper = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    width: 40,
    height: 40,
}));

const Date = styled(Typography)(({theme}) => ({
    fontSize: 12,
    color: theme.palette.grey[700],
    lineHeight: 1,
}));

interface ITitleProps {
    transaction: ITransaction;
}

export const Title = ({transaction}: ITitleProps) => (
    <Container>
        <LogoContainer>
            {!!transaction.location?.logo ?
                (
                    <LogoWrapper>
                        <Logo logo={transaction.location?.logo} />
                    </LogoWrapper>
                ) :
                <Avatar>
                    <Business />
                </Avatar>
            }
        </LogoContainer>

        <TitleContainer>
            <Typography variant="h5">{getDisplayLabel(transaction) || 'Transaction'}</Typography>
            <Box sx={{marginTop: -1}}>
                <Date variant="overline">{transaction.purchased_at.format('dddd, MMMM Do YYYY')}</Date>
            </Box>
        </TitleContainer>
    </Container>
)