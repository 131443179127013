import * as React from 'react';
import {ReactNode} from "react";
import {Typography} from "@mui/material";

interface ITitleProps {
    gutterBottom?: boolean;
    children: ReactNode;
}

export const Title = ({gutterBottom, children}: ITitleProps) => (
    <Typography component="h2" variant="h6" color="primary" gutterBottom={gutterBottom === true}>
        {children}
    </Typography>
);
