import * as React from "react";
import {styled} from "@mui/material";
import { ILineItem, LineItemsDisplayScope } from "../../../Components/Form/Fields/Details/Fields/LineItems/LineItemsField";
import { DataTable } from "../../../Components/Form/Fields/Details/Fields/LineItems/DataTable";
import { ITransaction } from "../../../../../config/transactions";
import { Section } from "../Components/Section";
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';

export const Container = styled('div')(() => ({
    overflow: 'hidden',
}));

interface IExtraLineItemsProps {
    transaction: ITransaction;
    value: ILineItem[];
}

export const ExtraLineItems = ({transaction, value}: IExtraLineItemsProps) => {
    if (!transaction.details?.line_items?.length) {
        return null;
    }

    return (
        <Section title="Line Items" sections={[
            {
                icon: <LocalGroceryStoreIcon />,
                value: (
                    <Container>
                        <DataTable
                            scope={LineItemsDisplayScope.TRANSACTION_VIEW}
                            value={value}
                            onChange={() => {}}
                            currency={transaction.original_currency}
                        />
                    </Container>
                ),
            }
        ]} />
    );
};
