import * as React from "react";
import {Alert, styled} from "@mui/material";

const StyledDiv = styled('div')(({theme}) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
}));

export const UnsuccessfulResponse = ({message}: { message: string }) => (
    <StyledDiv>
        <Alert severity="error">{message}</Alert>
    </StyledDiv>
);
