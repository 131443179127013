import * as React from "react";
import { Box } from "@mui/material";
import {styled} from "@mui/material";

const Container = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'left',
})<{ left: number }>(({ theme, left }) => ({
    position: 'absolute',
    left: left,
}));

const Header = styled(Box)(({ theme }) => ({
    position: 'absolute',
    // display: 'flex',
    width: 150,
    padding: theme.spacing(1),
    borderTop: 'solid 4px',
    borderLeft: 'solid 4px',
    borderRight: 'solid 4px',
    borderTopColor: theme.palette.warning.light,
    borderLeftColor: theme.palette.warning.light,
    borderRightColor: theme.palette.warning.light,
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
    top: theme.spacing(-1),

    '&::before': {
        position: 'absolute',
        paddingTop: theme.spacing(0.3),
        paddingBottom: theme.spacing(0.3),
        borderRadius: 4,
        backgroundColor: theme.palette.warning.light,
        color: theme.palette.warning.contrastText,
        fontSize: 12,
        fontWeight: 'bold',
        content: '"your plan"',
        textAlign: 'center',
        top: -12,
        left: '50%',
        width: 80,
        marginLeft: -40,
    }
}));

const VerticalLine = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'height',
})<{ height: number }>(({ theme, height }) => ({
    position: 'absolute',
    width: 4,
    height: height,
    backgroundColor: theme.palette.warning.light,
}));

const RightVerticalLine = styled(VerticalLine)(({ theme }) => ({
    marginLeft: 146,
}));

const Footer = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'height',
})<{ height: number }>(({ theme, height }) => ({
    position: 'absolute',
    top: height - 4,
    width: 150,
    height: 10,
    borderBottom: 'solid 4px',
    borderLeft: 'solid 4px',
    borderRight: 'solid 4px',
    borderBottomColor: theme.palette.warning.light,
    borderLeftColor: theme.palette.warning.light,
    borderRightColor: theme.palette.warning.light,
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
}));

interface ILevelSelectionProps {
    left: number;
    height: number;
}

export const LevelSelection = ({left, height}: ILevelSelectionProps) => {
    height -= 10;

    return (
        <Container left={left}>
            <Header />
            <VerticalLine height={height} />
            <RightVerticalLine height={height} />
            <Footer height={height} />
        </Container>
    );
};
