import * as React from "react";
import {ISnapshotTimeline} from "../../../../config/snapshot";
import {periodPercentageComparison} from "../../../../helpers/snapshot_helpers";
import {Currency} from "../../../../config/currencies";
import "../../../../utils/number_extensions";
import {SnapshotCard, SnapshotCardColor} from "../../../Components/SnapshotCard";
import {Trend} from "../../../Components/Icons/TrendIcon";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";

interface IBudgetLeftItemProps {
    snapshotTimeline: ISnapshotTimeline;
    currency: Currency;
}

export const BudgetLeftItem = ({snapshotTimeline, currency}: IBudgetLeftItemProps) => {
    return (
        <SnapshotCard
            title="Budget left"
            icon={<AccountBalanceWalletIcon />}
            color={SnapshotCardColor.PURPLE}
            trend={snapshotTimeline.current.budget_left > snapshotTimeline.previous.budget_left ? Trend.UP : Trend.DOWN}
            value={(snapshotTimeline.current.budget_left as any).toCurrency(currency, {minimumFractionDigits: 0})}
            details={`${periodPercentageComparison(snapshotTimeline, 'budget_left')} from last month`}
        />
    );
};
