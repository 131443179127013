import * as React from 'react';

interface IIndentedItemProps {
    value: string;
    searchValue?: any;
    replaceValue?: string;
}

export const IndentedItem = ({value, searchValue = /--/g, replaceValue = "&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"}: IIndentedItemProps) => (
    <span dangerouslySetInnerHTML={{__html: value.replace(searchValue, replaceValue)}} />
);
