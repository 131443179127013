import * as React from "react";
import {observer} from "mobx-react";
import { BudgetsProgress } from "../../Settings/Budgets/Progress/BudgetsProgress";
import { PageOuterContainerCancelled } from "../../Components/PageOuterContainer";
import "../../../utils/number_extensions";
import { useGlobalStores } from "../../../hooks/use_global_stores";
import { fetchSnapshotV3 } from "../../../stores/snapshot";
import { ProgressBar } from "../../Components/ProgressBar/ProgressBar";
import { CurrentMonthData } from "./CurrentMonthData";
import Error from "../../Components/Error";
import { IReactionDisposer, reaction } from "mobx";
import { LoadingPlaceholders } from "./Components/LoadingPlaceholders";

export const SnapshotV3Component = () => {
    const { snapshotStore, refreshStore } = useGlobalStores();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [error, setError] = React.useState<boolean>(false);

    const loadSnapshotV3 = () => {
        setLoading(true);

        fetchSnapshotV3()
            .then(result => {
                snapshotStore.setSnapshotV3(result);

                setLoading(false);
                setError(false);
            })
            .catch(error => {
                setLoading(false);
                setError(true);
            });
    };

    let reloadReactionDisposer: IReactionDisposer;
    React.useEffect(() => {
        if (!reloadReactionDisposer) {
            reloadReactionDisposer = reaction(
                () => refreshStore.dashboardSnapshot,
                () => {
                    loadSnapshotV3();
                }
            );
        }

        return () => {
            reloadReactionDisposer();
        }
    });

    React.useEffect(() => {
        if (!!snapshotStore.snapshotV3) {
            return;
        }

        loadSnapshotV3();
    }, []);

    if (loading) {
        return (
            <LoadingPlaceholders />
        );
    }

    if (error) {
        return (
            <Error />
        );
    }

    const value = snapshotStore.snapshotV3;
    if (!value) {
        return null;
    }

    return (
        <>
            <PageOuterContainerCancelled>
                <ProgressBar
                    value={value.income_total - value.income_left}
                    total={value.income_total}
                    width="fullwidth"
                    height={5}
                    animation
                    steps={2}
                />
                <CurrentMonthData snapshot={value} />
            </PageOuterContainerCancelled>

            <BudgetsProgress />
        </>
    );
};
export const SnapshotV3 = observer(SnapshotV3Component);
