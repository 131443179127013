import * as React from "react";
import {useEffect} from "react";
import {FormModal} from "../../../../Components/FormModal";
import {IncludeCsvHeader} from "./Fields/IncludeCsvHeader";
import {IncludeFields} from "./Fields/IncludeFields";
import {Format} from "./Fields/Format";
import {Grid} from "@mui/material";
import {saveBlob} from "../../../../../utils/save_blob_to_file";
import {useGlobalStores} from "../../../../../hooks/use_global_stores";
import {useTranslation} from "react-i18next";
import {BulkAction} from "../../../Components/List/BulkContainer";
import {FormWrapper} from "../../../../Components/Form/FormWrapper";
import {SaveButton} from "../../../../Components/SaveButton";
import {IExportPreRequest} from "../../../../../config/transactions";
import {exportTransactions} from "../../../../../stores/transactions";
import { ProButton } from "../../../../Pro/Components/ProButton";
import { Level } from "../../../../../config/auth";
import { Feature } from "../../../../../config/features";

interface IExportActionProps {
    transactionIds: string[];
    onSuccess(action: BulkAction): void;
}

const EXPORT_FILE_NAME = 'export.csv';

export const ExportAction = ({transactionIds, onSuccess}: IExportActionProps) => {
    const { snackbarStore } = useGlobalStores();
    const { t } = useTranslation();

    const [open, setOpen] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [data, setData] = React.useState<IExportPreRequest>({format: ''});

    const handleExport = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (field: string) => (value: any) => {
        setData((prev) => {
            return {
                ...prev,
                [field]: value,
            };
        });
    };

    const handleSubmit = (e: React.ChangeEvent<HTMLFormElement>) => {
        e.preventDefault();

        setLoading(true);

        exportTransactions(data)
            .then((res: Blob) => {
                setLoading(false);
                setOpen(false);

                saveBlob(res, EXPORT_FILE_NAME);

                snackbarStore.open(true);

                onSuccess(BulkAction.EXPORT);
            })
            .catch(() => {
                setLoading(false);
                snackbarStore.open(false);
            });
    };

    useEffect(() => {
        handleChange('transaction_ids')(transactionIds);
    }, [transactionIds]);

    return (
        <>
            <FormModal title={t('bulk.action.export.title')} open={open} loading={loading} onClose={handleClose}>
                <FormWrapper onSubmit={handleSubmit} loading={loading}>
                    <Format onChange={handleChange('format')} />

                    {
                        data.format === 'csv' &&
                        <>
                            <IncludeCsvHeader onChange={handleChange('include_csv_header')} />
                            <IncludeFields onChange={handleChange('include_fields')} />
                        </>
                    }

                    <Grid item xs={12}>
                        <SaveButton loading={loading} onSubmit={handleSubmit} />
                    </Grid>
                </FormWrapper>
            </FormModal>

            <ProButton min={Level.PRO} feature={Feature.TRANSACTIONS_EXPORT} text={t('global.buttons.export')} onClick={handleExport} variant="contained" color="primary">
                {t('global.buttons.export')}
            </ProButton>
        </>
    );
};
