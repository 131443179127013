import * as React from "react";
import {Currencies, Currency, CurrencyItem} from "../../../config/currencies";
import {useTranslation} from "react-i18next";
import {convertToTranslationKeys, ValidationError} from "../../../services/validator";
import {useEffect, useState} from "react";
import {FormControl} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

interface ICurrencyFieldsProps {
    value: Currency;
    onChange(value: Currency): void;
    label?: string;
    optionRender?(currency: CurrencyItem): string;
    errors?: ValidationError[];
}

export const CurrencyField = ({value, onChange, label = 'Currency', optionRender, errors}: ICurrencyFieldsProps) => {
    const { t } = useTranslation();

    const [currency, setCurrency] = useState<Currency>(value);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCurrency(event.target.value as Currency);
    };

    useEffect(() => {
        setCurrency(value);
    }, [value]);

    useEffect(() => {
        onChange(currency);
    }, [currency]);

    return (
        <FormControl fullWidth variant="outlined">
            <TextField
                select
                value={currency}
                onChange={handleChange}
                label={label}
                variant="outlined"
                error={!!errors && !!errors.length}
                helperText={!!errors && !!errors.length ? convertToTranslationKeys(errors).map(e => t(e)).join(', ') : undefined}
            >
                {Currencies.map(option => (
                    <MenuItem key={option.label} value={option.code}>
                        {!!optionRender ? optionRender(option) : t(`global.currencies.${option.code}`)}
                    </MenuItem>
                ))}
            </TextField>
        </FormControl>
    );
};
