import {IBulkDryRunResult} from "../config/bulk";
import {INewTransaction, ITransaction} from "../config/transactions";
import moment from "moment";
import {ITransactionFormOutputData} from "../components/Transactions/Components/Form/config/models";
// import {ITransactionFormInputData, ITransactionFormOutputData} from "../components/TransactionForm/TransactionForm";

const CONTENT_TYPES = {
    'text/csv': 'csv',
} as any;

export const getFormatFromFile = (file: File): string => {
    const contentType = file.type;

    let format = CONTENT_TYPES[contentType] || '';

    if (format === '') {
        format = file.name.split('').pop();
    }

    if (format === 'undefined') {
        format = '';
    }

    return format;
}

export const convertToNewTransaction = (d: IBulkDryRunResult): INewTransaction => {
    return {
        currency: d.currency,
        amount: d.amount,
        purchased_at: d.date.format("YYYY-MM-DD"),
        description: d.description,
        payment_method_id: d.paymentMethod ? parseInt(d.paymentMethod) : null,
        category_id: null,
        company_id: null,
        is_visible: true,
        budget_id: null,
    };
}

export const convertToTransactionFormInputData = (d: IBulkDryRunResult): ITransaction => {
    return {
        original_currency: d.currency,
        original_amount: d.amount,
        purchased_at: d.date,
        description: d.description,
        payment_method_id: d.paymentMethod ? parseInt(d.paymentMethod) : null,
        location: null,
        transaction_id: "",
        amount: 0,
        category: null,
        company: null,
        is_recurring: false,
        main_category: null,
        main_category_id: null,
        payment_method: null,
        category_id: null,
        company_id: null
    };
}

export const convertFromTransactionFormInputData = (d: ITransactionFormOutputData): IBulkDryRunResult => {
    return {
        date: moment(d.purchasedAt),
        currency: d.currency,
        amount: d.amount,
        description: d.description || '',
        paymentMethod: d.paymentMethodId?.toString(),
        errors: null,
    };
}
