import * as React from "react";
import Skeleton from '@mui/material/Skeleton';
import {styled} from "@mui/material";

const YearsContainer = styled('div')(({theme}) => ({
    display: 'flex',
    marginBottom: theme.spacing(3),
    overflowX: 'auto',
    overflowY: 'hidden',
}));

const Year = styled(Skeleton)(({theme}) => ({
    flexShrink: 0,
    width: 180,
    height: 150,
    marginRight: theme.spacing(3),
}));

const LeftWrapper = styled('div')(({theme}) => ({
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
    },
}));

const LeftContainer = styled('div')(({theme}) => ({
    marginRight: theme.spacing(3),
    width: 360,
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
}));

const TotalSpent = styled(Skeleton)(({theme}) => ({
    width: '100%',
    height: 250,
    marginBottom: theme.spacing(3),
}));

const RightContainer = styled('div')(() => ({
    flexGrow: 1,
}));

const MonthlyAverage = styled(Skeleton)(() => ({
    width: '100%',
    height: 150,
}));

const Chart = styled(Skeleton)(({theme}) => ({
    width: '100%',
    height: '100%',
    [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(3),
        height: 400,
    },
}));

interface IYearsDashboardLoadingPlaceholdersProps {
    showYearsLoading: boolean;
}

export const LoadingPlaceholders = ({showYearsLoading}: IYearsDashboardLoadingPlaceholdersProps) => (
    <div>
        {showYearsLoading &&
            <YearsContainer>
                {Array(10).fill(null).map((x, i) => <Year key={i} variant="rectangular" />)}
            </YearsContainer>
        }

        <LeftWrapper>
            <LeftContainer>
                <TotalSpent variant="rectangular" />
                <MonthlyAverage variant="rectangular" />
            </LeftContainer>

            <RightContainer>
                <Chart variant="rectangular" />
            </RightContainer>

            <RightContainer>
                <Chart variant="rectangular" />
            </RightContainer>
        </LeftWrapper>
    </div>
);
