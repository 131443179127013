import {fetchGet} from "../services/api";
import {ILocation, ILocationLogo, ILocationSearchRequest} from "../config/location";

export const searchLocations = (request: ILocationSearchRequest): Promise<ILocation[]> => {
    return fetchGet('/locations', request)
        .then(response => response.json())
        .then(data => data.data);
};

export const fetchLogos = (): Promise<ILocationLogo> => {
    return fetchGet('/locations')
        .then(response => response.json())
        .then(data => data.data);
};
