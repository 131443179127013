import * as React from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import Card from "@mui/material/Card";
import CardActionArea from "@mui/material/CardActionArea";
import {useNavigate} from "react-router-dom";
import {styled} from "@mui/material";

const Container = styled(Card)(({theme}) => ({
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(3),

    [theme.breakpoints.down('sm')]: {
        marginRight: 0,
        width: '100%',
    },
}));

const StyledCardActionArea = styled(CardActionArea)(({theme}) => ({
    padding: theme.spacing(5),
    height: '100%',

    [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(1),
    },
}));

const IconContainer = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

const Icon = styled(AddCircleIcon)(({theme}) => ({
    fontSize: 100,

    [theme.breakpoints.down('sm')]: {
        fontSize: 50,
    },
}));

export const AddNewExtraIncome = () => {
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/extra-incomes/new');
    };

    return (
        <Container>
            <StyledCardActionArea onClick={handleClick}>
                <IconContainer>
                    <Icon color="primary" />
                </IconContainer>
            </StyledCardActionArea>
        </Container>
    );
};
