import * as React from "react";
import TextField from "@mui/material/TextField";
import {styled} from "@mui/material";
import {IUserPaymentMethod} from "../../../../config/user_payment_method";
import {grey} from "@mui/material/colors";

interface IStyledTextField {
    active: boolean;
}

const StyledTextField = styled(TextField, {
    shouldForwardProp: (prop) => prop !== "active",
})<IStyledTextField>(({active}) => ({
    backgroundColor: grey[100],
    width: '90%',

    ...(!active ? {
        border: 'solid 1px',
        borderColor: grey[400],
        backgroundColor: '#fff',
    } : {}),
}));

interface IPaymentMethodNameFieldProps {
    paymentMethod: IUserPaymentMethod;
    value: string;
    onChange: any;
}

export const PaymentMethodNameField = ({paymentMethod, value, onChange}: IPaymentMethodNameFieldProps) => {
    const handleKeyUp = (e: any) => {
        if (e.which === 13 || e.which === 27) {
            (window.document.getElementById(`edit-user-payment-method-button-${paymentMethod.id}`) as any).click();
        }
    };

    return (
        <StyledTextField
            active={paymentMethod.is_active}
            onKeyUp={handleKeyUp}
            value={value}
            onChange={onChange}
            autoFocus
            error={value === ''}
            helperText={value === '' ? "Please enter a name" : null}
            fullWidth
            size="small"
        />
    );
};
