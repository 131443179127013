import * as React from "react";
import {IIncomeRequest} from "../../../../config/income";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {FormModal} from "../../../Components/FormModal";
import {IncomeForm} from "../Form/IncomeForm";
import {createIncome} from "../../../../stores/incomes";
import {SnackbarStore} from "../../../../stores/snackbar";

interface INewIncomeProps {
    open: boolean;
    onClose(): void;
}

export const NewIncome = ({open, onClose}: INewIncomeProps) => {
    const { incomeStore, snackbarStore } = useGlobalStores();

    const handleSubmit = (data: IIncomeRequest) => {
        return createIncome(data)
            .then((newIncome) => {
                snackbarStore.open(SnackbarStore.SUCCESS, 'Income created successfully!');
                incomeStore.addIncome(newIncome);
                onClose();
            })
            .catch((reason) => {
                snackbarStore.open(SnackbarStore.FAIL);
                return Promise.reject(reason);
            });
    };

    return (
        <FormModal title="New income" open={open} loading={false} onClose={onClose}>
            <IncomeForm
                onSubmit={handleSubmit}
            />
        </FormModal>
    );
};
