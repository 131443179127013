export enum Currency {
    EUR = "EUR",
    USD = "USD",
    GBP = "GBP",
    HRK = "HRK",
    CHF = "CHF",
    PLN = "PLN",
    HUF = "HUF",
}

export const CurrencySymbol = {
    [Currency.EUR]: "€",
    [Currency.USD]: "$",
    [Currency.GBP]: "£",
    [Currency.HRK]: "kn",
    [Currency.CHF]: "fr.",
    [Currency.PLN]: "zł",
    [Currency.HUF]: "Ft",
};

export const CurrencyLabel = {
    [Currency.EUR]: "Euro",
    [Currency.USD]: "Dollar",
    [Currency.GBP]: "Pound",
    [Currency.HRK]: "Croatian Kuna",
    [Currency.CHF]: "Swiss Franc",
    [Currency.PLN]: "Polish złoty",
    [Currency.HUF]: "Hungarian Forint",
};

export const Currencies = [
    {
        code: Currency.EUR,
        label: CurrencyLabel[Currency.EUR],
        symbol: CurrencySymbol[Currency.EUR],
    },
    {
        code: Currency.USD,
        label: CurrencyLabel[Currency.USD],
        symbol: CurrencySymbol[Currency.USD],
    },
    {
        code: Currency.GBP,
        label: CurrencyLabel[Currency.GBP],
        symbol: CurrencySymbol[Currency.GBP],
    },
    {
        code: Currency.HRK,
        label: CurrencyLabel[Currency.HRK],
        symbol: CurrencySymbol[Currency.HRK],
    },
    {
        code: Currency.CHF,
        label: CurrencyLabel[Currency.CHF],
        symbol: CurrencySymbol[Currency.CHF],
    },
    {
        code: Currency.PLN,
        label: CurrencyLabel[Currency.PLN],
        symbol: CurrencySymbol[Currency.PLN],
    },
    {
        code: Currency.HUF,
        label: CurrencyLabel[Currency.HUF],
        symbol: CurrencySymbol[Currency.HUF],
    },
];

export interface CurrencyItem {
    code: Currency;
    label: string;
    symbol: string;
}

export const CurrenciesAllowed = [
    Currency.EUR,
    Currency.USD,
    Currency.GBP,
    Currency.HRK,
    Currency.CHF,
    Currency.PLN,
    Currency.HUF,
];
