import * as React from "react";
import {Grid} from "@mui/material";
import {Currency} from "../../../../config/currencies";
import {IExtraIncomeState} from "../ExtraIncomeForm";
import {useEffect} from "react";
import {CurrencyField} from "../../../Components/Form/CurrencyField";
import {MoneyField} from "../../../Components/Form/MoneyField";

interface IAmountFieldProps {
    value: IExtraIncomeState;
    onSubmit(currency: Currency, amount: string): void;
}

export const AmountField = ({value, onSubmit}: IAmountFieldProps) => {
    const [currency, setCurrency] = React.useState<Currency>(value.currency);
    const [amount, setAmount] = React.useState<string>(value.amount as any);

    const handleCurrencyChange = (value: Currency) => {
        setCurrency(value);
    };
    const handleAmount = (value: string) => {
        setAmount(value);
    };

    useEffect(() => {
        onSubmit(currency, amount);
    }, [currency, amount]);

    return (
        <>
            <Grid item xs={4} md={2}>
                <CurrencyField value={currency} onChange={handleCurrencyChange} />
            </Grid>

            <Grid item xs={8} md={10}>
                <MoneyField value={amount} onChange={handleAmount} currency={currency} />
            </Grid>
        </>
    );
};
