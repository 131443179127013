import * as React from "react";
import {GridActionsCellItem} from "@mui/x-data-grid";
import {Delete} from "@mui/icons-material";
import CircularProgress from "@mui/material/CircularProgress";

export const GridDeleteAction = (loading: boolean, onClick: any) => (
    <GridActionsCellItem
        sx={{padding: 0, margin: 0}}
        icon={!loading ? <Delete sx={{fontSize: 24}} color="primary"/> : <CircularProgress size={24} />}
        onClick={onClick}
        label="View"
        disabled={loading}
    />
);
