import * as React from "react";
import {useModal} from "../../../../hooks/use_modal";
import {useForm} from "../../../../hooks/use_form_v2";
import {Box, Button, Typography} from "@mui/material";
import {Form} from "../../../Components/Form/Form/Form";
import {FormModal} from "../../../Components/FormModal";
import {ILocation} from "../../../../config/location";
import {RadioField} from "../../../Components/Form/RadioField";
import {LOCATION_VENDOR} from "../config";
import {mergeLocations} from "../../stores/locations";

export interface IMergeActionFormState {
    main: string;
}

interface IMergeActionProps {
    locations: ILocation[];
    onSuccess(mainId: number, mergedIds: number[]): void;
}

export const MergeAction = ({locations, onSuccess}: IMergeActionProps) => {
    const { loading, open, handleModalOpen, handleModalClose } = useModal();
    const form = useForm<IMergeActionFormState>();
    const { register } = form;

    const handleSubmit = async (data: IMergeActionFormState): Promise<any> => {
        const mainId = parseInt(data.main);
        const mergeIds = locations.filter(l => l.id! !== mainId).map(l => l.id!);

        await mergeLocations(mainId, mergeIds);

        onSuccess(mainId, mergeIds);

        handleModalClose();

        return await Promise.resolve();
    };

    return (
        <>
            <Button variant="contained" color="primary" onClick={handleModalOpen}>
                Merge
            </Button>

            <FormModal
                title="Merge transactions"
                loading={loading}
                open={open}
                onClose={handleModalClose}
            >
                <Form form={form} onSubmit={handleSubmit}>
                    <Form.Field
                        {...register('main')}
                        type={RadioField}
                        label="Select main location"
                        options={locations}
                        optionValueGetter={(o: any) => o.id!}
                        optionLabelGetter={(o: any) => (
                            <Box>
                                <div>
                                    <strong>{o.id!}</strong>{' - '}
                                    <Typography component="span">{o.name}</Typography>{' '}
                                    <Typography component="span" variant="subtitle2">({(LOCATION_VENDOR as any)[o.vendor]})</Typography>
                                </div>
                                {!!o.logo && (
                                    <div style={{fontSize: 12}}>
                                        Has logo
                                    </div>
                                )}
                            </Box>
                        )}
                    />
                </Form>
            </FormModal>
        </>
    );
};
