import {action, computed, makeObservable, observable} from "mobx";
import { AccessToken } from "../config/auth";

// @todo think about this for clearing the token when the browser window closes
// https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage

const TOKEN_CACHE_KEY = 'em:user:access_token';
const REMEMBER_ME_CACHE_KEY = 'em:user:access_token:remember_me';

export interface IAuthRequest {
    email: string;
    password: string;
}

export interface IAuthSuccessfulResponse {
    access_token: string;
}

export class AuthStore {
    @observable accessTokenString: string | null = null;
    @observable accessToken: AccessToken | null = null;
    rememberMe: boolean = false;

    constructor() {
        makeObservable(this)

        const rememberMe = window.localStorage.getItem(REMEMBER_ME_CACHE_KEY) || null;
        if (!!rememberMe) {
            this.rememberMe = JSON.parse(rememberMe);
        }

        if (this.rememberMe) {
            this.updateAccessToken(AuthStore.getStoredAccessToken() || '');
        }
    }

    static getStoredAccessToken(): string | null {
        return window.localStorage.getItem(TOKEN_CACHE_KEY) || null;
    }

    setRememberMe(rememberMe: boolean) {
        window.localStorage.setItem(REMEMBER_ME_CACHE_KEY, JSON.stringify(rememberMe));

        this.rememberMe = rememberMe;
    }

    @action
    setAccessToken(accessToken: string) {
        window.localStorage.setItem(TOKEN_CACHE_KEY, accessToken);

        this.updateAccessToken(accessToken);
    }

    @action
    clear() {
        window.localStorage.removeItem(TOKEN_CACHE_KEY);

        this.accessTokenString = null;
        this.accessToken = null;
    }

    @computed get isAccessTokenValid(): boolean {
        if (!this.accessToken) {
            return false;
        }

        return this.accessToken.isExpired();
    }

    private updateAccessToken(accessToken: string) {
        if (accessToken === '') {
            return;
        }

        this.accessTokenString = accessToken;
        this.accessToken = new AccessToken(this.accessTokenString);
    }
}

const authUser = (data: IAuthRequest): Promise<IAuthSuccessfulResponse> => {
    return fetch(process.env.REACT_APP_API_HOST + '/auth/token', {
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json',
        },
        method: 'POST',
        body: JSON.stringify(data),
    })
        .then(response => {
            if (response.status === 200) {
                return response.json();
            }

            return Promise.reject(response.json());
        });
};

export {authUser}
