import * as React from "react";
import { AddNewButton } from "./AddNewButton";
import { Box, Button } from "@mui/material";
import { CallbackAction, ICallbackAction, options } from "../../../../../config/user_payment_method_callback";
import { useForm } from "../../../../../hooks/use_form_v2";
import { SelectField } from "../../../../Components/Form/SelectField";
import { ShareWithFriendsPercentage } from "./Actions/ShareWithFriendsPercentage";
import { Details } from "./Details";
import { useModal } from "../../../../../hooks/use_modal";
import { Form } from "../../../../Components/Form/Form/Form";

interface IItemFormProps {
    value?: ICallbackAction;
    onChange(data: ICallbackAction | null): void;
}

export const ItemForm = ({value, onChange}: IItemFormProps) => {
    const { open, handleModalOpen, handleModalClose } = useModal();
    const form = useForm<ICallbackAction>(value || {
        action: '' as any,
        payload: '',
    });
    const { register, watch } = form;

    const handleDelete = () => {
        onChange(null);
    };

    const handleSubmit = (values: ICallbackAction) => {
        onChange(values);
        handleModalClose();

        return Promise.resolve();
    };

    if (!!value && !open) {
        return (
            <Details value={value} onEdit={handleModalOpen} onDelete={handleDelete} />
        );
    }

    if (!value && !open) {
        return (
            <AddNewButton onClick={handleModalOpen} />
        );
    }

    return (
        <Box sx={{marginTop: !!value ? 2 : 0}}>
            <Form form={form} onSubmit={handleSubmit}>
                <Form.Field
                    type={SelectField}
                    {...register('action')}
                    options={options}
                    optionValueGetter={(o: any) => o.key}
                    optionLabelGetter={(o: any) => o.label}
                />

                {watch('action') === CallbackAction.SHARE_WITH_FRIENDS_PERCENTAGE && <Form.Field type={ShareWithFriendsPercentage} {...register('payload')} />}

                <Form.Buttons position="beforeSubmit">
                    <Button
                        onClick={handleModalClose}
                        variant="contained"
                        sx={{marginRight: (theme) => theme.spacing(2)}}
                    >
                        Cancel
                    </Button>
                </Form.Buttons>
            </Form>
        </Box>
    )
};
