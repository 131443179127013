import * as React from "react";
import {styled} from "@mui/material";

export const ListContainer = styled('ul')(() => ({
    margin: 0,
    padding: 0,
    listStyle: 'none',
}));

export const Item = styled('li')(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: 'solid 1px',
    borderBottomColor: theme.palette.divider,

    '&:first-of-type': {
        paddingTop: 0,
    },

    '&:last-of-type': {
        paddingBottom: 0,
        borderBottom: 0,
    },
}));

export const Label = styled('div')(({theme}) => ({
    width: 250,
    marginRight: theme.spacing(1),

    [theme.breakpoints.down('sm')]: {
        width: 200,
    },
}));

export const Value = styled('div')(({theme}) => ({
    maxWidth: 100,
    marginRight: theme.spacing(1),
    fontWeight: 'bold',
}));

interface IListItemProps {
    label: string;
    value: any;
}

// classes on Label and Value components are used to modify the size from outside
export const ListItem = ({label, value}: IListItemProps) => (
    <Item>
        <Label className="details-list-item-label">{label}</Label>
        <Value className="details-list-item-value">{value}</Value>
    </Item>
)
